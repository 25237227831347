import { Button, Col, Layout, Row, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import { contentTitle } from '../../style/page';
import { defaultPage, Page, Query } from '../../store';
import { useAxios } from '../../hook/axios';
import { BulkOrderContactListState } from '../../store/order/bulk.order.contact.state';
import { BulkOrderContactDetailPopup, BulkOrderContactDetailPopupProps } from './BulkOrderContactDetailPopup';

const initQuery: Query = {
  page: 1,
  size: 10,
};

const initPage = defaultPage as Page<BulkOrderContactListState>;

export const BulkOrderContactListPage = (): JSX.Element => {
  const [query, setQuery] = useState<Query>(initQuery);
  const [pageQuery, setPageQuery] = useState<Query>(initQuery);

  const [page, setPage] = useState<Page<BulkOrderContactListState>>(initPage);

  const { result, fetch } = useAxios<Query, Page<BulkOrderContactListState>>({
    url: '/api/admin/bulk/orders/contact',
    method: 'get',
    params: query,
  });

  const [editPopup, setEditPopup] = useState<BulkOrderContactDetailPopupProps>({
    open: false,
    contactId: undefined,
    close: () => setEditPopup({ ...editPopup, open: false, contactId: undefined }),
    reload: fetch,
  });

  useEffect(() => {
    setPageQuery({ ...query });
    fetch();
  }, []);

  useEffect(() => {
    if (!!result) {
      setPage(result);
    } else {
      setPage(initPage);
    }
  }, [result]);

  const columns: ColumnsType<BulkOrderContactListState> = [
    {
      title: '문의 ID',
      dataIndex: 'bulkOrderContactId',
      key: 'bulkOrderContactId',
      width: 100,
      align: 'center',
      render: (value: number) => (
        <Button type="link" onClick={() => setEditPopup({ ...editPopup, open: true, contactId: value })}>
          {value}
        </Button>
      ),
    },
    {
      title: '문의일시',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      align: 'center',
      ellipsis: true,
      render: (value) => (!!value ? value : '-'),
    },
    {
      title: '사용여부',
      dataIndex: 'enabled',
      key: 'enabled',
      width: 100,
      align: 'center',
      ellipsis: true,
      render: (value) => (value ? '미사용' : '사용'),
    },
    {
      title: '문의자 이름',
      dataIndex: 'bulkOrderContactName',
      key: 'bulkOrderContactName',
      width: 150,
      align: 'center',
      ellipsis: true,
    },
    {
      title: '문의자 연락처',
      dataIndex: 'bulkOrderContactPhoneNo',
      key: 'bulkOrderContactPhoneNo',
      width: 150,
      align: 'center',
      ellipsis: true,
      render: (value) => (!!value ? value : '-'),
    },
    {
      title: '문의자 이메일',
      dataIndex: 'bulkOrderContactEmail',
      key: 'bulkOrderContactEmail',
      width: 150,
      align: 'center',
      ellipsis: true,
      render: (value) => (!!value ? value : '-'),
    },
    {
      title: '문의자 기업 단체명',
      dataIndex: 'bulkOrderContactOrgName',
      key: 'bulkOrderContactOrgName',
      width: 300,
      align: 'center',
      ellipsis: true,
      render: (value) => (!!value ? value : '-'),
    },
    {
      title: '문의 확인여부',
      dataIndex: 'bulkOrderContactChecked',
      key: 'bulkOrderContactChecked',
      width: 150,
      align: 'center',
      render: (value) => (value ? '확인' : <span style={{ color: 'red' }}>미확인</span>),
    },
    {
      title: '문의 확인일',
      dataIndex: 'bulkOrderContactCheckedAt',
      key: 'bulkOrderContactCheckedAt',
      width: 200,
      align: 'center',
      render: (value) => (!!value ? value : '-'),
    },
    {
      title: '문의 확인자',
      dataIndex: 'bulkOrderContactCheckedBy',
      key: 'bulkOrderContactCheckedBy',
      width: 100,
      align: 'center',
      render: (value) => (!!value ? value : '-'),
    },
  ];

  const handlePagination = (page: number, pageSize: number) => {
    const newPageQuery = {
      ...query,
      ['page']: page,
      ['size']: pageSize,
    };
    // page만 변경
    setPageQuery(newPageQuery);
    fetch(newPageQuery);
  };

  return (
    <Layout style={{ minWidth: '1200px' }}>
      <Row style={{ ...contentTitle }}>
        <Typography.Title level={5} style={{ margin: '0' }}>
          대량 주문 문의
        </Typography.Title>
      </Row>

      <Row style={{ padding: '1rem' }}>
        <Col xs={24} style={{ marginTop: '2rem', marginBottom: '-.5rem' }}>
          <Row justify="space-between"></Row>
        </Col>
        <Col xs={24} style={{ marginTop: '.5rem' }}>
          <Table
            style={{ width: '2000px', overflow: 'hidden', padding: 10 }}
            rowKey="bulkOrderContactId"
            dataSource={page.contents}
            columns={columns}
            scroll={{ x: 800, y: 600 }}
            pagination={{
              position: ['bottomCenter'],
              pageSize: pageQuery.size,
              defaultPageSize: query.size,
              current: pageQuery.page,
              defaultCurrent: query.page,
              total: page.totalElements,
              onChange: (page, pageSize) => handlePagination(page, pageSize),
            }}
          />
        </Col>
      </Row>

      <BulkOrderContactDetailPopup {...editPopup} />
    </Layout>
  );
};
