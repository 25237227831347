import { SortableContainer, SortableContainerProps, SortableElement, SortableHandle, SortEnd } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import { Space, Table, Tag, Typography } from 'antd';
import { arrayMoveImmutable } from 'array-move';
import './ProductCategoryDragTable.css';
import { Category, CategoryType } from '../../../store/product/category.state';
import { useRecoilState } from 'recoil';
import { categoryListState } from '../../../store/product/category.store';
import { cloneDeep, findIndex, findLastIndex } from 'lodash';

export interface ProductCategoryDragTableProps {
  type: CategoryType;
  data: Array<Category>;
  parent?: Category;
  editPopupFn: (category: Category) => void;
}

export const ProductCategoryDragTable = (props: ProductCategoryDragTableProps): JSX.Element => {
  const [categories, setCategories] = useRecoilState(categoryListState);

  const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
  const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />);
  const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />);

  const [dataSource, setDataSource] = useState<Array<Category>>([]);
  const columns: ColumnsType<Category> = [
    {
      dataIndex: 'id',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: '카테고리명',
      dataIndex: 'categoryName',
      width: 200,
      className: 'drag-visible',
      render: (value, record) => (
        <Space>
          {record.enabled ? <Tag color="blue">사용</Tag> : <Tag>미사용</Tag>}
          <Typography.Link onClick={() => props.editPopupFn(record)}>{value}</Typography.Link>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (!!props.data) {
      setDataSource(
        props.data.map((item) => ({
          ...item,
          children: undefined,
        })),
      );
    }
  }, [props.data]);

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter((el: any) => !!el);

      const newCategories = newData.map((category, index) => ({
        ...category,
        categorySeq: index + 1,
      }));

      const startIndex = findIndex(categories, (category) => {
        return category.categoryType === props.type;
      });
      const lastIndex = findLastIndex(categories, (category) => {
        return category.categoryType === props.type;
      });

      const copyOriginal = cloneDeep(categories);
      copyOriginal.splice(startIndex, lastIndex - startIndex + 1, ...newCategories);

      setCategories(copyOriginal);
    }
  };

  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  const DraggableBodyRow: React.FC<any> = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex((x) => {
      return x.categoryId === restProps['data-row-key'];
    });

    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <>
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        rowKey="categoryId"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
    </>
  );
};
