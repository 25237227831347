import { Checkbox, Col, Input, message, Modal, Row, Space, Switch } from 'antd';
import { useSetRecoilState } from 'recoil';
import { UserState, userStateSchema } from '../../store/system/user.state';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { DaumAddressPopupStore } from '../../store/component';
import { AuthorityState } from '../../store/system/authority.state';
import { Address } from 'react-daum-postcode';
import { useAxios } from '../../hook/axios';
import { LabeledValue } from 'antd/es/select';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import confirm from 'antd/es/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import * as yup from 'yup';
import { ValidUtils } from '../../utils/ValidUtils';
import Button from 'antd-button-color';
import { ErrorMessage } from '../../components/ErrorMessage';

export interface UserEditPopupProps {
  userId?: number;
  open: boolean;
  close: Function;
  reloadFn: Function;
}

const initUser: UserState = {
  userEmail: '',
  userPassword: '',
  userName: '',
  userLocked: false,
  enabled: true,
  deleted: false,
  userZipcode: '',
  userAddress: '',
  userAddressDetail: '',
  userCellphoneNo: '',
  userMileage: 0,
  enableReceiveEmail: false,
  enableReceiveMessage: false,
  authorities: [],
};

export const UserEditPopup = (props: UserEditPopupProps): JSX.Element => {
  const [user, setUser] = useState<UserState>({
    ...initUser,
  });

  useEffect(() => {
    setValidation();
  }, [user]);

  const { result: userResult, fetch: userFetch } = useAxios<void, UserState>({
    url: `/api/admin/users/${props.userId}`,
    method: 'get',
  });

  const { result: authorities, fetch: authorityFetch } = useAxios<void, Array<AuthorityState>>({
    url: '/api/admin/authorities',
    method: 'get',
  });

  const [labelValueAuthorities, setLabelValueAuthorities] = useState<Array<LabeledValue>>([]);

  useEffect(() => {
    authorityFetch();
  }, []);

  useEffect(() => {
    if (!!props.userId) {
      userFetch();
    } else {
      setUser({ ...initUser });
    }
  }, [props]);

  useEffect(() => {
    if (!!userResult) {
      setUser(userResult);
    } else {
      setUser({
        ...initUser,
      });
    }
  }, [userResult]);

  useEffect(() => {
    if (!!authorities) {
      setLabelValueAuthorities(
        authorities.map((value) => ({
          label: value.roleName,
          value: value.roleId,
        })),
      );
    }
  }, [authorities]);

  const title = () => {
    return !!props.userId ? '사용자 추가' : '사용자 수정';
  };

  const handleUserCellphoneNo = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const data = !!value ? value.replace(/\D/g, '') : '';
    let userCellphoneNo: string = '';
    if (data.length < 4) {
      userCellphoneNo = data;
    } else if (data.length > 3 && data.length < 8) {
      userCellphoneNo = data.replace(/([\d]{3})([\d]{1,4})/, '$1-$2');
    } else if (data.length === 8 || data.length === 9) {
      userCellphoneNo = data.replace(/([\d]{3})([\d]{3,4})([\d]{1,2})/, '$1-$2-$3');
    } else if (data.length === 11 || data.length === 10) {
      userCellphoneNo = data.replace(/([\d]{3})([\d]{3,4})([\d]{4})/, '$1-$2-$3');
    }

    setUser({
      ...user,
      ['userCellphoneNo']: userCellphoneNo,
    });
  };

  const setZipcode = useSetRecoilState(DaumAddressPopupStore);
  const searchAddress = () => {
    setZipcode({ open: true, callback: selectedAddress });
  };

  const selectedAddress = (address: Address) => {
    setUser({
      ...user,
      ['userZipcode']: address.zonecode,
      ['userAddress']: address.address,
    });
  };

  const getUserAuthorities = (data: Array<AuthorityState>): Array<string> => {
    return data.map((value) => value.roleId);
  };

  const setUserAuthorities = (checkedValue: Array<CheckboxValueType>) => {
    setUser({
      ...user,
      ['authorities']: checkedValue.map((value: CheckboxValueType) => ({ roleId: value as string, roleName: '', enabled: true })),
    });
  };

  const { fetch: create } = useAxios<UserState, void>({
    url: '/api/admin/users',
    method: 'post',
  });

  const { fetch: update } = useAxios<UserState, void>({
    url: '/api/admin/users',
    method: 'put',
  });

  const save = () => {
    confirm({
      title: '사용자 정보를 저장하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: '예',
      cancelText: '아니오',
      type: 'error',
      onOk() {
        if (!!props.userId) {
          update({
            ...user,
          })
            .then(() => {
              message.success('사용자 정보가 수정되었습니다.');
              props.reloadFn();
              props.close();
            })
            .catch(() => {
              message.error('사용자 정보 수정에 실패하였습니다.');
            });
        } else {
          create({
            ...user,
          })
            .then(() => {
              message.success('사용자 정보가 생성되었습니다.');
              props.reloadFn();
              props.close();
            })
            .catch(() => {
              message.error('사용자 정보 생성에 실패하였습니다.');
            });
        }
      },
    });
  };

  const [isValid, setValid] = useState<boolean>(false);
  const schema: yup.SchemaOf<UserState> = userStateSchema;
  const isEmail = ValidUtils.isValid(schema, 'userEmail', user.userEmail);
  const isPassword = ValidUtils.isValid(schema, 'userPassword', user.userPassword);
  const isName = ValidUtils.isValid(schema, 'userName', user.userName);
  const isCellPhoneNo = ValidUtils.isValid(schema, 'userCellphoneNo', user.userCellphoneNo);
  const isZipcode = ValidUtils.isValid(schema, 'userZipcode', user.userZipcode);
  const isAddress = ValidUtils.isValid(schema, 'userAddress', user.userAddress);
  const isAddressDetail = ValidUtils.isValid(schema, 'userAddressDetail', user.userAddressDetail);
  const isAuthorities = ValidUtils.isValid(schema, 'authorities', user.authorities);

  const setValidation = () => {
    const changeData = {
      ...user,
      userPassword: !!props.userId ? 'password' : user.userPassword,
    };

    const result = schema.isValidSync(changeData);

    setValid(result && (isPassword || !!props.userId));
  };

  return (
    <Modal title={title()} centered visible={props.open} width={410} footer={null} onCancel={() => props.close()}>
      <Row gutter={[0, 10]}>
        <Col xs={24}>
          <Space>
            <label style={{ width: '100px', display: 'inline-block' }}>사용자 아이디</label>
            <Input
              style={{ width: '250px' }}
              name="userEmail"
              value={user.userEmail}
              onChange={({ target: { name, value } }) => setUser({ ...user, [name]: value })}
              disabled={!!props.userId}
            />
          </Space>
          {!isEmail && <ErrorMessage paddingLeft="110px" marginTop="5px" message="아이디를 입력해주세요." />}
        </Col>
        <Col xs={24}>
          <Space>
            <label style={{ width: '100px', display: 'inline-block' }}>비밀번호</label>
            <Input.Password
              style={{ width: '250px' }}
              name="userPassword"
              value={!!props.userId ? 'password' : user.userPassword}
              onChange={({ target: { name, value } }) => setUser({ ...user, [name]: value })}
              disabled={!!props.userId}
            />
          </Space>
          {!isPassword && !props.userId && <ErrorMessage paddingLeft="110px" marginTop="5px" message="비밀번호를 입력해주세요." />}
        </Col>

        <Col xs={24}>
          <Space>
            <label style={{ width: '100px', display: 'inline-block' }}>이름</label>
            <Input
              style={{ width: '250px' }}
              name="userName"
              value={user.userName}
              onChange={({ target: { name, value } }) => setUser({ ...user, [name]: value })}
            />
          </Space>
          {!isName && <ErrorMessage paddingLeft="110px" marginTop="5px" message="이름을 입력해주세요." />}
        </Col>

        <Col xs={24}>
          <Space>
            <label style={{ width: '100px', display: 'inline-block' }}>휴대전화번호</label>
            <Input style={{ width: '250px' }} name="userCellphoneNo" value={user.userCellphoneNo} onChange={handleUserCellphoneNo} />
          </Space>
          {!isCellPhoneNo && <ErrorMessage paddingLeft="110px" marginTop="5px" message="휴대전화번호를 입력해주세요." />}
        </Col>
        <Col xs={24}>
          <Space>
            <label style={{ width: '100px', display: 'inline-block' }}>우편번호</label>
            <Input.Search
              style={{ width: '250px' }}
              name="userZipcode"
              value={user.userZipcode}
              maxLength={13}
              readOnly={true}
              enterButton="우편번호찾기"
              onSearch={() => searchAddress()}
            />
          </Space>
          {!isZipcode && <ErrorMessage paddingLeft="110px" marginTop="5px" message="우편번호를 입력해주세요." />}
        </Col>

        <Col xs={24}>
          <Space>
            <label style={{ width: '100px', display: 'inline-block' }}>주소</label>
            <Input style={{ width: '250px' }} name="userAddress" value={user.userAddress} readOnly={true} maxLength={255} />
          </Space>
          {!isAddress && <ErrorMessage paddingLeft="110px" marginTop="5px" message="주소를 입력해주세요." />}
        </Col>

        <Col xs={24}>
          <Space>
            <label style={{ width: '100px', display: 'inline-block' }}>주소 상세</label>
            <Input
              style={{ width: '250px' }}
              name="userAddressDetail"
              value={user.userAddressDetail}
              maxLength={255}
              onChange={({ target: { name, value } }) => setUser({ ...user, [name]: value })}
            />
          </Space>
          {!isAddressDetail && <ErrorMessage paddingLeft="110px" marginTop="5px" message="주소 상세를 입력해주세요." />}
        </Col>
        <Col xs={24}>
          <Space>
            <label style={{ width: '100px', display: 'inline-block' }}>권한선택</label>
            <Checkbox.Group
              style={{ width: '250px' }}
              name="authorities"
              value={getUserAuthorities(user.authorities)}
              options={labelValueAuthorities}
              onChange={setUserAuthorities}
            />
          </Space>
          {!isAuthorities && <ErrorMessage paddingLeft="110px" marginTop="5px" message="권한을 선택해주세요." />}
        </Col>
        <Col xs={24}>
          <Space>
            <label htmlFor="enableReceiveEmail" style={{ width: '100px', display: 'inline-block' }}>
              이메일 알림 받기
            </label>
            <div style={{ width: '80px' }}>
              <Switch
                id="enableReceiveEmail"
                checked={user.enableReceiveEmail}
                onChange={(checked) => setUser({ ...user, ['enableReceiveEmail']: checked })}
              />
            </div>
            <label htmlFor="enableReceiveMessage" style={{ width: '100px', display: 'inline-block' }}>
              SMS 알림 받기
            </label>
            <div style={{ width: '80px' }}>
              <Switch
                id="enableReceiveMessage"
                checked={user.enableReceiveMessage}
                onChange={(checked) => setUser({ ...user, ['enableReceiveMessage']: checked })}
              />
            </div>
          </Space>
        </Col>
        <Col xs={24}>
          <Space>
            <label htmlFor="enabled" style={{ width: '100px', display: 'inline-block' }}>
              사용여부
            </label>
            <div style={{ width: '80px' }}>
              <Switch id="enabled" checked={user.enabled} onChange={(checked) => setUser({ ...user, ['enabled']: checked })} />
            </div>
            <label htmlFor="userLocked" style={{ width: '100px', display: 'inline-block' }}>
              잠김여부
            </label>
            <div style={{ width: '80px' }}>
              <Switch id="userLocked" checked={user.userLocked} onChange={(checked) => setUser({ ...user, ['userLocked']: checked })} />
            </div>
          </Space>
        </Col>
        <Col xs={24}>
          <Row justify="end">
            <Space>
              <Col>
                <Button type="danger" onClick={() => props.close()}>
                  취소
                </Button>
              </Col>
              <Col>
                <Button type="primary" onClick={() => save()} disabled={!isValid}>
                  저장
                </Button>
              </Col>
            </Space>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};
