import { Button, Col, Collapse, Input, message, Row, Space, Table } from 'antd';
import React, { useState } from 'react';
import { OrderDetailMemoState } from '../../../store/order/order.state';
import { ColumnsType } from 'antd/lib/table/interface';
import confirm from 'antd/es/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useAxios } from '../../../hook/axios';

interface OrderDetailMemoComponentProps {
  orderMemos: Array<OrderDetailMemoState>;
  orderId: number;
  reloadFn: Function;
}

export const OrderDetailMemoComponent = (props: OrderDetailMemoComponentProps): JSX.Element => {
  const orderMemos = props.orderMemos;
  const [contents, setContents] = useState<string>('');

  const { fetch: memo } = useAxios({
    url: `/api/admin/orders/${props.orderId}/comment`,
    method: 'post',
    data: { content: contents },
  });

  const columns: ColumnsType<OrderDetailMemoState> = [
    { title: '내용', dataIndex: 'orderChargerMemoContents', key: 'orderChargerMemoContents', width: 300, align: 'center' },
    { title: '담당자', dataIndex: 'orderChargerMemoCreatedBy', key: 'orderChargerMemoCreatedBy', width: 60, align: 'center' },
    { title: '일시', dataIndex: 'orderChargerMemoCreatedAt', key: 'orderChargerMemoCreatedAt', width: 100, align: 'center' },
  ];
  const handleMemo = () => {
    confirm({
      title: '메모를 저장하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: '예',
      cancelText: '아니오',
      type: 'error',
      onOk() {
        memo()
          .then(() => {
            message.success('메모가 저장되었습니다.');
            setContents('');
            props.reloadFn();
          })
          .catch(() => message.error('메모 저장 중 오류가 발생했습니다.'));
      },
    });
  };

  return (
    <Collapse defaultActiveKey={['order_detail_memo']} expandIconPosition="end" style={{ margin: '1rem 0' }}>
      <Collapse.Panel key={'order_detail_memo'} header={'담당자 메모'}>
        <Row gutter={[8, 8]}>
          {orderMemos.length > 0 ? (
            <Col xs={24}>
              <Table bordered={true} dataSource={orderMemos} columns={columns} pagination={false} />
            </Col>
          ) : (
            <Col xs={24}>조회된 데이터가 없습니다.</Col>
          )}
          <Col xs={24} style={{ marginTop: '2rem' }}>
            <Space>
              <Input
                name="content"
                placeholder={'내용을 입력 해 주세요.'}
                value={contents}
                onChange={({ target: { value } }) => setContents(value)}
                style={{ width: '250px' }}
              />
              <Button type="primary" htmlType="submit" onClick={() => handleMemo()}>
                저장
              </Button>
            </Space>
          </Col>
        </Row>
      </Collapse.Panel>
    </Collapse>
  );
};
