import { Card, Select } from 'antd';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useAxios } from '../../hook/axios';

interface ProductSelectProps {
  value?: number;
  setValue: Function;

  style?: CSSProperties;
}

interface SelectType {
  label: string;
  value: number;
}

export const ProductSelect = (props: ProductSelectProps): JSX.Element => {
  const [state, setState] = useState<number[]>([]);
  const [search, setSearch] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<number>();
  const [query, setQuery] = useState({
    productName: '',
  });

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (!!props.value) {
      setValue(props.value);
    } else {
      setValue(undefined);
    }
  }, [props.value]);

  useEffect(() => {
    if (!!value) {
      setState([value]);
    } else {
      setState([]);
    }
  }, [value]);

  useEffect(() => {
    setSearch('');
    setOpen(false);
  }, [state]);

  const { result, fetch } = useAxios<any, SelectType[]>({
    url: '/api/admin/products/items',
    params: query,
    method: 'get',
  });

  const getBulkData = state.length === 0 ? [] : [state[0]];
  const getOptions = !!result && result.length > 0 ? result.filter((item) => item.label.includes(search)) : [];

  const handleChange = (value: Array<any>) => {
    if (value.length > 1) {
      value.splice(0, 1);
    }

    setState(value);
    setValue(value[0]);
    setOpen(false);
    props.setValue(value[0]);
  };

  return (
    <Select
      mode="multiple"
      allowClear
      style={{ ...props.style }}
      placeholder="상품을 선택해주세요."
      value={getBulkData}
      open={open}
      filterOption={false}
      onSearch={(value) => {
        setSearch(value);
        if (!!value) {
          setOpen(true);
        }
      }}
      onInputKeyDown={(event) => {
        if (event.key === 'Backspace' && !search) {
          setState([]);
        }
      }}
      onChange={(value) => handleChange(value)}
      onClick={() => setOpen(!open)}
      onBlur={() => setOpen(false)}
      defaultActiveFirstOption={false}
      notFoundContent={null}
    >
      {getOptions.map((data) => (
        <Select.Option key={`${data.value}`} value={data.value}>
          {data.label}
        </Select.Option>
      ))}
    </Select>
  );
};
