import { Button, Col, Layout, message, Row, Space, Table, Typography } from 'antd';
import { contentTitle } from '../../../style/page';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ColumnsType, TableRowSelection } from 'antd/lib/table/interface';
import { RiFileExcel2Fill } from 'react-icons/ri';
import confirm from 'antd/es/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { BulkOrderApplicationCatalog, BulkOrderApplicationListState } from '../../../store/order/bulk.order.state';
import { useAxios } from '../../../hook/axios';
import { Query } from '../../../store';
import { BulkOrderDetailEditPopup, BulkOrderDetailEditPopupProps } from './BulkOrderDetailEditPopup';

const initPage: BulkOrderApplicationCatalog = {
  totalElements: 0,
  orderCount: 0,
  bulkOrderQuantity: 0,
  deliveryWaitCount: 0,
  deliveringCount: 0,
  purchaseDecidedCount: 0,
  contents: [],
};

const initQuery = {
  page: 1,
  size: 10,
};

export const BulkOrderDetailPage = (): JSX.Element => {
  const params = useParams();
  const id = params.id;

  const [bulkOrder, setBulkOrder] = useState({ ...initPage });
  const [query, setQuery] = useState<Query>({ ...initQuery });
  const [pageQuery, setPageQuery] = useState<Query>({ ...initQuery });

  const { result, fetch } = useAxios<Query, BulkOrderApplicationCatalog>({
    url: `/api/admin/bulk/orders/applications/${id}`,
    method: 'get',
    params: query,
  });

  useEffect(() => {
    setPageQuery({ ...query });
    fetch();
  }, []);

  useEffect(() => {
    if (!!result) {
      setBulkOrder({ ...result });
    } else {
      setBulkOrder({ ...initPage });
    }
  }, [result]);

  const [editPopup, setEditPopup] = useState<BulkOrderDetailEditPopupProps>({
    open: false,
    detail: undefined,
    close: () => setEditPopup({ ...editPopup, open: false, detail: undefined }),
    reload: fetch,
  });

  const { fetch: excel } = useAxios<URLSearchParams, any>({
    url: `/api/admin/bulk/orders/xlsx`,
    method: 'get',
  });

  const handleExcel = () => {
    confirm({
      title: '엑셀다운로드',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: '예',
      cancelText: '아니오',
      type: 'error',
      onOk() {
        const params = new URLSearchParams();
        params.append('bulkOrderId', id!);
        excel(params)
          .then((result) => {
            const a = document.createElement('a');
            a.href = `data:${result.mimeType};base64,${result.data}`;
            a.download = result.filename; //File name Here
            a.click(); //Downloaded file
            a.remove();
            message.info('엑셀 다운로드가 완료되었습니다.');
          })
          .catch(() => {
            message.error('엑셀 다운로드에 실패했습니다.');
          });
      },
    });
  };

  const handlePagination = (page: number, pageSize: number) => {
    const newPageQuery = {
      ...query,
      ['page']: page,
      ['size']: pageSize,
    };
    // page만 변경
    setPageQuery(newPageQuery);
    fetch(newPageQuery);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const rowSelection: TableRowSelection<BulkOrderApplicationListState> = {
    selectedRowKeys: selectedRowKeys,
    type: 'checkbox',
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const columns: ColumnsType<BulkOrderApplicationListState> = [
    {
      title: '주문번호',
      dataIndex: 'orderNo',
      key: 'orderNo',
      width: 300,
      align: 'center',
      ellipsis: true,
      render: (value, record) => (
        <Button type="link" onClick={() => setEditPopup({ ...editPopup, open: true, detail: record })}>
          {value}
        </Button>
      ),
    },
    {
      title: '주문일시',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      align: 'center',
    },
    {
      title: '주문자',
      dataIndex: 'purchaserName',
      key: 'purchaserName',
      width: 150,
      align: 'center',
    },
    {
      title: '주문상태',
      dataIndex: 'orderStatusType',
      key: 'orderStatusType',
      width: 150,
      align: 'center',
    },
    {
      title: '받는사람',
      dataIndex: 'destRecipientName',
      key: 'destRecipientName',
      width: 150,
      align: 'center',
    },
    {
      title: '연락처',
      dataIndex: 'destRecipientCellphoneNo',
      key: 'destRecipientCellphoneNo',
      width: 200,
      align: 'center',
    },
    {
      title: '배송지',
      width: 450,
      align: 'center',
      render: (value, record) => `(${record.destZipcode}) ${record.destAddress} ${record.destAddressDetail}`,
    },
  ];

  const { fetch: deletion } = useAxios<URLSearchParams, void>({
    url: '/api/admin/orders',
    method: 'delete',
  });

  const deleteForSelected = () => {
    if (selectedRowKeys.length === 0) {
      message.error('한 건 이상의 주문을 선택해주세요.');
      return;
    }

    confirm({
      title: '선택된 주문을 삭제하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: '예',
      cancelText: '아니오',
      type: 'error',
      onOk() {
        const urlSearchParams = new URLSearchParams();
        selectedRowKeys.forEach((id) => urlSearchParams.append('id', String(id)));

        deletion(urlSearchParams)
          .then(() => {
            message.success('선택된 주문이 삭제하였습니다.');
            fetch();
          })
          .catch(() => {
            message.error('선택된 주문 삭제에 실패하였습니다.');
          });
      },
    });
  };

  return (
    <Layout style={{ minWidth: '1200px' }}>
      <Row style={{ ...contentTitle }}>
        <Typography.Title level={5} style={{ margin: '0' }}>
          대량 주문 상세 리스트
        </Typography.Title>
      </Row>
      <Row style={{ padding: '1rem' }} gutter={[0, 20]}>
        <Col xs={24}>
          <Row gutter={[20, 0]}>
            <Col>총 수량 : {bulkOrder.bulkOrderQuantity}</Col>
            <Col>주문 : {`${bulkOrder.orderCount}/${bulkOrder.bulkOrderQuantity}`}</Col>
            <Col>발송대기 : {bulkOrder.deliveryWaitCount}</Col>
            <Col>배송중 : {bulkOrder.deliveringCount}</Col>
            <Col>구매확정 : {bulkOrder.purchaseDecidedCount}</Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row justify="space-between">
            <Space>
              <Button danger type="primary" onClick={() => deleteForSelected()}>
                선택삭제
              </Button>
            </Space>
            <Space>
              <Col xs={24}>
                <Button type="primary" icon={<RiFileExcel2Fill />} onClick={() => handleExcel()}>
                  엑셀 다운로드
                </Button>
              </Col>
            </Space>
          </Row>
        </Col>
        <Col xs={24}>
          <Table
            style={{ width: '2000px', overflow: 'hidden' }}
            rowKey="orderId"
            rowSelection={rowSelection}
            dataSource={bulkOrder.contents}
            columns={columns}
            scroll={{ x: 800, y: 600 }}
            pagination={{
              position: ['bottomCenter'],
              pageSize: pageQuery.size,
              defaultPageSize: query.size,
              current: pageQuery.page,
              defaultCurrent: query.page,
              onChange: (page, pageSize) => handlePagination(page, pageSize),
            }}
          />
        </Col>
      </Row>
      <BulkOrderDetailEditPopup {...editPopup} />
    </Layout>
  );
};
