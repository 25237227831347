import { Col, Collapse, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { UploadImages } from '../../../components/UploadImages';
import { UploadFile } from 'antd/es/upload/interface';
import { ImageState } from '../../../store';
import confirm from 'antd/es/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ProductEditProps } from '../ProductEditPage';
import { useAxios } from '../../../hook/axios';

export const ProductImageFragment = (props: ProductEditProps): JSX.Element => {
  const [product, setProduct] = [props.product, props.setProduct];
  const [images, setImages] = useState<Array<ImageState>>([]);

  const { fetch: deletion } = useAxios<any, void>({
    url: '/api/admin/images',
    method: 'delete',
  });

  useEffect(() => {
    if (!!product.images && product.images.length > 0) {
      setImages(product.images);
    } else {
      setImages([]);
    }
  }, [product.images]);

  const handleMainImage = (image: ImageState) => {
    if (images.length === 0 || images.length === 1) {
      setImages([image]);
      setProduct({
        ...product,
        ['images']: [image],
      });
    } else {
      const copy = [...images].splice(0, 1, image);
      setImages(copy);
      setProduct({
        ...product,
        ['images']: copy,
      });
    }
  };

  const handleSubImages = (image: ImageState) => {
    const copy = [...images];
    if (images.length === 0) {
      copy.push({
        imageName: '',
        imageUrl: '',
        imageType: 'NORMAL',
        imagePath: '',
        contentType: '',
        enabled: true,
      });
    }

    copy.push(image);

    setImages(copy);
    setProduct({
      ...product,
      ['images']: copy,
    });
  };

  const handleRemove = (file: UploadFile): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      confirm({
        title: '선택한 이미지를 삭제하시겠습니까?',
        icon: <ExclamationCircleOutlined />,
        okType: 'danger',
        okText: '예',
        cancelText: '아니오',
        type: 'error',
        onOk() {
          if (!!file.url) {
            deletion({ url: file.url })
              .then(() => {
                resolve(true);
              })
              .catch(() => reject(false));
          } else {
            reject(false);
          }
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  const getMainImage = () => {
    if (images.length === 0) {
      return [];
    }

    return [images[0]];
  };

  const getSubImages = () => {
    if (images.length < 2) {
      return [];
    }

    return images.slice(1);
  };

  return (
    <Collapse defaultActiveKey={['product4']} expandIconPosition="right">
      <Collapse.Panel key={'product4'} header={'상품이미지'}>
        <Row>
          <Col xs={24}>
            <Space align="start">
              <label style={{ width: '160px', display: 'inline-block' }}>대표이미지</label>
              <UploadImages value={getMainImage()} onChange={handleMainImage} onRemove={handleRemove} limit={1} />
            </Space>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Space align="start">
              <label style={{ width: '160px', display: 'inline-block' }}>추가이미지</label>
              <UploadImages value={getSubImages()} onChange={handleSubImages} onRemove={handleRemove} limit={9} />
            </Space>
          </Col>
        </Row>
      </Collapse.Panel>
    </Collapse>
  );
};
