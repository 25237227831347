import { atom } from 'recoil';
import { Category } from './category.state';

export const categoryListState = atom<Array<Category>>({
  key: 'categoryListState',
  default: [],
});

export const categoryState = atom<Category>({
  key: 'categoryState',
  default: {
    categoryType: 'LARGE',
    categoryCode: '',
    categorySeq: 0,
    categoryName: '',
    categoryDescription: '',
    enabled: true,
  },
});
