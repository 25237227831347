import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Input, Layout, List, Row, Space, Table, Typography } from 'antd';
import { contentTitle } from '../../style/page';
import { useAxios } from '../../hook/axios';
import { ColumnsType } from 'antd/lib/table/interface';
import { AuctCattlePriceDetail, AuctCattlePriceDetailRequest } from '../../store/ext/auct.cattle.price.detail';

const date: string =
  new Date().getFullYear() +
  (new Date().getMonth() + 1).toString().padStart(2, '0') +
  (new Date().getDate() - 1).toString().padStart(2, '0');

const initQuery: AuctCattlePriceDetailRequest = {
  abattCode: '0809',
  startYmd: date,
  endYmd: date,
  breedCd: '024001',
  sexCd: '025001',
  defectIncludeYn: 'Y',
};

export const AuctCattlePriceDetailSearch = (): JSX.Element => {
  const [query, setQuery] = useState<AuctCattlePriceDetailRequest>({ ...initQuery });
  const [pageQuery, setPageQuery] = useState<AuctCattlePriceDetailRequest>({ ...initQuery });

  const { result: page, fetch: listFetch } = useAxios<AuctCattlePriceDetailRequest, Array<AuctCattlePriceDetail>>({
    url: 'api/v1/ekape/auct/cattle/price/detail',
    method: 'post',
    params: query,
  });

  const handleSearch = () => {
    setPageQuery({ ...query });
    listFetch(query);
  };

  const columns: ColumnsType<AuctCattlePriceDetail> = [
    {
      title: '등급 구분',
      dataIndex: 'gradeType',
      key: 'gradeType',
      width: 100,
      align: 'center',
      render: (gradeType: string) => <>{!!gradeType ? gradeType : '-'}</>,
    },
    {
      title: '시작일',
      dataIndex: 'startYmd',
      key: 'startYmd',
      width: 100,
      align: 'center',
      render: (startYmd: string) => <>{!!startYmd ? startYmd : '-'}</>,
    },
    {
      title: '종료일',
      dataIndex: 'endYmd',
      key: 'endYmd',
      width: 100,
      align: 'center',
      render: (endYmd: string) => <>{!!endYmd ? endYmd : '-'}</>,
    },
    {
      title: '등급 코드',
      dataIndex: 'gradeCd',
      key: 'gradeCd',
      width: 100,
      align: 'center',
      render: (gradeCd: string) => <>{!!gradeCd ? gradeCd : '-'}</>,
    },
    {
      title: '등급명',
      dataIndex: 'gradeNm',
      key: 'gradeNm',
      width: 100,
      align: 'center',
      render: (gradeNm: string) => <>{!!gradeNm ? gradeNm : '-'}</>,
    },
    {
      title: '품종 코드',
      dataIndex: 'judgeBreedCd',
      key: 'judgeBreedCd',
      width: 100,
      align: 'center',
      render: (judgeBreedCd: string) => <>{!!judgeBreedCd ? judgeBreedCd : '-'}</>,
    },
    {
      title: '품종',
      dataIndex: 'judgeBreedNm',
      key: 'judgeBreedNm',
      width: 100,
      align: 'center',
      render: (judgeBreedNm: string) => <>{!!judgeBreedNm ? judgeBreedNm : '-'}</>,
    },
    {
      title: '성별 코드',
      dataIndex: 'judgeSexCd',
      key: 'judgeSexCd',
      width: 100,
      align: 'center',
      render: (judgeSexCd: string) => <>{!!judgeSexCd ? judgeSexCd : '-'}</>,
    },
    {
      title: '성별',
      dataIndex: 'judgeSexNm',
      key: 'judgeSexNm',
      width: 100,
      align: 'center',
      render: (judgeSexNm: string) => <>{!!judgeSexNm ? judgeSexNm : '-'}</>,
    },
    {
      title: '경락 두수',
      dataIndex: 'auctCnt',
      key: 'auctCnt',
      width: 100,
      align: 'center',
      render: (auctCnt: number) => <>{!!auctCnt ? auctCnt : '-'}</>,
    },
    {
      title: '평균 도체중',
      dataIndex: 'weight',
      key: 'weight',
      width: 100,
      align: 'center',
      render: (weight: number) => <>{!!weight ? weight : '-'}</>,
    },
    {
      title: '평군 가격',
      dataIndex: 'auctAmt',
      key: 'auctAmt',
      width: 100,
      align: 'center',
      render: (auctAmt: number) => <>{!!auctAmt ? auctAmt : '-'}</>,
    },
    {
      title: '거래 중량(KG)',
      dataIndex: 'sumWeight',
      key: 'sumWeight',
      width: 100,
      align: 'center',
      render: (sumWeight: number) => <>{!!sumWeight ? sumWeight : '-'}</>,
    },
    {
      title: '거래 대금(원)',
      dataIndex: 'sumAuctAmt',
      key: 'sumAuctAmt',
      width: 100,
      align: 'center',
      render: (sumAuctAmt: number) => <>{!!sumAuctAmt ? sumAuctAmt : '-'}</>,
    },
    {
      title: '최저 단가',
      dataIndex: 'minAuctAmt',
      key: 'minAuctAmt',
      width: 100,
      align: 'center',
      render: (minAuctAmt: number) => <>{!!minAuctAmt ? minAuctAmt : '-'}</>,
    },
    {
      title: '최고 단가',
      dataIndex: 'maxAuctAmt',
      key: 'maxAuctAmt',
      width: 100,
      align: 'center',
      render: (maxAuctAmt: number) => <>{!!maxAuctAmt ? maxAuctAmt : '-'}</>,
    },
  ];

  return (
    <Layout>
      <Row style={{ ...contentTitle }}>
        <Typography.Title level={5} style={{ margin: '0' }}>
          소도체 경락 상세 정보
        </Typography.Title>
      </Row>

      <Row style={{ padding: '1rem' }}>
        <Col xs={24}>
          <Card style={{ width: '100%' }}>
            <Row gutter={[20, 10]}>
              <Col>
                <Space>
                  <label style={{ width: '100px', display: 'inline-block' }}>도매 시장 코드</label>
                  <Input
                    style={{ width: '180px' }}
                    name="abattCode"
                    value={query.abattCode.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
              <Col>
                <Space>
                  <label style={{ width: '100px', display: 'inline-block' }}>경매 시작 일</label>
                  <Input
                    style={{ width: '180px' }}
                    name="startYmd"
                    value={query.startYmd.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
              <Col>
                <Space>
                  <label style={{ width: '100px', display: 'inline-block' }}>경매 종료 일</label>
                  <Input
                    style={{ width: '180px' }}
                    name="endYmd"
                    value={query.endYmd.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
            </Row>

            <Col>
              <Divider />
            </Col>
            <Row gutter={[20, 10]}>
              <Col>
                <Space>
                  <label style={{ width: '100px', display: 'inline-block' }}>판정 품종</label>
                  <Input
                    style={{ width: '180px' }}
                    name="breedCd"
                    value={query.breedCd.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
              <Col>
                <Space>
                  <label style={{ width: '100px', display: 'inline-block' }}>성별 코드</label>
                  <Input
                    style={{ width: '180px' }}
                    name="sexCd"
                    value={query.sexCd.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
              <Col>
                <Space>
                  <label style={{ width: '100px', display: 'inline-block' }}>가격 구분</label>
                  <Input
                    style={{ width: '180px' }}
                    name="defectIncludeYn"
                    value={query.defectIncludeYn.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
            </Row>

            <Col xs={24}>
              <Divider />
            </Col>

            <Row justify="center">
              <Space>
                <Button type="primary" htmlType="submit" onClick={() => handleSearch()}>
                  검색
                </Button>
              </Space>
            </Row>
          </Card>
        </Col>

        <Col xs={24} style={{ marginTop: '2rem' }}>
          <Table
            size="large"
            style={{ width: '100%' }}
            rowKey="auctPriceDetailId"
            dataSource={page}
            columns={columns}
            scroll={{ x: 1400, y: 700 }}
          />
        </Col>
      </Row>
    </Layout>
  );
};
