import { Button, Col, Layout, message, Row, Typography } from 'antd';
import { contentTitle } from '../../../style/page';
import React, { useEffect, useState } from 'react';
import { ProductCategoryDragTable, ProductCategoryDragTableProps } from './ProductCategoryDragTable';
import { ProductCategoryEditPopup, ProductCategoryEditPopupProps } from './ProductCategoryEditPopup';
import { useRecoilState } from 'recoil';
import { categoryListState } from '../../../store/product/category.store';
import { Category } from '../../../store/product/category.state';
import confirm from 'antd/es/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useAxios } from '../../../hook/axios';

export const ProductCategoryListPage = (): JSX.Element => {
  const [categories, setCategories] = useRecoilState(categoryListState);
  const { result, fetch } = useAxios<void, Category[]>({
    url: '/api/admin/products/categories',
    method: 'get',
  });

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (!!result) {
      setCategories(result);
    } else {
      setCategories([]);
    }
  }, [result]);

  useEffect(() => {
    if (!!categories) {
      setLargeCategory({
        ...largeCategory,
        ['data']: categories.filter((category) => category.categoryType === 'LARGE'),
      });

      setMiddleCategory({
        ...middleCategory,
        ['data']: categories.filter((category) => category.categoryType === 'MIDDLE'),
      });

      setSmallCategory({
        ...smallCategory,
        ['data']: categories.filter((category) => category.categoryType === 'SMALL'),
      });
    }
  }, [categories]);

  const [popupProps, setPopupProps] = useState<ProductCategoryEditPopupProps>({
    open: false,
    categoryType: 'LARGE',
    close: () => setPopupProps({ ...popupProps, open: false, data: undefined }),
    reloadFn: fetch,
  });

  const [largeCategory, setLargeCategory] = useState<ProductCategoryDragTableProps>({
    type: 'LARGE',
    data: [],
    editPopupFn: (category) =>
      setPopupProps({
        ...popupProps,
        open: true,
        categoryType: 'LARGE',
        data: category,
      }),
  });

  const [middleCategory, setMiddleCategory] = useState<ProductCategoryDragTableProps>({
    type: 'MIDDLE',
    data: [],
    editPopupFn: (category) =>
      setPopupProps({
        ...popupProps,
        open: true,
        categoryType: 'MIDDLE',
        data: category,
      }),
  });

  const [smallCategory, setSmallCategory] = useState<ProductCategoryDragTableProps>({
    type: 'SMALL',
    data: [],
    editPopupFn: (category) =>
      setPopupProps({
        ...popupProps,
        open: true,
        categoryType: 'SMALL',
        data: category,
      }),
  });

  const { fetch: saveSortingCategories } = useAxios<Category[], undefined>({
    url: '/api/admin/products/categories',
    method: 'put',
  });

  const save = () => {
    confirm({
      title: '현재 보이는 상태로 카테고리 정렬 순서를 변경하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: '예',
      cancelText: '아니오',
      type: 'error',
      onOk() {
        saveSortingCategories(categories)
          .then(() => {
            message.success('카테고리 정렬 순서를 수정하였습니다.');
            fetch();
          })
          .catch(() => {
            message.error('카테고리 정렬 순서 변경에 실패하였습니다.');
          });
      },
    });
  };

  return (
    <Layout>
      <Row style={{ ...contentTitle }}>
        <Typography.Title level={5} style={{ margin: '0' }}>
          상품 카테고리
        </Typography.Title>
      </Row>

      <Row gutter={[32, 0]} style={{ padding: '1rem', paddingRight: '1rem', marginRight: '0' }}>
        <Col xs={8}>
          <Row>
            <Col xs={24} style={{ marginBottom: '.5rem' }}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Typography.Text>대분류</Typography.Text>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => setPopupProps({ ...popupProps, open: true, categoryType: 'LARGE', data: undefined })}
                  >
                    추가
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xs={24}>
              <ProductCategoryDragTable {...largeCategory} />
            </Col>
          </Row>
        </Col>
        <Col xs={8}>
          <Row>
            <Col xs={24} style={{ marginBottom: '.5rem' }}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Typography.Text>중분류</Typography.Text>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => setPopupProps({ ...popupProps, open: true, categoryType: 'MIDDLE', data: undefined })}
                  >
                    추가
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xs={24}>
              <ProductCategoryDragTable {...middleCategory} />
            </Col>
          </Row>
        </Col>
        <Col xs={8}>
          <Row>
            <Col xs={24} style={{ marginBottom: '.5rem' }}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Typography.Text>소분류</Typography.Text>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => setPopupProps({ ...popupProps, open: true, categoryType: 'SMALL', data: undefined })}
                  >
                    추가
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xs={24}>
              <ProductCategoryDragTable {...smallCategory} />
            </Col>
          </Row>
        </Col>
        <Col xs={24} style={{ marginTop: '1rem' }}>
          <Row justify="end">
            <Col>
              <Button type="primary" onClick={() => save()}>
                저장
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <ProductCategoryEditPopup {...popupProps} />
    </Layout>
  );
};
