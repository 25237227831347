import React, { KeyboardEvent } from 'react';
import DaumPostcode, { Address } from 'react-daum-postcode';
import { DaumAddressPopupStore } from '../store/component';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { flexCenter, fullSize } from '../style/CssProperties';

export const ZipcodeSearchPopup = (): JSX.Element => {
  const zipcode = useRecoilValue(DaumAddressPopupStore);
  const close = useResetRecoilState(DaumAddressPopupStore);

  const popupClose = () => {
    close();
  };

  const escape = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      popupClose();
    }
  };

  const handleComplete = (address: Address) => {
    zipcode.callback(address);
    close();
  };

  return (
    <>
      {!!zipcode && zipcode.open ? (
        <>
          <div style={{ ...fullSize, position: 'fixed', zIndex: '99999999', backgroundColor: 'black', opacity: '0.3' }} />
          <div style={{ ...fullSize, position: 'fixed', zIndex: '1000000000' }} onClick={() => popupClose()} onKeyUp={escape}>
            <DaumPostcode
              style={{ display: 'block', width: '300px', padding: '7px', margin: '0 auto' }}
              onComplete={handleComplete}
              autoClose={true}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
