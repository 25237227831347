import { authorizationState } from '../store/auth/login.store';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { BiBox } from 'react-icons/bi';
import { MdOutlineLocalShipping } from 'react-icons/md';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { AiOutlineBarChart, AiOutlineShopping } from 'react-icons/ai';
import { FiLogOut, FiMenu } from 'react-icons/fi';
import { Button, Col, Layout, Menu, message, Row } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import { FormOutlined, SettingOutlined } from '@ant-design/icons';
import { useAxios } from '../hook/axios';
import { CodeObject } from '../store';
import { codeListState } from '../store/index.store';

const menus = [
  { key: 'dashboard', label: <Link to={'/dashboard'}>Dashboard</Link>, icon: <AiOutlineBarChart /> },
  {
    key: 'product',
    label: '상품관리',
    icon: <BiBox />,
    children: [
      { key: 'product_category', label: <Link to={'/products/categories'}>상품 카테고리</Link> },
      { key: 'product_manage', label: <Link to={'/products'}>상품 관리</Link> },
      { key: 'product_template', label: <Link to={'/products/template'}>상품 주요정보관리</Link> },
    ],
  },
  {
    key: 'order',
    label: '주문 관리',
    icon: <AiOutlineShopping />,
    children: [
      { key: 'order_list', label: <Link to={'/orders'}>주문 관리</Link>, icon: <AiOutlineShopping /> },
      { key: 'delivery', label: <Link to={'/orders/deliveries'}>배송 관리</Link>, icon: <MdOutlineLocalShipping /> },
      { key: 'bulk', label: <Link to={'/orders/bulk'}>대량 주문</Link>, icon: <MdOutlineLocalShipping /> },
      { key: 'bulk_contact', label: <Link to={'/orders/bulk/contact'}>대량 주문 문의</Link>, icon: <MdOutlineLocalShipping /> },
    ],
  },
  {
    key: 'contents',
    label: '컨텐츠 관리',
    icon: <FormOutlined />,
    children: [
      { key: 'notice', label: <Link to={'/contents/notice'}>공지사항 관리</Link> },
      { key: 'recipe', label: <Link to={'/contents/recipe'}>레시피 관리</Link> },
      { key: 'enquiry', label: <Link to={'/contents/inquiries'}>문의 관리</Link> },
    ],
  },
  {
    key: 'system',
    label: '시스템 관리',
    icon: <SettingOutlined />,
    children: [
      { key: 'user', label: <Link to={'/users'}>사용자 관리</Link> },
      { key: 'authority', label: <Link to={'/authorities'}>권한 관리</Link> },
      { key: 'agreement', label: <Link to={'/agreements'}>약관 관리</Link> },
    ],
  },
  {
    key: 'etxDataPlace',
    label: '소도체 가격 정보',
    children: [
      { key: 'gradeWholesaleMarket', label: <Link to={'/auctCattles'}>도매시장 등급별</Link> },
      { key: 'bidPriceDetail', label: <Link to={'/auctCattlePriceDetails'}>경락 상세</Link> },
      { key: 'byRegion', label: <Link to={'/cattleApperences'}>권역별</Link> },
      { key: 'doublePlusBidPriceDetail', label: <Link to={'/cattleDetails'}>1++등급 세부 경락</Link> },
      { key: 'meatAndMeatGradeBidPrice', label: <Link to={'/cattleGrades'}>육질육량 등급별 경락</Link> },
    ],
  },
  {
    key: 'etxDataAuction',
    label: '소도체 경매 정보',
    children: [
      { key: 'stateRealtime', label: <Link to={'/liveAuctCattles'}>실시간 현황</Link> },
      { key: 'stateGradeInfo', label: <Link to={'/liveAuctCattleGrades'}>등급별 현황 정보</Link> },
    ],
  },
  { key: 'etxDataWholesaleMarket', label: <Link to={'/abattoirs'}>소도체 도매시장 정보</Link> },
  { key: 'etxDataSearchInfo', label: <Link to={'/animalTraces'}>축산물 통합 이력정보 조회</Link> },
  { key: 'etxDataResultGrade', label: <Link to={'/gradeCattles'}>소도체 등급 판정 결과</Link> },
  { key: 'etxDataPartMeatInfo', label: <Link to={'/cattleParts'}>소도체 부위별 고기 정보</Link> },
];

export const Index = (): JSX.Element => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);

  const resetAuthorization = useResetRecoilState(authorizationState);
  const setCodeList = useSetRecoilState<CodeObject>(codeListState);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const { result, fetch } = useAxios<void, CodeObject>({
    url: '/api/v1/codes',
    method: 'get',
  });

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (!!result) {
      setCodeList(result);
    }
  }, [result]);

  const logout = () => {
    resetAuthorization();
    message.success('로그아웃 되었습니다.');
    navigate('/login', { replace: true });
  };

  return (
    <Layout>
      <Header style={{ width: '100%', padding: '0 25px' }}>
        <Row justify="space-between">
          <Col>
            <Button type="link" style={{ color: 'white' }} onClick={toggle} icon={<FiMenu style={{ fontSize: '1.2rem' }} />} />
          </Col>
          <Col>
            <Button type="link" style={{ color: 'white' }} onClick={logout} icon={<FiLogOut style={{ fontSize: '1.2rem' }} />} />
          </Col>
        </Row>
      </Header>
      <Layout>
        <Sider trigger={null} collapsible collapsed={collapsed} style={{ borderRight: '1px solid #eee' }}>
          <Menu mode="inline" style={{ height: '100%', borderRight: 0 }} items={menus} />
        </Sider>
        <Layout>
          <Content
            style={{
              margin: 0,
              minHeight: 280,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
