import React, { ChangeEvent, CSSProperties, useEffect, useState } from 'react';
import { Col, DatePicker, Input, Row, Select, Space } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import { RangeValue } from 'rc-picker/lib/interface';
import moment, { Moment } from 'moment';
import { parseInt } from 'lodash';

interface LabelInputLengthProps {
  labelText: string;
  name: string;
  value?: string;
  labelStyle?: CSSProperties;
  inputStyle?: CSSProperties;
  changeFn: (name: string, value: string) => void;
  length: number;
}

export const LabelInputLength = (props: LabelInputLengthProps): JSX.Element => {
  const size = !!props.value ? props.value.length : 0;
  return (
    <Space>
      <label style={{ ...props.labelStyle }}>{props.labelText}</label>
      <Row gutter={[0, 8]}>
        <Col xs={24}>
          <Input
            style={{ ...props.inputStyle }}
            name={props.name}
            value={props.value}
            onChange={(e) => props.changeFn(e.target.name, e.target.value)}
          />
        </Col>
      </Row>
      <span>{`${size}/${props.length}`}</span>
    </Space>
  );
};

interface RangeDatePickerProps {
  names: Array<string>;
  value: [string | undefined, string | undefined];
  style?: CSSProperties;
  changeFn: (rangeDate: RangeValue<Moment>) => void;
  disabled?: boolean;
}

export const RangeDatePicker = (props: RangeDatePickerProps): JSX.Element => {
  const rangeDateValue = (value: [string | undefined, string | undefined]): RangeValue<Moment> | null => {
    if (!!value[0] && !!value[1]) {
      return [moment(value[0]), moment(value[1])];
    }

    return null;
  };

  return (
    <DatePicker.RangePicker
      locale={locale}
      style={{ ...props.style }}
      value={rangeDateValue(props.value)}
      onChange={props.changeFn}
      disabled={!!props.disabled}
    />
  );
};

interface RangeDateTimePickerProps {
  names: Array<string>;
  value: [string | undefined, string | undefined];
  style?: CSSProperties;
  changeFn: (name: string[], value: string[]) => void;
  disabled?: boolean;
}

export const RangeDateTimePicker = (props: RangeDateTimePickerProps): JSX.Element => {
  const handleChange = (rangeDate: RangeValue<Moment>) => {
    if (!!rangeDate && !!rangeDate[0] && !!rangeDate[1]) {
      props.changeFn(props.names, [rangeDate[0]!.format('YYYY-MM-DD HH:mm:ss'), rangeDate[1]!.format('YYYY-MM-DD HH:mm:ss')]);
    }
  };

  const rangeDateValue = (value: [string | undefined, string | undefined]): RangeValue<Moment> | null => {
    if (!!value[0] && !!value[1]) {
      return [moment(value[0]), moment(value[1])];
    }

    return null;
  };

  return (
    <DatePicker.RangePicker
      showTime
      locale={locale}
      style={{ ...props.style }}
      value={rangeDateValue(props.value)}
      onChange={handleChange}
      disabled={!!props.disabled}
    />
  );
};

interface InputPriceProps {
  name: string;
  type?: 'text' | 'number';
  value?: number;
  maxlength?: number;
  inputStyle?: CSSProperties;
  changeFn: (name: string, value: number) => void;
  readonly?: boolean;
}

export const InputPrice = (props: InputPriceProps): JSX.Element => {
  const [value, setValue] = useState<string | number>('0');
  const [type, setType] = useState<'text' | 'number'>('text');
  const [on, setOn] = useState(false);

  useEffect(() => {
    if (!!props.value) {
      if (on) {
        setType('number');
        setValue(props.value);
      } else {
        setType('text');
        setValue(parseInt(String(props.value)).toLocaleString('ko-KR'));
      }
    } else {
      setValue(0);
    }
  }, [props.value]);

  const blur = () => {
    setOn(false);
    setType('text');
    setValue(Number(value).toLocaleString('ko-KR'));
  };

  const focus = () => {
    setOn(true);
    setType('number');
    setValue(Number(String(value).split(',').join('')));
  };

  const handleChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    let convertValue = value;
    if (/^[0]*[1-9]*$/i.test(value)) {
      convertValue = value.replace(/^[0]*/i, '');
    }

    if (!convertValue) {
      props.changeFn(name, 0);
    } else if (type === 'number') {
      props.changeFn(name, parseInt(convertValue));
    } else {
      props.changeFn(name, parseInt(String(convertValue).split(',').join('')));
    }
  };

  return (
    <Space>
      <Input
        style={{ ...props.inputStyle }}
        type={type}
        maxLength={props.maxlength}
        name={props.name}
        value={value}
        onChange={handleChange}
        onBlur={() => blur()}
        onFocus={() => focus()}
        readOnly={!!props.readonly}
      />
    </Space>
  );
};

interface LabelInputPriceProps {
  labelText: string;
  name: string;
  type?: 'text' | 'number';
  maxlength?: number;
  value?: number;
  labelStyle?: CSSProperties;
  inputStyle?: CSSProperties;
  changeFn: (name: string, value: number) => void;
  readonly?: boolean;
}

export const LabelInputPrice = (props: LabelInputPriceProps): JSX.Element => {
  return (
    <Space>
      <label style={{ ...props.labelStyle }}>{props.labelText}</label>
      <InputPrice
        name={props.name}
        changeFn={props.changeFn}
        maxlength={props.maxlength}
        inputStyle={props.inputStyle}
        value={props.value}
        readonly={props.readonly}
      />
    </Space>
  );
};
