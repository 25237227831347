import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Checkbox, Col, Form, Image, Input, Row, Typography } from 'antd';
import { LoginPageContainer, LoginPageFormContainer } from '../../style/page/login/LoginPage';
import logo from '../../static/images/default/bi.svg';
import { useNavigate } from 'react-router-dom';
import { ReqAuthState, ResAuthState } from '../../store/auth/login.service';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { authorizationState, loginFormStore } from '../../store/auth/login.store';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useAxios } from '../../hook/axios';

export const LoginPage = (): JSX.Element => {
  const [loginForm, setLoginForm] = useRecoilState(loginFormStore);
  const [form] = Form.useForm();

  const [authentication, setAuthentication] = useState<ReqAuthState>({
    username: loginForm.username,
    password: '',
    remember: !!loginForm.remember,
  });

  const { fetch, result } = useAxios<ReqAuthState, ResAuthState>({
    url: '/api/admin/login',
    method: 'post',
    data: authentication,
  });

  const setAuthorization = useSetRecoilState(authorizationState);
  const navigate = useNavigate();

  useEffect(() => {
    if (!!result) {
      setAuthorization({
        ...result,
        authenticated: true,
      });
      navigate('/');
    }
  }, [result]);

  const handleLogin = () => {
    fetch({ ...authentication }).then(() => {
      if (authentication.remember) {
        setLoginForm({
          username: authentication.username,
          remember: authentication.remember,
        });
      }
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAuthentication({
      ...authentication,
      [e.target.name]: e.target.value,
    });
  };

  const handleRemember = (e: CheckboxChangeEvent) => {
    const data = {
      ...authentication,
      ['remember']: e.target.checked,
    };

    setAuthentication({ ...data });
    setLoginForm({ ...data });
  };

  const invalid = () => {
    const nonUsernameTouch = !(form.isFieldTouched('username') || form.getFieldValue('username'));
    const nonPasswordTouch = !form.isFieldTouched('password');

    return nonUsernameTouch || nonPasswordTouch || form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
  };

  return (
    <Row justify="end" style={{ ...LoginPageContainer }}>
      <Row style={{ ...LoginPageFormContainer }}>
        <Col xs={24} style={{ marginBottom: '10%' }}>
          <Image src={logo} preview={false} style={{ width: '8rem' }} />
        </Col>
        <Col xs={24}>
          <Typography.Title level={3} style={{ color: '#805844', marginBottom: '10%' }}>
            관리자 페이지 로그인
          </Typography.Title>
        </Col>
        <Col xs={24}>
          <Form
            form={form}
            name="login"
            labelAlign={'left'}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            layout="vertical"
            autoComplete="off"
            style={{ width: '100%' }}
            initialValues={{
              username: authentication.username,
            }}
            onFinish={handleLogin}
          >
            <Form.Item
              wrapperCol={{ span: 24 }}
              style={{ marginBottom: '1rem' }}
              label="아이디"
              name="username"
              rules={[
                { required: true, message: '사용자 아이디를 입력해주세요.' },
                { required: true, type: 'email', message: '아이디 규칙은 이메일입니다.' },
              ]}
            >
              <Input name="username" onChange={handleChange} value={authentication.username} />
            </Form.Item>

            <Form.Item
              wrapperCol={{ span: 24 }}
              style={{ marginBottom: '1rem' }}
              label="비밀번호"
              name="password"
              rules={[{ required: true, message: '비밀번호를 입력해주세요.' }]}
            >
              <Input.Password name="password" onChange={handleChange} />
            </Form.Item>

            <Form.Item>
              <Checkbox
                name="remember"
                onChange={handleRemember}
                defaultChecked={authentication.remember}
                checked={authentication.remember}
              >
                아이디 저장
              </Checkbox>
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }}>
              <Button type="primary" htmlType="submit" block disabled={invalid()}>
                로그인
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Row>
  );
};
