import { Checkbox, Col, Input, message, Modal, Radio, Row, Space } from 'antd';
import Button from 'antd-button-color';
import React, { useEffect, useState } from 'react';
import { NoticeState, noticeStateSchema } from '../../store/system/notice.state';
import { ToastEditor } from '../../components/ToastEditor';
import { RangeDateTimePicker } from '../../components/LabelInputs';
import confirm from 'antd/es/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useAxios } from '../../hook/axios';
import * as yup from 'yup';
import sanitizeHtml from 'sanitize-html';
import { ErrorMessage } from '../../components/ErrorMessage';
import { ValidUtils } from '../../utils/ValidUtils';

export interface NoticeEditPopupProps {
  open: boolean;
  detail?: NoticeState;
  close: () => void;
  reloadFn: Function;
}

const initPopup: NoticeState = {
  noticeType: 'NORMAL',
  noticeTitle: '',
  noticeContents: '',
  noticeTopFixed: false,
  noticeViewCount: 0,
  enabled: true,
};

export const NoticeEditPopup = (props: NoticeEditPopupProps): JSX.Element => {
  const [detail, setDetail] = useState<NoticeState>(initPopup);

  useEffect(() => {
    if (!!props.detail) {
      setDetail({
        ...props.detail,
        createdAt: undefined,
        createdBy: undefined,
        updatedAt: undefined,
        updatedBy: undefined,
      });
    } else {
      setDetail(initPopup);
    }
  }, [props]);

  useEffect(() => {
    setValidation();
  }, [detail]);

  const { fetch: saveFn } = useAxios<NoticeState, undefined>({
    url: '/api/admin/notice',
    method: 'post',
    data: detail,
  });

  const { fetch: updateFn } = useAxios<NoticeState, undefined>({
    url: '/api/admin/notice',
    method: 'put',
    data: detail,
  });

  const save = () => {
    confirm({
      title: '공지사항 내용을 저장하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: '예',
      cancelText: '아니오',
      type: 'error',
      onOk() {
        const fn = !props.detail ? saveFn() : updateFn();
        fn.then(() => {
          message.info('공지사항 저장에 성공하였습니다.');
          props.reloadFn();
          props.close();
        }).catch(() => {
          message.error('공지사항 저장에 실패하였습니다.');
        });
      },
    });
  };

  const [isValid, setValid] = useState<boolean>(false);
  const schema: yup.SchemaOf<NoticeState> = noticeStateSchema;
  const isTitle = ValidUtils.isValid(schema, 'noticeTitle', detail.noticeTitle);
  const isContents = ValidUtils.isValidForContents(schema, 'noticeContents', detail.noticeContents);

  const setValidation = () => {
    const changeData = {
      ...detail,
      ['noticeContents']: sanitizeHtml(detail.noticeContents, { allowedTags: [], allowedAttributes: {} }),
    };

    const result = schema.isValidSync(changeData);
    setValid(result);
  };

  return (
    <Modal
      title={!!props.detail ? '공지사항 수정' : '공지사항 등록'}
      visible={props.open}
      width="750px"
      footer={null}
      onCancel={props.close}
    >
      <Row gutter={[8, 16]}>
        <Col xs={24}>
          <Space>
            <label style={{ width: '100px', display: 'inline-block' }}>
              <span>제목</span>
              <span style={{ color: 'red' }}>*</span>
            </label>
            <Input
              style={{ width: '600px' }}
              name="noticeTitle"
              value={detail.noticeTitle}
              onChange={({ target: { name, value } }) => setDetail({ ...detail, [name]: value })}
            />
          </Space>
          {!isTitle && <ErrorMessage paddingLeft="110px" marginTop="5px" message="제목을 입력해주세요." />}
        </Col>
        <Col xs={24}>
          <Space>
            <label style={{ width: '100px', display: 'inline-block' }}>
              내용<span style={{ color: 'red' }}>*</span>
            </label>
            <ToastEditor
              name="noticeContents"
              initialValue={detail.noticeContents}
              width="600px"
              onChange={(name, value) => setDetail({ ...detail, [name]: value })}
            />
          </Space>
          {!isContents && <ErrorMessage paddingLeft="110px" marginTop="5px" message="내용을 입력해주세요." />}
        </Col>
        <Col xs={24}>
          <Space>
            <label style={{ width: '100px', display: 'inline-block' }}>고정 공지</label>
            <Checkbox
              name="noticeTopFixed"
              defaultChecked={detail.noticeTopFixed}
              checked={detail.noticeTopFixed}
              onChange={({ target: { name, checked } }) => setDetail({ ...detail, [name!]: checked })}
            >
              사용
            </Checkbox>
          </Space>
        </Col>
        <Col xs={24}>
          <Space>
            <label style={{ width: '100px', display: 'inline-block' }}>노출기간 설정</label>
            <RangeDateTimePicker
              names={['noticeViewStartDate', 'noticeViewEndDate']}
              value={[detail.noticeViewStartDate, detail.noticeViewEndDate]}
              changeFn={(name, value) => setDetail({ ...detail, [name[0]]: value[0], [name[1]]: value[1] })}
            />
          </Space>
        </Col>
        <Col xs={24}>
          <Space>
            <label style={{ width: '100px', display: 'inline-block' }}>사용여부</label>
            <Radio.Group
              options={[
                { label: '사용', value: true },
                { label: '미사용', value: false },
              ]}
              name="enabled"
              value={detail.enabled}
              onChange={({ target: { name, value } }) => setDetail({ ...detail, [name!]: value })}
            />
          </Space>
        </Col>
        <Col xs={24}>
          <Row justify="end">
            <Col>
              <Button type="primary" onClick={() => save()} disabled={!isValid}>
                저장
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};
