import { CSSProperties } from 'react';
import bgImage from '../../../static/images/login/background.jpg';
import { fullSize } from '../index';

export const LoginPageContainer: CSSProperties = {
  padding: '1rem',
  backgroundImage: `url(${bgImage})`,
  backgroundPosition: 'cover',
  backgroundRepeat: 'no-repeat',
  ...fullSize,
};

export const LoginPageFormContainer: CSSProperties = {
  padding: '2rem',
  paddingTop: '5%',
  minWidth: '25rem',
  maxWidth: '20%',
  backgroundColor: 'white',
  borderRadius: '1em',
  justifyItems: 'center',
  justifyContent: 'center',
  alignItems: 'start',
  alignContent: 'start',
};
