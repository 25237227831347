import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Input, Layout, List, Row, Space, Table, Typography } from 'antd';
import { contentTitle } from '../../style/page';
import { useAxios } from '../../hook/axios';
import { ColumnsType } from 'antd/lib/table/interface';
import { AnimalTraceSearchRequest, AnimalTraceSearchContent } from '../../store/ext/animal.trace.search';

const initQuery: AnimalTraceSearchRequest = {
  traceNo: '002155280898',
  optionNo: '1',
  corpNo: '',
};

export const AnimalTraceSearch = (): JSX.Element => {
  const [query, setQuery] = useState<AnimalTraceSearchRequest>({ ...initQuery });
  const [pageQuery, setPageQuery] = useState<AnimalTraceSearchRequest>({ ...initQuery });

  const { result: page, fetch: listFetch } = useAxios<AnimalTraceSearchRequest, Array<AnimalTraceSearchContent>>({
    url: '/api/v1/ext/ekape/animalTrace',
    method: 'post',
    params: query,
  });

  const handleSearch = () => {
    console.log(page);
    setPageQuery({ ...query });
    listFetch(query);
  };

  const columns: ColumnsType<AnimalTraceSearchContent> = [
    {
      title: '도축장 주소',
      dataIndex: 'butcheryPlaceAddr',
      key: 'butcheryPlaceAddr',
      width: 100,
      align: 'center',
      render: (butcheryPlaceAddr: String) => <>{!!butcheryPlaceAddr ? butcheryPlaceAddr : '-'}</>,
    },
    {
      title: '도축장명',
      dataIndex: 'butcheryPlaceNm',
      key: 'butcheryPlaceNm',
      width: 100,
      align: 'center',
      render: (butcheryPlaceNm: String) => <>{!!butcheryPlaceNm ? butcheryPlaceNm : '-'}</>,
    },
    {
      title: '도축일자',
      dataIndex: 'butcheryYmd',
      key: 'butcheryYmd',
      width: 100,
      align: 'center',
      render: (butcheryYmd: String) => <>{!!butcheryYmd ? butcheryYmd : '-'}</>,
    },
    {
      title: '소 개체 번호',
      dataIndex: 'cattleNo',
      key: 'cattleNo',
      width: 100,
      align: 'center',
      render: (cattleNo: String) => <>{!!cattleNo ? cattleNo : '-'}</>,
    },
    {
      title: '사업자 번호',
      dataIndex: 'corpNo',
      key: 'corpNo',
      width: 100,
      align: 'center',
      render: (corpNo: String) => <>{!!corpNo ? corpNo : '-'}</>,
    },
    {
      title: '농장 주소',
      dataIndex: 'farmAddr',
      key: 'farmAddr',
      width: 100,
      align: 'center',
      render: (farmAddr: String) => <>{!!farmAddr ? farmAddr : '-'}</>,
    },
    {
      title: '등급명',
      dataIndex: 'gradeNm',
      key: 'gradeNm',
      width: 100,
      align: 'center',
      render: (gradeNm: String) => <>{!!gradeNm ? gradeNm : '-'}</>,
    },
    {
      title: '조회 정보 옵션',
      dataIndex: 'infoType',
      key: 'infoType',
      width: 100,
      align: 'center',
      render: (infoType: String) => <>{!!infoType ? infoType : '-'}</>,
    },
    {
      title: '묶음 번호',
      dataIndex: 'lotNo',
      key: 'lotNo',
      width: 100,
      align: 'center',
      render: (lotNo: String) => <>{!!lotNo ? lotNo : '-'}</>,
    },
    {
      title: '소의 종류',
      dataIndex: 'lsTypeNm',
      key: 'lsTypeNm',
      width: 100,
      align: 'center',
      render: (lsTypeNm: String) => <>{!!lsTypeNm ? lsTypeNm : '-'}</>,
    },
    {
      title: '소/돼지 묶음 구분',
      dataIndex: 'traceNoType',
      key: 'traceNoType',
      width: 100,
      align: 'center',
      render: (traceNoType: String) => <>{!!traceNoType ? traceNoType : '-'}</>,
    },
    {
      title: '조회 정보',
      dataIndex: 'inspectYn',
      key: 'inspectYn',
      width: 100,
      align: 'center',
      render: (inspectYn: String) => <>{!!inspectYn ? inspectYn : '-'}</>,
    },
    {
      title: '재부착 번호',
      dataIndex: 'flatEartagNo',
      key: 'flatEartagNo',
      width: 100,
      align: 'center',
      render: (flatEartagNo: String) => <>{!!flatEartagNo ? flatEartagNo : '-'}</>,
    },
    {
      title: '돼지 이력 번호',
      dataIndex: 'pigNo',
      key: 'pigNo',
      width: 100,
      align: 'center',
      render: (pigNo: String) => <>{!!pigNo ? pigNo : '-'}</>,
    },
    {
      title: '포장 처리 업소 주소',
      dataIndex: 'processPlaceAddr',
      key: 'processPlaceAddr',
      width: 100,
      align: 'center',
      render: (processPlaceAddr: String) => <>{!!processPlaceAddr ? processPlaceAddr : '-'}</>,
    },
    {
      title: '포장 처리 업소명',
      dataIndex: 'processPlaceNm',
      key: 'processPlaceNm',
      width: 100,
      align: 'center',
      render: (processPlaceNm: String) => <>{!!processPlaceNm ? processPlaceNm : '-'}</>,
    },
    {
      title: '신고일자',
      dataIndex: 'regYmd',
      key: 'regYmd',
      width: 100,
      align: 'center',
      render: (regYmd: String) => <>{!!regYmd ? regYmd : '-'}</>,
    },
    {
      title: '성별',
      dataIndex: 'sexNm',
      key: 'sexNm',
      width: 100,
      align: 'center',
      render: (sexNm: String) => <>{!!sexNm ? sexNm : '-'}</>,
    },
    {
      title: '구제역 백신 접종 차수',
      dataIndex: 'vaccineorder',
      key: 'vaccineorder',
      width: 100,
      align: 'center',
      render: (vaccineorder: String) => <>{!!vaccineorder ? vaccineorder : '-'}</>,
    },
    {
      title: '신고 구분',
      dataIndex: 'regType',
      key: 'regType',
      width: 100,
      align: 'center',
      render: (regType: String) => <>{!!regType ? regType : '-'}</>,
    },
    {
      title: '위생 검사 결과',
      dataIndex: 'inspectPassYn',
      key: 'inspectPassYn',
      width: 100,
      align: 'center',
      render: (inspectPassYn: String) => <>{!!inspectPassYn ? inspectPassYn : '-'}</>,
    },
    {
      title: '구제역 예방접종 일자',
      dataIndex: 'injectionYmd',
      key: 'injectionYmd',
      width: 100,
      align: 'center',
      render: (injectionYmd: String) => <>{!!injectionYmd ? injectionYmd : '-'}</>,
    },
    {
      title: '농장 식별 번호',
      dataIndex: 'farmUniqueNo',
      key: 'farmUniqueNo',
      width: 100,
      align: 'center',
      render: (farmUniqueNo: String) => <>{!!farmUniqueNo ? farmUniqueNo : '-'}</>,
    },
    {
      title: '브루셀라 검사 최종 일자',
      dataIndex: 'inspectDt',
      key: 'inspectDt',
      width: 100,
      align: 'center',
      render: (inspectDt: String) => <>{!!inspectDt ? inspectDt : '-'}</>,
    },
    {
      title: '구제역 백신 접종 경과일',
      dataIndex: 'injectiondayCnt',
      key: 'injectiondayCnt',
      width: 100,
      align: 'center',
      render: (injectiondayCnt: String) => <>{!!injectiondayCnt ? injectiondayCnt : '-'}</>,
    },
    {
      title: '수입 경과 월',
      dataIndex: 'monthDiff',
      key: 'monthDiff',
      width: 100,
      align: 'center',
      render: (monthDiff: String) => <>{!!monthDiff ? monthDiff : '-'}</>,
    },
    {
      title: '출생일자',
      dataIndex: 'birthYmd',
      key: 'birthYmd',
      width: 100,
      align: 'center',
      render: (birthYmd: String) => <>{!!birthYmd ? birthYmd : '-'}</>,
    },
    {
      title: '포장 처리 일자',
      dataIndex: 'processYmd',
      key: 'processYmd',
      width: 100,
      align: 'center',
      render: (processYmd: String) => <>{!!processYmd ? processYmd : '-'}</>,
    },
    {
      title: '소유주',
      dataIndex: 'farmerNm',
      key: 'farmerNm',
      width: 100,
      align: 'center',
      render: (farmerNm: String) => <>{!!farmerNm ? farmerNm : '-'}</>,
    },
    {
      title: '수입 국가',
      dataIndex: 'nationNm',
      key: 'nationNm',
      width: 100,
      align: 'center',
      render: (nationNm: String) => <>{!!nationNm ? nationNm : '-'}</>,
    },
    {
      title: '질병 유무',
      dataIndex: 'inspectDesc',
      key: 'inspectDesc',
      width: 100,
      align: 'center',
      render: (inspectDesc: String) => <>{!!inspectDesc ? inspectDesc : '-'}</>,
    },
    {
      title: '근내지방도',
      dataIndex: 'insfat',
      key: 'insfat',
      width: 100,
      align: 'center',
      render: (insfat: String) => <>{!!insfat ? insfat : '-'}</>,
    },
    {
      title: '농장 번호',
      dataIndex: 'farmNo',
      key: 'farmNo',
      width: 100,
      align: 'center',
      render: (farmNo: String) => <>{!!farmNo ? farmNo : '-'}</>,
    },
    {
      title: '이력 번호',
      dataIndex: 'histNo',
      key: 'histNo',
      width: 100,
      align: 'center',
      render: (histNo: String) => <>{!!histNo ? histNo : '-'}</>,
    },
    {
      title: '농장 식별 번호',
      dataIndex: 'farmIdno',
      key: 'farmIdno',
      width: 100,
      align: 'center',
      render: (farmIdno: String) => <>{!!farmIdno ? farmIdno : '-'}</>,
    },
    {
      title: '농장 경영자 명',
      dataIndex: 'mngrNm',
      key: 'mngrNm',
      width: 100,
      align: 'center',
      render: (mngrNm: String) => <>{!!mngrNm ? mngrNm : '-'}</>,
    },
    {
      title: '사육환경',
      dataIndex: 'brdngEnvrnNm',
      key: 'brdngEnvrnNm',
      width: 100,
      align: 'center',
      render: (brdngEnvrnNm: String) => <>{!!brdngEnvrnNm ? brdngEnvrnNm : '-'}</>,
    },
    {
      title: '축산업 등록번호',
      dataIndex: 'lvindRgrfc',
      key: 'lvindRgrfc',
      width: 100,
      align: 'center',
      render: (lvindRgrfc: String) => <>{!!lvindRgrfc ? lvindRgrfc : '-'}</>,
    },
    {
      title: '작업장명',
      dataIndex: 'abattNm',
      key: 'abattNm',
      width: 100,
      align: 'center',
      render: (abattNm: String) => <>{!!abattNm ? abattNm : '-'}</>,
    },
    {
      title: '도축장 소재지',
      dataIndex: 'add0R',
      key: 'add0R',
      width: 100,
      align: 'center',
      render: (add0R: String) => <>{!!add0R ? add0R : '-'}</>,
    },
    {
      title: '도축 일자',
      dataIndex: 'rceptDt',
      key: 'rceptDt',
      width: 100,
      align: 'center',
      render: (rceptDt: String) => <>{!!rceptDt ? rceptDt : '-'}</>,
    },
    {
      title: '도축 검사 결과',
      dataIndex: 'psexmYn',
      key: 'psexmYn',
      width: 100,
      align: 'center',
      render: (psexmYn: String) => <>{!!psexmYn ? psexmYn : '-'}</>,
    },
    {
      title: '업체명',
      dataIndex: 'entrpNm',
      key: 'entrpNm',
      width: 100,
      align: 'center',
      render: (entrpNm: String) => <>{!!entrpNm ? entrpNm : '-'}</>,
    },
    {
      title: '농장 경영자 명',
      dataIndex: 'frmrNm',
      key: 'frmrNm',
      width: 100,
      align: 'center',
      render: (frmrNm: String) => <>{!!frmrNm ? frmrNm : '-'}</>,
    },
    {
      title: '신청인 업체 명',
      dataIndex: 'reqerEntrpNm',
      key: 'reqerEntrpNm',
      width: 100,
      align: 'center',
      render: (reqerEntrpNm: String) => <>{!!reqerEntrpNm ? reqerEntrpNm : '-'}</>,
    },
    {
      title: '신청인 업체 주소',
      dataIndex: 'reqerEntrpAddr',
      key: 'reqerEntrpAddr',
      width: 100,
      align: 'center',
      render: (reqerEntrpAddr: String) => <>{!!reqerEntrpAddr ? reqerEntrpAddr : '-'}</>,
    },
    {
      title: '발급 일자',
      dataIndex: 'issueDt',
      key: 'issueDt',
      width: 100,
      align: 'center',
      render: (issueDt: String) => <>{!!issueDt ? issueDt : '-'}</>,
    },
    {
      title: '식용란 산란 일자',
      dataIndex: 'edygOvpstDt',
      key: 'edygOvpstDt',
      width: 100,
      align: 'center',
      render: (edygOvpstDt: String) => <>{!!edygOvpstDt ? edygOvpstDt : '-'}</>,
    },
    {
      title: '선별 검사 결과',
      dataIndex: 'successYn',
      key: 'successYn',
      width: 100,
      align: 'center',
      render: (successYn: String) => <>{!!successYn ? successYn : '-'}</>,
    },
    {
      title: '거래처 소재지',
      dataIndex: 'entrpAddr',
      key: 'entrpAddr',
      width: 100,
      align: 'center',
      render: (entrpAddr: String) => <>{!!entrpAddr ? entrpAddr : '-'}</>,
    },
  ];

  return (
    <Layout>
      <Row style={{ ...contentTitle }}>
        <Typography.Title level={5} style={{ margin: '0' }}>
          축산물 통합 이력 정보 조회
        </Typography.Title>
      </Row>

      <Row style={{ padding: '1rem' }}>
        <Col xs={24}>
          <Card style={{ width: '100%' }}>
            <Row gutter={[20, 10]}>
              <Col>
                <Space>
                  <label style={{ width: '120px', display: 'inline-block' }}>개체/이력/묶음 번호</label>
                  <Input
                    style={{ width: '180px' }}
                    name="traceNo"
                    value={query.traceNo.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
              <Col>
                <Space>
                  <label style={{ width: '100px', display: 'inline-block' }}>옵션번호</label>
                  <Input
                    style={{ width: '180px' }}
                    name="optionNo"
                    value={query.optionNo.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
              <Col>
                <Space>
                  <label style={{ width: '210px', display: 'inline-block' }}>묶음을 구성한 업소의 사업자 번호</label>
                  <Input
                    style={{ width: '180px' }}
                    name="corpNo"
                    value={query.corpNo.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
            </Row>

            <Col xs={24}>
              <Divider />
            </Col>

            <Row justify="center">
              <Space>
                <Button type="primary" htmlType="submit" onClick={() => handleSearch()}>
                  검색
                </Button>
              </Space>
            </Row>
          </Card>
        </Col>

        <Col xs={24} style={{ marginTop: '2rem' }}>
          <Table
            size="large"
            style={{ width: '100%' }}
            rowKey="animalTraceId"
            dataSource={page}
            columns={columns}
            scroll={{ x: 1400, y: 500 }}
          />
        </Col>
      </Row>
    </Layout>
  );
};
