import { useSetRecoilState } from 'recoil';
import React, { useEffect, useState } from 'react';
import { DaumAddressPopupStore } from '../../../store/component';
import { Col, Input, message, Modal, Row, Space } from 'antd';
import confirm from 'antd/es/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Address } from 'react-daum-postcode';
import { BulkOrderDestinationState } from '../../../store/order/bulk.order.state';
import { useAxios } from '../../../hook/axios';

export interface BulkOrderDetailEditPopupProps {
  open: boolean;
  detail?: BulkOrderDestinationState;
  close: Function;
  reload: Function;
}

export const BulkOrderDetailEditPopup = (props: BulkOrderDetailEditPopupProps): JSX.Element => {
  const setDestZipcode = useSetRecoilState(DaumAddressPopupStore);
  const searchAddress = () => {
    setDestZipcode({ open: true, callback: selectedAddress });
  };

  const [dest, setDest] = useState<BulkOrderDestinationState>({
    bulkOrderId: 0,
    orderDestSeq: 0,
    orderId: 0,
    orderNo: '',
    destZipcode: '',
    destAddress: '',
    destAddressDetail: '',
    destRecipientName: '',
    destRecipientCellphoneNo: '',
  });

  useEffect(() => {
    if (props.open) {
      setDest({ ...props.detail! });
    }
  }, [props.open]);

  const selectedAddress = (destAddress: Address) => {
    setDest({
      ...dest,
      destZipcode: destAddress.zonecode,
      destAddress: destAddress.address,
    });
  };

  const { fetch } = useAxios<BulkOrderDestinationState, void>({
    url: `/api/admin/bulk/orders/destinations/${dest.bulkOrderId}`,
    method: 'put',
  });

  const save = () => {
    confirm({
      title: '주문 배송지 정보를 내용을 변경하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: '예',
      cancelText: '아니오',
      type: 'error',
      onOk() {
        fetch({ ...dest })
          .then(() => {
            message.success('배송지 정보가 변경되었습니다.');
            props.reload();
            props.close();
          })
          .catch(() => {
            message.error('배송지 정보 변경에 실패하였습니다.');
          });
      },
    });
  };

  const convertPhoneNumber = (value: string) => {
    const data = !!value ? value.replace(/\D/g, '') : '';
    let destRecipientCellphoneNo = '';
    if (data.length < 4) {
      destRecipientCellphoneNo = data;
    } else if (data.length > 3 && data.length < 8) {
      destRecipientCellphoneNo = data.replace(/([\d]{3})([\d]{1,4})/, '$1-$2');
    } else if (data.length === 8 || data.length === 9) {
      destRecipientCellphoneNo = data.replace(/([\d]{3})([\d]{3,4})([\d]{1,2})/, '$1-$2-$3');
    } else if (data.length === 11 || data.length === 10) {
      destRecipientCellphoneNo = data.replace(/([\d]{3})([\d]{3,4})([\d]{4})/, '$1-$2-$3');
    }

    setDest({ ...dest, ['destRecipientCellphoneNo']: destRecipientCellphoneNo });
  };

  const handleChange = (name: string, value: unknown) => {
    setDest({
      ...dest,
      [name]: value,
    });
  };

  return (
    <Modal
      title="주소 정보 수정"
      visible={props.open}
      width={450}
      okText="저장"
      cancelText="취소"
      onOk={() => save()}
      onCancel={() => props.close()}
    >
      <Row gutter={[0, 16]}>
        <Col xs={24}>
          <Space>
            <label style={{ display: 'inline-block', width: '100px' }}>주문번호</label>
            <Input style={{ width: '300px' }} name="orderNo" value={dest.orderNo} disabled={true} />
          </Space>
        </Col>
        <Col xs={24}>
          <Space>
            <label style={{ display: 'inline-block', width: '100px' }}>수령인</label>
            <Input
              style={{ width: '300px' }}
              name="destRecipientName"
              value={dest.destRecipientName}
              onChange={({ target: { name, value } }) => handleChange(name, value)}
            />
          </Space>
        </Col>
        <Col xs={24}>
          <Space>
            <label style={{ display: 'inline-block', width: '100px' }}>수령인 연락처</label>
            <Input
              style={{ width: '300px' }}
              name="destRecipientCellphoneNo"
              value={dest.destRecipientCellphoneNo}
              onChange={({ target: { value } }) => convertPhoneNumber(value)}
            />
          </Space>
        </Col>
        <Col xs={24}>
          <Space>
            <label style={{ display: 'inline-block', width: '100px' }}>우편번호</label>
            <Input.Search
              name="destZipcode"
              maxLength={13}
              readOnly={true}
              enterButton="우편번호찾기"
              style={{ width: '300px' }}
              value={dest.destZipcode}
              onSearch={() => searchAddress()}
            />
          </Space>
        </Col>
        <Col xs={24}>
          <Space>
            <label style={{ display: 'inline-block', width: '100px' }}>주소</label>
            <Input
              style={{ width: '300px' }}
              name="destAddress"
              value={dest.destAddress}
              onChange={({ target: { name, value } }) => handleChange(name, value)}
            />
          </Space>
        </Col>
        <Col xs={24}>
          <Space>
            <label style={{ display: 'inline-block', width: '100px' }}>주소 상세</label>
            <Input
              style={{ width: '300px' }}
              name="destAddressDetail"
              value={dest.destAddressDetail}
              onChange={({ target: { name, value } }) => handleChange(name, value)}
            />
          </Space>
        </Col>
      </Row>
    </Modal>
  );
};
