import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';
import { EnableLoadingStore } from '../store/component';

export const Loading = (): JSX.Element => {
  const enabled = useRecoilValue(EnableLoadingStore);
  return (
    <>
      {enabled ? (
        <div style={{ width: '100%', height: '100%', top: '0', position: 'absolute', zIndex: '900' }}>
          <div
            style={{
              width: '100%',
              height: '100%',
              top: '0',
              zIndex: '998',
              position: 'absolute',
              backgroundColor: 'black',
              opacity: '.2',
            }}
          />
          <div
            style={{
              width: '100%',
              height: '100%',
              top: '0',
              zIndex: '999',
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
