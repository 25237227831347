import { Col, Collapse, DatePicker, Input, Radio, Row, Select, Space } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { ProductEditProps } from '../ProductEditPage';
import locale from 'antd/es/date-picker/locale/ko_KR';
import { useAxios } from '../../../hook/axios';
import { ProductInformationTemplateState } from '../../../store/product/product.state';
import { TemplateSelect } from '../../../components/product/template.select';

export const ProductInformationFragment = (props: ProductEditProps): JSX.Element => {
  const [product, setProduct] = [props.product, props.setProduct];
  const [templateId, setTemplateId] = useState<number>();

  const handleChange = (name: string, value: string) => {
    setProduct({
      ...product,
      productInformation: {
        ...product.productInformation,
        [name]: value,
      },
    });

    // 최초 로드될 때 직접입력으로 바뀌지 말아야 함
    // 그 이후로는 변경시 직접입력으로 변경
    if (!!result && value === result[name as keyof ProductInformationTemplateState]) {
      return;
    } else {
      setTemplateId(undefined);
    }
  };

  const dateValue = (date: string | undefined) => {
    const momentDate = moment(date);

    if (momentDate.isValid()) {
      return momentDate;
    }
    return null;
  };

  const handleProductInformationTemplate = (value?: number) => {
    // handleInfoChange('templateId', value);
    setTemplateId(value);
  };

  const { result, fetch } = useAxios<any, ProductInformationTemplateState>({
    url: `/api/admin/products/information-templates/${templateId}`,
    method: 'get',
  });

  useEffect(() => {
    if (!!templateId) {
      fetch();
    }
  }, [templateId]);

  useEffect(() => {
    if (!!result)
      setProduct({
        ...product,
        productInformation: {
          ...product.productInformation,
          ...result,
        },
      });
  }, [result]);

  return (
    <Collapse defaultActiveKey={['product_information']} expandIconPosition="right">
      <Collapse.Panel key={'product_information'} header={'상품 주요 정보'}>
        <Row gutter={[0, 8]}>
          <Col xs={24}>
            <Space>
              <label style={{ width: '140px', display: 'inline-block' }}>상품주요정보 선택</label>
              <TemplateSelect setTemplateId={handleProductInformationTemplate} templateId={templateId} style={{ width: '200px' }} />
            </Space>
          </Col>
          <Col xs={24}>
            <Space>
              <label style={{ width: '140px', display: 'inline-block' }}>품목 또는 명칭</label>
              <Input
                style={{ width: '200px' }}
                name="modelName"
                value={product.productInformation.modelName}
                onChange={({ target: { name, value } }) => handleChange(name, value)}
              />
            </Space>
          </Col>
          <Col xs={24}>
            <Space>
              <label style={{ width: '140px', display: 'inline-block' }}>생산자</label>
              <Input
                style={{ width: '200px' }}
                name="manufacturerName"
                value={product.productInformation.manufacturerName}
                onChange={({ target: { name, value } }) => handleChange(name, value)}
              />
            </Space>
          </Col>
          <Col xs={24}>
            <Space>
              <label style={{ width: '140px', display: 'inline-block' }}>원산지</label>
              <Select
                style={{ width: '200px' }}
                value={product.productInformation.originType}
                onChange={(value) => handleChange('originType', value)}
              >
                <Select.Option value={'DOMESTIC'}>국내산</Select.Option>
                <Select.Option value={'FOREIGN'}>수입산</Select.Option>
                <Select.Option value={'ETC'}>기타</Select.Option>
              </Select>
              {product.productInformation.originType === 'ETC' && (
                <Space>
                  <label style={{ width: '80px', display: 'inline-block', textAlign: 'right' }}>직접입력</label>
                  <Input
                    width={300}
                    name="originMemo"
                    value={product.productInformation.originMemo}
                    onChange={({ target: { name, value } }) => handleChange(name, value)}
                  />
                </Space>
              )}
            </Space>
          </Col>
          <Col xs={24}>
            <Space>
              <label style={{ width: '140px', display: 'inline-block' }}>중량</label>
              <Input
                style={{ width: '200px' }}
                name="weight"
                value={product.productInformation.weight}
                onChange={({ target: { name, value } }) => handleChange(name, value)}
              />
            </Space>
          </Col>
          <Col xs={24}>
            <Space>
              <label style={{ width: '140px', display: 'inline-block' }}>크기</label>
              <Input
                style={{ width: '200px' }}
                name="size"
                value={product.productInformation.size}
                onChange={({ target: { name, value } }) => handleChange(name, value)}
              />
            </Space>
          </Col>
          <Col xs={24}>
            <Space>
              <label style={{ width: '140px', display: 'inline-block' }}>포장 단위별 수량</label>
              <Input
                style={{ width: '200px' }}
                name="quantityPerPackage"
                value={product.productInformation.quantityPerPackage}
                onChange={({ target: { name, value } }) => handleChange(name, value)}
              />
            </Space>
          </Col>
          <Col xs={24}>
            <Space>
              <label style={{ width: '140px', display: 'inline-block' }}>상품구성</label>
              <Input
                style={{ width: '200px' }}
                name="legalProductComposition"
                value={product.productInformation.legalProductComposition}
                onChange={({ target: { name, value } }) => handleChange(name, value)}
              />
            </Space>
          </Col>
          <Col xs={24}>
            <Space>
              <label style={{ width: '140px', display: 'inline-block' }}>보관</label>
              <Input
                style={{ width: '200px' }}
                name="storageMethod"
                value={product.productInformation.storageMethod}
                onChange={({ target: { name, value } }) => handleChange(name, value)}
              />
            </Space>
          </Col>
          <Col xs={24}>
            <Space>
              <label style={{ width: '140px', display: 'inline-block' }}>축산물 이력정보</label>
              <Input
                style={{ width: '200px' }}
                name="legalHistoryInfo"
                value={product.productInformation.legalHistoryInfo}
                onChange={({ target: { name, value } }) => handleChange(name, value)}
              />
            </Space>
          </Col>
          <Col xs={24}>
            <Space>
              <label style={{ width: '140px', display: 'inline-block' }}>축산법에 따른 등급표시</label>
              <Input
                style={{ width: '200px' }}
                name="legalGrade"
                value={product.productInformation.legalGrade}
                onChange={({ target: { name, value } }) => handleChange(name, value)}
              />
            </Space>
          </Col>
          <Col xs={24}>
            <Space align="start">
              <label style={{ width: '140px', display: 'inline-block' }}>제조년월일</label>
              <Row justify="start" gutter={[0, 8]}>
                <Col xs={24}>
                  <Radio.Group
                    style={{ width: '200px' }}
                    name="productionDateType"
                    value={product.productInformation.productionDateType}
                    onChange={({ target: { name, value } }) => handleChange(name as string, value)}
                  >
                    <Radio value={'CALENDAR'}>캘린더</Radio>
                    <Radio value={'INPUT'}>직접입력</Radio>
                  </Radio.Group>
                </Col>
                {product.productInformation.productionDateType === 'CALENDAR' && (
                  <Col xs={24}>
                    <DatePicker
                      name="productionDate"
                      value={dateValue(product.productInformation.productionDate)}
                      locale={locale}
                      onChange={(value, dateString) => handleChange('productionDate', dateString)}
                    />
                  </Col>
                )}
                {product.productInformation.productionDateType === 'INPUT' && (
                  <Col xs={24}>
                    <Input
                      style={{ width: '133px' }}
                      name="productionDate"
                      value={product.productInformation.productionDate}
                      onChange={({ target: { name, value } }) => handleChange(name, value)}
                    />
                  </Col>
                )}
              </Row>
            </Space>
          </Col>
          <Col xs={24}>
            <Space align="start">
              <label style={{ width: '140px', display: 'inline-block' }}>유통기한 또는 품질유지기한</label>
              <Row justify="start" gutter={[0, 8]}>
                <Col xs={24}>
                  <Radio.Group
                    style={{ width: '200px' }}
                    name="expiryDateType"
                    value={product.productInformation.expiryDateType}
                    onChange={({ target: { name, value } }) => handleChange(name as string, value)}
                  >
                    <Radio value={'CALENDAR'}>캘린더</Radio>
                    <Radio value={'INPUT'}>직접입력</Radio>
                  </Radio.Group>
                </Col>
                {product.productInformation.expiryDateType === 'CALENDAR' && (
                  <Col xs={24}>
                    <DatePicker
                      name="expiryDate"
                      value={dateValue(product.productInformation.expiryDate)}
                      locale={locale}
                      onChange={(value, dateString) => handleChange('expiryDate', dateString)}
                    />
                  </Col>
                )}
                {product.productInformation.expiryDateType === 'INPUT' && (
                  <Col xs={24}>
                    <Input
                      style={{ width: '133px' }}
                      name="expiryDate"
                      value={product.productInformation.expiryDate}
                      onChange={({ target: { name, value } }) => handleChange(name, value)}
                    />
                  </Col>
                )}
              </Row>
            </Space>
          </Col>
          <Col xs={24}>
            <Space>
              <label style={{ width: '140px', display: 'inline-block' }}>주의사항</label>
              <Input
                style={{ width: '200px' }}
                name="cautionInfo"
                value={product.productInformation.cautionInfo}
                onChange={({ target: { name, value } }) => handleChange(name, value)}
              />
            </Space>
          </Col>
        </Row>
      </Collapse.Panel>
    </Collapse>
  );
};
