import {
  ProductInformationState,
  ProductInformationTemplateListState,
  ProductInformationTemplateState,
  productInformationTemplateStateSchema,
  ProductState,
} from '../../../store/product/product.state';
import { useNavigate, useParams } from 'react-router-dom';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAxios } from '../../../hook/axios';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Affix, Button, Card, Col, Collapse, DatePicker, Input, Layout, message, Radio, Row, Select, Space, Typography } from 'antd';
import { contentTitle } from '../../../style/page';
import locale from 'antd/es/date-picker/locale/ko_KR';
import moment from 'moment';
import confirm from 'antd/es/modal/confirm';
import TextArea from 'antd/es/input/TextArea';
import * as yup from 'yup';
import { ValidUtils } from '../../../utils/ValidUtils';
import { ErrorMessage } from '../../../components/ErrorMessage';
import { ProductInfoTemplateEditCopyTarget, ProductInfoTemplateEditCopyTargetProps } from './ProductInfoTemplateEditCopyTarget';

const initInfoTemplate: ProductInformationTemplateState = {
  templateId: undefined,
  templateName: '',
  modelName: '',
  manufacturerName: '',
  originType: 'DOMESTIC',
  originMemo: '',
  productionDateType: 'CALENDAR',
  productionDate: '',
  expiryDateType: 'CALENDAR',
  expiryDate: '',
  weight: '',
  size: '',
  quantityPerPackage: '',
  legalProductComposition: '',
  storageMethod: '',
  legalGrade: '',
  legalHistoryInfo: '',
  cautionInfo: '',
  enabled: false,
};

export const ProductInformationTemplateEditPage = (): JSX.Element => {
  const params = useParams();
  const id = params.templateId;

  const title = !!id ? '상품 주요 정보 수정' : '상품 주요 정보 등록';
  const navigate = useNavigate();

  const [infoTemplate, setInfoTemplate] = useState<ProductInformationTemplateState>({
    ...initInfoTemplate,
  });

  useEffect(() => {
    setValidation();
  }, [infoTemplate]);

  const url = '/api/admin/products/information-templates';

  const { result, fetch } = useAxios<undefined, ProductInformationTemplateState>({
    url: `${url}/${id}`,
    method: 'get',
  });

  useEffect(() => {
    if (!!id) {
      fetch();
    }

    return () => {
      setInfoTemplate({
        ...initInfoTemplate,
      });
    };
  }, []);

  useEffect(() => {
    if (!!result) {
      setInfoTemplate(result);
    }
  }, [result]);

  const { fetch: saveFn } = useAxios<ProductInformationTemplateState, undefined>({
    url: url,
    method: 'post',
    data: infoTemplate,
  });

  const { fetch: updateFn } = useAxios<ProductInformationTemplateState, undefined>({
    url: `${url}/${id}`,
    method: 'put',
    data: infoTemplate,
  });

  const save = () => {
    confirm({
      title: '상품 내용을 저장하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: '예',
      cancelText: '아니오',
      type: 'error',
      onOk() {
        const fn = !!id ? updateFn() : saveFn();
        fn.then(() => {
          navigate('/products/template');
        }).catch((error) => {
          switch (error.response.status) {
            case 409:
              message.error('등록명이 중복되었습니다.');
              break;
            case 400:
            default:
              message.error('저장에 실패하였습니다.');
              break;
          }
        });
      },
    });
  };

  const [isValid, setValid] = useState<boolean>(false);
  const schema: yup.SchemaOf<ProductInformationTemplateState> = productInformationTemplateStateSchema;
  const isTemplateName = ValidUtils.isValid(schema, 'templateName', infoTemplate.templateName);
  const isModelName = ValidUtils.isValid(schema, 'modelName', infoTemplate.modelName);
  const isManufacturerName = ValidUtils.isValid(schema, 'manufacturerName', infoTemplate.manufacturerName);
  const isWeight = ValidUtils.isValid(schema, 'weight', infoTemplate.weight);
  const isSize = ValidUtils.isValid(schema, 'size', infoTemplate.size);
  const isQuantityPerPackage = ValidUtils.isValid(schema, 'quantityPerPackage', infoTemplate.quantityPerPackage);
  const isLegalProductComposition = ValidUtils.isValid(schema, 'legalProductComposition', infoTemplate.legalProductComposition);
  const isStorageMethod = ValidUtils.isValid(schema, 'storageMethod', infoTemplate.storageMethod);
  const isLegalGrade = ValidUtils.isValid(schema, 'legalGrade', infoTemplate.legalGrade);
  const isLegalHistoryInfo = ValidUtils.isValid(schema, 'legalHistoryInfo', infoTemplate.legalHistoryInfo);
  const isCautionInfo = ValidUtils.isValid(schema, 'cautionInfo', infoTemplate.cautionInfo);
  const isProductionDate = ValidUtils.isValid(schema, 'productionDate', infoTemplate.productionDate);
  const isExpiryDate = ValidUtils.isValid(schema, 'expiryDate', infoTemplate.expiryDate);

  const setValidation = () => {
    const changeData = {
      ...infoTemplate,
    };

    const result = schema.isValidSync(changeData);

    setValid(result);
  };
  const handleChange = (name: string, value: string) => {
    setInfoTemplate({
      ...infoTemplate,
      [name]: value,
    });
  };

  const dateValue = (date: string | undefined) => {
    const momentDate = moment(date);

    if (momentDate.isValid()) {
      return momentDate;
    }

    return null;
  };

  const [templateId, setTemplateId] = useState<number>();
  const { fetch: copyTargetFetch } = useAxios<undefined, ProductInformationTemplateState>({
    url: `${url}/${templateId}`,
    method: 'get',
  });

  useEffect(() => {
    if (!!templateId) {
      copyTargetFetch().then((result) => {
        const template = {
          ...result,
          templateId: undefined,
        };
        setInfoTemplate(template);
      });
    }

    return () => {
      setTemplateId(undefined);
    };
  }, [templateId]);

  const [popup, setPopup] = useState<ProductInfoTemplateEditCopyTargetProps>({
    open: false,
    onSelect: (templateId: number) => {
      setTemplateId(templateId);
    },
    onClose: () => {
      setPopup({
        ...popup,
        open: false,
      });
    },
  });

  return (
    <Layout>
      <Row style={{ ...contentTitle }}>
        <Typography.Title level={5} style={{ margin: '0' }}>
          {title}
        </Typography.Title>
      </Row>

      <Row style={{ padding: '1rem' }}>
        <Card size="small" style={{ width: '100%', padding: '1rem' }}>
          {!id && (
            <Row justify="end" style={{ marginBottom: '1rem' }}>
              <Col>
                <Button type="primary" onClick={() => setPopup({ ...popup, open: true })}>
                  복사 등록
                </Button>
              </Col>
            </Row>
          )}
          <Space direction="vertical" style={{ width: '100%', marginBottom: '1rem' }}>
            <Row gutter={[0, 8]}>
              <Col xs={24}>
                <Space>
                  <label style={{ width: '140px', display: 'inline-block' }}>등록명</label>
                  <Input
                    style={{ width: '200px' }}
                    name="templateName"
                    value={infoTemplate.templateName}
                    onChange={({ target: { name, value } }) => handleChange(name, value)}
                  />
                </Space>
                {!isTemplateName && <ErrorMessage paddingLeft="150px" marginBottom="5px" message="등록명을 입력해주세요." />}
              </Col>
              <Col xs={24}>
                <Space>
                  <label style={{ width: '140px', display: 'inline-block' }}>사용여부</label>
                  <Radio.Group
                    name="enabled"
                    value={infoTemplate.enabled}
                    onChange={({ target: { name, value } }) => handleChange(name!, value)}
                  >
                    <Radio value={true}>사용</Radio>
                    <Radio value={false}>미사용</Radio>
                  </Radio.Group>
                </Space>
              </Col>
              <Col xs={24}>
                <Space>
                  <label style={{ width: '140px', display: 'inline-block' }}>품목 또는 명칭</label>
                  <Input
                    style={{ width: '200px' }}
                    name="modelName"
                    value={infoTemplate.modelName}
                    onChange={({ target: { name, value } }) => handleChange(name, value)}
                  />
                </Space>
                {!isModelName && <ErrorMessage paddingLeft="150px" marginBottom="5px" message="품목을 입력해주세요." />}
              </Col>
              <Col xs={24}>
                <Space>
                  <label style={{ width: '140px', display: 'inline-block' }}>생산자</label>
                  <Input
                    style={{ width: '200px' }}
                    name="manufacturerName"
                    value={infoTemplate.manufacturerName}
                    onChange={({ target: { name, value } }) => handleChange(name, value)}
                  />
                </Space>
                {!isManufacturerName && <ErrorMessage paddingLeft="150px" marginBottom="5px" message="생산자명을 입력해주세요." />}
              </Col>
              <Col xs={24}>
                <Space>
                  <label style={{ width: '140px', display: 'inline-block' }}>원산지</label>
                  <Select
                    style={{ width: '200px' }}
                    value={infoTemplate.originType}
                    onChange={(value) => handleChange('originType', value)}
                  >
                    <Select.Option value={'DOMESTIC'}>국내산</Select.Option>
                    <Select.Option value={'FOREIGN'}>수입산</Select.Option>
                    <Select.Option value={'ETC'}>기타</Select.Option>
                  </Select>
                  {infoTemplate.originType === 'ETC' && (
                    <Space>
                      <label style={{ width: '80px', display: 'inline-block', textAlign: 'right' }}>직접입력</label>
                      <Input
                        width={300}
                        name="originMemo"
                        value={infoTemplate.originMemo}
                        onChange={({ target: { name, value } }) => handleChange(name, value)}
                      />
                    </Space>
                  )}
                </Space>
              </Col>
              <Col xs={24}>
                <Space>
                  <label style={{ width: '140px', display: 'inline-block' }}>중량</label>
                  <Input
                    style={{ width: '200px' }}
                    name="weight"
                    value={infoTemplate.weight}
                    onChange={({ target: { name, value } }) => handleChange(name, value)}
                  />
                </Space>
                {!isWeight && <ErrorMessage paddingLeft="150px" marginBottom="5px" message="중량을 입력해주세요." />}
              </Col>
              <Col xs={24}>
                <Space>
                  <label style={{ width: '140px', display: 'inline-block' }}>크기</label>
                  <Input
                    style={{ width: '200px' }}
                    name="size"
                    value={infoTemplate.size}
                    onChange={({ target: { name, value } }) => handleChange(name, value)}
                  />
                </Space>
                {!isSize && <ErrorMessage paddingLeft="150px" marginBottom="5px" message="크기를 입력해주세요." />}
              </Col>

              <Col xs={24}>
                <Space>
                  <label style={{ width: '140px', display: 'inline-block' }}>포장 단위별 수량</label>
                  <Input
                    style={{ width: '200px' }}
                    name="quantityPerPackage"
                    value={infoTemplate.quantityPerPackage}
                    onChange={({ target: { name, value } }) => handleChange(name, value)}
                  />
                </Space>
                {!isQuantityPerPackage && <ErrorMessage paddingLeft="150px" marginBottom="5px" message="수량을 입력해주세요." />}
              </Col>
              <Col xs={24}>
                <Space>
                  <label style={{ width: '140px', display: 'inline-block' }}>상품구성</label>
                  <Input
                    style={{ width: '200px' }}
                    name="legalProductComposition"
                    value={infoTemplate.legalProductComposition}
                    onChange={({ target: { name, value } }) => handleChange(name, value)}
                  />
                </Space>
                {!isLegalProductComposition && <ErrorMessage paddingLeft="150px" marginBottom="5px" message="상품구성을 입력해주세요." />}
              </Col>
              <Col xs={24}>
                <Space>
                  <label style={{ width: '140px', display: 'inline-block' }}>보관</label>
                  <Input
                    style={{ width: '200px' }}
                    name="storageMethod"
                    value={infoTemplate.storageMethod}
                    onChange={({ target: { name, value } }) => handleChange(name, value)}
                  />
                </Space>
                {!isStorageMethod && <ErrorMessage paddingLeft="150px" marginBottom="5px" message="보관방법을 입력해주세요." />}
              </Col>
              <Col xs={24}>
                <Space>
                  <label style={{ width: '140px', display: 'inline-block' }}>축산물법에 따른 등급표시</label>
                  <Input
                    style={{ width: '200px' }}
                    name="legalGrade"
                    value={infoTemplate.legalGrade}
                    onChange={({ target: { name, value } }) => handleChange(name, value)}
                  />
                </Space>
                {!isLegalGrade && <ErrorMessage paddingLeft="150px" marginBottom="5px" message="등급을 입력해주세요." />}
              </Col>
              <Col xs={24}>
                <Space>
                  <label style={{ width: '140px', display: 'inline-block' }}>축산물 이력정보</label>
                  <Input
                    style={{ width: '200px' }}
                    name="legalHistoryInfo"
                    value={infoTemplate.legalHistoryInfo}
                    onChange={({ target: { name, value } }) => handleChange(name, value)}
                  />
                </Space>
                {!isLegalHistoryInfo && <ErrorMessage paddingLeft="150px" marginBottom="5px" message="축산물 이력정보를 입력해주세요." />}
              </Col>
              <Col xs={24}>
                <Space align="start">
                  <label style={{ width: '140px', display: 'inline-block' }}>제조년월일</label>
                  <Row justify="start" gutter={[0, 8]}>
                    <Col xs={24}>
                      <Radio.Group
                        style={{ width: '200px' }}
                        name="productionDateType"
                        value={infoTemplate.productionDateType}
                        onChange={({ target: { name, value } }) => handleChange(name as string, value)}
                      >
                        <Radio value={'CALENDAR'}>캘린더</Radio>
                        <Radio value={'INPUT'}>직접입력</Radio>
                      </Radio.Group>
                    </Col>
                    {infoTemplate.productionDateType === 'CALENDAR' && (
                      <Col xs={24}>
                        <DatePicker
                          name="productionDate"
                          value={dateValue(infoTemplate.productionDate)}
                          locale={locale}
                          onChange={(value, dateString) => handleChange('productionDate', dateString)}
                        />
                      </Col>
                    )}
                    {infoTemplate.productionDateType === 'INPUT' && (
                      <Col xs={24}>
                        <Input
                          style={{ width: '133px' }}
                          name="productionDate"
                          value={infoTemplate.productionDate}
                          onChange={({ target: { name, value } }) => handleChange(name, value)}
                        />
                      </Col>
                    )}
                  </Row>
                </Space>
                {!isProductionDate && <ErrorMessage paddingLeft="150px" marginBottom="5px" message="제조일자를 입력해주세요." />}
              </Col>
              <Col xs={24}>
                <Space align="start">
                  <label style={{ width: '140px', display: 'inline-block' }}>유통기한 또는 품질유지기한</label>
                  <Row justify="start" gutter={[0, 8]}>
                    <Col xs={24}>
                      <Radio.Group
                        style={{ width: '200px' }}
                        name="expiryDateType"
                        value={infoTemplate.expiryDateType}
                        onChange={({ target: { name, value } }) => handleChange(name as string, value)}
                      >
                        <Radio value={'CALENDAR'}>캘린더</Radio>
                        <Radio value={'INPUT'}>직접입력</Radio>
                      </Radio.Group>
                    </Col>
                    {infoTemplate.expiryDateType === 'CALENDAR' && (
                      <Col xs={24}>
                        <DatePicker
                          name="expiryDate"
                          value={dateValue(infoTemplate.expiryDate)}
                          locale={locale}
                          onChange={(value, dateString) => handleChange('expiryDate', dateString)}
                        />
                      </Col>
                    )}
                    {infoTemplate.expiryDateType === 'INPUT' && (
                      <Col xs={24}>
                        <Input
                          style={{ width: '133px' }}
                          name="expiryDate"
                          value={infoTemplate.expiryDate}
                          onChange={({ target: { name, value } }) => handleChange(name, value)}
                        />
                      </Col>
                    )}
                  </Row>
                </Space>
                {!isExpiryDate && <ErrorMessage paddingLeft="150px" marginBottom="5px" message="유통기한을 입력해주세요." />}
              </Col>
              <Col xs={24}>
                <Space>
                  <label style={{ width: '140px', display: 'inline-block' }}>주의사항</label>
                  <TextArea
                    style={{ width: '400px' }}
                    name="cautionInfo"
                    value={infoTemplate.cautionInfo}
                    onChange={({ target: { name, value } }) => handleChange(name, value)}
                  />
                </Space>
                {!isCautionInfo && <ErrorMessage paddingLeft="150px" marginBottom="5px" message="주의사항을 입력해주세요." />}
              </Col>
            </Row>
          </Space>
          <Affix offsetBottom={0}>
            <Card bordered={false} type="inner">
              <Row justify="end" gutter={[8, 0]}>
                <Col>
                  <Button type="primary" onClick={() => save()} disabled={!isValid}>
                    저장
                  </Button>
                </Col>
                <Col>
                  <Button type="primary" danger href="/products/template">
                    취소
                  </Button>
                </Col>
              </Row>
            </Card>
          </Affix>
        </Card>
      </Row>
      <ProductInfoTemplateEditCopyTarget {...popup} />
    </Layout>
  );
};
