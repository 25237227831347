import React, { useEffect, useRef, useState } from 'react';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Col, Input, message, Modal, Row, Select, Switch } from 'antd';
import { AgreementContent, agreementContentSchema } from '../../store/system/agreement.state';
import { useAxios } from '../../hook/axios';
import confirm from 'antd/es/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ToastEditor } from '../../components/ToastEditor';
import { Code } from '../../store/system/system.state';
import { ValidUtils } from '../../utils/ValidUtils';
import sanitizeHtml from 'sanitize-html';
import { ErrorMessage } from '../../components/ErrorMessage';

export interface AgreementContentEditDialogProps {
  agreeType: string;
  open: boolean;
  close: () => void;
  reloadFn: Function;
}

const initPopup: AgreementContent = {
  agreeType: '',
  agreeTitle: '',
  agreeContents: '',
  enabled: true,
};

export const AgreementContentEditDialog = (props: AgreementContentEditDialogProps): JSX.Element => {
  const [detail, setDetail] = useState<AgreementContent>(initPopup);

  const { result, fetch } = useAxios<void, AgreementContent>({
    url: `/api/admin/agreement-contents/${props.agreeType}`,
    method: 'get',
  });

  const { result: types, fetch: fetchTypes } = useAxios<void, Array<Code>>({
    url: '/api/admin/agreement-contents/types',
    method: 'get',
  });

  useEffect(() => {
    fetchTypes();
  }, []);

  useEffect(() => {
    if (!!props.agreeType) {
      fetch().then((result) => {
        setDetail(result);
      });
    }
  }, [props.agreeType]);

  useEffect(() => {
    if (!!result) {
      setDetail(result);
    }
  }, [result]);

  const getContents = (): string => {
    try {
      // @ts-ignore
      return editorRef.current.getInstance().getHTML();
    } catch {
      return '';
    }
  };

  const editorRef = useRef(null);

  const { fetch: saveFn } = useAxios<AgreementContent, undefined>({
    url: '/api/admin/agreement-contents',
    method: 'post',
    data: detail,
  });

  const { fetch: updateFn } = useAxios<AgreementContent, undefined>({
    url: '/api/admin/agreement-contents',
    method: 'put',
    data: detail,
  });

  const save = () => {
    confirm({
      title: '약관 내용을 저장하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: '예',
      cancelText: '아니오',
      type: 'error',
      onOk() {
        const content = getContents();
        setDetail({ ...detail, agreeContents: content });

        const fn = !props.agreeType ? saveFn() : updateFn();
        fn.then(() => {
          message.info('약관 저장에 성공하였습니다.');
          setDetail(initPopup);
          props.reloadFn();
          props.close();
        }).catch(() => {
          message.error('약관 저장에 실패하였습니다.');
        });
      },
    });
  };

  const close = () => {
    setDetail(initPopup);
    props.close();
  };

  useEffect(() => {
    const changeData = {
      ...detail,
      ['agreeContents']: sanitizeHtml(detail.agreeContents, { allowedTags: [], allowedAttributes: {} }),
    };

    const result = schema.isValidSync(changeData);
    setValid(result);
  }, [detail]);

  const [isValid, setValid] = useState<boolean>(false);
  const schema = agreementContentSchema;
  const isType = ValidUtils.isValid(schema, 'agreeType', detail.agreeType);
  const isTitle = ValidUtils.isValid(schema, 'agreeTitle', detail.agreeTitle);
  const isContents = ValidUtils.isValidForContents(schema, 'agreeContents', detail.agreeContents);

  return (
    <Modal
      title={!!props.agreeType ? '약관수정' : '약관추가'}
      centered
      visible={props.open}
      okText="저장"
      cancelText="취소"
      okButtonProps={{
        disabled: !isValid,
      }}
      onOk={() => save()}
      onCancel={() => close()}
      width={600}
    >
      <Row gutter={[0, 12]}>
        <Col xs={24} style={{ marginBottom: '0.5rem' }}>
          <Row>
            <Col xs={4}>
              <span>약관 타입</span>
            </Col>
            <Col xs={20}>
              <Select
                value={detail.agreeType}
                disabled={!!props.agreeType}
                onChange={(value) => setDetail({ ...detail, agreeType: value })}
                style={{ width: '460px' }}
              >
                <Select.Option value="">선택</Select.Option>
                {!!types && types.length > 0 ? (
                  types.map((item) => (
                    <Select.Option key={`agree-type-${item.id}`} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </Col>
            <Col xs={24}>{!isType && <ErrorMessage paddingLeft="95px" marginTop="5px" message="약관 타입을 선택해주세요." />}</Col>
          </Row>
        </Col>
        <Col xs={24} style={{ marginBottom: '0.5rem' }}>
          <Row>
            <Col xs={4}>
              <span>약관명</span>
            </Col>
            <Col xs={20}>
              <Input
                name="agreeTitle"
                value={detail.agreeTitle}
                onChange={({ target: { name, value } }) => setDetail({ ...detail, [name]: value })}
              />
            </Col>
            <Col xs={24}>{!isTitle && <ErrorMessage paddingLeft="95px" marginTop="5px" message="약관명을 입력해주세요." />}</Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row>
            <Col xs={4} />
            <Col xs={20}>
              <ToastEditor
                name="agreeContents"
                initialValue={detail.agreeContents}
                onChange={(name, value) => setDetail({ ...detail, [name]: value })}
              />
            </Col>
            <Col xs={24}>
              {!isContents && <ErrorMessage paddingLeft="95px" marginTop="5px" marginBottom="5px" message="약관내용을 입력해주세요." />}
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row>
            <Col xs={4}>
              <span>사용여부:</span>
            </Col>
            <Col xs={20}>
              <Switch checked={detail.enabled} onChange={(checked) => setDetail({ ...detail, enabled: checked })} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};
