import { OrderDeliveryListState } from '../../store/order/order.delivery.state';
import { OptRow } from 'tui-grid/types/options';
import { Button, message } from 'antd';
import React from 'react';
import { useAxios } from '../../hook/axios';
import confirm from 'antd/es/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export interface DeliveryCompleteButtonProps {
  data: Array<OrderDeliveryListState & OptRow>;
  reload: Function;
}

export const DeliveryCompleteButton = (props: DeliveryCompleteButtonProps) => {
  const { fetch } = useAxios<Array<OrderDeliveryListState & OptRow>, void>({
    url: '/api/admin/orders/deliveries/complete',
    method: 'patch',
  });

  const handleDeliveryComplete = () => {
    const orders = props.data;
    if (orders.length <= 0) {
      message.error('한건 이상의 배송건을 선택해주세요');
      return;
    }

    if (orders.some((order) => order.deliveryStatusType === 'DELIVERY_COMPLETED')) {
      message.error('이미 배송완료된 건이 존재합니다.');
      return;
    }

    const completeOrders = props.data.filter((value) => value.deliveryStatusType !== 'DELIVERY_COMPLETED');

    confirm({
      title: '선택된 배송건을 배송완료 처리하시겠습니까?',
      content: '배송완료 처리가 필요한 경우에만 사용하시기 바랍니다.',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: '예',
      cancelText: '아니오',
      type: 'error',
      onOk() {
        fetch(completeOrders)
          .then(() => {
            message.success('선택된 배송건이 배송완료 처리되었습니다.');
            props.reload();
          })
          .catch(() => message.error('선택한 배송건을 배송완료 처리하는데 실패하였습니다.'));
      },
    });
  };

  return (
    <Button type="primary" onClick={() => handleDeliveryComplete()}>
      배송완료
    </Button>
  );
};
