import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Input, Layout, List, Row, Space, Table, Typography } from 'antd';
import { contentTitle } from '../../style/page';
import { useAxios } from '../../hook/axios';
import { ColumnsType } from 'antd/lib/table/interface';
import { CattleApperenceRequest, CattleApperence } from '../../store/ext/cattle.apperence';

const date: string =
  new Date().getFullYear() +
  '-' +
  (new Date().getMonth() + 1).toString().padStart(2, '0') +
  '-' +
  (new Date().getDate() - 1).toString().padStart(2, '0');

const initQuery: CattleApperenceRequest = {
  baseYmd: date,
};

export const CattleApperenceSearch = (): JSX.Element => {
  const [query, setQuery] = useState<CattleApperenceRequest>({ ...initQuery });
  const [pageQuery, setPageQuery] = useState<CattleApperenceRequest>({ ...initQuery });

  const { result: page, fetch: listFetch } = useAxios<CattleApperenceRequest, Array<CattleApperence>>({
    url: 'api/v1/ekape/auct/cattle/apperence',
    method: 'post',
    params: query,
  });

  const handleSearch = () => {
    setPageQuery({ ...initQuery });
    listFetch(query);
  };

  const columns: ColumnsType<CattleApperence> = [
    {
      title: '기준 날짜',
      dataIndex: 'baseYmd',
      key: 'baseYmd',
      width: 100,
      align: 'center',
      render: (baseYmd: string) => <>{baseYmd || '-'}</>,
    },
    {
      title: '기준 날짜 이전 경매일',
      dataIndex: 'beforeYmd',
      key: 'beforeYmd',
      width: 100,
      align: 'center',
      render: (beforeYmd: string) => <>{beforeYmd || '-'}</>,
    },
    {
      title: '가축 종류 코드',
      dataIndex: 'judgeKindCd',
      key: 'judgeKindCd',
      width: 100,
      align: 'center',
      render: (judgeKindCd: string) => <>{judgeKindCd || '-'}</>,
    },
    {
      title: '가축명',
      dataIndex: 'judgeKindNm',
      key: 'judgeKindNm',
      width: 100,
      align: 'center',
      render: (judgeKindNm: string) => <>{judgeKindNm || '-'}</>,
    },
    {
      title: '소 품종 구분',
      dataIndex: 'judgeBreedCd',
      key: 'judgeBreedCd',
      width: 100,
      align: 'center',
      render: (judgeBreedCd: string) => <>{judgeBreedCd || '-'}</>,
    },
    {
      title: '소 품종명',
      dataIndex: 'judgeBreedNm',
      key: 'judgeBreedNm',
      width: 100,
      align: 'center',
      render: (judgeBreedNm: string) => <>{judgeBreedNm || '-'}</>,
    },
    {
      title: '권역명',
      dataIndex: 'localNm',
      key: 'localNm',
      width: 100,
      align: 'center',
      render: (localNm: string) => <>{localNm || '-'}</>,
    },
    {
      title: '권역 코드',
      dataIndex: 'localCode',
      key: 'localCode',
      width: 100,
      align: 'center',
      render: (localCode: string) => <>{localCode || '-'}</>,
    },
    {
      title: '경매 두수',
      dataIndex: 'auctCnt',
      key: 'auctCnt',
      width: 100,
      align: 'center',
      render: (auctCnt: number) => <>{auctCnt || '-'}</>,
    },
    {
      title: '경매 금액',
      dataIndex: 'auctAmt',
      key: 'auctAmt',
      width: 100,
      align: 'center',
      render: (auctAmt: number) => <>{auctAmt || '-'}</>,
    },
    {
      title: '전일 경매 금액 차이',
      dataIndex: 'diffAuctAmt',
      key: 'diffAuctAmt',
      width: 100,
      align: 'center',
      render: (diffAuctAmt: number) => <>{diffAuctAmt || '-'}</>,
    },
  ];

  return (
    <Layout>
      <Row style={{ ...contentTitle }}>
        <Typography.Title level={5} style={{ margin: '0' }}>
          권역별 경락 가격 현황
        </Typography.Title>
      </Row>

      <Row style={{ padding: '1rem' }}>
        <Col xs={24}>
          <Card style={{ width: '100%' }}>
            <Row gutter={[20, 10]}>
              <Col>
                <Space>
                  <label style={{ width: '80px', display: 'inline-block' }}>경매 기준일</label>
                  <Input
                    style={{ width: '180px' }}
                    name="baseYmd"
                    value={query.baseYmd.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
            </Row>

            <Col>
              <Divider />
            </Col>

            <Row justify="center">
              <Space>
                <Button type="primary" htmlType="submit" onClick={() => handleSearch()}>
                  검색
                </Button>
              </Space>
            </Row>
          </Card>
        </Col>

        <Col xs={24} style={{ marginTop: '2rem' }}>
          <Table
            size="large"
            style={{ width: '100%' }}
            rowKey="cattleApperenceId"
            dataSource={page}
            columns={columns}
            scroll={{ x: 1400, y: 700 }}
          />
        </Col>
      </Row>
    </Layout>
  );
};
