import { Button, Card, Col, DatePicker, Divider, Input, Layout, message, Pagination, Row, Select, Space, Typography } from 'antd';
import { contentTitle } from '../../style/page';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { CategoryComponent } from '../../components/CategoryComponent';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import locale from 'antd/es/date-picker/locale/ko_KR';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { OrderDeliveryUploadPopup, OrderDeliveryUploadPopupProps } from './OrderDeliveryUploadPopup';
import confirm from 'antd/es/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { CodeObject, defaultPage, Page } from '../../store';
import { OrderDeliverySuspendPopup, OrderDeliverySuspendPopupProps } from './OrderDeliveySuspendPopup';
import { DataGrid } from '../../components/DataGrid';
import { OptColumn, OptRow } from 'tui-grid/types/options';
import { useAxios } from '../../hook/axios';
import { useRecoilValue } from 'recoil';
import { codeListState } from '../../store/index.store';
import {
  OrderDeliveryListQueryState,
  OrderDeliveryListState,
  OrderDeliveryProgressState,
  OrderSuspendKeyState,
} from '../../store/order/order.delivery.state';
import { textValue, withAll } from '../../utils/EnumUtils';
import { DeliveryCompleteButton } from './DeliveryCompleteButton';
import { OrderStatusType } from '../../store/order/order.state';

const initQuery: OrderDeliveryListQueryState = {
  categories: [],
  paymentCompletedStartDate: '',
  paymentCompletedEndDate: '',
  orderCheckedStartDate: '',
  orderCheckedEndDate: '',
  deliveryTrackingCreatedStartDate: '',
  deliveryTrackingCreatedEndDate: '',
  orderNo: '',
  purchaserName: '',
  purchaserEmail: '',
  destRecipientName: '',
  purchaserCellphoneNo: '',
  destRecipientCellphoneNo: '',
  productSku: '',
  deliveryTrackingNo: '',
  orderStatusType: '',
  deliveryStatusType: '',
  paymentStatusType: '',
  orderCancelStatusType: '',
  deliveryType: '',
  page: 1,
  size: 10,
};

const initPage: Page<OrderDeliveryListState> = {
  ...defaultPage,
  contents: [],
};

const deliveryCompanyListItem = [
  {
    text: '로젠택배',
    value: '06',
  },
];

export const OrderDeliveryListPage = (): JSX.Element => {
  const codes = useRecoilValue<CodeObject>(codeListState);
  const [query, setQuery] = useState(initQuery);
  const [pageQuery, setPageQuery] = useState(initQuery);
  const [page, setPage] = useState(initPage);

  const { result, fetch } = useAxios<OrderDeliveryListQueryState, Page<OrderDeliveryListState>>({
    url: '/api/admin/orders/dispatches',
    method: 'get',
    params: query,
  });

  const [orderDeliveryUploadPopup, setOrderDeliveryUploadPopup] = useState<OrderDeliveryUploadPopupProps>({
    open: false,
    close: () =>
      setOrderDeliveryUploadPopup({
        ...orderDeliveryUploadPopup,
        open: false,
      } as OrderDeliveryUploadPopupProps),
    reloadFn: fetch,
  } as OrderDeliveryUploadPopupProps);

  const pageSizes = [
    { label: '10개씩 보기', value: 10 },
    { label: '20개씩 보기', value: 20 },
    { label: '30개씩 보기', value: 30 },
    { label: '50개씩 보기', value: 50 },
    { label: '100개씩 보기', value: 100 },
  ];

  const [orderDeliveryGridList, setOrderDeliveryGridList] = useState<Array<OrderDeliveryListState & OptRow>>([]);

  useEffect(() => {
    setPageQuery({ ...query });
    fetch();
  }, []);

  useEffect(() => {
    if (!!result) {
      setPage(result);
    } else {
      setPage({ ...initPage });
    }
  }, [result]);

  useEffect(() => {
    setOrderDeliveryGridList(
      page.contents.map((item, index) => ({
        ...item,
        rowKey: index,
        _attributes: {
          checked: false,
        },
      })),
    );
  }, [page]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setQuery({
      ...query,
      [name]: value,
    });
  };

  const handleCategoriesChange = (name: string, value: Array<number>) => {
    setQuery({
      ...query,
      [name]: value,
    });
  };

  const handleRangeDate = (startDateName: string, endDateName: string, rangeDate: RangeValue<Moment>) => {
    if (!!rangeDate && !!rangeDate[0] && !!rangeDate[1]) {
      setQuery({
        ...query,
        [startDateName]: rangeDate[0].format('YYYY-MM-DD HH:mm:ss'),
        [endDateName]: rangeDate[1].format('YYYY-MM-DD HH:mm:ss'),
      });
    } else {
      setQuery({
        ...query,
        [startDateName]: null,
        [endDateName]: null,
      });
    }
  };

  const handleSelectValue = (name: string, value: any) => {
    setQuery({ ...query, [name]: value });
  };

  const handleSearch = () => {
    setPageQuery({ ...query, page: 1 });
    fetch();
  };

  const handleReset = () => {
    setQuery({ ...initQuery });
    setPageQuery({ ...initQuery });
  };

  const rangePaymentDateValue = (): RangeValue<Moment> | null => {
    if (!!query.paymentCompletedStartDate && !!query.paymentCompletedEndDate) {
      return [moment(query.paymentCompletedStartDate), moment(query.paymentCompletedEndDate)];
    }
    return null;
  };

  const rangeOrderCheckDateValue = (): RangeValue<Moment> | null => {
    if (!!query.orderCheckedStartDate && !!query.orderCheckedEndDate) {
      return [moment(query.orderCheckedStartDate), moment(query.orderCheckedEndDate)];
    }
    return null;
  };

  const rangeDeliveryDateValue = (): RangeValue<Moment> | null => {
    if (!!query.deliveryTrackingCreatedStartDate && !!query.deliveryTrackingCreatedEndDate) {
      return [moment(query.deliveryTrackingCreatedStartDate), moment(query.deliveryTrackingCreatedEndDate)];
    }
    return null;
  };

  const handlePagination = (page: number, pageSize: number) => {
    const newPageQuery: OrderDeliveryListQueryState = {
      ...pageQuery,
      ['page']: page,
      ['size']: pageSize,
    };
    // page만 전환
    setPageQuery(newPageQuery);
    fetch(newPageQuery);
  };

  const { fetch: excelFetch } = useAxios<any, any>({
    url: '/api/admin/orders/dispatches/xlsx',
    method: 'get',
    params: query,
  });

  const handleExcel = () => {
    confirm({
      title: '엑셀다운로드',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: '예',
      cancelText: '아니오',
      type: 'error',
      onOk() {
        excelFetch({ ...query })
          .then((result) => {
            const a = document.createElement('a');
            a.href = `data:${result.mimeType};base64,${result.data}`;
            a.download = result.filename; //File name Here
            a.click(); //Downloaded file
            a.remove();

            message.info('엑셀 다운로드가 완료되었습니다.');
          })
          .catch(() => {
            message.error('엑셀 다운로드에 실패했습니다.');
          });
      },
    });
  };

  const [orderDeliverySuspendPopup, setOrderDeliverySuspendPopup] = useState<OrderDeliverySuspendPopupProps>({
    keys: [],
    open: false,
    reloadFn: fetch,
    close: () =>
      setOrderDeliverySuspendPopup({
        ...orderDeliverySuspendPopup,
        keys: [],
        open: false,
      } as OrderDeliverySuspendPopupProps),
  } as OrderDeliverySuspendPopupProps);

  const openSuspendPopup = () => {
    const orders = orderDeliveryGridList.filter((item) => item._attributes!.checked).map((item) => item);
    if (orders.length <= 0) {
      message.error('한건 이상의 주문을 선택해주세요');
      return;
    }

    const notAvailableStatus = ['DELIVERY_PROGRESS', 'DELIVERY_COMPLETED', 'REQUEST_CANCEL_ORDER', 'COMPLETE_CANCEL_ORDER'];
    const cannotSuspend = orders.some((order) => notAvailableStatus.includes(order.deliveryStatusType));

    if (cannotSuspend) {
      message.error('발송 전 상품만 발송대기 상태로 변경할 수 있습니다.');
      return;
    }

    const keys: Array<OrderSuspendKeyState> = orders.map((content) => ({
      orderId: content.orderId!,
      orderDestSeq: content.orderDestSeq!,
      orderDeliverySeq: content.orderDeliverySeq!,
      productId: content.productId!,
    }));

    setOrderDeliverySuspendPopup({
      ...orderDeliverySuspendPopup,
      open: true,
      keys: keys,
    });
  };

  const { fetch: sendDispatch } = useAxios<OrderDeliveryProgressState[], undefined>({
    url: '/api/admin/orders/dispatches',
    method: 'post',
  });

  const handleDispatch = () => {
    const dispatchList: OrderDeliveryProgressState[] = orderDeliveryGridList
      .filter((item) => item._attributes?.checked)
      .map((item) => ({
        orderId: item.orderId,
        orderDestSeq: item.orderDestSeq,
        orderDeliverySeq: item.orderDeliverySeq,
        productId: item.productId,
        productName: item.productName,
        deliveryQuantity: item.productQuantity,
        deliveryType: item.deliveryType,
        deliveryCompanyCode: item.deliveryCompanyCode,
        deliveryTrackingNo: item.deliveryTrackingNo,
        deliveryMemo: item.deliveryRequestMemo,
      }));

    sendDispatch(dispatchList).then(fetch);
  };

  const columns: Array<OptColumn> = [
    { header: '주문번호', name: 'orderNo', width: 200, align: 'center' },
    { header: '주문일시', name: 'createdAt', width: 200, align: 'center' },
    {
      header: '주문상태',
      name: 'orderStatusType',
      width: 120,
      align: 'center',
      formatter: 'listItemText',
      editor: {
        type: 'select',
        options: {
          listItems: textValue(
            codes.OrderStatusType.filter(
              (type) =>
                Object.keys(OrderStatusType).indexOf(type.value) >= Object.keys(OrderStatusType).indexOf(OrderStatusType.CHECK_ORDER),
            ),
          ), // 의미가 있나 이거?
        },
      },
    },
    {
      header: '결제상태',
      name: 'paymentStatusType',
      width: 120,
      align: 'center',
      formatter: 'listItemText',
      editor: {
        type: 'select',
        options: {
          listItems: textValue(codes.PaymentStatusType),
        },
      },
    },
    {
      header: '배송상태',
      name: 'deliveryStatusType',
      width: 120,
      align: 'center',
      formatter: 'listItemText',
      editor: {
        type: 'select',
        options: {
          listItems: textValue(codes.DeliveryStatusType),
        },
      },
    },
    { header: '주문확인일', name: 'orderCheckedAt', width: 120, align: 'center' },
    { header: '구매자ID', name: 'purchaserEmail', width: 120, align: 'center' },
    { header: '구매자명', name: 'purchaserName', width: 120, align: 'center' },
    { header: '상품명', name: 'productName', width: 200, align: 'center' },
    { header: '수량', name: 'productQuantity', width: 120, align: 'center' },
    {
      header: '배송방법',
      name: 'deliveryType',
      width: 200,
      align: 'center',
      formatter: 'listItemText',
      relations: [
        {
          targetNames: ['deliveryCompanyCode'],
          disabled: ({ value }) => value !== 'PARCEL',
          listItems: ({ value }) => deliveryCompanyListItem,
        },
        {
          targetNames: ['deliveryTrackingNo'],
          disabled: ({ value }) => value !== 'PARCEL',
        },
      ],
      editor: {
        type: 'select',
        options: {
          listItems: textValue(codes.DeliveryType),
        },
      },
    },
    {
      header: '택배사',
      name: 'deliveryCompanyCode',
      width: 200,
      align: 'center',
      formatter: 'listItemText',
      editor: {
        type: 'select',
        options: {
          listItems: deliveryCompanyListItem, // relation에 의해 가려짐
        },
      },
    },
    { header: '송장번호', name: 'deliveryTrackingNo', width: 200, align: 'center', editor: 'text' },
    { header: '발송일', name: 'deliveryTrackingCreatedAt', width: 120, align: 'center' },
    { header: '수령인 이름', name: 'destRecipientName', width: 120, align: 'center' },
    { header: '수령인 연락처', name: 'destRecipientCellphoneNo', width: 120, align: 'center' },
    { header: '수령인 우편번호', name: 'destZipcode', width: 80, align: 'center' },
    { header: '수령인 주소', name: 'destAddress', width: 200, align: 'center' },
    { header: '출입방법(상세)', name: 'destEnterTypeMemo', width: 200, align: 'center' },
    { header: '배송메모/요청사항', name: 'deliveryRequestMemo', width: 200, align: 'center' },
  ];

  const selectedOrder = () => {
    return orderDeliveryGridList.filter((item) => item._attributes!.checked).map((item) => item);
  };

  return (
    <Layout style={{ minWidth: '1200px' }}>
      <Row style={{ ...contentTitle }}>
        <Typography.Title level={5} style={{ margin: '0' }}>
          배송 관리
        </Typography.Title>
      </Row>

      <Row style={{ padding: '1rem' }}>
        <Col xs={24}>
          <Card style={{ width: '100%' }}>
            <Row>
              <Col xs={24}>
                <Row gutter={[12, 2]}>
                  <Col xs={24}>
                    <Space>
                      <label style={{ width: '140px', display: 'inline-block' }}>카테고리</label>
                      <CategoryComponent value={query.categories} name="categories" onChange={handleCategoriesChange} />
                    </Space>
                  </Col>
                  <Col xs={24}>
                    <Divider />
                  </Col>
                  <Col xs={24}>
                    <Space align={'start'}>
                      <label style={{ width: '140px', display: 'inline-block' }}>조회기간</label>
                      <Row gutter={[16, 8]}>
                        <Col>
                          <label style={{ width: '80px', display: 'inline-block' }}>결제일</label>
                          <DatePicker.RangePicker
                            locale={locale}
                            style={{ width: '240px' }}
                            defaultValue={rangePaymentDateValue()}
                            value={rangePaymentDateValue()}
                            onChange={(value) => handleRangeDate('paymentCompletedStartDate', 'paymentCompletedEndDate', value)}
                          />
                        </Col>
                        <Col>
                          <label style={{ width: '80px', display: 'inline-block' }}>주문확인일</label>
                          <DatePicker.RangePicker
                            locale={locale}
                            style={{ width: '240px' }}
                            defaultValue={rangeOrderCheckDateValue()}
                            value={rangeOrderCheckDateValue()}
                            onChange={(value) => handleRangeDate('orderCheckedStartDate', 'orderCheckedEndDate', value)}
                          />
                        </Col>
                        <Col xs={24}>
                          <label style={{ width: '80px', display: 'inline-block' }}>배송처리일</label>
                          <DatePicker.RangePicker
                            locale={locale}
                            style={{ width: '240px' }}
                            defaultValue={rangeDeliveryDateValue()}
                            value={rangeDeliveryDateValue()}
                            onChange={(value) =>
                              handleRangeDate('deliveryTrackingCreatedStartDate', 'deliveryTrackingCreatedEndDate', value)
                            }
                          />
                        </Col>
                      </Row>
                    </Space>
                  </Col>
                  <Col xs={24}>
                    <Divider />
                  </Col>
                  <Col xs={24}>
                    <Space align={'start'}>
                      <label style={{ width: '140px', display: 'inline-block' }}>조건</label>
                      <Row gutter={[16, 8]}>
                        <Col xs={24}>
                          <Row gutter={[36, 8]}>
                            <Col xs={24}>
                              <Row gutter={[36, 8]}>
                                <Col>
                                  <label style={{ width: '80px', display: 'inline-block' }}>주문상태</label>
                                  <Select
                                    options={withAll(
                                      codes.OrderStatusType.filter(
                                        (type) =>
                                          Object.keys(OrderStatusType).indexOf(type.value) >=
                                          Object.keys(OrderStatusType).indexOf(OrderStatusType.CHECK_ORDER),
                                      ),
                                    )}
                                    style={{ width: '160px' }}
                                    defaultValue={query.orderStatusType}
                                    value={query.orderStatusType}
                                    onChange={(value) => handleSelectValue('orderStatusType', value)}
                                  />
                                </Col>
                                <Col>
                                  <label style={{ width: '80px', display: 'inline-block' }}>배송상태</label>
                                  <Select
                                    options={withAll(codes.DeliveryStatusType)}
                                    style={{ width: '160px' }}
                                    defaultValue={query.deliveryStatusType}
                                    value={query.deliveryStatusType}
                                    onChange={(value) => handleSelectValue('deliveryStatusType', value)}
                                  />
                                </Col>
                                <Col>
                                  <label style={{ width: '80px', display: 'inline-block' }}>배송방법</label>
                                  <Select
                                    options={withAll(codes.DeliveryType)}
                                    style={{ width: '160px' }}
                                    defaultValue={query.deliveryType}
                                    value={query.deliveryType}
                                    onChange={(value) => handleSelectValue('deliveryType', value)}
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              <label style={{ width: '80px', display: 'inline-block' }}>주문번호</label>
                              <Input style={{ width: '160px' }} name="orderNo" value={query.orderNo} onChange={handleChange} />
                            </Col>
                            <Col>
                              <label style={{ width: '80px', display: 'inline-block' }}>구매자명</label>
                              <Input style={{ width: '160px' }} name="purchaserName" value={query.purchaserName} onChange={handleChange} />
                            </Col>
                            <Col>
                              <label style={{ width: '80px', display: 'inline-block' }}>수취인명</label>
                              <Input
                                style={{ width: '160px' }}
                                name="destRecipientName"
                                value={query.destRecipientName}
                                onChange={handleChange}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={24}>
                          <Row gutter={[36, 8]}>
                            <Col>
                              <label style={{ width: '80px', display: 'inline-block' }}>구매자ID</label>
                              <Input
                                style={{ width: '160px' }}
                                name="purchaserEmail"
                                value={query.purchaserEmail}
                                onChange={handleChange}
                              />
                            </Col>
                            <Col>
                              <label style={{ width: '80px', display: 'inline-block' }}>연락처</label>
                              <Input
                                style={{ width: '160px' }}
                                name="destRecipientCellphoneNo"
                                value={query.destRecipientCellphoneNo}
                                onChange={handleChange}
                              />
                            </Col>
                            <Col>
                              <label style={{ width: '80px', display: 'inline-block' }}>상품코드</label>
                              <Input style={{ width: '160px' }} name="productSku" value={query.productSku} onChange={handleChange} />
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={24}>
                          <Row gutter={[36, 8]}>
                            <Col>
                              <label style={{ width: '80px', display: 'inline-block' }}>송장번호</label>
                              <Input
                                style={{ width: '160px' }}
                                name="deliveryTrackingNo"
                                value={query.deliveryTrackingNo}
                                onChange={handleChange}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Space>
                  </Col>
                  <Col xs={24}>
                    <Divider />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row justify="center">
              <Space>
                <Button type="primary" htmlType="submit" onClick={() => handleSearch()}>
                  검색
                </Button>

                <Button type="default" onClick={() => handleReset()}>
                  초기화
                </Button>
              </Space>
            </Row>
          </Card>
        </Col>

        <Col xs={24} style={{ marginTop: '2rem' }}>
          <Row justify="space-between">
            <Space>
              <Button type="primary" onClick={() => handleDispatch()}>
                발송처리
              </Button>
              <DeliveryCompleteButton data={selectedOrder()} reload={fetch} />
              <Button type="primary" onClick={() => openSuspendPopup()}>
                발송대기
              </Button>
              <Button type="primary" onClick={() => setOrderDeliveryUploadPopup({ ...orderDeliveryUploadPopup, open: true })}>
                엑셀 일괄 발송처리
              </Button>
            </Space>
            <Space>
              <Select
                options={pageSizes}
                value={pageQuery.size}
                defaultValue={query.size}
                onChange={(value) => handlePagination(1, value)}
              />
              <Button type="primary" icon={<RiFileExcel2Fill />} onClick={() => handleExcel()}>
                엑셀 다운로드
              </Button>
            </Space>
          </Row>
        </Col>
        <Col xs={24} style={{ margin: '1rem 0' }}>
          <DataGrid key="deliveryGrid" data={orderDeliveryGridList} columns={columns} onSetChange={setOrderDeliveryGridList} />
        </Col>
        <Col xs={24}>
          <Row justify="center">
            <Col>
              <Pagination
                current={pageQuery.page}
                defaultCurrent={query.page}
                pageSize={pageQuery.size}
                defaultPageSize={query.size}
                total={page.totalElements}
                onChange={handlePagination}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <OrderDeliveryUploadPopup {...orderDeliveryUploadPopup} />
      <OrderDeliverySuspendPopup {...orderDeliverySuspendPopup} />
    </Layout>
  );
};
