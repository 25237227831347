import { Col, Input, message, Modal, Radio, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import Button from 'antd-button-color';
import confirm from 'antd/es/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Category, CategoryType } from '../../../store/product/category.state';
import { categoryListState, categoryState } from '../../../store/product/category.store';
import { findLast } from 'lodash';
import { useAxios } from '../../../hook/axios';

export interface ProductCategoryEditPopupProps {
  open: boolean;
  parent?: Category;
  data?: Category;
  categoryType: CategoryType;
  close: () => void;
  reloadFn: Function;
}

export const ProductCategoryEditPopup = (props: ProductCategoryEditPopupProps): JSX.Element => {
  const categories = useRecoilValue(categoryListState);
  const [category, setCategory] = useRecoilState(categoryState);
  const resetCategory = useResetRecoilState(categoryState);
  const [categoryId, setCategoryId] = useState<number>();

  useEffect(() => {
    if (!!props.data) {
      setCategory(props.data);
      setCategoryId(props.data.categoryId);
    } else {
      resetCategory();
    }
  }, [props]);

  const { fetch: saveFn } = useAxios<Category[], undefined>({
    url: '/api/admin/products/categories',
    method: 'post',
  });

  const { fetch: updateFn } = useAxios<Category[], undefined>({
    url: '/api/admin/products/categories',
    method: 'put',
  });

  const { fetch: deleteFn } = useAxios<number, undefined>({
    url: `/api/admin/products/categories/${categoryId}`,
    method: 'delete',
  });

  const categoryTypeName = () => {
    if (props.categoryType === 'LARGE') {
      return '대분류';
    } else if (props.categoryType === 'MIDDLE') {
      return '중분류';
    } else if (props.categoryType === 'SMALL') {
      return '소분류';
    }

    return '';
  };

  const handleChange = (name: string, value: any) => {
    setCategory({
      ...category,
      [name]: value,
    });
  };

  const save = () => {
    confirm({
      title: '현재 내용으로 저장하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: '예',
      cancelText: '아니오',
      type: 'error',
      onOk() {
        let instance = {
          ...category,
        };

        if (!category.categoryId) {
          const lastCategory = findLast(categories, (category) => {
            return category.categoryType === props.categoryType;
          });

          instance.categorySeq = lastCategory!.categorySeq + 1;
          instance.categoryType = props.categoryType;
        }

        const fn = !category.categoryId ? saveFn([instance]) : updateFn([instance]);

        fn.then(() => {
          message.info('카테고리를 저장하였습니다.');
          props.reloadFn();
          props.close();
        }).catch(() => {
          message.error('카테고리를 저장에 실패하였습니다.');
        });
      },
      onCancel() {},
    });
  };

  const deleteCategory = () => {
    confirm({
      title: '선택한 카테고리를 삭제하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: '예',
      cancelText: '아니오',
      type: 'error',
      onOk() {
        deleteFn()
          .then(() => {
            message.info('카테고리를 삭제하였습니다.');
            props.reloadFn();
            props.close();
          })
          .catch(() => {
            message.error('카테고리를 삭제에 실패하였습니다.');
          });
      },
      onCancel() {},
    });
  };

  return (
    <Modal
      title={!!category.categoryId ? '카테고리 수정' : '카테고리 추가'}
      visible={props.open}
      width="337px"
      footer={null}
      onCancel={props.close}
    >
      <Row gutter={[0, 8]}>
        <Col xs={24}>
          <Space>
            <label style={{ width: '80px', display: 'inline-block' }}>현재 분류</label>
            <Input style={{ width: '200px' }} readOnly={true} value={categoryTypeName()} />
          </Space>
        </Col>
        <Col xs={24}>
          <Space>
            <label style={{ width: '80px', display: 'inline-block' }}>카테고리명</label>
            <Input
              style={{ width: '200px' }}
              name="categoryName"
              value={category.categoryName}
              onChange={({ target: { name, value } }) => handleChange(name, value)}
            />
          </Space>
        </Col>

        <Col xs={24}>
          <Space>
            <label style={{ width: '80px', display: 'inline-block' }}>사용유무</label>
            <Radio.Group
              name="enabled"
              value={category.enabled}
              options={[
                { label: '사용', value: true },
                { label: '미사용', value: false },
              ]}
              onChange={({ target: { name, value } }) => handleChange(name!, value)}
            />
          </Space>
        </Col>
        <Col xs={24}>
          <Space>
            <label style={{ width: '80px', display: 'inline-block' }}>설명</label>
            <Input.TextArea
              style={{ width: '200px' }}
              name="categoryDescription"
              value={category.categoryDescription}
              onChange={({ target: { name, value } }) => handleChange(name, value)}
            />
          </Space>
        </Col>
        <Col xs={24}>
          <Row justify={!!props.data ? 'space-between' : 'end'}>
            {!!props.data && (
              <Col>
                <Button type="primary" danger onClick={() => deleteCategory()}>
                  삭제
                </Button>
              </Col>
            )}
            <Col>
              <Button type="primary" onClick={() => save()}>
                저장
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};
