import React, { useEffect, useState } from 'react';
import { message, Modal, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';
import type { RcFile } from 'antd/es/upload';
import { useAxios } from '../hook/axios';
import { ImageState } from '../store';

interface UploadImagesProps {
  value: ImageState[];
  onChange: Function;
  onRemove: (file: UploadFile) => Promise<boolean>;
  limit: number;
}

export const UploadImages = (props: UploadImagesProps): JSX.Element => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleCancel = () => setPreviewVisible(false);

  const { fetch } = useAxios<FormData, ImageState>({
    url: '/api/admin/images',
    method: 'post',
  });

  useEffect(() => {
    setFileList(
      props.value.map<UploadFile>((image) => ({
        uid: String(image.imageId),
        name: image.imageName,
        url: image.imageUrl,
      })),
    );
  }, [props.value]);

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('이미지는 PNG 또는 JPG만 허용됩니다.');
    }
  };

  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url || (file.preview as string));
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Upload
        name="file"
        customRequest={(options) => {
          const file = options.file as RcFile;
          const formData = new FormData();
          formData.append('file', file);

          fetch(formData)
            .then((result) => {
              props.onChange(result);
              message.success('이미지 업로드에 성공하였습니다.');
            })
            .catch(() => {
              message.error('이미지 업로드에 실패하였습니다.');
            });
        }}
        beforeUpload={beforeUpload}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={() => {}}
        onRemove={props.onRemove}
      >
        {fileList.length >= props.limit ? null : uploadButton}
      </Upload>

      <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt={previewTitle} style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};
