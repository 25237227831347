import { Col, Collapse, Input, Row, Space } from 'antd';
import { ToastEditor } from '../../../components/ToastEditor';
import React from 'react';
import { ProductEditProps } from '../ProductEditPage';

export const ProductCustomerFragment = (props: ProductEditProps): JSX.Element => {
  const handleChange = (name: string, value: unknown) => {
    props.setProduct({
      ...props.product,
      productCustomerPolicy: {
        ...props.product.productCustomerPolicy,
        [name]: value,
      },
    });
  };

  const onChangeEditor = (name: string, value: string) => {
    props.setProduct({
      ...props.product,
      productCustomerPolicy: {
        ...props.product.productCustomerPolicy,
        [name]: value,
      },
    });
  };

  return (
    <>
      <Collapse defaultActiveKey={['product_customer_policy2']} expandIconPosition="right">
        <Collapse.Panel key={'product_customer_policy2'} header={'고객센터 및 안내'}>
          <Row gutter={[0, 8]}>
            <Col xs={24}>
              <Space>
                <label style={{ width: '140px', display: 'inline-block' }}>고객센터</label>
                <Input
                  style={{ width: '200px' }}
                  name="customerContact"
                  value={props.product.productCustomerPolicy.customerContact}
                  onChange={({ target: { name, value } }) => handleChange(name, value)}
                />
              </Space>
            </Col>
            <Col xs={24}>
              <Space>
                <label style={{ width: '140px', display: 'inline-block' }}>교환 및 반품 안내</label>
                <ToastEditor
                  key="changeInformation"
                  name="changeInformation"
                  initialValue={props.product.productCustomerPolicy.changeInformation}
                  onChange={onChangeEditor}
                  width="750px"
                  height="200px"
                />
              </Space>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
    </>
  );
};
