import { Affix, Button, Card, Col, Collapse, Input, Layout, Row, Space, Typography } from 'antd';
import { contentTitle } from '../../style/page';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductState } from '../../store/product/product.state';
import { ProductPricePolicyFragment } from './detail/ProductPricePolicyFragment';
import { ProductImageFragment } from './detail/ProductImageFragment';
import { ToastEditor } from '../../components/ToastEditor';
import { ProductInformationFragment } from './detail/ProductInformationFragment';
import { ProductDeliveryFragment } from './detail/ProductDeliveryFragment';
import { ProductReturnAndExchangeFragment } from './detail/ProductReturnAndExchangeFragment';
import confirm from 'antd/es/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ProductDefaultFragment } from './detail/ProductDefaultFragment';
import { ProductCustomerFragment } from './detail/ProductCustomerFragment';
import { useAxios } from '../../hook/axios';
import { ProductEditCopyTargetPopup, ProductEditCopyTargetPopupProps } from './detail/ProductEditCopyTargetPopup';

export interface ProductEditProps {
  product: ProductState;
  setProduct: Dispatch<SetStateAction<ProductState>>;
}

const initProduct: ProductState = {
  productId: undefined,
  productName: '',
  productContents: '',
  productSku: '',
  productDescription: '',
  productStockQuantity: 0,
  productType: 'SINGULAR',
  productStatusType: 'READY',
  enabledSalePeriod: false,
  productSaleStartDate: undefined,
  productSaleEndDate: undefined,
  enabledView: true,
  enabledSale: true,
  enabledReservation: false,
  enabledOption: false,
  enabledOptionPrice: false,
  enabledAdditionProduct: false,
  enabledRelatedProduct: false,
  viewCount: 0,
  enabled: true,
  categories: [],
  productPricePolicy: {
    productId: undefined,
    productNormalPrice: 0,
    enabledDiscount: false,
    productDiscountType: 'PRICE',
    productDiscountUnit: 0,
    productDiscountPrice: 0,
    enableDiscountPeriod: false,
    productDiscountStartDate: undefined,
    productDiscountEndDate: undefined,
    productSalePrice: 0,
    productTaxType: 'NORMAL',
    productTaxFreePrice: 0,
  },
  productInformation: {
    productId: undefined,
    modelName: '',
    brandName: '',
    manufacturerName: '',
    originType: 'DOMESTIC',
    originMemo: '',
    madeType: 'NORMAL',
    productionDateType: 'CALENDAR',
    productionDate: '',
    expiryDateType: 'CALENDAR',
    expiryDate: '',
    weight: '',
    size: '',
    storageMethod: '',
    thickness: '',
    purpose: '',
    legalProductComposition: '',
    legalHistoryInfo: '', // 축산물 이력 정보
    legalGrade: '', // 축산물법에 따른 등급 표시
    quantityPerPackage: '', // 포장 단위별 수량
    cautionInfo: '', // 주의사항
  },
  productDelivery: {
    productId: undefined,
    deliveryType: 'SELECT',
    deliveryMemo: '',
    deliveryAttributeType: 'NORMAL',
    deliveryCheckOrderMaking: false,
    deliveryDueDay: 0,
    deliveryEnabledBundling: false,
    deliveryFeeType: 'FREE',
    deliveryFee: 0,
    deliveryPaymentType: 'PRE_PAID',
    deliveryFreeTerms: 0,
    deliveryEnabledAddedRegionPayment: false,
    deliveryRegionZoneType: 'SECOND',
    deliveryAddedSecondZoneFee: 0,
    deliveryAddedThirdZoneFee: 0,
    deliveryDiffInformation: '',
    deliveryReleaseZipcode: '',
    deliveryReleaseAddress: '',
    deliveryReleaseAddressDetail: '',
  },
  productCustomerPolicy: {
    productId: undefined,
    changeDeliveryCompany: '',
    returnDeliveryFee: 0,
    exchangeDeliveryFee: 0,
    changeZipcode: '',
    changeAddress: '',
    changeAddressDetail: '',
    customerContact: '',
    changeInformation: '',
  },
  images: [],
};

const url = '/api/admin/products';

export const ProductEditPage = (): JSX.Element => {
  const params = useParams();
  const id = params.id;

  const title = !!id ? '상품 수정' : '상품 등록';
  const navigate = useNavigate();

  const [product, setProduct] = useState<ProductState>({
    ...initProduct,
  });

  const props: ProductEditProps = {
    product: product,
    setProduct: setProduct,
  };

  const { result, fetch } = useAxios<undefined, ProductState>({
    url: `${url}/${id}`,
    method: 'get',
  });

  useEffect(() => {
    if (!!id) {
      fetch();
    }

    return () => {
      setProduct({
        ...initProduct,
      });
    };
  }, []);

  useEffect(() => {
    if (!!result) {
      setProduct(result);
    }
  }, [result]);

  const handleChange = (name: string, value: string) => {
    setProduct({
      ...product,
      [name]: value,
    });
  };

  const onChangeEditor = (name: string, value: string) => {
    setProduct({ ...product, [name]: value });
  };

  const { fetch: process } = useAxios<ProductState, undefined>({
    url: url,
    method: !!id ? 'post' : 'put',
    data: product,
  });

  // TODO 저장
  const save = () => {
    confirm({
      title: '상품 내용을 저장하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: '예',
      cancelText: '아니오',
      type: 'error',
      onOk() {
        process().then(() => {
          navigate('/products');
        });
      },
    });
  };

  const [productId, setProductId] = useState<number>();
  const { fetch: copyTargetFetch } = useAxios<undefined, ProductState>({
    url: `${url}/${productId}`,
    method: 'get',
  });

  useEffect(() => {
    if (!!productId) {
      copyTargetFetch().then((result) => {
        const product = {
          ...result,
          productId: undefined,
          images: [],
        };
        setProduct(product);
      });
    }

    return () => {
      setProductId(undefined);
    };
  }, [productId]);

  const [popup, setPopup] = useState<ProductEditCopyTargetPopupProps>({
    open: false,
    onSelect: (productId: number) => {
      setProductId(productId);
    },
    onClose: () => {
      setPopup({
        ...popup,
        open: false,
      });
    },
  });

  return (
    <Layout>
      <Row style={{ ...contentTitle }}>
        <Typography.Title level={5} style={{ margin: '0' }}>
          {title}
        </Typography.Title>
      </Row>

      <Row style={{ padding: '1rem' }}>
        <Card size="small" style={{ width: '100%', padding: '1rem' }}>
          {!id && (
            <Row justify="end" style={{ marginBottom: '1rem' }}>
              <Col>
                <Button type="primary" onClick={() => setPopup({ ...popup, open: true })}>
                  복사 등록
                </Button>
              </Col>
            </Row>
          )}
          <Space direction="vertical" style={{ width: '100%', marginBottom: '1rem' }}>
            <Collapse defaultActiveKey={'product_base_information'} expandIconPosition="right">
              <Collapse.Panel key={'product_base_information'} header={'기본정보'}>
                <ProductDefaultFragment {...props} />
              </Collapse.Panel>
            </Collapse>

            <Collapse defaultActiveKey={'product_quantity'} expandIconPosition="right">
              <Collapse.Panel key={'product_quantity'} header={'재고수량'}>
                <Row>
                  <Col xs={24}>
                    <Space>
                      <label style={{ width: '160px', display: 'inline-block' }}>재고</label>
                      <Input
                        name="productStockQuantity"
                        value={product.productStockQuantity}
                        onChange={({ target: { name, value } }) => handleChange(name, value)}
                      />
                    </Space>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>

            <ProductPricePolicyFragment {...props} />

            {/*상품 이미지 관리*/}
            <ProductImageFragment {...props} />

            {/* 예약상품 관리
            <ProductReservationComponent />
            */}

            <Collapse defaultActiveKey={['product_contents']} expandIconPosition="right">
              <Collapse.Panel key={'product_contents'} header={'상세설명'}>
                <ToastEditor
                  key="productContents"
                  name="productContents"
                  initialValue={product.productContents}
                  onChange={onChangeEditor}
                  height="200px"
                />
              </Collapse.Panel>
            </Collapse>

            {/*상품정보*/}
            <ProductInformationFragment {...props} />

            {/*배송관리*/}
            <ProductDeliveryFragment {...props} />

            {/*반품 및 교환*/}
            <ProductReturnAndExchangeFragment {...props} />

            {/*고객센터*/}
            <ProductCustomerFragment {...props} />

            {/* 추가 상품 관리 */}

            {/* 혜택 관리*/}

            {/* 옵션 관리 */}
          </Space>

          <Affix offsetBottom={0}>
            <Card bordered={false} type="inner">
              <Row justify="end" gutter={[8, 0]}>
                <Col>
                  <Button type="primary" onClick={() => save()}>
                    저장
                  </Button>
                </Col>
                <Col>
                  <Button type="primary" danger href="/products">
                    취소
                  </Button>
                </Col>
              </Row>
            </Card>
          </Affix>
        </Card>
      </Row>
      <ProductEditCopyTargetPopup {...popup} />
    </Layout>
  );
};
