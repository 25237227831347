import { Col, Collapse, Divider, Row } from 'antd';
import React from 'react';
import { OrderDetailDestinationState } from '../../../store/order/order.state';

interface OrderDestinationComponentProps {
  orderDestinations: Array<OrderDetailDestinationState>;
}

export const OrderDestinationComponent = (props: OrderDestinationComponentProps): JSX.Element => {
  const orderDestinations = props.orderDestinations;

  return (
    <Collapse defaultActiveKey={['order_destination_information']} expandIconPosition="end" style={{ margin: '1rem 0' }}>
      <Collapse.Panel key={'order_destination_information'} header={'배송지정보'}>
        <Row>
          {orderDestinations.length > 0 ? (
            orderDestinations.map((destination) => (
              <>
                <Col xs={24}>
                  <Row>
                    <Col xs={12}>
                      <label>받는사람: {destination.destRecipientName}</label>
                    </Col>

                    <Col xs={12}>
                      <label>연락처: {destination.destRecipientCellphoneNo}</label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Divider />
                </Col>
                <Col xs={24}>
                  <label>
                    배송지: {'(' + destination.destZipcode + ') ' + destination.destAddress + ' ' + destination.destAddressDetail}
                  </label>
                </Col>
                <Col xs={24}>
                  <Divider />
                </Col>
                <Col xs={24}>
                  <Row>
                    <Col xs={12}>
                      <label>배송요청사항: {destination.deliveryRequestMemo}</label>
                    </Col>

                    <Col xs={12}>
                      <label>
                        출입방법: {destination.destEnterType} {destination.destEnterTypeMemo && `(${destination.destEnterTypeMemo})`}
                      </label>
                    </Col>
                  </Row>
                </Col>
              </>
            ))
          ) : (
            <Col xs={24}>조회된 데이터가 없습니다.</Col>
          )}
        </Row>
      </Collapse.Panel>
    </Collapse>
  );
};
