import sanitizeHtml from 'sanitize-html';
import { SchemaOf } from 'yup';
import * as yup from 'yup';
import { MixedSchema } from 'yup/lib/mixed';

export const ValidUtils = {
  isValid: <T>(schema: SchemaOf<T>, name: string, value: any) => {
    try {
      const singleSchema = yup.reach(schema, name, value) as MixedSchema<T>;
      return singleSchema.isValidSync(value);
    } catch (err) {
      return false;
    }
  },
  isValidForContents: <T>(schema: SchemaOf<T>, name: string, value: any) => {
    try {
      const convert = sanitizeHtml(value, { allowedTags: [], allowedAttributes: {} });
      const singleSchema = yup.reach(schema, name, convert) as MixedSchema<T>;
      return singleSchema.isValidSync(convert);
    } catch (err) {
      return false;
    }
  },
};
