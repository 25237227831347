import { Col, Collapse, Row, Table } from 'antd';
import React from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import { OrderDeliveryFeeInfo, OrderDetailDeliveryState } from '../../../store/order/order.state';

interface OrderDeliveryComponentProps {
  orderDelivery: Array<OrderDetailDeliveryState>;
  orderDeliveryFeeInfos: Array<OrderDeliveryFeeInfo>;
}

export const OrderDeliveryComponent = (props: OrderDeliveryComponentProps): JSX.Element => {
  const orderDetailDeliveries = props.orderDelivery;
  const orderDeliveryFeeInfos = props.orderDeliveryFeeInfos;

  const columns: ColumnsType<OrderDetailDeliveryState> = [
    {
      title: '배송방법',
      dataIndex: 'deliveryType',
      key: 'deliveryType',
      width: 200,
      align: 'center',
    },
    {
      title: '배송상태',
      dataIndex: 'deliveryStatusType',
      key: 'deliveryStatusType',
      width: 200,
      align: 'center',
    },
    // { title: '배송비', dataIndex: 'deliveryFee', key: 'deliveryFee', width: 200, align: 'center' },
    // { title: '주문확인일', dataIndex: '', key: '', width: 300, align: 'center' },
    { title: '발송처리일', dataIndex: 'deliveryTrackingCreatedAt', key: 'deliveryTrackingCreatedAt', width: 300, align: 'center' },
    { title: '택배사', dataIndex: 'deliveryCompanyName', key: 'deliveryCompanyName', width: 200, align: 'center' },
    { title: '송장번호', dataIndex: 'deliveryTrackingNo', key: 'deliveryTrackingNo', width: 200, align: 'center' },
  ];

  const feeInfoColumns: ColumnsType<OrderDeliveryFeeInfo> = [
    {
      title: 'Seq',
      dataIndex: 'orderDeliveryFeeInfoSeq',
      key: 'orderDeliveryFeeInfoSeq',
      width: 200,
      align: 'center',
    },
    {
      title: '배송비',
      dataIndex: 'deliveryFee',
      key: 'deliveryFee',
      width: 200,
      align: 'center',
    },
    {
      title: '계산근거',
      dataIndex: 'reason',
      key: 'reason',
      width: 200,
      align: 'center',
    },
  ];

  return (
    <Collapse defaultActiveKey={['order_delivery_information']} expandIconPosition="right" style={{ margin: '1rem 0' }}>
      <Collapse.Panel key={'order_delivery_information'} header={'배송정보'}>
        <Row gutter={[8, 8]}>
          <Col style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <label>{'배송비 세부내역'}</label>
          </Col>
          <Table
            bordered={true}
            dataSource={orderDeliveryFeeInfos}
            columns={feeInfoColumns}
            pagination={false}
            scroll={{ x: 800, y: 600 }}
          />
        </Row>
        <Row gutter={[8, 8]}>
          <Col style={{ marginTop: '2rem', marginBottom: '1rem' }}>
            <label>{'배송 정보'}</label>
          </Col>
          <Table bordered={true} dataSource={orderDetailDeliveries} columns={columns} pagination={false} scroll={{ x: 800, y: 600 }} />
        </Row>
      </Collapse.Panel>
    </Collapse>
  );
};
