import { AuthorityState } from './authority.state';
import { AgreementState } from './agreement.state';
import { EnterType, Query } from '../index';
import * as yup from 'yup';

export interface UserListQuery extends Query {
  userName: string;
  userEmail: string;
  userCellphoneNo: string;
  userLocked?: boolean;
  enabled?: boolean;
  page: number;
  size: number;
}

export interface UserListState {
  userId: number;
  userName: string;
  userEmail: string;
  userMileage: number;
  userChallenge: number;
  userCellphoneNo: string;
  userLocked: boolean;
  enabled: boolean;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
}

export interface UserState {
  userId?: number;
  userName: string;
  userEmail: string;
  userPassword?: string;
  userCellphoneNo: string;
  userZipcode: string;
  userAddress: string;
  userAddressDetail: string;
  userMileage: number;
  userLocked: boolean;
  enabled: boolean;
  enableReceiveEmail: boolean;
  enableReceiveMessage: boolean;
  deleted: boolean;
  authorities: Array<AuthorityState>;
  userDestinations?: UserDestinationState[];
  agreements?: Array<AgreementState>;
  createdAt?: string;
  createdBy?: number;
  updatedAt?: string;
  updatedBy?: number;
}

export const userStateSchema: yup.SchemaOf<UserState> = yup.object().shape({
  userId: yup.number(),
  userName: yup.string().required(),
  userEmail: yup.string().required().email(),
  userPassword: yup.string().required(),
  userCellphoneNo: yup.string().required(),
  userZipcode: yup.string().required(),
  userAddress: yup.string().required(),
  userAddressDetail: yup.string().required(),
  userMileage: yup.number().default(0),
  userLocked: yup.boolean().required(),
  enabled: yup.boolean().required(),
  enableReceiveEmail: yup.boolean().required(),
  enableReceiveMessage: yup.boolean().required(),
  deleted: yup.boolean().required(),
  authorities: yup.array<AuthorityState, any>().required().min(1),
  userDestinations: yup
    .object()
    .shape({
      destId: yup.number(),
      userId: yup.number(),
      destSeq: yup.number(),
      destName: yup.string(),
      destRecipientName: yup.string(),
      destRecipientPhoneNo: yup.string(),
      destRecipientCellphoneNo: yup.string(),
      destRecipientEmail: yup.string(),
      destZipcode: yup.string(),
      destAddress: yup.string(),
      destAddressDetail: yup.string(),
      destDefault: yup.boolean(),
      destEnterType: yup.mixed<EnterType>().oneOf(['PASSWORD', 'FREEDOM', 'ETC']),
      destEnterTypeMemo: yup.string(),
      enabled: yup.boolean(),
      createdAt: yup.string(),
      createdBy: yup.string(),
      updatedAt: yup.string(),
      updatedBy: yup.string(),
      deleted: yup.boolean(),
      deletedAt: yup.string(),
      deletedBy: yup.string(),
    })
    .nullable(true),
  agreements: yup.array<AgreementState>(),
  createdAt: yup.string(),
  createdBy: yup.number(),
  updatedAt: yup.string(),
  updatedBy: yup.number(),
});

export interface UserDestinationState {
  destId?: number;
  userId?: number;
  destSeq?: number;
  destName: string;
  destRecipientName: string;
  destRecipientPhoneNo: string;
  destRecipientCellphoneNo: string;
  destRecipientEmail: string;
  destZipcode: string;
  destAddress: string;
  destAddressDetail: string;
  destDefault: boolean;
  destEnterType: EnterType;
  destEnterTypeMemo: string;
  enabled: boolean;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  deleted?: boolean;
  deletedAt?: string;
  deletedBy?: string;
}

export type Role = 'ROLE_NO_MEMBER' | 'ROLE_USER' | 'ROLE_ENTERPRISE' | 'ROLE_ORGANIZATION' | 'ROLE_ADMIN' | 'ROLE_SUPER_USER';
