import { Button, Card, Col, DatePicker, Divider, Input, Layout, message, Row, Select, Space, Table, Typography } from 'antd';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { contentTitle } from '../../style/page';
import { ColumnsType, TableRowSelection } from 'antd/lib/table/interface';
import locale from 'antd/es/date-picker/locale/ko_KR';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { RiFileExcel2Fill } from 'react-icons/ri';
import confirm from 'antd/es/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { CategoryComponent } from '../../components/CategoryComponent';
import { CodeObject, defaultPage, Page } from '../../store';
import { useAxios } from '../../hook/axios';
import { OrderListQueryState, OrderListState, OrderStatusType } from '../../store/order/order.state';
import { codeListState } from '../../store/index.store';
import { useRecoilValue } from 'recoil';
import { codeName, withAll } from '../../utils/EnumUtils';

const initQuery: OrderListQueryState = {
  categories: [],
  paymentCompletedStartDate: '',
  paymentCompletedEndDate: '',
  orderCheckedStartDate: '',
  orderCheckedEndDate: '',
  deliveryTrackingCreatedStartDate: '',
  deliveryTrackingCreatedEndDate: '',
  orderNo: '',
  purchaserName: '',
  purchaserEmail: '',
  destRecipientName: '',
  destRecipientCellphoneNo: '',
  productSku: '',
  deliveryTrackingNo: '',
  orderStatusType: '',
  deliveryStatusType: '',
  paymentStatusType: '',
  orderCancelStatusType: '',
  page: 1,
  size: 10,
};

const initPage: Page<OrderListState> = {
  ...defaultPage,
  contents: [],
};

export const OrderListPage = (): JSX.Element => {
  const [query, setQuery] = useState(initQuery);
  const [pageQuery, setPageQuery] = useState(initQuery);
  const codes = useRecoilValue<CodeObject>(codeListState);

  const pageSizes = [
    { label: '10개씩 보기', value: 10 },
    { label: '20개씩 보기', value: 20 },
    { label: '30개씩 보기', value: 30 },
    { label: '50개씩 보기', value: 50 },
    { label: '100개씩 보기', value: 100 },
  ];

  const [page, setPage] = useState<Page<OrderListState>>({ ...initPage });
  const { result, fetch: reload } = useAxios<OrderListQueryState, Page<OrderListState>>({
    params: query,
    url: '/api/admin/orders',
    method: 'get',
  });

  const { fetch: approve } = useAxios({
    url: '/api/admin/orders/approve',
    method: 'post',
  });

  const { fetch: cancel } = useAxios({
    url: '/api/admin/orders/cancel',
    method: 'post',
  });

  useEffect(() => {
    if (!!result) {
      setPage(result);
    } else {
      setPage({ ...initPage });
    }
  }, [result]);

  useEffect(() => {
    setPageQuery({ ...query });
    reload();
  }, []);

  const handleSelectValue = (name: string, value: any) => {
    setQuery({ ...query, [name]: value });
  };

  const handlePagination = (page: number, pageSize: number) => {
    const newPageQuery: OrderListQueryState = {
      ...pageQuery,
      ['page']: page,
      ['size']: pageSize,
    };

    // page만 전환
    setPageQuery(newPageQuery);
    reload(newPageQuery);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setQuery({
      ...query,
      [name]: value,
    });
  };

  const rangePaymentDateValue = (): RangeValue<Moment> | null => {
    if (!!query.paymentCompletedStartDate && !!query.paymentCompletedEndDate) {
      return [moment(query.paymentCompletedStartDate), moment(query.paymentCompletedEndDate)];
    }

    return null;
  };

  const rangeDeliveryDateValue = (): RangeValue<Moment> | null => {
    if (!!query.deliveryTrackingCreatedStartDate && !!query.deliveryTrackingCreatedEndDate) {
      return [moment(query.deliveryTrackingCreatedStartDate), moment(query.deliveryTrackingCreatedEndDate)];
    }

    return null;
  };

  const rangeOrderCheckDateValue = (): RangeValue<Moment> | null => {
    if (!!query.orderCheckedStartDate && !!query.orderCheckedEndDate) {
      return [moment(query.orderCheckedStartDate), moment(query.orderCheckedEndDate)];
    }

    return null;
  };

  const handleRangeDate = (startDateName: string, endDateName: string, rangeDate: RangeValue<Moment>) => {
    if (!!rangeDate && !!rangeDate[0] && !!rangeDate[1]) {
      setQuery({
        ...query,
        [startDateName]: rangeDate[0].format('YYYY-MM-DD HH:mm:ss'),
        [endDateName]: rangeDate[1].format('YYYY-MM-DD HH:mm:ss'),
      });
    } else {
      setQuery({
        ...query,
        [startDateName]: null,
        [endDateName]: null,
      });
    }
  };

  const columns: ColumnsType<OrderListState> = [
    {
      title: '주문번호',
      dataIndex: 'orderNo',
      key: 'orderNo',
      width: 300,
      align: 'center',
      render: (name: string, record: OrderListState) => (
        <div style={{ textAlign: 'left' }}>
          <Button type="link" href={`/orders/${record.orderId}`}>
            {name}
          </Button>
        </div>
      ),
    },
    { title: '주문일시', dataIndex: 'createdAt', width: 300, key: 'createdAt', align: 'center' },
    {
      title: '주문상태',
      dataIndex: 'orderStatusType',
      key: 'orderStatusType',
      width: 200,
      align: 'center',
      render: (status) => codeName(status, codes.OrderStatusType),
    },
    { title: '구매자명', dataIndex: 'purchaserName', key: 'purchaserName', width: 100, align: 'center' },
    { title: '구매자ID', dataIndex: 'purchaserEmail', key: 'purchaserEmail', width: 300, align: 'center' },
    { title: '상품명', dataIndex: 'productName', key: 'productName', width: 300, align: 'center' },
    { title: '수량', dataIndex: 'orderTotalQuantity', key: 'orderTotalQuantity', width: 100, align: 'center' },
    { title: '주문확인일', dataIndex: 'orderCheckedAt', key: 'orderCheckedAt', width: 300, align: 'center' },
    {
      title: '발송일',
      dataIndex: 'deliveryTrackingCreatedAt',
      key: 'deliveryTrackingCreatedAt',
      width: 300,
      align: 'center',
    },
    {
      title: '결제상태',
      dataIndex: 'paymentStatusType',
      key: 'paymentStatusType',
      width: 200,
      align: 'center',
    },
    { title: '받는사람', dataIndex: 'destRecipientName', key: 'destRecipientName', width: 100, align: 'center' },
    { title: '연락처', dataIndex: 'destRecipientCellphoneNo', key: 'destRecipientCellphoneNo', width: 150, align: 'center' },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<React.Key>>([]);
  const rowSelection: TableRowSelection<OrderListState> = {
    selectedRowKeys: selectedRowKeys,
    type: 'checkbox',
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const handleSearch = () => {
    setPageQuery({ ...query, page: 1 });
    reload();
  };

  const handleReset = () => {
    setQuery({ ...initQuery });
    setPageQuery({ ...initQuery });
  };

  const { fetch: excelResultFetch } = useAxios<OrderListQueryState, any>({
    url: '/api/admin/orders/xlsx',
    method: 'get',
  });

  const handleExcel = () => {
    confirm({
      title: '엑셀다운로드',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: '예',
      cancelText: '아니오',
      type: 'error',
      onOk() {
        excelResultFetch({ ...query })
          .then((result) => {
            const a = document.createElement('a');
            a.href = `data:${result.mimeType};base64,${result.data}`;
            a.download = result.filename; //File name Here
            a.click(); //Downloaded file
            a.remove();
            message.info('엑셀 다운로드가 완료되었습니다.');
          })
          .catch(() => {
            message.error('엑셀 다운로드에 실패했습니다.');
          });
      },
    });
  };

  const handleCategoriesChange = (name: string, value: Array<number>) => {
    setQuery({
      ...query,
      [name]: value,
    });
  };

  const handleApprove = () => {
    const approveList = page.contents.filter((value, index) => selectedRowKeys.some((value2) => value2 === index));
    const orderIds: number[] = approveList.map((content) => content.orderId!);

    if (approveList.length === 0) {
      message.error('한 건 이상의 주문건을 선택해주세요.');
      return;
    }

    if (
      approveList.some(
        (item) =>
          Object.keys(OrderStatusType).indexOf(item.orderStatusType) >= Object.keys(OrderStatusType).indexOf(OrderStatusType.CHECK_ORDER),
      )
    ) {
      message.error('이미 주문 확인된 건이 있습니다.');
      return;
    }

    confirm({
      title: '주문상태를 주문확인으로 변경하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: '예',
      cancelText: '아니오',
      type: 'error',
      onOk() {
        approve(orderIds)
          .then(() => {
            message.success('주문 확인 처리가 되었습니다.');
            reload(pageQuery);
          })
          .catch(() => message.info('주문 확인 처리에 실패하였습니다.'));
      },
    });
  };

  const handleCancel = () => {
    const cancelList = page.contents.filter((value, index) => selectedRowKeys.some((value2) => value2 === index));

    if (cancelList.length === 0) {
      message.error('한 건 이상의 주문건을 선택해주세요.');
      return;
    }

    if (
      cancelList.some(
        (item) =>
          Object.keys(OrderStatusType).indexOf(item.orderStatusType) >=
          Object.keys(OrderStatusType).indexOf(OrderStatusType.COMPLETE_CANCEL_ORDER),
      )
    ) {
      message.error('이미 취소된 건이 있습니다.');
      return;
    }

    confirm({
      title: '주문상태를 주문취소로 변경하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: '예',
      cancelText: '아니오',
      type: 'error',
      onOk() {
        cancel(cancelList.map((content) => ({ id: content.orderId! }))).then(() => {
          reload(pageQuery);
        });
      },
    });
  };

  return (
    <Layout style={{ minWidth: '1200px' }}>
      <Row style={{ ...contentTitle }}>
        <Typography.Title level={5} style={{ margin: '0' }}>
          주문 관리
        </Typography.Title>
      </Row>

      <Row style={{ padding: '1rem' }}>
        <Col xs={24}>
          <Card style={{ width: '100%' }}>
            <Row>
              <Col xs={24}>
                <Row gutter={[12, 2]}>
                  <Col xs={24}>
                    <Space>
                      <label style={{ width: '140px', display: 'inline-block' }}>카테고리</label>
                      <CategoryComponent value={query.categories} name="categories" onChange={handleCategoriesChange} />
                    </Space>
                  </Col>
                  <Col xs={24}>
                    <Divider />
                  </Col>
                  <Col xs={24}>
                    <Space align={'start'}>
                      <label style={{ width: '140px', display: 'inline-block' }}>조회기간</label>
                      <Row gutter={[16, 8]}>
                        <Col>
                          <Row gutter={[16, 0]}>
                            <Col>
                              <label
                                style={{
                                  width: '70px',
                                  display: 'inline-block',
                                }}
                              >
                                결제일
                              </label>
                            </Col>
                            <Col>
                              <Space style={{ flexWrap: 'wrap' }}>
                                <DatePicker.RangePicker
                                  locale={locale}
                                  style={{ width: '240px' }}
                                  defaultValue={rangePaymentDateValue()}
                                  value={rangePaymentDateValue()}
                                  onChange={(value) => handleRangeDate('paymentCompletedStartDate', 'paymentCompletedEndDate', value)}
                                />
                              </Space>
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row gutter={[16, 0]}>
                            <Col>
                              <label
                                style={{
                                  width: '70px',
                                  display: 'inline-block',
                                }}
                              >
                                주문확인일
                              </label>
                            </Col>
                            <Col>
                              <Space style={{ flexWrap: 'wrap' }}>
                                <DatePicker.RangePicker
                                  locale={locale}
                                  style={{ width: '240px' }}
                                  defaultValue={rangeOrderCheckDateValue()}
                                  value={rangeOrderCheckDateValue()}
                                  onChange={(value) => handleRangeDate('orderCheckedStartDate', 'orderCheckedEndDate', value)}
                                />
                              </Space>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={24}>
                          <Row gutter={[16, 0]}>
                            <Col>
                              <label
                                style={{
                                  width: '70px',
                                  display: 'inline-block',
                                }}
                              >
                                배송처리일
                              </label>
                            </Col>
                            <Col>
                              <Space style={{ flexWrap: 'wrap' }}>
                                <DatePicker.RangePicker
                                  locale={locale}
                                  style={{ width: '240px' }}
                                  defaultValue={rangeDeliveryDateValue()}
                                  value={rangeDeliveryDateValue()}
                                  onChange={(value) =>
                                    handleRangeDate('deliveryTrackingCreatedStartDate', 'deliveryTrackingCreatedEndDate', value)
                                  }
                                />
                              </Space>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Space>
                  </Col>
                  <Col xs={24}>
                    <Divider />
                  </Col>
                  <Col xs={24}>
                    <Space align={'start'}>
                      <label style={{ width: '140px', display: 'inline-block' }}>조건</label>
                      <Row gutter={[16, 8]}>
                        <Col xs={24}>
                          <Row gutter={[36, 8]}>
                            <Col xs={24}>
                              <Row gutter={[36, 8]}>
                                <Col>
                                  <Row gutter={[36, 8]}>
                                    <Col>
                                      <label
                                        style={{
                                          width: '60px',
                                          display: 'inline-block',
                                        }}
                                      >
                                        주문번호
                                      </label>
                                    </Col>
                                    <Col>
                                      <Input style={{ width: '160px' }} name="orderNo" value={query.orderNo} onChange={handleChange} />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col>
                                  <Row gutter={[36, 8]}>
                                    <Col>
                                      <label
                                        style={{
                                          width: '60px',
                                          display: 'inline-block',
                                        }}
                                      >
                                        구매자명
                                      </label>
                                    </Col>
                                    <Col>
                                      <Input
                                        style={{ width: '160px' }}
                                        name="purchaserName"
                                        value={query.purchaserName}
                                        onChange={handleChange}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col>
                                  <Row gutter={[36, 8]}>
                                    <Col>
                                      <label
                                        style={{
                                          width: '60px',
                                          display: 'inline-block',
                                        }}
                                      >
                                        수취인명
                                      </label>
                                    </Col>
                                    <Col>
                                      <Input
                                        style={{ width: '160px' }}
                                        name="destRecipientName"
                                        value={query.destRecipientName}
                                        onChange={handleChange}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              <Row gutter={[36, 8]}>
                                <Col>
                                  <label
                                    style={{
                                      width: '60px',
                                      display: 'inline-block',
                                    }}
                                  >
                                    구매자ID
                                  </label>
                                </Col>
                                <Col>
                                  <Input
                                    style={{ width: '160px' }}
                                    name="purchaserEmail"
                                    value={query.purchaserEmail}
                                    onChange={handleChange}
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              <Row gutter={[36, 8]}>
                                <Col>
                                  <label
                                    style={{
                                      width: '60px',
                                      display: 'inline-block',
                                    }}
                                  >
                                    연락처
                                  </label>
                                </Col>
                                <Col>
                                  <Input
                                    style={{ width: '160px' }}
                                    name="destRecipientCellphoneNo"
                                    value={query.destRecipientCellphoneNo}
                                    onChange={handleChange}
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              <Row gutter={[36, 8]}>
                                <Col>
                                  <label
                                    style={{
                                      width: '60px',
                                      display: 'inline-block',
                                    }}
                                  >
                                    상품코드
                                  </label>
                                </Col>
                                <Col>
                                  <Input style={{ width: '160px' }} name="productSku" value={query.productSku} onChange={handleChange} />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={24}>
                          <Row gutter={[36, 8]}>
                            <Col>
                              <Row gutter={[36, 8]}>
                                <Col>
                                  <label
                                    style={{
                                      width: '60px',
                                      display: 'inline-block',
                                    }}
                                  >
                                    송장번호
                                  </label>
                                </Col>
                                <Col>
                                  <Input
                                    style={{ width: '160px' }}
                                    name="deliveryTrackingNo"
                                    value={query.deliveryTrackingNo}
                                    onChange={handleChange}
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              <Row gutter={[36, 8]}>
                                <Col>
                                  <label
                                    style={{
                                      width: '60px',
                                      display: 'inline-block',
                                    }}
                                  >
                                    주문상태
                                  </label>
                                </Col>
                                <Col>
                                  <Select
                                    options={withAll(codes.OrderStatusType)}
                                    style={{ width: '160px' }}
                                    defaultValue={query.orderStatusType}
                                    value={query.orderStatusType}
                                    onChange={(value) => handleSelectValue('orderStatusType', value)}
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              <Row gutter={[36, 8]}>
                                <Col>
                                  <label
                                    style={{
                                      width: '60px',
                                      display: 'inline-block',
                                    }}
                                  >
                                    배송상태
                                  </label>
                                </Col>
                                <Col>
                                  <Select
                                    options={withAll(codes.DeliveryStatusType)}
                                    style={{ width: '160px' }}
                                    defaultValue={query.deliveryStatusType}
                                    value={query.deliveryStatusType}
                                    onChange={(value) => handleSelectValue('deliveryStatusType', value)}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={24}>
                          <Row gutter={[36, 8]}>
                            <Col>
                              <Row gutter={[36, 8]}>
                                <Col>
                                  <label
                                    style={{
                                      width: '60px',
                                      display: 'inline-block',
                                    }}
                                  >
                                    취소상태
                                  </label>
                                </Col>
                                <Col>
                                  <Select
                                    options={withAll(codes.OrderCancelStatusType)}
                                    style={{ width: '160px' }}
                                    defaultValue={query.orderCancelStatusType}
                                    value={query.orderCancelStatusType}
                                    onChange={(value) => handleSelectValue('orderCancelStatusType', value)}
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              <Row gutter={[36, 8]}>
                                <Col>
                                  <label
                                    style={{
                                      width: '60px',
                                      display: 'inline-block',
                                    }}
                                  >
                                    결제상태
                                  </label>
                                </Col>
                                <Col>
                                  <Select
                                    options={withAll(codes.PaymentStatusType)}
                                    style={{ width: '160px' }}
                                    defaultValue={query.paymentStatusType}
                                    value={query.paymentStatusType}
                                    onChange={(value) => handleSelectValue('paymentStatusType', value)}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Space>
                  </Col>
                  <Col xs={24}>
                    <Divider />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row justify="center">
              <Space>
                <Button type="primary" htmlType="submit" onClick={() => handleSearch()}>
                  검색
                </Button>

                <Button type="default" onClick={() => handleReset()}>
                  초기화
                </Button>
              </Space>
            </Row>
          </Card>
        </Col>

        <Col xs={24} style={{ marginTop: '2rem' }}>
          <Row justify="space-between">
            <Space>
              <Button type="primary" onClick={() => handleApprove()}>
                주문확인
              </Button>
              <Button danger type="primary" onClick={() => handleCancel()}>
                주문취소
              </Button>
            </Space>
            <Space>
              <Select
                options={pageSizes}
                value={pageQuery.size}
                defaultValue={query.size}
                onChange={(value) => handlePagination(1, value)}
              />
              <Button type="primary" icon={<RiFileExcel2Fill />} onClick={() => handleExcel()}>
                엑셀 다운로드
              </Button>
            </Space>
          </Row>
        </Col>
        <Col xs={24} style={{ marginTop: '.5rem' }}>
          <Table
            style={{ width: '2300px', overflow: 'hidden' }}
            rowKey={(record, index) => index!}
            rowSelection={rowSelection}
            dataSource={page.contents}
            columns={columns}
            scroll={{ x: 800, y: 600 }}
            pagination={{
              position: ['bottomCenter'],
              pageSize: pageQuery.size,
              defaultPageSize: query.size,
              current: pageQuery.page,
              defaultCurrent: query.page,
              total: page.totalElements,
              onChange: (page, pageSize) => handlePagination(page, pageSize),
            }}
          />
        </Col>
      </Row>
    </Layout>
  );
};
