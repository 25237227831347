import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Checkbox, Col, Collapse, Input, Radio, Row, Select, Space } from 'antd';
import React from 'react';
import { range } from 'lodash';
import { DaumAddressPopupStore } from '../../../store/component';
import { Address } from 'react-daum-postcode';
import { ProductEditProps } from '../ProductEditPage';
import { CodeObject } from '../../../store';
import { codeListState } from '../../../store/index.store';

export const ProductDeliveryFragment = (props: ProductEditProps): JSX.Element => {
  const codes = useRecoilValue<CodeObject>(codeListState);
  const [product, setProduct] = [props.product, props.setProduct];
  const productDelivery = product.productDelivery;
  const dueDateRange = range(2, 10).map((date) => ({
    label: date + '일',
    value: date,
  }));

  const handleChange = (name: string, value: unknown) => {
    setProduct({
      ...product,
      productDelivery: {
        ...productDelivery,
        [name]: value,
      },
    });
  };

  const setZipcode = useSetRecoilState(DaumAddressPopupStore);
  const searchAddress = () => {
    setZipcode({ open: true, callback: selectedAddress });
  };

  const selectedAddress = (address: Address) => {
    setProduct({
      ...product,
      productDelivery: {
        ...productDelivery,
        ['deliveryReleaseZipcode']: address.zonecode,
        ['deliveryReleaseAddress']: address.address,
      },
    });
  };

  return (
    <Collapse defaultActiveKey={['product_delivery']} expandIconPosition="right">
      <Collapse.Panel key={'product_delivery'} header={'배송'}>
        <Row gutter={[0, 16]}>
          <Col xs={24}>
            <Space>
              <label style={{ width: '140px', display: 'inline-block' }}>배송</label>
              <Input
                style={{ width: '200px' }}
                name="deliveryMemo"
                value={productDelivery.deliveryMemo}
                onChange={({ target: { name, value } }) => handleChange(name, value)}
              />
            </Space>
          </Col>
          <Col xs={24}>
            <Space>
              <label style={{ width: '140px', display: 'inline-block' }}>배송방법</label>
              <Col xs={24}>
                <Radio.Group
                  style={{ width: '400px' }}
                  name="deliveryType"
                  value={productDelivery.deliveryType}
                  onChange={({ target: { name, value } }) => handleChange(name as string, value)}
                >
                  {codes.DeliveryType.map((type) => (
                    <Radio key={type.value} value={type.value}>
                      {type.label}
                    </Radio>
                  ))}
                </Radio.Group>
              </Col>
            </Space>
          </Col>
          <Col xs={24}>
            <Space>
              <label style={{ width: '140px', display: 'inline-block' }}>배송속성</label>
              <Row gutter={[0, 8]}>
                <Col xs={24}>
                  <Select
                    style={{ width: '200px' }}
                    value={productDelivery.deliveryAttributeType}
                    onChange={(value) => handleChange('deliveryAttributeType', value)}
                  >
                    <Select.Option value="NORMAL">일반배송</Select.Option>
                    <Select.Option value="TODAY">오늘출발</Select.Option>
                  </Select>
                </Col>
                <Col xs={24}>
                  <Checkbox
                    name="deliveryCheckOrderMaking"
                    value={productDelivery.deliveryCheckOrderMaking}
                    checked={productDelivery.deliveryCheckOrderMaking}
                    defaultChecked={productDelivery.deliveryCheckOrderMaking}
                    onChange={({ target: { name, checked } }) => handleChange(name!, checked)}
                  >
                    주문확인 후 제작
                  </Checkbox>
                </Col>

                {productDelivery.deliveryCheckOrderMaking && (
                  <Col xs={24}>
                    <Space>
                      <label>발송예정일</label>
                      <Select
                        options={dueDateRange}
                        style={{ width: '100px' }}
                        value={!!productDelivery.deliveryDueDay ? productDelivery.deliveryDueDay.toString() : '2'}
                        onChange={(value) => handleChange('deliveryDueDay', value)}
                      />
                      <span>일 이내 발송예정(주말/공휴일을 제외한 영업일 기준)</span>
                    </Space>
                  </Col>
                )}
              </Row>
            </Space>
          </Col>
          <Col xs={24}>
            <Space>
              <label style={{ width: '140px', display: 'inline-block' }}>묶음배송</label>
              <Radio.Group
                name="deliveryEnabledBundling"
                value={productDelivery.deliveryEnabledBundling}
                onChange={({ target: { name, value } }) => handleChange(name!, value)}
              >
                <Radio value={true}>가능</Radio>
                <Radio value={false}>불가능</Radio>
              </Radio.Group>
            </Space>
          </Col>
          <Col xs={24}>
            <Space align="start">
              <label style={{ width: '140px', display: 'inline-block' }}>상품별 배송비</label>
              <Select
                style={{ width: '200px' }}
                value={productDelivery.deliveryFeeType}
                onChange={(value) => handleChange('deliveryFeeType', value)}
              >
                <Select.Option value="FREE">무료</Select.Option>
                <Select.Option value="CONDITION_FREE">조건별 무료</Select.Option>
                <Select.Option value="PAID">유료</Select.Option>
              </Select>
            </Space>
          </Col>
          {productDelivery.deliveryFeeType !== 'FREE' && (
            <Col xs={24}>
              <Space>
                <label style={{ width: '140px', display: 'inline-block' }}>기본 배송비</label>
                <Input
                  style={{ width: '200px' }}
                  name="deliveryFee"
                  value={productDelivery.deliveryFee}
                  onChange={({ target: { name, value } }) => handleChange(name!, value)}
                />
                <span>원</span>
              </Space>
            </Col>
          )}
          {productDelivery.deliveryFeeType === 'CONDITION_FREE' && (
            <Col xs={24}>
              <Space>
                <label style={{ width: '140px', display: 'inline-block' }}>배송비 조건</label>
                <Input
                  style={{ width: '200px' }}
                  name="deliveryFreeTerms"
                  value={productDelivery.deliveryFreeTerms}
                  onChange={({ target: { name, value } }) => handleChange(name!, value)}
                />
                <span>원</span>
              </Space>
            </Col>
          )}
          {productDelivery.deliveryFeeType !== 'FREE' && (
            <Col xs={24}>
              <Space>
                <label style={{ width: '140px', display: 'inline-block' }}>결제방식</label>
                <Radio.Group
                  style={{ width: '400px' }}
                  name="deliveryPaymentType"
                  value={productDelivery.deliveryPaymentType}
                  onChange={({ target: { name, value } }) => handleChange(name!, value)}
                >
                  <Radio value="PRE_PAID">선결제</Radio>
                  <Radio value="POST_PAID">착불</Radio>
                  <Radio value="SELECT_PAID">선결제 또는 착불</Radio>
                </Radio.Group>
              </Space>
            </Col>
          )}

          <Col xs={24}>
            <Space>
              <label style={{ width: '140px', display: 'inline-block' }}>제주/도서산간 추가배송비</label>
              <Radio.Group
                style={{ width: '400px' }}
                name="deliveryEnabledAddedRegionPayment"
                value={productDelivery.deliveryEnabledAddedRegionPayment}
                onChange={({ target: { name, value } }) => handleChange(name!, value)}
              >
                <Radio value={true}>설정</Radio>
                <Radio value={false}>미설정</Radio>
              </Radio.Group>
            </Space>
          </Col>
          {productDelivery.deliveryEnabledAddedRegionPayment && (
            <>
              <Col xs={24}>
                <Space>
                  <label style={{ width: '140px', display: 'inline-block' }}>배송권역</label>
                  <Select
                    style={{ width: '200px' }}
                    value={productDelivery.deliveryRegionZoneType}
                    onChange={(value) => handleChange('deliveryRegionZoneType', value)}
                  >
                    <Select.Option value="SECOND">2구간</Select.Option>
                    <Select.Option value="THIRD">3구간</Select.Option>
                  </Select>
                </Space>
              </Col>
              <Col xs={24}>
                <Space>
                  <label style={{ width: '140px', display: 'inline-block' }}>
                    {productDelivery.deliveryRegionZoneType === 'SECOND' ? '제주 및 도서산간 추가 배송비' : '제주 추가배송비'}
                  </label>
                  <Input
                    style={{ width: '200px' }}
                    name="deliveryAddedSecondZoneFee"
                    value={productDelivery.deliveryAddedSecondZoneFee}
                    onChange={({ target: { name, value } }) => handleChange(name, value)}
                  />
                </Space>
              </Col>
              {productDelivery.deliveryRegionZoneType === 'THIRD' && (
                <Col xs={24}>
                  <Space>
                    <label style={{ width: '140px', display: 'inline-block' }}>제주 외 도서산간 추가배송비</label>
                    <Input
                      style={{ width: '200px' }}
                      name="deliveryAddedThirdZoneFee"
                      value={productDelivery.deliveryAddedThirdZoneFee}
                      onChange={({ target: { name, value } }) => handleChange(name, value)}
                    />
                  </Space>
                </Col>
              )}
            </>
          )}

          <Col xs={24}>
            <Space>
              <label style={{ width: '140px', display: 'inline-block' }}>차등 배송 안내</label>
              <Input
                style={{ width: '300px' }}
                name="deliveryDiffInformation"
                value={productDelivery.deliveryDiffInformation}
                onChange={({ target: { name, value } }) => handleChange(name, value)}
              />
            </Space>
          </Col>
          <Col xs={24}>
            <Space>
              <label style={{ width: '140px', display: 'inline-block' }}>발송지</label>
              <Row gutter={[0, 8]}>
                <Col xs={24}>
                  <Input.Search
                    name="deliveryReleaseZipcode"
                    maxLength={13}
                    readOnly={true}
                    enterButton="우편번호찾기"
                    style={{ width: '300px' }}
                    value={productDelivery.deliveryReleaseZipcode}
                    onSearch={() => searchAddress()}
                  />
                </Col>
                <Col xs={24}>
                  <Input
                    style={{ width: '300px' }}
                    readOnly={true}
                    name="deliveryReleaseAddress"
                    value={productDelivery.deliveryReleaseAddress}
                  />
                </Col>
                <Col xs={24}>
                  <Input
                    style={{ width: '300px' }}
                    name="deliveryReleaseAddressDetail"
                    value={productDelivery.deliveryReleaseAddressDetail}
                    onChange={({ target: { name, value } }) => handleChange(name, value)}
                  />
                </Col>
              </Row>
            </Space>
          </Col>
        </Row>
      </Collapse.Panel>
    </Collapse>
  );
};
