import React, { useEffect } from 'react';
import { AuthorityState } from '../../store/system/authority.state';
import { Col, Layout, Row, Table, Typography } from 'antd';
import { contentTitle } from '../../style/page';
import { useAxios } from '../../hook/axios';
import { ColumnsType } from 'antd/lib/table/interface';

export const AuthorityPage = (): JSX.Element => {
  const { result: data, fetch } = useAxios<void, Array<AuthorityState>>({
    url: '/api/admin/authorities',
    method: 'get',
  });

  useEffect(() => {
    fetch();
  }, []);

  const columns: ColumnsType<AuthorityState> = [
    { title: '권한 ID', dataIndex: 'roleId', key: 'roleId' },
    { title: '권한명', dataIndex: 'roleName', key: 'roleName' },
    {
      title: '사용여부',
      dataIndex: 'enabled',
      key: 'enabled',
      render: (enabled: boolean) => <>{enabled ? '사용' : '미사용'}</>,
    },
    { title: '작성일', dataIndex: 'createdAt', key: 'createdAt' },
    { title: '수정일', dataIndex: 'updatedAt', key: 'updatedAt' },
  ];

  return (
    <Layout>
      <Row style={{ ...contentTitle }}>
        <Typography.Title level={5} style={{ margin: '0' }}>
          권한 관리
        </Typography.Title>
      </Row>
      <Row style={{ padding: '1rem' }}>
        <Col xs={24} style={{ marginTop: '.5rem' }}>
          <Table size="large" style={{ width: '100%' }} rowKey="roleId" dataSource={data} columns={columns} pagination={false} />
        </Col>
      </Row>
    </Layout>
  );
};
