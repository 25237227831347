import React, { useEffect, useState } from 'react';
import { Col, Input, message, Modal, Row, Space, Switch } from 'antd';
import '../../index.css';
import { BulkOrderState } from '../../store/order/bulk.order.state';
import { ProductSelect } from '../../components/product/product.select';
import confirm from 'antd/es/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useAxios } from '../../hook/axios';

export interface BulkOrderEditPopupProps {
  open: boolean;
  bulkOrderId: number | undefined;
  close: Function;
  reload: Function;
}

const initBulkOrder: BulkOrderState = {
  bulkOrderId: undefined,
  bulkOrderNo: undefined,
  bulkOrderCompanyId: undefined,
  bulkOrderCompanyName: '',
  bulkOrderManagerId: undefined,
  bulkOrderManagerName: '',
  bulkOrderChargerId: undefined,
  bulkOrderChargerName: '',
  bulkOrderProductId: undefined,
  bulkOrderProductName: '',
  bulkOrderQuantity: 0,
  enabled: true,
};

export const BulkOrderEditPopup = (props: BulkOrderEditPopupProps): JSX.Element => {
  const [bulkOrder, setBulkOrder] = useState<BulkOrderState>({ ...initBulkOrder });

  const { result, fetch } = useAxios<void, BulkOrderState>({
    url: `/api/admin/bulk/orders/${props.bulkOrderId}`,
    method: 'get',
  });

  const { fetch: saveFetch } = useAxios<BulkOrderState, void>({
    url: `/api/admin/bulk/orders`,
    method: !!props.bulkOrderId ? 'put' : 'post',
  });

  useEffect(() => {
    if (props.open && props.bulkOrderId) {
      fetch();
    } else {
      setBulkOrder({ ...initBulkOrder });
    }
  }, [props.open]);

  useEffect(() => {
    if (!!result) {
      setBulkOrder({
        ...result,
      });
    } else {
      setBulkOrder({
        ...initBulkOrder,
      });
    }
  }, [result]);

  const save = () => {
    confirm({
      title: '대량주문 내용을 저장하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: '예',
      cancelText: '아니오',
      type: 'error',
      onOk() {
        saveFetch(bulkOrder)
          .then(() => {
            message.success('대량주문 저장에 성공하였습니다.');
            props.reload();
            props.close();
          })
          .catch(() => {
            message.error('대량주문 저장에 실패하였습니다.');
          });
      },
    });
  };

  const handleChange = (name: string, value: unknown) => {
    setBulkOrder({
      ...bulkOrder,
      [name]: value,
    });
  };

  const handleProductSelectValue = (value: number | undefined) => {
    handleChange('bulkOrderProductId', value);
  };

  return (
    <form>
      <Modal
        title="주문 생성"
        visible={props.open}
        width="400px"
        okText="저장"
        cancelText="취소"
        onOk={() => save()}
        onCancel={() => props.close()}
      >
        <Row gutter={[3, 17]}>
          <Col xs={24}>
            <Space>
              <label style={{ width: '100px', marginLeft: 10, display: 'inline-block' }}>업체명</label>
              <Input
                name="bulkOrderCompanyName"
                value={bulkOrder.bulkOrderCompanyName}
                style={{ width: '220px' }}
                onChange={({ target: { name, value } }) => handleChange(name, value)}
                autoComplete="off"
              />
            </Space>
          </Col>
          <Col xs={24}>
            <Space>
              <label style={{ width: '100px', marginLeft: 10, display: 'inline-block' }}>업체 담당자명</label>
              <Input
                type="text"
                name="bulkOrderManagerName"
                value={bulkOrder.bulkOrderManagerName}
                onChange={({ target: { name, value } }) => handleChange(name, value)}
                autoComplete="off"
                style={{ width: '220px' }}
              />
            </Space>
          </Col>
          <Col xs={24}>
            <Space>
              <label style={{ width: '100px', marginLeft: 10, display: 'inline-block' }}>담당자</label>
              <Input
                type="text"
                name="bulkOrderChargerName"
                placeholder="관리자"
                value={bulkOrder.bulkOrderChargerName}
                onChange={({ target: { name, value } }) => handleChange(name, value)}
                autoComplete="off"
                style={{ width: '220px' }}
              />
            </Space>
          </Col>
          <Col xs={24}>
            <Space>
              <label style={{ width: '100px', marginLeft: 10, display: 'inline-block' }}>상품</label>
              <ProductSelect setValue={handleProductSelectValue} value={bulkOrder.bulkOrderProductId} style={{ width: '220px' }} />
            </Space>
          </Col>
          <Col xs={24}>
            <Space>
              <label style={{ width: '100px', marginLeft: 10, display: 'inline-block' }}>수량</label>
              <Input
                name="bulkOrderQuantity"
                type="number"
                value={bulkOrder.bulkOrderQuantity}
                onChange={({ target: { name, value } }) => handleChange(name, value)}
                autoComplete="off"
                style={{ width: '220px' }}
                min="0"
              />
            </Space>
          </Col>
          <Col xs={24}>
            <Space>
              <label style={{ width: '100px', marginLeft: 10, display: 'inline-block' }}>사용</label>
              <Switch
                id="enabled"
                checked={bulkOrder.enabled}
                onChange={(checked) => setBulkOrder({ ...bulkOrder, ['enabled']: checked })}
              />
            </Space>
          </Col>
        </Row>
      </Modal>
    </form>
  );
};
