import React, { useEffect, useState } from 'react';
import { Page } from '../../store';
import { UserListQuery, UserListState } from '../../store/system/user.state';
import { Button, Card, Col, Divider, Input, Layout, Radio, Row, Space, Table, Typography } from 'antd';
import { contentTitle } from '../../style/page';
import { ColumnsType, TableRowSelection } from 'antd/lib/table/interface';
import { UserEditPopup, UserEditPopupProps } from './UserEditPopup';
import { UserUnlockButton } from './UserUnlockButton';
import { UserDeleteButton } from './UserDeleteButton';
import { useAxios } from '../../hook/axios';

const initQuery: UserListQuery = {
  userCellphoneNo: '',
  userEmail: '',
  userName: '',
  page: 1,
  size: 10,
};

export const UserPage = (): JSX.Element => {
  const [query, setQuery] = useState<UserListQuery>({ ...initQuery });
  const [pageQuery, setPageQuery] = useState<UserListQuery>({ ...initQuery });

  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<React.Key>>([]);

  const { result: page, fetch: listFetch } = useAxios<UserListQuery, Page<UserListState>>({
    url: '/api/admin/users',
    method: 'get',
    params: query,
  });

  useEffect(() => {
    setPageQuery({ ...query });
    listFetch();
  }, []);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [page]);

  const [userEditPopup, setUserEditPopup] = useState<UserEditPopupProps>({
    open: false,
    close: () =>
      setUserEditPopup({
        ...userEditPopup,
        ['open']: false,
      }),
    reloadFn: listFetch,
  });

  const columns: ColumnsType<UserListState> = [
    { title: '아이디(이메일)', dataIndex: 'userEmail', key: 'userEmail', width: 200, align: 'center' },
    {
      title: '이름',
      dataIndex: 'userName',
      key: 'userName',
      width: 100,
      align: 'center',
      render: (userName: string, record: UserListState) => (
        <Button type="link" onClick={() => editUserPopup(record.userId)}>
          {userName}
        </Button>
      ),
    },
    {
      title: '마일리지',
      dataIndex: 'userMileage',
      key: 'userMileage',
      width: 100,
      align: 'center',
      render: (userMileage: number) => <>{!!userMileage ? userMileage : '0'}</>,
    },
    {
      title: '계정잠김',
      dataIndex: 'userLocked',
      key: 'userLocked',
      width: 100,
      align: 'center',
      render: (userLocked: boolean) => <>{userLocked ? '잠김' : '정상'}</>,
    },
    {
      title: '로그인시도회수',
      dataIndex: 'userChallenge',
      key: 'userChallenge',
      width: 140,
      align: 'center',
      render: (userChallenge: number) => <>{!!userChallenge ? userChallenge : '0'}</>,
    },
    {
      title: '사용여부',
      dataIndex: 'enabled',
      key: 'enabled',
      width: 100,
      align: 'center',
      render: (enabled: boolean) => <>{enabled ? '사용' : '미사용'}</>,
    },
    {
      title: '생성자',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: 100,
      align: 'center',
      render: (createdBy: string) => <>{!!createdBy ? createdBy : '-'}</>,
    },
    {
      title: '생성일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 180,
      align: 'center',
    },
    {
      title: '수정자',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      width: 100,
      align: 'center',
      render: (updatedBy: string) => <>{!!updatedBy ? updatedBy : '-'}</>,
    },
    {
      title: '수정일',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 180,
      align: 'center',
      render: (updatedAt: string) => <>{!!updatedAt ? updatedAt : '-'}</>,
    },
    {
      title: '관리',
      dataIndex: 'userId',
      key: 'userId',
      width: 200,
      align: 'center',
      render: (userId: number, record: UserListState) =>
        record.userLocked ? <UserUnlockButton userId={userId} reloadFn={listFetch} /> : <>-</>,
    },
  ];

  const rowSelection: TableRowSelection<UserListState> = {
    selectedRowKeys: selectedRowKeys,
    type: 'checkbox',
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const editUserPopup = (userId: number) => {
    setUserEditPopup({
      ...userEditPopup,
      userId: userId,
      open: true,
    });
  };

  const newUserPopup = () => {
    setUserEditPopup({
      ...userEditPopup,
      userId: undefined,
      open: true,
    });
  };

  const handleReset = () => {
    setQuery({ ...initQuery });
    setPageQuery({ ...initQuery });
  };

  const handleSearch = () => {
    setPageQuery({ ...query, page: 1 });
    listFetch(query);
  };

  const handlePagination = (page: number, pageSize: number) => {
    const newPageQuery: UserListQuery = {
      ...query,
      ['page']: page,
      ['size']: pageSize,
    };
    // page만 변경
    setPageQuery(newPageQuery);
    listFetch(newPageQuery);
  };

  return (
    <Layout>
      <Row style={{ ...contentTitle }}>
        <Typography.Title level={5} style={{ margin: '0' }}>
          사용자관리
        </Typography.Title>
      </Row>

      <Row style={{ padding: '1rem' }}>
        <Col xs={24}>
          <Card style={{ width: '100%' }}>
            <Row gutter={[20, 10]}>
              <Col>
                <Space>
                  <label style={{ width: '100px', display: 'inline-block' }}>아이디(이메일)</label>
                  <Input
                    style={{ width: '180px' }}
                    name="userEmail"
                    value={query.userEmail}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
              <Col>
                <Space>
                  <label style={{ width: '100px', display: 'inline-block' }}>사용자명</label>
                  <Input
                    style={{ width: '180px' }}
                    name="userName"
                    value={query.userName}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
              <Col>
                <Space>
                  <label style={{ width: '100px', display: 'inline-block' }}>휴대전화번호</label>
                  <Input
                    style={{ width: '180px' }}
                    name="userCellphoneNo"
                    value={query.userCellphoneNo}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
            </Row>

            <Col xs={24}>
              <Divider />
            </Col>

            <Row gutter={[20, 10]}>
              <Col>
                <Space>
                  <label style={{ width: '100px', display: 'inline-block' }}>사용여부</label>
                  <Radio.Group
                    name="enabled"
                    value={query.enabled}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name as string]: value })}
                  >
                    <Radio.Button value={undefined} defaultChecked>
                      전체
                    </Radio.Button>
                    <Radio.Button value={true}>사용</Radio.Button>
                    <Radio.Button value={false}>미사용</Radio.Button>
                  </Radio.Group>
                </Space>
              </Col>
              <Col>
                <Space>
                  <label style={{ width: '100px', display: 'inline-block' }}>계정잠김</label>
                  <Radio.Group
                    name="userLocked"
                    value={query.userLocked}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name as string]: value })}
                  >
                    <Radio.Button value={undefined} defaultChecked>
                      전체
                    </Radio.Button>
                    <Radio.Button value={false}>정상</Radio.Button>
                    <Radio.Button value={true}>잠김</Radio.Button>
                  </Radio.Group>
                </Space>
              </Col>
            </Row>

            <Col xs={24}>
              <Divider />
            </Col>

            <Row justify="center">
              <Space>
                <Button type="primary" htmlType="submit" onClick={() => handleSearch()}>
                  검색
                </Button>

                <Button type="default" onClick={() => handleReset()}>
                  초기화
                </Button>
              </Space>
            </Row>
          </Card>
        </Col>

        <Col xs={24} style={{ marginTop: '2rem' }}>
          <Row justify="space-between">
            <UserDeleteButton userIds={selectedRowKeys} reloadFn={() => listFetch(pageQuery)} />
            <Button type="primary" onClick={() => newUserPopup()}>
              사용자 등록
            </Button>
          </Row>
        </Col>
        <Col xs={24} style={{ marginTop: '.5rem' }}>
          <Table
            size="large"
            style={{ width: '100%' }}
            rowKey="userId"
            rowSelection={rowSelection}
            dataSource={page?.contents}
            columns={columns}
            scroll={{ x: 1400, y: 600 }}
            pagination={{
              position: ['bottomCenter'],
              pageSize: pageQuery.size,
              defaultPageSize: query.size,
              current: pageQuery.page,
              defaultCurrent: query.page,
              total: page?.totalElements,
              onChange: (page, pageSize) => handlePagination(page, pageSize),
            }}
          />
        </Col>
      </Row>
      <UserEditPopup {...userEditPopup} />
    </Layout>
  );
};
