import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

interface Props {
  paddingLeft?: string;
  marginTop?: string;
  marginBottom?: string;
  color?: string;
  message: string;
}

export const ErrorMessage = (props: Props) => {
  const [paddingLeft, setPaddingLeft] = useState<string>('0px');
  const [marginTop, setMarginTop] = useState<string>('0px');
  const [marginBottom, setMarginBottom] = useState<string>('0px');
  const [color, setColor] = useState<string>('red');

  useEffect(() => {
    if (!!props.paddingLeft) {
      setPaddingLeft(props.paddingLeft);
    }

    if (!!props.marginTop) {
      setMarginTop(props.marginTop);
    }

    if (!!props.marginBottom) {
      setMarginBottom(props.marginBottom);
    }

    if (!!props.color) {
      setColor(props.color);
    }
  }, [props]);

  return (
    <motion.div
      style={{ paddingLeft: paddingLeft, marginTop: marginTop, marginBottom: marginBottom, color: color }}
      initial={{ height: 0 }}
      animate={{ height: 10 }}
      exit={{ opacity: 0 }}
    >
      {props.message}
    </motion.div>
  );
};
