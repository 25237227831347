import { Button, Col, Collapse, message, Row, Table } from 'antd';
import React from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import confirm from 'antd/es/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useAxios } from '../../../hook/axios';
import { OrderDetailPaymentState } from '../../../store/order/order.state';

export interface OrderPaymentComponentProps {
  orderPayments: Array<OrderDetailPaymentState>;
  orderId: number;
  orderStatusType: string;
  totalAmount: number;
  paidAmount: number;
  refundAmount: number;
  fetch: () => void;
}

export const OrderPaymentComponent = (props: OrderPaymentComponentProps): JSX.Element => {
  const orderPayments = props.orderPayments;

  const { fetch: cancel } = useAxios({
    url: `/api/admin/orders/${props.orderId}/cancel`,
    method: 'post',
  });

  const columns: ColumnsType<OrderDetailPaymentState> = [
    {
      title: '결제상태',
      dataIndex: 'paymentStatusType',
      key: 'paymentStatusType',
      width: 150,
      align: 'center',
    },
    {
      title: '결제구분',
      dataIndex: 'orderPaymentType',
      key: 'orderPaymentType',
      width: 150,
      align: 'center',
    },
    { title: '결제정보', dataIndex: 'orderPaymentTrackingContents', key: 'orderPaymentTrackingContents', width: 200, align: 'center' },
    {
      title: '금액',
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
      width: 200,
      align: 'center',
      render: (paymentAmount: number) => paymentAmount.toLocaleString('ko'),
    },
    { title: '담당자', dataIndex: 'createdBy', key: 'createdBy', width: 100, align: 'center' },
    { title: '일시', dataIndex: 'createdAt', key: 'createdAt', width: 200, align: 'center' },
  ];

  const handleCancel = () => {
    confirm({
      title: '주문을 취소하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: '예',
      cancelText: '아니오',
      type: 'error',
      onOk() {
        cancel()
          .then(() => {
            message.success('취소처리되었습니다.');
            props.fetch();
          })
          .catch(() => message.error('취소처리 중 오류가 발생했습니다.'));
      },
    });
  };

  return (
    <Collapse defaultActiveKey={['order_payments']} expandIconPosition="end" style={{ margin: '1rem 0' }}>
      <Collapse.Panel key={'order_payments'} header={'결제정보'}>
        <Row gutter={[8, 8]}>
          <Col xs={24}>
            <Row justify={'space-between'} align="middle">
              <Col>
                <Row gutter={[32, 0]}>
                  <Col>
                    <label>{'총 판매금액 : ' + props.totalAmount.toLocaleString('ko')}</label>
                  </Col>
                  <Col>{'입금금액 : ' + (props.paidAmount + props.refundAmount).toLocaleString('ko')}</Col>
                  <Col>{'환불금액 : ' + props.refundAmount.toLocaleString('ko')}</Col>
                  <Col>{'미수금액 : ' + (props.totalAmount - props.paidAmount - props.refundAmount).toLocaleString('ko')}</Col>
                </Row>
              </Col>
              {props.orderStatusType === 'COMPLETE_CANCEL_ORDER' ? (
                ''
              ) : (
                <Col>
                  <Button danger style={{ float: 'right', marginBottom: '1rem' }} type="primary" onClick={() => handleCancel()}>
                    취소처리
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
          {orderPayments.length > 0 ? (
            <Col xs={24}>
              <Table bordered={true} dataSource={orderPayments} columns={columns} pagination={false} />
            </Col>
          ) : (
            <Col xs={24}>조회된 데이터가 없습니다.</Col>
          )}
        </Row>
      </Collapse.Panel>
    </Collapse>
  );
};
