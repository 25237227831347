import { Card, Col, Collapse, Divider, Layout, Row, Space, Table, Typography } from 'antd';
import { contentTitle } from '../../../style/page';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table/interface';
import { OrderDeliveryComponent } from './OrderDeliveryComponent';
import { OrderDestinationComponent } from './OrderDestinationComponent';
import { OrderPaymentComponent } from './OrderPaymentComponent';
import { OrderPaymentTrackingComponent } from './OrderPaymentTrackingComponent';
import { OrderDetailMemoComponent } from './OrderDetailMemoComponent';
import { OrderDetailTrackingComponent } from './OrderDetailTrackingComponent';
import { useAxios } from '../../../hook/axios';
import { OrderDetailProductState, OrderDetailState } from '../../../store/order/order.state';
import { useRecoilValue } from 'recoil';
import { CodeObject } from '../../../store';
import { codeListState } from '../../../store/index.store';
import { codeName } from '../../../utils/EnumUtils';

const initial: OrderDetailState = {
  orderId: 0,
  orderNo: '',
  createdAt: '',
  orderCheckedAt: '',
  purchaseDecidedAt: '',
  orderStatusType: '',
  deliveryStatusType: '',
  orderCancelStatusType: '',
  paymentStatusType: '',
  purchaserName: '',
  purchaserEmail: '',
  purchaserCellphoneNo: '',
  orderAmount: 0,
  paidAmount: 0,
  refundAmount: 0,
  orderDiscountAmount: 0,
  orderDeliveryAmount: 0,
  orderGrandTotal: 0,
  orderTotalQuantity: 0,
  orderCancelReason: '',
  orderProducts: [],
  orderDeliveries: [],
  orderDestinations: [],
  orderTracking: [],
  orderMemos: [],
  orderPayments: [],
  orderDeliveryFeeInfos: [],
  orderPaymentTracking: [],
};

export const OrderDetailPage = (): JSX.Element => {
  const params = useParams();
  const id = params.id;
  const orderId = parseInt(id!);
  const [order, setOrder] = useState<OrderDetailState>({
    ...initial,
  });

  const codes = useRecoilValue<CodeObject>(codeListState);

  const { result, fetch } = useAxios<void, OrderDetailState>({
    url: `/api/admin/orders/${id}`,
    method: 'get',
  });

  useEffect(() => {
    if (!!id) {
      fetch();
    }

    return () => {
      setOrder({ ...initial });
    };
  }, []);

  useEffect(() => {
    if (!!result) {
      setOrder(result);
    }
  }, [result]);

  const columns: ColumnsType<OrderDetailProductState> = [
    { title: '상품명', dataIndex: 'productName', width: 300, key: 'productName', align: 'center' },
    {
      title: '정상가',
      dataIndex: 'productNormalPrice',
      key: 'productNormalPrice',
      width: 200,
      align: 'center',
      render: (productNormalPrice: number) => (!!productNormalPrice ? productNormalPrice.toLocaleString('ko') : '0'),
    },
    {
      title: '할인가',
      dataIndex: 'productDiscountPrice',
      key: 'productDiscountPrice',
      width: 200,
      align: 'center',
      render: (productDiscountPrice: number) => (!!productDiscountPrice ? productDiscountPrice.toLocaleString('ko') : '0'),
    },
    {
      title: '판매가',
      dataIndex: 'productSalePrice',
      key: 'productSalePrice',
      width: 200,
      align: 'center',
      render: (productSalePrice: number) => (!!productSalePrice ? productSalePrice.toLocaleString('ko') : '0'),
    },
    { title: '수량', dataIndex: 'productQuantity', key: 'productQuantity', width: 100, align: 'center' },
  ];

  return (
    <Layout>
      <Row style={{ ...contentTitle }}>
        <Typography.Title level={5} style={{ margin: '0' }}>
          주문상세정보
        </Typography.Title>
      </Row>

      <Row style={{ padding: '1rem' }}>
        <Card size="small" style={{ width: '100%', padding: '2rem 1rem' }}>
          <Collapse defaultActiveKey={['order_base_information']} expandIconPosition="end" style={{ marginBottom: '1rem' }}>
            <Collapse.Panel key={'order_base_information'} header={'주문정보'}>
              <Row gutter={[8, 0]} justify="start" style={{ margin: '8px 0' }}>
                <Col xs={24}>
                  <label style={{ display: 'inline-block' }}>주문번호: {order.orderNo}</label>
                </Col>
                <Col xs={24}>
                  <Divider />
                </Col>
                <Col xs={24}>
                  <Row gutter={[120, 0]}>
                    <Col xs={6}>
                      <label style={{ display: 'inline-block' }}>주문상태: {codeName(order.orderStatusType, codes.OrderStatusType)}</label>
                    </Col>
                    <Col xs={6}>
                      <label style={{ display: 'inline-block' }}>
                        배송상태: {codeName(order.deliveryStatusType, codes.DeliveryStatusType)}
                      </label>
                    </Col>
                    <Col xs={6}>
                      <label style={{ display: 'inline-block' }}>
                        취소상태: {codeName(order.orderCancelStatusType, codes.OrderCancelStatusType)}
                      </label>
                    </Col>
                    <Col xs={6}>
                      <label style={{ display: 'inline-block' }}>
                        결제상태: {codeName(order.paymentStatusType, codes.PaymentStatusType)}
                      </label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Divider />
                </Col>
                <Col xs={24}>
                  <Row gutter={[120, 0]}>
                    <Col xs={6}>
                      <label style={{ display: 'inline-block' }}>구매자명: {order.purchaserName}</label>
                    </Col>
                    <Col xs={6}>
                      <label style={{ display: 'inline-block' }}>구매자ID: {order.purchaserEmail}</label>
                    </Col>
                    <Col xs={6}>
                      <label style={{ display: 'inline-block' }}>연락처: {order.purchaserCellphoneNo}</label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Divider />
                </Col>
                <Col xs={24}>
                  <Row gutter={[120, 0]}>
                    <Col xs={5}>
                      <label style={{ display: 'inline-block' }}>총 정상가: {order.orderAmount.toLocaleString('ko')}</label>
                    </Col>
                    <Col xs={5}>
                      <label style={{ display: 'inline-block' }}>총 할인가: {order.orderDiscountAmount.toLocaleString('ko')}</label>
                    </Col>
                    <Col xs={5}>
                      <label style={{ display: 'inline-block' }}>총 판매가: {order.orderAmount.toLocaleString('ko')}</label>
                    </Col>
                    <Col xs={4}>
                      <label style={{ display: 'inline-block' }}>총 주문수량: {order.orderTotalQuantity.toLocaleString('ko')}</label>
                    </Col>
                    <Col xs={5}>
                      <label style={{ display: 'inline-block' }}>배송비: {order.orderDeliveryAmount.toLocaleString('ko')}</label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Divider />
                </Col>
                <Col xs={24}>
                  <Row>
                    <Col>
                      <label style={{ display: 'inline-block' }}>
                        취소사유: {!!order.orderCancelStatusType ? order.orderCancelStatusType : '-'}
                      </label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24}>
                  <Row>
                    <Col xs={24} style={{ marginTop: '2rem', marginBottom: '1rem' }}>
                      <label>{'주문목록'}</label>
                    </Col>
                    <Col xs={24}>
                      <Table bordered={true} dataSource={order.orderProducts} columns={columns} pagination={false} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>

          <OrderDeliveryComponent orderDelivery={order.orderDeliveries} orderDeliveryFeeInfos={order.orderDeliveryFeeInfos} />

          <OrderDestinationComponent orderDestinations={order.orderDestinations} />

          <OrderDetailTrackingComponent orderDetailTracking={order.orderTracking} />

          <OrderDetailMemoComponent orderMemos={order.orderMemos} orderId={orderId} reloadFn={fetch} />

          <OrderPaymentComponent
            orderPayments={order.orderPayments}
            orderId={orderId}
            orderStatusType={order.orderStatusType}
            totalAmount={order.orderGrandTotal}
            paidAmount={order.paidAmount}
            refundAmount={order.refundAmount}
            fetch={fetch}
          />

          <OrderPaymentTrackingComponent orderPaymentTracking={order.orderPaymentTracking} />
        </Card>
      </Row>
    </Layout>
  );
};
