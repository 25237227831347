import { CSSProperties, useEffect, useState } from 'react';
import { useAxios } from '../../hook/axios';
import { Select } from 'antd';
import { ProductInformationTemplatePreset } from '../../store/product/product.state';

interface ProductInformationTemplateProps {
  templateId?: number;
  setTemplateId: Function;
  style?: CSSProperties;
}

export const TemplateSelect = ({ templateId, setTemplateId, style }: ProductInformationTemplateProps): JSX.Element => {
  const defaultTemplateDropdown = [{ templateId: 0, templateName: '직접입력' }] as ProductInformationTemplatePreset[];

  // preset list load
  // onchange -> preset data load, save via setValue
  const [value, setValue] = useState<number>();

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (!!templateId) {
      setValue(templateId);
    } else {
      setValue(defaultTemplateDropdown[0].templateId);
    }
  }, [templateId]);

  const { result, fetch } = useAxios<any, ProductInformationTemplatePreset[]>({
    url: '/api/admin/products/information-templates/preset',
    method: 'get',
  });

  const getOptions = [...defaultTemplateDropdown, ...(result ?? [])];

  const handleChange = (value: number) => {
    setTemplateId(value);
  };

  return (
    <Select
      style={{ ...style }}
      value={value}
      defaultValue={defaultTemplateDropdown[0].templateId}
      onChange={(value) => handleChange(value)}
    >
      {getOptions.map((data) => (
        <Select.Option key={`${data.templateId}`} value={data.templateId}>
          {data.templateName}
        </Select.Option>
      ))}
    </Select>
  );
};
