import * as yup from 'yup';
import { NoticeState, NoticeType } from './notice.state';

export interface AgreementState {
  id?: number;
  agreeType: string;
  agreeDate: string;
  agreeChecked: boolean;
}

export interface AgreementContent {
  agreeType: string;
  agreeTitle: string;
  agreeContents: string;
  enabled: boolean;
  createdAt?: string;
}

export const agreementContentSchema: yup.SchemaOf<AgreementContent> = yup.object().shape({
  agreeType: yup.string().required(),
  agreeTitle: yup.string().required(),
  agreeContents: yup.string().required(),
  enabled: yup.boolean().required(),
  createdAt: yup.string(),
});

export interface AgreementContentCatalog {
  agreeType: string;
  agreeTypeName: string;
  agreeTitle: string;
  agreeContents: string;
  enabled: boolean;
  createdAt: string;
  createdBy: string;
  updatedAt?: string;
  updatedBy?: string;
}
