import { Button, Col, Layout, message, Row, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { ColumnsType, TableRowSelection } from 'antd/lib/table/interface';
import { BulkOrderListState } from '../../store/order/bulk.order.state';
import { contentTitle } from '../../style/page';
import { BulkOrderEditPopup, BulkOrderEditPopupProps } from './BulkOrderEditPopup';
import { defaultPage, Page, Query } from '../../store';
import { Link } from 'react-router-dom';
import { useAxios } from '../../hook/axios';
import confirm from 'antd/es/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const initPage = defaultPage as Page<BulkOrderListState>;
const initQuery: Query = { page: 1, size: 10 };
export const BulkOrderListPage = (): JSX.Element => {
  const [query, setQuery] = useState<Query>(initQuery);
  const [pageQuery, setPageQuery] = useState<Query>(initQuery);
  const [page, setPage] = useState<Page<BulkOrderListState>>(initPage);

  const { result, fetch } = useAxios<Query, Page<BulkOrderListState>>({
    url: '/api/admin/bulk/orders',
    method: 'get',
    params: query,
  });

  useEffect(() => {
    setPageQuery({ ...query });
    fetch();
  }, []);

  useEffect(() => {
    if (!!result) {
      setPage(result);
    } else {
      setPage(initPage);
    }
  }, [result]);

  const [editPopup, setEditPopup] = useState<BulkOrderEditPopupProps>({
    open: false,
    bulkOrderId: undefined,
    close: () => setEditPopup({ ...editPopup, open: false, bulkOrderId: undefined }),
    reload: fetch,
  });

  const columns: ColumnsType<BulkOrderListState> = [
    {
      title: '주문번호',
      dataIndex: 'bulkOrderNo',
      key: 'bulkOrderNo',
      width: 200,
      align: 'center',
      render: (value: string, record: BulkOrderListState) => <Link to={`/orders/bulk/detail/${record.bulkOrderId}`}>{value}</Link>,
    },
    {
      title: '주문일시',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      align: 'center',
      ellipsis: true,
      render: (value) => (!!value ? value : '-'),
    },
    {
      title: '사용여부',
      dataIndex: 'enabled',
      key: 'enabled',
      width: 100,
      align: 'center',
      ellipsis: true,
      render: (value) => (value ? '사용' : '미사용'),
    },
    {
      title: '업체명',
      dataIndex: 'bulkOrderCompanyName',
      key: 'bulkOrderCompanyName',
      width: 150,
      align: 'center',
      ellipsis: true,
    },
    {
      title: '업체 담당자명',
      dataIndex: 'bulkOrderManagerName',
      key: 'bulkOrderManagerName',
      width: 150,
      align: 'center',
      ellipsis: true,
      render: (value) => (!!value ? value : '-'),
    },
    {
      title: '담당자',
      dataIndex: 'bulkOrderChargerName',
      key: 'bulkOrderChargerName',
      width: 150,
      align: 'center',
      ellipsis: true,
      render: (value) => (!!value ? value : '-'),
    },
    {
      title: '상품명',
      dataIndex: 'bulkOrderProductName',
      key: 'bulkOrderProductName',
      width: 300,
      align: 'center',
      ellipsis: true,
      render: (value) => (!!value ? value : '-'),
    },
    {
      title: '진행율',
      width: 150,
      align: 'center',
      render: (value, record: BulkOrderListState) => `${record.purchaseQuantity}/${record.bulkOrderQuantity}`,
    },
    {
      title: '수량',
      dataIndex: 'bulkOrderQuantity',
      key: 'bulkOrderQuantity',
      width: 100,
      align: 'center',
      render: (value) => (!!value ? value : '-'),
    },
    {
      title: '수정',
      width: 200,
      align: 'center',
      render: (value, record) => (
        <Button type="primary" onClick={() => setEditPopup({ ...editPopup, open: true, bulkOrderId: record.bulkOrderId })}>
          수정
        </Button>
      ),
    },
    {
      title: '주문페이지',
      width: 200,
      align: 'center',
      render: (value, record) => (
        <a href={`${process.env.REACT_APP_MODAM_URL}/product/${record.bulkOrderProductId}?categoryId=${record.categoryId}`} target="_blank">
          주문 페이지
        </a>
      ),
    },
  ];

  const handlePagination = (page: number, pageSize: number) => {
    const newPageQuery = {
      ...query,
      ['page']: page,
      ['size']: pageSize,
    };
    // page만 변경
    setPageQuery(newPageQuery);
    fetch(newPageQuery);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const rowSelection: TableRowSelection<BulkOrderListState> = {
    selectedRowKeys: selectedRowKeys,
    type: 'checkbox',
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const { fetch: deletion } = useAxios<URLSearchParams, void>({
    url: '/api/admin/bulk/orders',
    method: 'delete',
  });

  const deleteForSelected = () => {
    if (selectedRowKeys.length === 0) {
      message.error('한 건 이상의 대량주문을 선택해주세요.');
      return;
    }

    confirm({
      title: '선택된 대량주문을 삭제하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: '예',
      cancelText: '아니오',
      type: 'error',
      onOk() {
        const urlSearchParams = new URLSearchParams();
        selectedRowKeys.forEach((id) => urlSearchParams.append('id', String(id)));

        deletion(urlSearchParams)
          .then(() => {
            message.success('선택된 대량주문을 삭제하였습니다.');
            fetch();
          })
          .catch(() => {
            message.error('선택된 대량주문 삭제에 실패하였습니다.');
          });
      },
    });
  };

  return (
    <Layout style={{ minWidth: '1200px' }}>
      <Row style={{ ...contentTitle }}>
        <Typography.Title level={5} style={{ margin: '0' }}>
          대량 주문 리스트
        </Typography.Title>
      </Row>

      <Col xs={24} style={{ marginTop: '2rem', marginBottom: '-.5rem' }}>
        <Row justify="space-between">
          <Button style={{ marginLeft: 10 }} danger type="primary" onClick={() => deleteForSelected()}>
            선택삭제
          </Button>
          <Button
            style={{ marginRight: 10 }}
            type="primary"
            onClick={() => setEditPopup({ ...editPopup, open: true, bulkOrderId: undefined })}
          >
            생성
          </Button>
        </Row>
      </Col>
      <Col xs={24} style={{ marginTop: '.5rem' }}>
        <Table
          style={{ width: '2000px', overflow: 'hidden', padding: 10 }}
          rowKey="bulkOrderId"
          rowSelection={rowSelection}
          dataSource={page.contents}
          columns={columns}
          scroll={{ x: 800, y: 600 }}
          pagination={{
            position: ['bottomCenter'],
            pageSize: pageQuery.size,
            defaultPageSize: query.size,
            current: pageQuery.page,
            defaultCurrent: query.page,
            total: page.totalElements,
            onChange: (page, pageSize) => handlePagination(page, pageSize),
          }}
        />
      </Col>
      <BulkOrderEditPopup {...editPopup} />
    </Layout>
  );
};
