import { Col, message, Modal, Row, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { ExclamationCircleOutlined, InboxOutlined } from '@ant-design/icons';
import Button from 'antd-button-color';
import { RcFile } from 'antd/es/upload/interface';
import { useAxios } from '../../hook/axios';
import confirm from 'antd/es/modal/confirm';

export interface OrderDeliveryUploadPopupProps {
  open: boolean;
  close: () => void;
  reloadFn: Function;
}

export const OrderDeliveryUploadPopup = (props: OrderDeliveryUploadPopupProps): JSX.Element => {
  const [fileList, setFileList] = useState<RcFile[]>([]);

  useEffect(() => {
    if (props.open) {
      setFileList([]);
    }
  }, [props.open]);

  const { fetch: xlsx } = useAxios({
    url: '/api/admin/orders/dispatches/xlsx',
    method: 'post',
  });

  const save = () => {
    if (fileList.length === 0) {
      message.error('파일을 선택해주세요.');
      return;
    }

    // 업로드 로직 시작
    const formData = new FormData();
    formData.append('file', fileList[0]);

    confirm({
      title: '엑셀 일괄 발송처리를 하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: '예',
      cancelText: '아니오',
      type: 'error',
      onOk() {
        xlsx(formData)
          .then(() => {
            message.success('엑셀 일괄 발송처리가 완료되었습니다');
            props.reloadFn();
            props.close();
          })
          .catch(() => {
            message.error('엑셀 일괄 발송처리 중 오류가 발생했습니다.');
          });
      },
    });
  };

  return (
    <Modal title="엑셀 일괄 발송처리" visible={props.open} footer={null}>
      <div style={{ padding: '20px' }}>
        <Upload.Dragger
          name="file"
          multiple={false}
          fileList={fileList}
          onRemove={() => setFileList([])}
          beforeUpload={(file: RcFile) => {
            if (!file.name.endsWith('.xls') && !file.name.endsWith('.xlsx')) {
              message.error('엑셀 파일만 업로드 가능합니다.');
              return false;
            }

            setFileList([file]);
            return false;
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">업로드할 파일을 여기에 올리거나 클릭해주세요.</p>
        </Upload.Dragger>
        <div style={{ padding: '10px', wordWrap: 'break-word' }}>
          <h4>※ 유의사항</h4>
          <ul>
            <li>
              발송 처리하려는 주문을 '엑셀다운로드' 기능을 통해 다운받아 상품주문번호, 배송방법, 택배사, 송장번호를 입력하신 후 다시 업로드
              하시면 일괄 등록됩니다.
            </li>
            <li>업로드 하는 엑셀파일은 다운 받은 엑셀파일과 양식이 동일해야 합니다. 송장번호만 추가 부탁 드립니다.</li>
            <li>파일업로드 후 반드시 '일괄 발송처리' 버튼을 통해 일괄등록을 완료해 주세요.</li>
            <li>엑셀 다운로드를 통해 받으신 파일의 형식과 동일한 확장자(xls) 파일이어야 합니다.</li>
          </ul>
          <Row justify="space-evenly" style={{ marginTop: '40px' }}>
            <Col>
              <Button type="primary" ghost={true} size="large" style={{ width: '150px' }} onClick={() => save()}>
                일괄 배송처리
              </Button>
            </Col>
            <Col>
              <Button type="default" size="large" style={{ width: '100px' }} onClick={() => props.close()}>
                취소
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};
