import { useSetRecoilState } from 'recoil';
import { Col, Collapse, Input, Row, Space } from 'antd';
import React from 'react';
import { DaumAddressPopupStore } from '../../../store/component';
import { Address } from 'react-daum-postcode';
import { ProductEditProps } from '../ProductEditPage';

export const ProductReturnAndExchangeFragment = (props: ProductEditProps): JSX.Element => {
  const handleChange = (name: string, value: unknown) => {
    props.setProduct({
      ...props.product,
      productCustomerPolicy: {
        ...props.product.productCustomerPolicy,
        [name]: value,
      },
    });
  };

  const setZipcode = useSetRecoilState(DaumAddressPopupStore);
  const searchAddress = () => {
    setZipcode({ open: true, callback: selectedAddress });
  };

  const selectedAddress = (address: Address) => {
    props.setProduct({
      ...props.product,
      productCustomerPolicy: {
        ...props.product.productCustomerPolicy,
        ['changeZipcode']: address.zonecode,
        ['changeAddress']: address.address,
      },
    });
  };

  return (
    <>
      <Collapse defaultActiveKey={['product_customer_policy1']} expandIconPosition="right">
        <Collapse.Panel key={'product_customer_policy1'} header={'반품 및 교환'}>
          <Row gutter={[0, 12]}>
            <Col xs={24}>
              <Space>
                <label style={{ width: '140px', display: 'inline-block' }}>반품/교환 택배사</label>
                <Input
                  style={{ width: '200px' }}
                  name="changeDeliveryCompany"
                  value={props.product.productCustomerPolicy.changeDeliveryCompany}
                  onChange={({ target: { name, value } }) => handleChange(name, value)}
                />
              </Space>
            </Col>
            <Col xs={24}>
              <Space>
                <label style={{ width: '140px', display: 'inline-block' }}>반품 배송비(편도)</label>
                <Input
                  style={{ width: '200px' }}
                  name="returnDeliveryFee"
                  value={props.product.productCustomerPolicy.returnDeliveryFee}
                  onChange={({ target: { name, value } }) => handleChange(name, value)}
                />
                <span>원</span>
              </Space>
              <span>최소 배송비가 무료였을 경우 반품 배송비는 왕복으로 청구됩니다.</span>
            </Col>
            <Col xs={24}>
              <Space>
                <label style={{ width: '140px', display: 'inline-block' }}>교환 배송비(왕복)</label>
                <Input
                  style={{ width: '200px' }}
                  name="exchangeDeliveryFee"
                  value={props.product.productCustomerPolicy.exchangeDeliveryFee}
                  onChange={({ target: { name, value } }) => handleChange(name, value)}
                />
                <span>원</span>
              </Space>
            </Col>
            <Col xs={24}>
              <Space>
                <label style={{ width: '140px', display: 'inline-block' }}>교환 및 반품지</label>
                <Row gutter={[0, 8]}>
                  <Col xs={24}>
                    <Input.Search
                      name="changeZipcode"
                      maxLength={13}
                      readOnly={true}
                      enterButton="우편번호찾기"
                      style={{ width: '300px' }}
                      value={props.product.productCustomerPolicy.changeZipcode}
                      onSearch={() => searchAddress()}
                    />
                  </Col>
                  <Col xs={24}>
                    <Input
                      style={{ width: '300px' }}
                      readOnly={true}
                      name="changeAddress"
                      value={props.product.productCustomerPolicy.changeAddress}
                    />
                  </Col>
                  <Col xs={24}>
                    <Input
                      style={{ width: '300px' }}
                      name="changeAddressDetail"
                      value={props.product.productCustomerPolicy.changeAddressDetail}
                      onChange={({ target: { name, value } }) => handleChange(name, value)}
                    />
                  </Col>
                </Row>
              </Space>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
    </>
  );
};
