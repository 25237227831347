import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Input, Layout, List, Row, Space, Table, Typography } from 'antd';
import { contentTitle } from '../../style/page';
import { useAxios } from '../../hook/axios';
import { ColumnsType } from 'antd/lib/table/interface';
import { LiveAuctCattleGradeRequest, LiveAuctCattleGrade } from '../../store/ext/live.auct.cattle.grade';

const date: string =
  new Date().getFullYear() + (new Date().getMonth() + 1).toString().padStart(2, '0') + new Date().getDate().toString().padStart(2, '0');

const initQuery = {
  auctDate: date,
  abattCd: '',
};

export const LiveAuctCattleGradeSearch = (): JSX.Element => {
  const [query, setQuery] = useState<LiveAuctCattleGradeRequest>({ ...initQuery });
  const [pageQuery, setPageQuery] = useState<LiveAuctCattleGradeRequest>({ ...initQuery });

  const { result: page, fetch: listFetch } = useAxios<LiveAuctCattleGradeRequest, Array<LiveAuctCattleGrade>>({
    url: 'api/v1/ekape/live/auction/grade/cattle',
    method: 'post',
    params: query,
  });

  const handleSearch = () => {
    setPageQuery({ ...initQuery });
    listFetch(query);
  };

  const columns: ColumnsType<LiveAuctCattleGrade> = [
    {
      title: '경매일자',
      dataIndex: 'auctDate',
      key: 'auctDate',
      width: 100,
      align: 'center',
      render: (auctDate: string) => <>{auctDate || '-'}</>,
    },
    {
      title: '도매시장 코드',
      dataIndex: 'abattCode',
      key: 'abattCode',
      width: 100,
      align: 'center',
      render: (abattCode: string) => <>{abattCode || '-'}</>,
    },
    {
      title: '품종구분',
      dataIndex: 'judgeBreedCd',
      key: 'judgeBreedCd',
      width: 100,
      align: 'center',
      render: (judgeBreedCd: string) => <>{judgeBreedCd || '-'}</>,
    },
    {
      title: '성별',
      dataIndex: 'judgeSexCd',
      key: 'judgeSexCd',
      width: 100,
      align: 'center',
      render: (judgeSexCd: string) => <>{judgeSexCd || '-'}</>,
    },
    {
      title: '도매시장명',
      dataIndex: 'abattNm',
      key: 'abattNm',
      width: 100,
      align: 'center',
      render: (abattNm: string) => <>{abattNm || '-'}</>,
    },
    {
      title: '품종(한우,육우,젖소)',
      dataIndex: 'judgeBreedNm',
      key: 'judgeBreedNm',
      width: 100,
      align: 'center',
      render: (judgeBreedNm: string) => <>{judgeBreedNm || '-'}</>,
    },
    {
      title: '성별(암,수,거세)',
      dataIndex: 'judgeSexNm',
      key: 'judgeSexNm',
      width: 100,
      align: 'center',
      render: (judgeSexNm: string) => <>{judgeSexNm || '-'}</>,
    },
    {
      title: '1++A 경매 가격',
      dataIndex: 'auct_0aAmt',
      key: 'auct_0aAmt',
      width: 100,
      align: 'center',
      render: (auct_0aAmt: string) => <>{auct_0aAmt || '-'}</>,
    },
    {
      title: '1++B 경매 가격',
      dataIndex: 'auct_0bAmt',
      key: 'auct_0bAmt',
      width: 100,
      align: 'center',
      render: (auct_0bAmt: string) => <>{auct_0bAmt || '-'}</>,
    },
    {
      title: '1++C_경매 가격',
      dataIndex: 'auct_0cAmt',
      key: 'auct_0cAmt',
      width: 100,
      align: 'center',
      render: (auct_0cAmt: string) => <>{auct_0cAmt || '-'}</>,
    },
    {
      title: '1+A 경매 가격',
      dataIndex: 'auct_1aAmt',
      key: 'auct_1aAmt',
      width: 100,
      align: 'center',
      render: (auct_1aAmt: string) => <>{auct_1aAmt || '-'}</>,
    },
    {
      title: '1+B 경매 가격',
      dataIndex: 'auct_1bAmt',
      key: 'auct_1bAmt',
      width: 100,
      align: 'center',
      render: (auct_1bAmt: string) => <>{auct_1bAmt || '-'}</>,
    },
    {
      title: '1+C 경매 가격',
      dataIndex: 'auct_1cAmt',
      key: 'auct_1cAmt',
      width: 100,
      align: 'center',
      render: (auct_1cAmt: string) => <>{auct_1cAmt || '-'}</>,
    },
    {
      title: 'A 경매 가격',
      dataIndex: 'auct_2aAmt',
      key: 'auct_2aAmt',
      width: 100,
      align: 'center',
      render: (auct_2aAmt: string) => <>{auct_2aAmt || '-'}</>,
    },
    {
      title: 'B 경매 가격',
      dataIndex: 'auct_2bAmt',
      key: 'auct_2bAmt',
      width: 100,
      align: 'center',
      render: (auct_2bAmt: string) => <>{auct_2bAmt || '-'}</>,
    },
    {
      title: 'C 경매 가격',
      dataIndex: 'auct_2cAmt',
      key: 'auct_2cAmt',
      width: 100,
      align: 'center',
      render: (auct_2cAmt: string) => <>{auct_2cAmt || '-'}</>,
    },
    {
      title: '2A 경매 가격',
      dataIndex: 'auct_3aAmt',
      key: 'auct_3aAmt',
      width: 100,
      align: 'center',
      render: (auct_3aAmt: string) => <>{auct_3aAmt || '-'}</>,
    },
    {
      title: '2B 경매 가격',
      dataIndex: 'auct_3bAmt',
      key: 'auct_3bAmt',
      width: 100,
      align: 'center',
      render: (auct_3bAmt: string) => <>{auct_3bAmt || '-'}</>,
    },
    {
      title: '2C 경매 가격',
      dataIndex: 'auct_3cAmt',
      key: 'auct_3cAmt',
      width: 100,
      align: 'center',
      render: (auct_3cAmt: string) => <>{auct_3cAmt || '-'}</>,
    },
    {
      title: '3A 경매 가격',
      dataIndex: 'auct_4aAmt',
      key: 'auct_4aAmt',
      width: 100,
      align: 'center',
      render: (auct_4aAmt: string) => <>{auct_4aAmt || '-'}</>,
    },
    {
      title: '3B 경매 가격',
      dataIndex: 'auct_4bAmt',
      key: 'auct_4bAmt',
      width: 100,
      align: 'center',
      render: (auct_4bAmt: string) => <>{auct_4bAmt || '-'}</>,
    },
    {
      title: '3C 경매 가격',
      dataIndex: 'auct_4cAmt',
      key: 'auct_4cAmt',
      width: 100,
      align: 'center',
      render: (auct_4cAmt: string) => <>{auct_4cAmt || '-'}</>,
    },
    {
      title: '등외 경매 가격',
      dataIndex: 'auct_5dAmt',
      key: 'auct_5dAmt',
      width: 100,
      align: 'center',
      render: (auct_5dAmt: string) => <>{auct_5dAmt || '-'}</>,
    },
    {
      title: '평균 가격',
      dataIndex: 'totalAuctAmt',
      key: 'totalAuctAmt',
      width: 100,
      align: 'center',
      render: (totalAuctAmt: string) => <>{totalAuctAmt || '-'}</>,
    },
    {
      title: '평균 두수',
      dataIndex: 'totalAuctCnt',
      key: 'totalAuctCnt',
      width: 100,
      align: 'center',
      render: (totalAuctCnt: string) => <>{totalAuctCnt || '-'}</>,
    },
  ];

  return (
    <Layout>
      <Row style={{ ...contentTitle }}>
        <Typography.Title level={5} style={{ margin: '0' }}>
          실시간 소도체 등급별 경매 현황 정보
        </Typography.Title>
      </Row>

      <Row style={{ padding: '1rem' }}>
        <Col xs={24}>
          <Card style={{ width: '100%' }}>
            <Row gutter={[20, 10]}>
              <Col>
                <Space>
                  <label style={{ width: '100px', display: 'inline-block' }}>경매 일자</label>
                  <Input
                    style={{ width: '180px' }}
                    name="auctDate"
                    value={query.auctDate.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
              <Col>
                <Space>
                  <label style={{ width: '100px', display: 'inline-block' }}>도매 시장 코드</label>
                  <Input
                    style={{ width: '180px' }}
                    name="abattCd"
                    value={query.abattCd.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
            </Row>

            <Col>
              <Divider />
            </Col>

            <Row justify="center">
              <Space>
                <Button type="primary" htmlType="submit" onClick={() => handleSearch()}>
                  검색
                </Button>
              </Space>
            </Row>
          </Card>
        </Col>

        <Col xs={24} style={{ marginTop: '2rem' }}>
          <Table
            size="large"
            style={{ width: '100%' }}
            rowKey="liveAuctionCattleGradeId"
            dataSource={page}
            columns={columns}
            scroll={{ x: 1400, y: 700 }}
          />
        </Col>
      </Row>
    </Layout>
  );
};
