import React from 'react';

import { Route, Routes } from 'react-router-dom';
import { Index } from './views';
import { LoginPage } from './views/login/LoginPage';
import { flex, fullSize } from './style/CssProperties';
import { Dashboard } from './views/dashboard';
import { ProductCategoryListPage } from './views/product/category/ProductCategoryListPage';
import { UserPage } from './views/user/UserPage';
import { AuthorityPage } from './views/authority/AuthorityPage';
import { AgreementContentPage } from './views/agreement/AgreementContentPage';
import { ZipcodeSearchPopup } from './components/ZipcodeSearchPopup';
import { Loading } from './components/Loading';
import { ProductListPage } from './views/product/ProductListPage';
import { ProductEditPage } from './views/product/ProductEditPage';
import { ProductInformationTemplatePage } from './views/product/ProductInformationTemplatePage';
import { OrderListPage } from './views/order/OrderListPage';
import { OrderDetailPage } from './views/order/detail/OrderDetailPage';
import { OrderDeliveryListPage } from './views/order/OrderDeliveryListPage';
import { NoticeListPage } from './views/content/NoticeListPage';
import { AllAuthPage, SuperUserAuthPage } from './components/PageAuth';
import { Layout } from 'antd';
import { BulkOrderDetailPage } from './views/order/detail/BulkOrderDetailPage';
import { BulkOrderListPage } from './views/order/BulkOrderListPage';
import { BulkOrderContactListPage } from './views/order/BulkOrderContactListPage';
import { ProductInformationTemplateEditPage } from './views/product/detail/ProductInformationTemplateEditPage';
import { Cattle } from './views/ext/Cattle';
import { AnimalTraceSearch } from './views/ext/AnimalTraceSearch';
import { CattlePartSearch } from './views/ext/CattlePart';
import { AbattoirSearch } from './views/ext/Abattoir';
import { AuctCattleSearch } from './views/ext/AuctCattle';
import { AuctCattlePriceDetailSearch } from './views/ext/AcutCattlePriceDetail';
import { CattleDetailSearch } from './views/ext/CattleDetail';
import { CattleGradeSearch } from './views/ext/CattleGrade';
import { CattleApperenceSearch } from './views/ext/CattleApperence';
import { LiveAuctCattleSearch } from './views/ext/LiveAuctCattle';
import { LiveAuctCattleGradeSearch } from './views/ext/LiveAuctCattleGrade';

export default class App extends React.Component<any, any> {
  render() {
    return (
      <Layout style={{ ...fullSize, ...flex }}>
        <Routes>
          <Route
            key="index"
            path="/"
            element={
              <AllAuthPage>
                <Index />
              </AllAuthPage>
            }
          >
            <Route
              key="dashboard"
              index
              element={
                <AllAuthPage>
                  <Dashboard />
                </AllAuthPage>
              }
            />
            <Route
              key="dashboard"
              path="/dashboard"
              element={
                <AllAuthPage>
                  <Dashboard />
                </AllAuthPage>
              }
            />
            <Route key="products" path="/products">
              <Route
                key="product_list"
                index
                element={
                  <AllAuthPage>
                    <ProductListPage />
                  </AllAuthPage>
                }
              />
              <Route
                key="product_edit"
                path="edit"
                element={
                  <AllAuthPage>
                    <ProductEditPage />
                  </AllAuthPage>
                }
              >
                <Route key="product_create_edit" index />
                <Route key="product_update_edit" path=":id" />
              </Route>
              <Route
                key="product_categories"
                path="categories"
                element={
                  <AllAuthPage>
                    <ProductCategoryListPage />
                  </AllAuthPage>
                }
              />
              <Route
                key="product_template"
                path="template"
                element={
                  <AllAuthPage>
                    <ProductInformationTemplatePage />
                  </AllAuthPage>
                }
              />
              <Route
                key="product_template_edit"
                path="template_edit"
                element={
                  <AllAuthPage>
                    <ProductInformationTemplateEditPage />
                  </AllAuthPage>
                }
              >
                <Route key="product_create_template_edit" index />
                <Route key="product_update_template_edit" path=":templateId" />
              </Route>
            </Route>
            <Route key="orders" path="orders">
              <Route
                key="order_list"
                index
                element={
                  <AllAuthPage>
                    <OrderListPage />
                  </AllAuthPage>
                }
              />
              <Route
                key="order_detail"
                path=":id"
                element={
                  <AllAuthPage>
                    <OrderDetailPage />
                  </AllAuthPage>
                }
              />
            </Route>
            <Route
              key="order_deliveries"
              path="/orders/deliveries"
              element={
                <AllAuthPage>
                  <OrderDeliveryListPage />
                </AllAuthPage>
              }
            />
            <Route
              key="order_bulk"
              path="/orders/bulk"
              element={
                <AllAuthPage>
                  <BulkOrderListPage />
                </AllAuthPage>
              }
            />
            <Route
              key="order_bulk_detail"
              path="/orders/bulk/detail/:id"
              element={
                <AllAuthPage>
                  <BulkOrderDetailPage />
                </AllAuthPage>
              }
            />
            <Route
              key="order_bulk_contact"
              path="/orders/bulk/contact"
              element={
                <AllAuthPage>
                  <BulkOrderContactListPage />
                </AllAuthPage>
              }
            />
            <Route key="contents" path="contents">
              <Route
                key="notice_page"
                path="notice"
                element={
                  <SuperUserAuthPage>
                    <NoticeListPage />
                  </SuperUserAuthPage>
                }
              />
            </Route>
            <Route
              key="user"
              path="users"
              element={
                <SuperUserAuthPage>
                  <UserPage />
                </SuperUserAuthPage>
              }
            />
            <Route
              key="authority"
              path="authorities"
              element={
                <SuperUserAuthPage>
                  <AuthorityPage />
                </SuperUserAuthPage>
              }
            />
            <Route
              key="agreement"
              path="agreements"
              element={
                <SuperUserAuthPage>
                  <AgreementContentPage />
                </SuperUserAuthPage>
              }
            />
            <Route
              key="animalTrace"
              path="animalTraces"
              element={
                <SuperUserAuthPage>
                  <AnimalTraceSearch />
                </SuperUserAuthPage>
              }
            />
            <Route
              key="gradeCattle"
              path="gradeCattles"
              element={
                <SuperUserAuthPage>
                  <Cattle />
                </SuperUserAuthPage>
              }
            />
            <Route
              key="cattlePart"
              path="cattleParts"
              element={
                <SuperUserAuthPage>
                  <CattlePartSearch />
                </SuperUserAuthPage>
              }
            />
            <Route
              key="abattoir"
              path="abattoirs"
              element={
                <SuperUserAuthPage>
                  <AbattoirSearch />
                </SuperUserAuthPage>
              }
            />
            <Route
              key="auctCattle"
              path="auctCattles"
              element={
                <SuperUserAuthPage>
                  <AuctCattleSearch />
                </SuperUserAuthPage>
              }
            />
            <Route
              key="auctCattlePriceDetail"
              path="auctCattlePriceDetails"
              element={
                <SuperUserAuthPage>
                  <AuctCattlePriceDetailSearch />
                </SuperUserAuthPage>
              }
            />
            <Route
              key="cattleDetail"
              path="cattleDetails"
              element={
                <SuperUserAuthPage>
                  <CattleDetailSearch />
                </SuperUserAuthPage>
              }
            />
            <Route
              key="cattleGrade"
              path="cattleGrades"
              element={
                <SuperUserAuthPage>
                  <CattleGradeSearch />
                </SuperUserAuthPage>
              }
            />
            <Route
              key="cattleApperence"
              path="cattleApperences"
              element={
                <SuperUserAuthPage>
                  <CattleApperenceSearch />
                </SuperUserAuthPage>
              }
            />
            <Route
              key="liveAuctCattle"
              path="liveAuctCattles"
              element={
                <SuperUserAuthPage>
                  <LiveAuctCattleSearch />
                </SuperUserAuthPage>
              }
            />
            <Route
              key="liveAuctCattleGrade"
              path="liveAuctCattleGrades"
              element={
                <SuperUserAuthPage>
                  <LiveAuctCattleGradeSearch />
                </SuperUserAuthPage>
              }
            />
          </Route>
          <Route key="login" path="/login" element={<LoginPage />} />
        </Routes>
        <Loading />
        <ZipcodeSearchPopup />
      </Layout>
    );
  }
}
