import { Col, Input, Radio, Row, Space } from 'antd';
import { RangeDatePicker } from '../../../components/LabelInputs';
import { SelectCategory } from '../../../components/CategoryComponent';
import React from 'react';
import { ProductEditProps } from '../ProductEditPage';
import { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';

export const ProductDefaultFragment = (props: ProductEditProps): JSX.Element => {
  const [product, setProduct] = [props.product, props.setProduct];
  const handleChange = (name: string, value: string) => {
    setProduct({
      ...product,
      [name]: value,
    });
  };

  const handleRangeDate = (rangeDate: RangeValue<Moment>) => {
    if (!!rangeDate && !!rangeDate[0] && !!rangeDate[1]) {
      setProduct({
        ...product,
        ['productSaleStartDate']: rangeDate[0]!.format('YYYY-MM-DD HH:mm:ss'),
        ['productSaleEndDate']: rangeDate[1]!.format('YYYY-MM-DD HH:mm:ss'),
      });
    }
  };

  const handleCategoriesChange = (name: string, value: Array<number>) => {
    setProduct({
      ...product,
      [name]: value.map((id) => ({ categoryId: id })),
    });
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Space>
          <label style={{ width: '160px', display: 'inline-block' }}>카테고리</label>
          <SelectCategory value={product.categories} name="categories" onChange={handleCategoriesChange} />
        </Space>
      </Col>
      <Col xs={24}>
        <Space>
          <label style={{ width: '160px', display: 'inline-block' }}>상품명</label>
          <Input
            style={{ width: '240px' }}
            name="productName"
            value={product.productName}
            onChange={({ target: { name, value } }) => handleChange(name, value)}
          />
        </Space>
      </Col>
      <Col xs={24}>
        <Space>
          <label style={{ width: '160px', display: 'inline-block' }}>상품코드</label>
          <Input
            style={{ width: '240px' }}
            name="productSku"
            value={product.productSku}
            onChange={({ target: { name, value } }) => handleChange(name, value)}
          />
        </Space>
      </Col>
      <Col xs={24}>
        <Space>
          <label style={{ width: '160px', display: 'inline-block' }}>상품한줄평</label>
          <Input
            style={{ width: '240px' }}
            name="productDescription"
            value={product.productDescription}
            onChange={({ target: { name, value } }) => handleChange(name, value)}
          />
        </Space>
      </Col>
      <Col xs={24}>
        <Space>
          <label style={{ width: '160px', display: 'inline-block' }}>상품판매상태</label>
          <Radio.Group
            name="productStatusType"
            value={product.productStatusType}
            onChange={({ target: { name, value } }) => handleChange(name!, value)}
          >
            <Radio value={'READY'}>판매대기</Radio>
            <Radio value={'SALE'}>판매중</Radio>
            <Radio value={'SOLD_OUT'}>품절</Radio>
            <Radio value={'STOP'}>판매중지</Radio>
            <Radio value={'CLOSE'}>판매종료</Radio>
            <Radio value={'FORBID'}>판매금지</Radio>
          </Radio.Group>
        </Space>
      </Col>
      <Col xs={24}>
        <Space align="start">
          <label style={{ width: '160px', display: 'inline-block' }}>전시여부</label>
          <Radio.Group name="enabledView" value={product.enabledView} onChange={(e) => handleChange('enabledView', e.target.value)}>
            <Radio value={true}>전시</Radio>
            <Radio value={false}>미전시</Radio>
          </Radio.Group>
        </Space>
      </Col>
      <Col xs={24}>
        <Space align="start">
          <label style={{ width: '160px', display: 'inline-block' }}>판매기간</label>
          <Row gutter={[0, 8]}>
            <Col xs={24}>
              <Radio.Group
                name="enabledSalePeriod"
                value={product.enabledSalePeriod}
                onChange={(e) => handleChange('enabledSalePeriod', e.target.value)}
              >
                <Radio value={true}>사용</Radio>
                <Radio value={false}>미사용</Radio>
              </Radio.Group>
            </Col>
            <Col xs={24}>
              <RangeDatePicker
                names={['productSaleStartDate', 'productSaleEndDate']}
                style={{ width: '308px' }}
                value={[product.productSaleStartDate, product.productSaleEndDate]}
                disabled={!product.enabledSalePeriod}
                changeFn={handleRangeDate}
              />
            </Col>
          </Row>
        </Space>
      </Col>
    </Row>
  );
};
