import { Col, Collapse, Row, Table } from 'antd';
import React from 'react';
import { OrderPaymentTrackingState } from '../../../store/order/order.state';
import { ColumnsType } from 'antd/lib/table/interface';

interface OrderPaymentTrackingComponentProps {
  orderPaymentTracking: Array<OrderPaymentTrackingState>;
}

export const OrderPaymentTrackingComponent = (props: OrderPaymentTrackingComponentProps): JSX.Element => {
  const orderDetailTracking = props.orderPaymentTracking;

  const columns: ColumnsType<OrderPaymentTrackingState> = [
    {
      title: '항목',
      dataIndex: 'paymentStatusType',
      key: 'paymentStatusType',
      width: 200,
      align: 'center',
    },
    { title: '내용', dataIndex: 'orderPaymentTrackingContents', key: 'orderPaymentTrackingContents', width: 200, align: 'center' },
    { title: '작업자', dataIndex: 'orderPaymentTrackingCreatedBy', key: 'orderPaymentTrackingCreatedBy', width: 200, align: 'center' },
    { title: '일시', dataIndex: 'orderPaymentTrackingCreatedAt', key: 'orderPaymentTrackingCreatedAt', width: 200, align: 'center' },
  ];

  return (
    <Collapse defaultActiveKey={['order_payment_tracking']} expandIconPosition="end" style={{ margin: '1rem 0' }}>
      <Collapse.Panel key={'order_payment_tracking'} header={'결제처리이력'}>
        <Row gutter={[8, 8]}>
          {orderDetailTracking.length > 0 ? (
            <Col xs={24}>
              <Table bordered={true} dataSource={orderDetailTracking} columns={columns} pagination={false} />
            </Col>
          ) : (
            <Col xs={24}>조회된 데이터가 없습니다.</Col>
          )}
        </Row>
      </Collapse.Panel>
    </Collapse>
  );
};
