import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Input, Layout, List, Row, Space, Table, Typography } from 'antd';
import { contentTitle } from '../../style/page';
import { useAxios } from '../../hook/axios';
import { ColumnsType } from 'antd/lib/table/interface';
import { AuctCattle, AuctCattleRequest } from '../../store/ext/auct.cattle';

const date: string =
  new Date().getFullYear() +
  (new Date().getMonth() + 1).toString().padStart(2, '0') +
  (new Date().getDate() - 1).toString().padStart(2, '0');

const initQuery: AuctCattleRequest = {
  startYmd: date,
  endYmd: date,
  breedCd: '024001',
  sexCd: '025001',
  qgradeYn: 'N',
  defectIncludeYn: 'Y',
};

export const AuctCattleSearch = (): JSX.Element => {
  const [query, setQuery] = useState<AuctCattleRequest>({ ...initQuery });
  const [pageQuery, setPageQuery] = useState<AuctCattleRequest>({ ...initQuery });

  const { result: page, fetch: listFetch } = useAxios<AuctCattleRequest, Array<AuctCattle>>({
    url: 'api/v1/ekape/auct/cattle',
    method: 'post',
    params: query,
  });

  const handleSearch = () => {
    setPageQuery({ ...initQuery });
    listFetch(query);
  };

  const columns: ColumnsType<AuctCattle> = [
    {
      title: '등급 구분',
      dataIndex: 'gradeType',
      key: 'gradeType',
      width: 100,
      align: 'center',
      render: (gradeType: string) => <>{!!gradeType ? gradeType : '-'}</>,
    },
    {
      title: '시작일',
      dataIndex: 'startYmd',
      key: 'startYmd',
      width: 100,
      align: 'center',
      render: (startYmd: string) => <>{!!startYmd ? startYmd : '-'}</>,
    },
    {
      title: '종료일',
      dataIndex: 'endYmd',
      key: 'endYmd',
      width: 100,
      align: 'center',
      render: (endYmd: string) => <>{!!endYmd ? endYmd : '-'}</>,
    },
    {
      title: '등급 코드',
      dataIndex: 'gradeCd',
      key: 'gradeCd',
      width: 100,
      align: 'center',
      render: (gradeCd: string) => <>{!!gradeCd ? gradeCd : '-'}</>,
    },
    {
      title: '등급명',
      dataIndex: 'gradeNm',
      key: 'gradeNm',
      width: 100,
      align: 'center',
      render: (gradeNm: string) => <>{!!gradeNm ? gradeNm : '-'}</>,
    },
    {
      title: '품종 코드',
      dataIndex: 'judgeBreedCd',
      key: 'judgeBreedCd',
      width: 100,
      align: 'center',
      render: (judgeBreedCd: string) => <>{!!judgeBreedCd ? judgeBreedCd : '-'}</>,
    },
    {
      title: '품종',
      dataIndex: 'judgeBreedNm',
      key: 'judgeBreedNm',
      width: 100,
      align: 'center',
      render: (judgeBreedNm: string) => <>{!!judgeBreedNm ? judgeBreedNm : '-'}</>,
    },
    {
      title: '성별 코드',
      dataIndex: 'judgeSexCd',
      key: 'judgeSexCd',
      width: 100,
      align: 'center',
      render: (judgeSexCd: string) => <>{!!judgeSexCd ? judgeSexCd : '-'}</>,
    },
    {
      title: '성별',
      dataIndex: 'judgeSexNm',
      key: 'judgeSexNm',
      width: 100,
      align: 'center',
      render: (judgeSexNm: string) => <>{!!judgeSexNm ? judgeSexNm : '-'}</>,
    },
    {
      title: '육질 등급 구분',
      dataIndex: 'qgradeYn',
      key: 'qgradeYn',
      width: 100,
      align: 'center',
      render: (qgradeYn: string) => <>{!!qgradeYn ? qgradeYn : '-'}</>,
    },
    {
      title: '가격 구분',
      dataIndex: 'defectIncludeYn',
      key: 'defectIncludeYn',
      width: 100,
      align: 'center',
      render: (defectIncludeYn: string) => <>{!!defectIncludeYn ? defectIncludeYn : '-'}</>,
    },
    {
      title: '가격 (농협서울)',
      dataIndex: 'c_0101Amt',
      key: 'c_0101Amt',
      width: 100,
      align: 'center',
      render: (c_0101Amt: number) => <>{!!c_0101Amt ? c_0101Amt : '-'}</>,
    },
    {
      title: '두수(농협서울)',
      dataIndex: 'c_0101Cnt',
      key: 'c_0101Cnt',
      width: 100,
      align: 'center',
      render: (c_0101Cnt: number) => <>{!!c_0101Cnt ? c_0101Cnt : '-'}</>,
    },
    {
      title: '가격(도드람)',
      dataIndex: 'c_0320Amt',
      key: 'c_0320Amt',
      width: 100,
      align: 'center',
      render: (c_0320Amt: number) => <>{!!c_0320Amt ? c_0320Amt : '-'}</>,
    },
    {
      title: '두수(도드람)',
      dataIndex: 'c_0320Cnt',
      key: 'c_0320Cnt',
      width: 100,
      align: 'center',
      render: (c_0320Cnt: number) => <>{!!c_0320Cnt ? c_0320Cnt : '-'}</>,
    },
    {
      title: '가격(협신식품)',
      dataIndex: 'c_0302Amt',
      key: 'c_0302Amt',
      width: 100,
      align: 'center',
      render: (c_0302Amt: number) => <>{!!c_0302Amt ? c_0302Amt : '-'}</>,
    },
    {
      title: '두수(협신식품)',
      dataIndex: 'c_0302Cnt',
      key: 'c_0302Cnt',
      width: 100,
      align: 'center',
      render: (c_0302Cnt: number) => <>{!!c_0302Cnt ? c_0302Cnt : '-'}</>,
    },
    {
      title: '가격(삼성식품)',
      dataIndex: 'c_1301Amt',
      key: 'c_1301Amt',
      width: 100,
      align: 'center',
      render: (c_1301Amt: number) => <>{!!c_1301Amt ? c_1301Amt : '-'}</>,
    },
    {
      title: '두수(삼성식품)',
      dataIndex: 'c_1301Cnt',
      key: 'c_1301Cnt',
      width: 100,
      align: 'center',
      render: (c_1301Cnt: number) => <>{!!c_1301Cnt ? c_1301Cnt : '-'}</>,
    },
    {
      title: '가격(농협부천)',
      dataIndex: 'c_0323Amt',
      key: 'c_0323Amt',
      width: 100,
      align: 'center',
      render: (c_0323Amt: number) => <>{!!c_0323Amt ? c_0323Amt : '-'}</>,
    },
    {
      title: '두수(농협부천)',
      dataIndex: 'c_0323Cnt',
      key: 'c_0323Cnt',
      width: 100,
      align: 'center',
      render: (c_0323Cnt: number) => <>{!!c_0323Cnt ? c_0323Cnt : '-'}</>,
    },
    {
      title: '가격(수도권)',
      dataIndex: 'CCityCnt',
      key: 'CCityCnt',
      width: 100,
      align: 'center',
      render: (CCityCnt: number) => <>{!!CCityCnt ? CCityCnt : '-'}</>,
    },
    {
      title: '두수(수도권)',
      dataIndex: 'CCityAmt',
      key: 'CCityAmt',
      width: 100,
      align: 'center',
      render: (CCityAmt: number) => <>{!!CCityAmt ? CCityAmt : '-'}</>,
    },
    {
      title: 'CCenterAmt',
      dataIndex: 'CCenterAmt',
      key: 'CCenterAmt',
      width: 100,
      align: 'center',
      render: (CCenterAmt: number) => <>{!!CCenterAmt ? CCenterAmt : '-'}</>,
    },
    {
      title: 'CCenterCnt',
      dataIndex: 'CCenterCnt',
      key: 'CCenterCnt',
      width: 100,
      align: 'center',
      render: (CCenterCnt: number) => <>{!!CCenterCnt ? CCenterCnt : '-'}</>,
    },
    {
      title: '가격(농협음성)',
      dataIndex: 'c_0513Amt',
      key: 'c_0513Amt',
      width: 100,
      align: 'center',
      render: (c_0513Amt: number) => <>{!!c_0513Amt ? c_0513Amt : '-'}</>,
    },
    {
      title: '두수(농협음성)',
      dataIndex: 'c_0513Cnt',
      key: 'c_0513Cnt',
      width: 100,
      align: 'center',
      render: (c_0513Cnt: number) => <>{!!c_0513Cnt ? c_0513Cnt : '-'}</>,
    },
    {
      title: '가격(관성)',
      dataIndex: 'c_0613Amt',
      key: 'c_0613Amt',
      width: 100,
      align: 'center',
      render: (c_0613Amt: number) => <>{!!c_0613Amt ? c_0613Amt : '-'}</>,
    },
    {
      title: '두수(관성)',
      dataIndex: 'c_0613Cnt',
      key: 'c_0613Cnt',
      width: 100,
      align: 'center',
      render: (c_0613Cnt: number) => <>{!!c_0613Cnt ? c_0613Cnt : '-'}</>,
    },
    {
      title: '가격(농협포크빌)',
      dataIndex: 'c_0616Amt',
      key: 'c_0616Amt',
      width: 100,
      align: 'center',
      render: (c_0616Amt: number) => <>{!!c_0616Amt ? c_0616Amt : '-'}</>,
    },
    {
      title: '두수(농협포크빌)',
      dataIndex: 'c_0616Cnt',
      key: 'c_0616Cnt',
      width: 100,
      align: 'center',
      render: (c_0616Cnt: number) => <>{!!c_0616Cnt ? c_0616Cnt : '-'}</>,
    },
    {
      title: '가격(중부권)',
      dataIndex: 'c_centerAmt',
      key: 'c_centerAmt',
      width: 100,
      align: 'center',
      render: (c_centerAmt: number) => <>{!!c_centerAmt ? c_centerAmt : '-'}</>,
    },
    {
      title: '두수(중부권)',
      dataIndex: 'c_centerCnt',
      key: 'c_centerCnt',
      width: 100,
      align: 'center',
      render: (c_centerCnt: number) => <>{!!c_centerCnt ? c_centerCnt : '-'}</>,
    },
    {
      title: '가격(김해축공)',
      dataIndex: 'c_1005Amt',
      key: 'c_1005Amt',
      width: 100,
      align: 'center',
      render: (c_1005Amt: number) => <>{!!c_1005Amt ? c_1005Amt : '-'}</>,
    },
    {
      title: '두수(김해축공)',
      dataIndex: 'c_1005Cnt',
      key: 'c_1005Cnt',
      width: 100,
      align: 'center',
      render: (c_1005Cnt: number) => <>{!!c_1005Cnt ? c_1005Cnt : '-'}</>,
    },
    {
      title: '가격(부경축공)',
      dataIndex: 'c_0202Amt',
      key: 'c_0202Amt',
      width: 100,
      align: 'center',
      render: (c_0202Amt: number) => <>{!!c_0202Amt ? c_0202Amt : '-'}</>,
    },
    {
      title: '두수(부경축공)',
      dataIndex: 'c_0202Cnt',
      key: 'c_0202Cnt',
      width: 100,
      align: 'center',
      render: (c_0202Cnt: number) => <>{!!c_0202Cnt ? c_0202Cnt : '-'}</>,
    },
    {
      title: '가격(동원산업)',
      dataIndex: 'c_0201Amt',
      key: 'c_0201Amt',
      width: 100,
      align: 'center',
      render: (c_0201Amt: number) => <>{!!c_0201Amt ? c_0201Amt : '-'}</>,
    },
    {
      title: '두수(동원산업)',
      dataIndex: 'c_0201Cnt',
      key: 'c_0201Cnt',
      width: 100,
      align: 'center',
      render: (c_0201Cnt: number) => <>{!!c_0201Cnt ? c_0201Cnt : '-'}</>,
    },
    {
      title: '가격(신흥산업)',
      dataIndex: 'c_1201Amt',
      key: 'c_1201Amt',
      width: 100,
      align: 'center',
      render: (c_1201Amt: number) => <>{!!c_1201Amt ? c_1201Amt : '-'}</>,
    },
    {
      title: '두수(신흥산업)',
      dataIndex: 'c_1201Cnt',
      key: 'c_1201Cnt',
      width: 100,
      align: 'center',
      render: (c_1201Cnt: number) => <>{!!c_1201Cnt ? c_1201Cnt : '-'}</>,
    },
    {
      title: '가격(농협고령)',
      dataIndex: 'c_0905Amt',
      key: 'c_0905Amt',
      width: 100,
      align: 'center',
      render: (c_0905Amt: number) => <>{!!c_0905Amt ? c_0905Amt : '-'}</>,
    },
    {
      title: '두수(농협고령)',
      dataIndex: 'c_0905Cnt',
      key: 'c_0905Cnt',
      width: 100,
      align: 'center',
      render: (c_0905Cnt: number) => <>{!!c_0905Cnt ? c_0905Cnt : '-'}</>,
    },
    {
      title: '가격(영남권)',
      dataIndex: 'CEastAmt',
      key: 'CEastAmt',
      width: 100,
      align: 'center',
      render: (CEastAmt: number) => <>{!!CEastAmt ? CEastAmt : '-'}</>,
    },
    {
      title: '두수(영남권)',
      dataIndex: 'CEastCnt',
      key: 'CEastCnt',
      width: 100,
      align: 'center',
      render: (CEastCnt: number) => <>{!!CEastCnt ? CEastCnt : '-'}</>,
    },
    {
      title: '가격(익산)',
      dataIndex: 'c_0714Amt',
      key: 'c_0714Amt',
      width: 100,
      align: 'center',
      render: (c_0714Amt: number) => <>{!!c_0714Amt ? c_0714Amt : '-'}</>,
    },
    {
      title: '두수(익산)',
      dataIndex: 'c_0714Cnt',
      key: 'c_0714Cnt',
      width: 100,
      align: 'center',
      render: (c_0714Cnt: number) => <>{!!c_0714Cnt ? c_0714Cnt : '-'}</>,
    },
    {
      title: '가격(농협나주)',
      dataIndex: 'c_0809Amt',
      key: 'c_0809Amt',
      width: 100,
      align: 'center',
      render: (c_0809Amt: number) => <>{!!c_0809Amt ? c_0809Amt : '-'}</>,
    },
    {
      title: '두수(농협나주)',
      dataIndex: 'c_0809Cnt',
      key: 'c_0809Cnt',
      width: 100,
      align: 'center',
      render: (c_0809Cnt: number) => <>{!!c_0809Cnt ? c_0809Cnt : '-'}</>,
    },
    {
      title: '가격(심호축산)',
      dataIndex: 'c_1401Amt',
      key: 'c_1401Amt',
      width: 100,
      align: 'center',
      render: (c_1401Amt: number) => <>{!!c_1401Amt ? c_1401Amt : '-'}</>,
    },
    {
      title: '두수(심호축산)',
      dataIndex: 'c_1401Cnt',
      key: 'c_1401Cnt',
      width: 100,
      align: 'center',
      render: (c_1401Cnt: number) => <>{!!c_1401Cnt ? c_1401Cnt : '-'}</>,
    },
    {
      title: '가격(호남권)',
      dataIndex: 'CWestAmt',
      key: 'CWestAmt',
      width: 100,
      align: 'center',
      render: (CWestAmt: number) => <>{!!CWestAmt ? CWestAmt : '-'}</>,
    },
    {
      title: '두수(호남권)',
      dataIndex: 'CWestCnt',
      key: 'CWestCnt',
      width: 100,
      align: 'center',
      render: (CWestCnt: number) => <>{!!CWestCnt ? CWestCnt : '-'}</>,
    },
    {
      title: '가격(제주축협)',
      dataIndex: 'c_1101Amt',
      key: 'c_1101Amt',
      width: 100,
      align: 'center',
      render: (c_1101Amt: number) => <>{!!c_1101Amt ? c_1101Amt : '-'}</>,
    },
    {
      title: '두수(제주축협)',
      dataIndex: 'c_1101Cnt',
      key: 'c_1101Cnt',
      width: 100,
      align: 'center',
      render: (c_1101Cnt: number) => <>{!!c_1101Cnt ? c_1101Cnt : '-'}</>,
    },
    {
      title: '가격(전체)',
      dataIndex: 'CTotAmt',
      key: 'CTotAmt',
      width: 100,
      align: 'center',
      render: (CTotAmt: number) => <>{!!CTotAmt ? CTotAmt : '-'}</>,
    },
    {
      title: '두수(전체)',
      dataIndex: 'CTotCnt',
      key: 'CTotCnt',
      width: 100,
      align: 'center',
      render: (CTotCnt: number) => <>{!!CTotCnt ? CTotCnt : '-'}</>,
    },
    {
      title: '가격(헤드림)',
      dataIndex: 'c_1015Amt',
      key: 'c_1015Amt',
      width: 100,
      align: 'center',
      render: (c_1015Amt: number) => <>{!!c_1015Amt ? c_1015Amt : '-'}</>,
    },
    {
      title: '두수(헤드림)',
      dataIndex: 'c_1015Cnt',
      key: 'c_1015Cnt',
      width: 100,
      align: 'center',
      render: (c_1015Cnt: number) => <>{!!c_1015Cnt ? c_1015Cnt : '-'}</>,
    },
  ];

  return (
    <Layout>
      <Row style={{ ...contentTitle }}>
        <Typography.Title level={5} style={{ margin: '0' }}>
          도매시장 소도체 등급별 경락가격 정보
        </Typography.Title>
      </Row>

      <Row style={{ padding: '1rem' }}>
        <Col xs={24}>
          <Card style={{ width: '100%' }}>
            <Row gutter={[20, 10]}>
              <Col>
                <Space>
                  <label style={{ width: '80px', display: 'inline-block' }}>경매 시작일</label>
                  <Input
                    style={{ width: '180px' }}
                    name="startYmd"
                    value={query.startYmd.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
              <Col>
                <Space>
                  <label style={{ width: '80px', display: 'inline-block' }}>경매 종료일</label>
                  <Input
                    style={{ width: '180px' }}
                    name="endYmd"
                    value={query.endYmd.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
              <Col>
                <Space>
                  <label style={{ width: '60px', display: 'inline-block' }}>품종</label>
                  <Input
                    style={{ width: '180px' }}
                    name="breedCd"
                    value={query.breedCd.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
            </Row>

            <Col>
              <Divider />
            </Col>
            <Row gutter={[20, 10]}>
              <Col>
                <Space>
                  <label style={{ width: '80px', display: 'inline-block' }}>성별</label>
                  <Input
                    style={{ width: '180px' }}
                    name="sexCd"
                    value={query.sexCd.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
              <Col>
                <Space>
                  <label style={{ width: '80px', display: 'inline-block' }}>등급 구분</label>
                  <Input
                    style={{ width: '180px' }}
                    name="qgradeYn"
                    value={query.qgradeYn.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
              <Col>
                <Space>
                  <label style={{ width: '60px', display: 'inline-block' }}>가격 구분</label>
                  <Input
                    style={{ width: '180px' }}
                    name="defectIncludeYn"
                    value={query.defectIncludeYn.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
            </Row>

            <Col>
              <Divider />
            </Col>

            <Row justify="center">
              <Space>
                <Button type="primary" htmlType="submit" onClick={() => handleSearch()}>
                  검색
                </Button>
              </Space>
            </Row>
          </Card>
        </Col>

        <Col xs={24} style={{ marginTop: '2rem' }}>
          <Table
            size="large"
            style={{ width: '100%' }}
            rowKey="cattlePartId"
            dataSource={page}
            columns={columns}
            scroll={{ x: 1400, y: 700 }}
          />
        </Col>
      </Row>
    </Layout>
  );
};
