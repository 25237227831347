import { atom, AtomEffect } from 'recoil';
import { AuthorizationState } from './login.state';

/**
 * 로그인 폼 관리 state
 * */
const loginFormEffect =
  (key: string): AtomEffect<any> =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue !== null) {
      try {
        setSelf(JSON.parse(savedValue));
      } catch (e) {
        localStorage.removeItem(key);
      }
    }

    onSet((newValue) => {
      if (newValue.remember) {
        localStorage.setItem(key, JSON.stringify(newValue));
      } else {
        localStorage.removeItem(key);
      }
    });
  };

export const loginFormStore = atom({
  key: 'loginFormStore',
  default: {
    username: '',
    remember: false,
  },
  effects: [loginFormEffect('loginFormStore')],
});

const authEffect =
  (key: string): AtomEffect<any> =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue !== null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, oldValue, isReset) => {
      if (isReset) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newValue));
      }
    });
  };

export const authorizationState = atom<AuthorizationState>({
  key: 'authorizationState',
  default: {
    token: '',
    name: '',
    authenticated: false,
    roles: [],
  },
  effects: [authEffect('authorizationState')],
});
