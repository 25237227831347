import { CSSProperties } from 'react';

export const fullWidth: CSSProperties = {
  width: '100%',
};

export const fullHeight: CSSProperties = {
  height: '100%',
  minHeight: '100%',
};

export const fullSize: CSSProperties = {
  ...fullHeight,
  ...fullWidth,
};

export const flex: CSSProperties = {
  display: 'flex !important',
};

export const alignStart: CSSProperties = {
  ...flex,
  alignItems: 'flex-start',
  alignContent: 'flex-start',
};

export const alignEnd: CSSProperties = {
  ...flex,
  alignItems: 'flex-end',
  alignContent: 'flex-end',
};

export const alignCenter: CSSProperties = {
  ...flex,
  alignItems: 'center',
  alignContent: 'center',
};

export const justifyEnd: CSSProperties = {
  ...flex,
  justifyItems: 'flex-end',
  justifyContent: 'flex-end',
};

export const justifyStart: CSSProperties = {
  ...flex,
  justifyItems: 'flex-start',
  justifyContent: 'flex-start',
};

export const justifyCenter: CSSProperties = {
  ...flex,
  justifyItems: 'flex-start',
  justifyContent: 'flex-start',
};

export const flexCenter: CSSProperties = {
  ...flex,
  alignItems: 'center',
  alignContent: 'center',
  justifyItems: 'center',
  justifyContent: 'center',
};

export const flexBetween: CSSProperties = {
  ...flex,
  alignItems: 'center',
  alignContent: 'center',
  justifyItems: 'space-between',
  justifyContent: 'space-between',
};

export const flexColumn: CSSProperties = {
  ...flex,
  flexDirection: 'column',
};

export const flexRow: CSSProperties = {
  ...flex,
  flexDirection: 'row',
};
