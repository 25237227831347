import { useAxios } from '../../hook/axios';
import { Button, message } from 'antd';
import React from 'react';

interface UserUnlockButtonProps {
  userId: number;
  reloadFn: Function;
}

interface DummyUserState {
  userId: number;
}

export const UserUnlockButton = (props: UserUnlockButtonProps) => {
  const { fetch } = useAxios<DummyUserState, undefined>({
    url: '/api/admin/users/unlock',
    method: 'patch',
    data: {
      userId: props.userId,
    },
  });

  const unlock = () => {
    fetch()
      .then(() => {
        message.success('사용자 계정 잠김이 해제 되었습니다.');
        props.reloadFn();
      })
      .catch(() => {
        message.error('사용자 계정 잠김 해제에 실패하였습니다.');
      });
  };

  return (
    <Button type="primary" onClick={() => unlock()}>
      사용자 잠금해제
    </Button>
  );
};
