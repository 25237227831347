import { atom } from 'recoil';

export const EnableLoadingStore = atom({
  key: 'EnableLoadingStore',
  default: false,
});

export class DaumAddressPopupState {
  open = false;
  callback: any;
}

export const DaumAddressPopupStore = atom<DaumAddressPopupState>({
  key: 'DaumAddressPopupStore',
  default: new DaumAddressPopupState(),
});
