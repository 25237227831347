import React, { useEffect, useState } from 'react';
import { AgreementContentEditDialog, AgreementContentEditDialogProps } from './AgreementContentEditDialog';
import { Button, Col, Layout, Row, Table, Typography } from 'antd';
import { contentTitle } from '../../style/page';
import { AgreementContentCatalog } from '../../store/system/agreement.state';
import { useAxios } from '../../hook/axios';
import { ColumnsType } from 'antd/lib/table/interface';

export const AgreementContentPage = (): JSX.Element => {
  const [list, setList] = useState<Array<AgreementContentCatalog>>([]);

  const { result, fetch } = useAxios<void, Array<AgreementContentCatalog>>({
    url: '/api/admin/agreement-contents',
    method: 'get',
  });

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (!!result) {
      setList(result);
    }
  }, [result]);

  const [editPopup, setEditPopup] = useState<AgreementContentEditDialogProps>({
    open: false,
    agreeType: '',
    close: () => setEditPopup({ ...editPopup, open: false, agreeType: '' }),
    reloadFn: fetch,
  });

  const columns: ColumnsType<AgreementContentCatalog> = [
    {
      title: '약관타입',
      dataIndex: 'agreeTypeName',
      key: 'agreeTypeName',
      align: 'center',
    },
    {
      title: '약관명',
      dataIndex: 'agreeTitle',
      key: 'agreeTitle',
      align: 'center',
      render: (agreeTitle: string, record: AgreementContentCatalog) => (
        <Button type="link" onClick={() => setEditPopup({ ...editPopup, open: true, agreeType: record.agreeType })}>
          {agreeTitle}
        </Button>
      ),
    },
    {
      title: '사용여부',
      dataIndex: 'enabled',
      key: 'enabled',
      align: 'center',
      render: (enabled: boolean) => <>{enabled ? '사용' : '미사용'}</>,
    },
    {
      title: '생성자',
      dataIndex: 'createdBy',
      key: 'createdBy',
      align: 'center',
      render: (createdBy: string) => <>{!!createdBy ? createdBy : '-'}</>,
    },
    {
      title: '생성일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
    },
    {
      title: '수정자',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      align: 'center',
      render: (updatedBy: string) => <>{!!updatedBy ? updatedBy : '-'}</>,
    },
    {
      title: '수정일',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'center',
      render: (updatedAt: string) => <>{!!updatedAt ? updatedAt : '-'}</>,
    },
  ];

  return (
    <Layout>
      <Row style={{ ...contentTitle }}>
        <Typography.Title level={5} style={{ margin: '0' }}>
          약관 관리
        </Typography.Title>
      </Row>

      <Row style={{ padding: '1rem' }}>
        <Col xs={24} style={{ marginTop: '2rem' }}>
          <Row justify="end">
            <Button type="primary" onClick={() => setEditPopup({ ...editPopup, open: true, agreeType: '' })}>
              약관 등록
            </Button>
          </Row>
        </Col>

        <Col xs={24} style={{ marginTop: '.5rem' }}>
          <Table size="large" style={{ width: '100%' }} rowKey="agreeType" dataSource={list} columns={columns} pagination={false} />
        </Col>
      </Row>

      <AgreementContentEditDialog {...editPopup} />
    </Layout>
  );
};
