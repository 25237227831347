import { LabelValue } from '../store';
import { concat } from 'lodash';

export const withAll = (value: Array<LabelValue>): Array<LabelValue> => {
  return concat([{ label: '전체', value: '' }], value);
};

export const codeName = (value: string, codes: Array<LabelValue>): string => {
  const code = codes.find((code) => code.value === value);

  return !!code ? code.label : '-';
};

export const convertCheckboxArray = (o: any): Array<LabelValue> => {
  return Object.keys(o).map((item) => ({
    label: o[item],
    value: item,
  }));
};

export const convertCheckboxArrayWithAll = (o: any): Array<LabelValue> => {
  const result = [{ label: '전체', value: '' }];
  Array.prototype.push.apply(
    result,
    Object.keys(o).map((item) => ({
      label: o[item],
      value: item,
    })),
  );

  return result;
};

interface TextToValue {
  text: string;
  value: string;
}

export const textToValue = (o: any): Array<TextToValue> => {
  return Object.keys(o).map((item) => ({
    text: o[item],
    value: item,
  }));
};

export const textValue = (value: Array<LabelValue>): Array<TextToValue> => {
  return value.map((item) => ({
    text: item.label,
    value: item.value,
  }));
};

export const textValueAll = (value: Array<LabelValue>): Array<TextToValue> => {
  return value.map((item) => ({
    text: item.label,
    value: item.value,
  }));
};

export const findEnum = (o: any, value: string): any => {
  return Object.keys(o).filter((item) => item === value)[0];
};

export const valueToLabel = (o: any, value: string): any => {
  if (!value) {
    return '';
  }

  return convertCheckboxArray(o).find((obj) => obj.value === value)!.label;
};
