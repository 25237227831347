import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Input, Layout, List, Row, Space, Table, Typography } from 'antd';
import { CattleContent, ConfirmRequest } from '../../store/ext/cattle';
import { contentTitle } from '../../style/page';
import { useAxios } from '../../hook/axios';
import { ColumnsType } from 'antd/lib/table/interface';

const date: string =
  new Date().getFullYear() +
  '-' +
  (new Date().getMonth() + 1).toString().padStart(2, '0') +
  '-' +
  (new Date().getDate() - 1).toString().padStart(2, '0');

const initQuery: ConfirmRequest = {
  issueNo: '',
  issueDate: date,
};

export const Cattle = (): JSX.Element => {
  const [query, setQuery] = useState<ConfirmRequest>({ ...initQuery });
  const [pageQuery, setPageQuery] = useState<ConfirmRequest>({ ...initQuery });

  const { result: page, fetch: listFetch } = useAxios<ConfirmRequest, Array<CattleContent>>({
    url: 'api/v1/ekape/confirm/cattle',
    method: 'post',
    params: query,
  });

  const handleSearch = () => {
    setPageQuery({ ...query });
    listFetch(query);
  };

  const columns: ColumnsType<CattleContent> = [
    {
      title: '발급일자',
      dataIndex: 'issueDate',
      key: 'issueDate',
      width: 100,
      align: 'center',
      render: (issueDate: String) => <>{!!issueDate ? issueDate : '-'}</>,
    },
    {
      title: '발급 번호',
      dataIndex: 'issueNo',
      key: 'issueNo',
      width: 100,
      align: 'center',
      render: (issueNo: String) => <>{!!issueNo ? issueNo : '-'}</>,
    },
    {
      title: '품종 코드',
      dataIndex: 'judgeKindCd',
      key: 'judgeKindCd',
      width: 100,
      align: 'center',
      render: (judgeKindCd: String) => <>{!!judgeKindCd ? judgeKindCd : '-'}</>,
    },
    {
      title: '품종명',
      dataIndex: 'judgeKindNm',
      key: 'judgeKindNm',
      width: 100,
      align: 'center',
      render: (judgeKindNm: String) => <>{!!judgeKindNm ? judgeKindNm : '-'}</>,
    },
    {
      title: '평가사 코드',
      dataIndex: 'raterCode',
      key: 'raterCode',
      width: 100,
      align: 'center',
      render: (raterCode: String) => <>{!!raterCode ? raterCode : '-'}</>,
    },
    {
      title: '평가사명',
      dataIndex: 'raterNm',
      key: 'raterNm',
      width: 100,
      align: 'center',
      render: (raterNm: String) => <>{!!raterNm ? raterNm : '-'}</>,
    },
    {
      title: '도축일자',
      dataIndex: 'abattDate',
      key: 'abattDate',
      width: 100,
      align: 'center',
      render: (abattDate: String) => <>{!!abattDate ? abattDate : '-'}</>,
    },
    {
      title: '도축장명',
      dataIndex: 'abattNm',
      key: 'abattNm',
      width: 100,
      align: 'center',
      render: (abattNm: String) => <>{!!abattNm ? abattNm : '-'}</>,
    },
    {
      title: '등급판정일자',
      dataIndex: 'judgeDate',
      key: 'judgeDate',
      width: 100,
      align: 'center',
      render: (judgeDate: String) => <>{!!judgeDate ? judgeDate : '-'}</>,
    },
    {
      title: '도축장 코드',
      dataIndex: 'abattCode',
      key: 'abattCode',
      width: 100,
      align: 'center',
      render: (abattCode: String) => <>{!!abattCode ? abattCode : '-'}</>,
    },
    {
      title: '도축장 주소',
      dataIndex: 'abattAddr',
      key: 'abattAddr',
      width: 100,
      align: 'center',
      render: (abattAddr: String) => <>{!!abattAddr ? abattAddr : '-'}</>,
    },
    {
      title: '도축장 전화번호',
      dataIndex: 'abattTelNo',
      key: 'abattTelNo',
      width: 100,
      align: 'center',
      render: (abattTelNo: String) => <>{!!abattTelNo ? abattTelNo : '-'}</>,
    },
    {
      title: '신청인 명',
      dataIndex: 'reqUserNm',
      key: 'reqUserNm',
      width: 100,
      align: 'center',
      render: (reqUserNm: String) => <>{!!reqUserNm ? reqUserNm : '-'}</>,
    },
    {
      title: '신청업체 사업바 번호',
      dataIndex: 'reqRegNo',
      key: 'reqRegNo',
      width: 100,
      align: 'center',
      render: (reqRegNo: String) => <>{!!reqRegNo ? reqRegNo : '-'}</>,
    },
    {
      title: '신청업체 명',
      dataIndex: 'reqComNm',
      key: 'reqComNm',
      width: 100,
      align: 'center',
      render: (reqComNm: String) => <>{!!reqComNm ? reqComNm : '-'}</>,
    },
    {
      title: '신청업체 주소명',
      dataIndex: 'reqAddr',
      key: 'reqAddr',
      width: 100,
      align: 'center',
      render: (reqAddr: String) => <>{!!reqAddr ? reqAddr : '-'}</>,
    },
    {
      title: '축종명',
      dataIndex: 'liveStockNm',
      key: 'liveStockNm',
      width: 100,
      align: 'center',
      render: (liveStockNm: String) => <>{!!liveStockNm ? liveStockNm : '-'}</>,
    },
    {
      title: '도축 시작 번호',
      dataIndex: 'abattFno',
      key: 'abattFno',
      width: 100,
      align: 'center',
      render: (abattFno: String) => <>{!!abattFno ? abattFno : '-'}</>,
    },
    {
      title: '도축 종료 번호',
      dataIndex: 'abattTno',
      key: 'abattTno',
      width: 100,
      align: 'center',
      render: (abattTno: String) => <>{!!abattTno ? abattTno : '-'}</>,
    },
    {
      title: '등급 판정 품종',
      dataIndex: 'judgeBreedNm',
      key: 'judgeBreedNm',
      width: 100,
      align: 'center',
      render: (judgeBreedNm: String) => <>{!!judgeBreedNm ? judgeBreedNm : '-'}</>,
    },
    {
      title: '등급 판정 성별',
      dataIndex: 'judgeSexNm',
      key: 'judgeSexNm',
      width: 100,
      align: 'center',
      render: (judgeSexNm: String) => <>{!!judgeSexNm ? judgeSexNm : '-'}</>,
    },
    {
      title: '도체중량',
      dataIndex: 'weight',
      key: 'weight',
      width: 100,
      align: 'center',
      render: (weight: String) => <>{!!weight ? weight : '-'}</>,
    },
    {
      title: '육량지수',
      dataIndex: 'windex',
      key: 'windex',
      width: 100,
      align: 'center',
      render: (windex: String) => <>{!!windex ? windex : '-'}</>,
    },
    {
      title: '육질등급',
      dataIndex: 'qgrade',
      key: 'qgrade',
      width: 100,
      align: 'center',
      render: (qgrade: String) => <>{!!qgrade ? qgrade : '-'}</>,
    },
    {
      title: '육질등급',
      dataIndex: 'wgrade',
      key: 'wgrade',
      width: 100,
      align: 'center',
      render: (wgrade: String) => <>{!!wgrade ? wgrade : '-'}</>,
    },
    {
      title: '등급코드',
      dataIndex: 'gradeCd',
      key: 'gradeCd',
      width: 100,
      align: 'center',
      render: (gradeCd: String) => <>{!!gradeCd ? gradeCd : '-'}</>,
    },
    {
      title: '등급명',
      dataIndex: 'gradeNm',
      key: 'gradeNm',
      width: 100,
      align: 'center',
      render: (gradeNm: String) => <>{!!gradeNm ? gradeNm : '-'}</>,
    },
    {
      title: '확인서 발급 두수',
      dataIndex: 'issueCnt',
      key: 'issueCnt',
      width: 100,
      align: 'center',
      render: (issueCnt: String) => <>{!!issueCnt ? issueCnt : '-'}</>,
    },
    {
      title: '개체 식별 번호',
      dataIndex: 'cattleNo',
      key: 'cattleNo',
      width: 100,
      align: 'center',
      render: (cattleNo: String) => <>{!!cattleNo ? cattleNo : '-'}</>,
    },
    {
      title: '등지방 두께',
      dataIndex: 'backfat',
      key: 'backfat',
      width: 100,
      align: 'center',
      render: (backfat: String) => <>{!!backfat ? backfat : '-'}</>,
    },
    {
      title: '등심 단면적',
      dataIndex: 'rea',
      key: 'rea',
      width: 100,
      align: 'center',
      render: (rea: String) => <>{!!rea ? rea : '-'}</>,
    },
    {
      title: '육색',
      dataIndex: 'yuksak',
      key: 'yuksak',
      width: 100,
      align: 'center',
      render: (yuksak: String) => <>{!!yuksak ? yuksak : '-'}</>,
    },
    {
      title: '지방색',
      dataIndex: 'fatsak',
      key: 'fatsak',
      width: 100,
      align: 'center',
      render: (fatsak: String) => <>{!!fatsak ? fatsak : '-'}</>,
    },
    {
      title: '성숙도',
      dataIndex: 'growth',
      key: 'growth',
      width: 100,
      align: 'center',
      render: (growth: String) => <>{!!growth ? growth : '-'}</>,
    },
    {
      title: '조직감',
      dataIndex: 'tissue',
      key: 'tissue',
      width: 100,
      align: 'center',
      render: (tissue: String) => <>{!!tissue ? tissue : '-'}</>,
    },
    {
      title: '근내지방도',
      dataIndex: 'insfat',
      key: 'insfat',
      width: 100,
      align: 'center',
      render: (insfat: String) => <>{!!insfat ? insfat : '-'}</>,
    },
    {
      title: '출하월령',
      dataIndex: 'birthMonth',
      key: 'birthMonth',
      width: 100,
      align: 'center',
      render: (birthMonth: String) => <>{!!birthMonth ? birthMonth : '-'}</>,
    },
    {
      title: '경락가격',
      dataIndex: 'costAmt',
      key: 'costAmt',
      width: 100,
      align: 'center',
      render: (costAmt: String) => <>{!!costAmt ? costAmt : '-'}</>,
    },
  ];

  return (
    <Layout>
      <Row style={{ ...contentTitle }}>
        <Typography.Title level={5} style={{ margin: '0' }}>
          소도체 등급 판정 결과
        </Typography.Title>
      </Row>

      <Row style={{ padding: '1rem' }}>
        <Col xs={24}>
          <Card style={{ width: '100%' }}>
            <Row gutter={[20, 10]}>
              <Col>
                <Space>
                  <label style={{ width: '100px', display: 'inline-block' }}>확인서 번호</label>
                  <Input
                    style={{ width: '180px' }}
                    name="issueNo"
                    value={query.issueNo.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
              <Col>
                <Space>
                  <label style={{ width: '100px', display: 'inline-block' }}>확인서 발급 일자</label>
                  <Input
                    style={{ width: '180px' }}
                    name="issueDate"
                    value={query.issueDate.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
            </Row>

            <Col xs={24}>
              <Divider />
            </Col>

            <Row justify="center">
              <Space>
                <Button type="primary" htmlType="submit" onClick={() => handleSearch()}>
                  검색
                </Button>
              </Space>
            </Row>
          </Card>
        </Col>

        <Col xs={24} style={{ marginTop: '2rem' }}>
          <Table
            size="large"
            style={{ width: '100%' }}
            rowKey="issueNo"
            dataSource={page}
            columns={columns}
            scroll={{ x: 1400, y: 1000 }}
          />
        </Col>
      </Row>
    </Layout>
  );
};
