import axios from 'axios';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { EnableLoadingStore } from '../store/component';
import { authorizationState } from '../store/auth/login.store';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface AxiosProps<P> {
  url: string;
  method: 'get' | 'post' | 'put' | 'delete' | 'patch';
  params?: P;
  data?: P;
}

interface Response<P, R> {
  fetch: (data?: P) => Promise<R>;
  result?: R;
}

export const useAxios = <P, R>(props: AxiosProps<P>): Response<P, R> => {
  const http = axios.create({
    baseURL: `${process.env.REACT_APP_URL}`,
    withCredentials: true,
  });

  const navigate = useNavigate();

  const [result, setResult] = useState<R>();

  const setLoading = useSetRecoilState(EnableLoadingStore);
  const [authorization, setAuthorization] = useRecoilState(authorizationState);
  const invalidate = useResetRecoilState(authorizationState);
  const [reloadable, setReloadable] = useState<boolean>(false);

  const fetch = async (dataOrParams?: P) => {
    if (props.method === 'get' || props.method === 'delete') {
      if (!!dataOrParams) {
        const { data } = await http[props.method](props.url, { params: dataOrParams });
        setResult(data);

        return data;
      } else {
        const { data } = await http[props.method](props.url, { params: props.params });
        setResult(data);

        return data;
      }
    }

    if (props.method === 'patch' || props.method === 'post' || props.method === 'put') {
      if (!!dataOrParams) {
        const { data } = await http[props.method](props.url, dataOrParams);
        setResult(data);

        return data;
      } else {
        const { data } = await http[props.method](props.url, props.data);
        setResult(data);

        return data;
      }
    }
  };

  useEffect(() => {
    if (reloadable) {
      setReloadable(false);
      fetch();
    }
  }, [reloadable]);

  useEffect(() => {
    return () => setLoading(false);
  });

  http.interceptors.request.use(
    (config) => {
      if (!props.url.startsWith('/api/admin/login') && !!config.headers) {
        config.headers.Authorization = `${authorization.token}`;
      }

      setLoading(true);

      return config;
    },
    () => {},
  );

  http.interceptors.response.use(
    (response) => {
      setLoading(false);

      return response;
    },
    (error) => {
      if (!props.url.startsWith('/api/admin/login') && (error.response.status === 401 || error.response.status === 403)) {
        http
          .post('/api/login/refresh_token')
          .then(({ data }) => {
            setAuthorization({
              ...data,
              authenticated: true,
            });

            setReloadable(true);
          })
          .catch(() => {
            // 리프레시 토큰이 만료된 경우
            invalidate();
            setLoading(false);
            navigate('/login', { replace: true });
          });
        return;
      }

      setLoading(false);
      return Promise.reject(error);
    },
  );

  return { fetch: fetch, result: result };
};
