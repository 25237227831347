import * as yup from 'yup';

export interface NoticeQueryState {
  type: NoticeQueryType;
  query: string;
  page: number;
  size: number;
}

export type NoticeQueryType = 'ALL' | 'TITLE' | 'CONTENTS';
export enum NoticeQueryTypes {
  ALL = '모두',
  TITLE = '제목',
  CONTENTS = '내용',
}

export interface NoticeState {
  noticeId?: number;
  noticeType: NoticeType;
  noticeTitle: string;
  noticeContents: string;
  noticeTopFixed: boolean;
  noticeViewCount: number;
  noticeViewStartDate?: string;
  noticeViewEndDate?: string;
  enabled: boolean;
  createdAt?: string;
  createdBy?: number;
  updatedAt?: string;
  updatedBy?: number;
}

export const noticeStateSchema: yup.SchemaOf<NoticeState> = yup.object().shape({
  noticeId: yup.number(),
  noticeType: yup.mixed<NoticeType>().oneOf(['NORMAL', 'PRODUCT']).required(),
  noticeTitle: yup.string().required(),
  noticeContents: yup.string().trim().required(),
  noticeTopFixed: yup.boolean().required(),
  noticeViewEndDate: yup.string(),
  noticeViewStartDate: yup.string(),
  noticeViewCount: yup.number().required(),
  enabled: yup.boolean().required(),
  createdAt: yup.string(),
  createdBy: yup.number(),
  updatedAt: yup.string(),
  updatedBy: yup.number(),
});

export type NoticeType = 'NORMAL' | 'PRODUCT';
export enum NoticeTypes {
  NORMAL = '일반',
  PRODUCT = '상품',
}
