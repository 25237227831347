import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Input,
  Layout,
  message,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Table,
  Typography,
} from 'antd';
import { contentTitle } from '../../style/page';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { ColumnsType, TableRowSelection } from 'antd/lib/table/interface';
import { ProductInformationTemplateListQueryState, ProductInformationTemplateListState } from '../../store/product/product.state';
import 'moment/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import confirm from 'antd/es/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useAxios } from '../../hook/axios';
import { defaultPage, Page } from '../../store';

const initQuery: ProductInformationTemplateListQueryState = {
  startDate: undefined,
  endDate: undefined,
  templateName: undefined,
  enabled: undefined,
  page: 1,
  size: 10,
};

const initPage: Page<ProductInformationTemplateListState> = defaultPage as Page<ProductInformationTemplateListState>;

export const ProductInformationTemplatePage = (): JSX.Element => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [query, setQuery] = useState<ProductInformationTemplateListQueryState>({ ...initQuery });
  const [pageQuery, setPageQuery] = useState<ProductInformationTemplateListQueryState>({ ...initQuery }); // 페이지 전환할 때 필요한 기존 쿼리 기억

  const [page, setPage] = useState<Page<ProductInformationTemplateListState>>({ ...initPage });

  const { result, fetch: reload } = useAxios<ProductInformationTemplateListQueryState, Page<ProductInformationTemplateListState>>({
    params: query,
    url: '/api/admin/products/information-templates',
    method: 'get',
  });

  const { fetch: deleteFn } = useAxios<URLSearchParams, void>({
    url: '/api/admin/products/information-templates',
    method: 'delete',
  });

  useEffect(() => {
    setPageQuery({ ...query });
    reload();
  }, []);

  useEffect(() => {
    if (!!result) {
      setPage(result);
    } else {
      setPage({ ...initPage });
    }
  }, [result]);

  const columns: ColumnsType<ProductInformationTemplateListState> = [
    {
      title: '등록명',
      dataIndex: 'templateName',
      key: 'templateName',
      width: 150,
      align: 'center',
      ellipsis: true,
      render: (templateName: string, record: ProductInformationTemplateListState) => (
        <div style={{ textAlign: 'left' }}>
          <Button type="link" href={`/products/template_edit/${record.templateId}`}>
            {templateName}
          </Button>
        </div>
      ),
    },
    {
      title: '사용여부',
      dataIndex: 'enabled',
      key: 'enabled',
      width: 300,
      align: 'center',
      render: (enabled: boolean) => <>{enabled ? '사용' : '미사용'}</>,
    },
    {
      title: '작성자',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: 150,
      align: 'center',
    },
    {
      title: '작성일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      align: 'center',
    },
    {
      title: '수정자',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      width: 150,
      align: 'center',
    },
    { title: '수정일', dataIndex: 'updatedAt', key: 'updatedAt', width: 100, align: 'center' },
  ];

  const handlePagination = (page: number, pageSize: number) => {
    const newPageQuery: ProductInformationTemplateListQueryState = {
      ...pageQuery,
      ['page']: page,
      ['size']: pageSize,
    };
    // page만 전환
    setPageQuery(newPageQuery);
    reload(newPageQuery);
  };

  const handleSearch = () => {
    setPageQuery({ ...query, page: 1 });
    reload();
  };

  const handleReset = () => {
    setQuery({ ...initQuery });
    setPageQuery({ ...initQuery });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setQuery({ ...query, [name]: value });
  };

  const handleRangeDate = (rangeDate: RangeValue<Moment>) => {
    if (!!rangeDate && !!rangeDate[0] && !!rangeDate[1]) {
      setQuery({
        ...query,
        ['startDate']: rangeDate[0].format('YYYY-MM-DD HH:mm:ss'),
        ['endDate']: rangeDate[1].format('YYYY-MM-DD HH:mm:ss'),
      });
    } else {
      setQuery({
        ...query,
        ['startDate']: undefined,
        ['endDate']: undefined,
      });
    }
  };

  const rangeDateValue = (): RangeValue<Moment> | null => {
    if (!!query.startDate && !!query.endDate) {
      return [moment(query.startDate), moment(query.endDate)];
    }

    return null;
  };

  const handleRadio = (e: RadioChangeEvent) => {
    const { name, value } = e.target;
    setQuery({ ...query, [name as string]: value });
  };

  const handleRemove = () => {
    if (selectedRowKeys.length > 0) {
      confirm({
        title: '선택한 상품을 삭제하겠습니까?',
        icon: <ExclamationCircleOutlined />,
        okType: 'danger',
        okText: '예',
        cancelText: '아니오',
        type: 'error',
        onOk() {
          const urlSearchParams = new URLSearchParams();
          selectedRowKeys.forEach((param) => urlSearchParams.append('templateIds', String(param)));
          deleteFn(urlSearchParams)
            .then(() => {
              message.info('선택하신 상품이 삭제되었습니다.');
              reload();
            })
            .catch(() => {
              message.error('상품 삭제 처리 중 오류가 발생했습니다.');
            });
        },
        onCancel() {},
      });
    }
  };

  const rowSelection: TableRowSelection<ProductInformationTemplateListState> = {
    selectedRowKeys: selectedRowKeys,
    type: 'checkbox',
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  return (
    <Layout>
      <Row style={{ ...contentTitle }}>
        <Typography.Title level={5} style={{ margin: '0' }}>
          상품주요정보등록 리스트
        </Typography.Title>
      </Row>

      <Row style={{ padding: '1rem' }}>
        <Col xs={24}>
          <Card style={{ width: '100%' }}>
            <Row>
              <Col xs={24}>
                <Space>
                  <label style={{ width: '90px', display: 'inline-block' }}>등록일</label>
                  <Col>
                    <DatePicker.RangePicker
                      name="date"
                      locale={locale}
                      style={{ width: '308px' }}
                      defaultValue={rangeDateValue()}
                      value={rangeDateValue()}
                      onChange={handleRangeDate}
                    />
                  </Col>
                </Space>
                <Divider />
                <Col xs={24}>
                  <Space>
                    <label style={{ width: '90px', display: 'inline-block' }}>등록명</label>
                    <Input style={{ width: '160px' }} name="templateName" value={query.templateName} onChange={handleChange} />
                  </Space>
                </Col>
                <Divider />
                <Col xs={24}>
                  <Col xs={24}>
                    <label style={{ width: '90px', display: 'inline-block' }}>사용여부</label>
                    <Radio.Group name="enabled" defaultValue={query.enabled} value={query.enabled} onChange={handleRadio}>
                      <Radio value={undefined} defaultChecked>
                        전체
                      </Radio>
                      <Radio value={true}>사용</Radio>
                      <Radio value={false}>미사용</Radio>
                    </Radio.Group>
                  </Col>
                </Col>
              </Col>
            </Row>
            <Divider />

            <Row justify="center">
              <Space>
                <Button type="primary" htmlType="submit" onClick={() => handleSearch()}>
                  검색
                </Button>

                <Button type="default" onClick={() => handleReset()}>
                  초기화
                </Button>
              </Space>
            </Row>
          </Card>
        </Col>

        <Col xs={24} style={{ marginTop: '2rem' }}>
          <Row justify="space-between">
            <Button danger type="primary" onClick={() => handleRemove()}>
              선택 삭제
            </Button>
            <Button type="primary" href="/products/template_edit">
              상품 등록
            </Button>
          </Row>
        </Col>
        <Col xs={24} style={{ marginTop: '.5rem' }}>
          <Table
            rowKey="templateId"
            style={{ width: '2300px', overflow: 'hidden' }}
            rowSelection={rowSelection}
            dataSource={page.contents}
            columns={columns}
            scroll={{ x: 800, y: 600 }}
            pagination={{
              position: ['bottomCenter'],
              pageSize: pageQuery.size,
              defaultPageSize: query.size,
              current: pageQuery.page,
              defaultCurrent: query.page,
              total: page.totalElements,
              onChange: handlePagination,
            }}
          />
        </Col>
      </Row>
    </Layout>
  );
};
