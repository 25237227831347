import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Input, Layout, List, Row, Space, Table, Typography } from 'antd';
import { contentTitle } from '../../style/page';
import { useAxios } from '../../hook/axios';
import { ColumnsType } from 'antd/lib/table/interface';
import { Abattoir, AbattoirRequest } from '../../store/ext/abattoir';

const initQuery: AbattoirRequest = {
  kindCd: '023001',
  abattCd: '',
};

export const AbattoirSearch = (): JSX.Element => {
  const [query, setQuery] = useState<AbattoirRequest>({ ...initQuery });
  const [pageQuery, setPageQuery] = useState<AbattoirRequest>({ ...initQuery });

  const { result: page, fetch: listFetch } = useAxios<AbattoirRequest, Array<Abattoir>>({
    url: 'api/v1/ekape/abattoir',
    method: 'post',
    params: query,
  });
  const handleSearch = () => {
    setPageQuery({ ...query });
    listFetch(query);
  };

  const columns: ColumnsType<Abattoir> = [
    {
      title: '도매시장 권역 코드',
      dataIndex: 'auctionMarkgetCd',
      key: 'auctionMarkgetCd',
      width: 100,
      align: 'center',
      render: (auctionMarkgetCd: number) => <>{!!auctionMarkgetCd ? auctionMarkgetCd : '-'}</>,
    },
    {
      title: '도매시장 권역명',
      dataIndex: 'auctionMarkgetLocalNm',
      key: 'auctionMarkgetLocalNm',
      width: 100,
      align: 'center',
      render: (auctionMarkgetLocalNm: number) => <>{!!auctionMarkgetLocalNm ? auctionMarkgetLocalNm : '-'}</>,
    },
    {
      title: '작업장 코드',
      dataIndex: 'abattCode',
      key: 'abattCode',
      width: 100,
      align: 'center',
      render: (abattCode: number) => <>{!!abattCode ? abattCode : '-'}</>,
    },
    {
      title: '도매시장명',
      dataIndex: 'auctionMarkgetNm',
      key: 'auctionMarkgetNm',
      width: 100,
      align: 'center',
      render: (auctionMarkgetNm: number) => <>{!!auctionMarkgetNm ? auctionMarkgetNm : '-'}</>,
    },
    {
      title: '돈육 선물 거래 여부',
      dataIndex: 'futuresTradingYn',
      key: 'futuresTradingYn',
      width: 100,
      align: 'center',
      render: (futuresTradingYn: number) => <>{!!futuresTradingYn ? futuresTradingYn : '-'}</>,
    },
    {
      title: '부분육 경매 여부',
      dataIndex: 'cutmeatAuctYn',
      key: 'cutmeatAuctYn',
      width: 100,
      align: 'center',
      render: (cutmeatAuctYn: number) => <>{!!cutmeatAuctYn ? cutmeatAuctYn : '-'}</>,
    },
    {
      title: '실시간 소 경매 시작 시간',
      dataIndex: 'cowliveauctStartTime',
      key: 'cowliveauctStartTime',
      width: 100,
      align: 'center',
      render: (cowliveauctStartTime: number) => <>{!!cowliveauctStartTime ? cowliveauctStartTime : '-'}</>,
    },
    {
      title: '실시간 소 경매 시작 시간',
      dataIndex: 'cowliveauctEndTime',
      key: 'cowliveauctEndTime',
      width: 100,
      align: 'center',
      render: (cowliveauctEndTime: number) => <>{!!cowliveauctEndTime ? cowliveauctEndTime : '-'}</>,
    },
    {
      title: '실시간 돼지 경매 시작 시간',
      dataIndex: 'pigliveauctStartTime',
      key: 'pigliveauctStartTime',
      width: 100,
      align: 'center',
      render: (pigliveauctStartTime: number) => <>{!!pigliveauctStartTime ? pigliveauctStartTime : '-'}</>,
    },
    {
      title: '실시간 돼지 경매 종료 시간',
      dataIndex: 'pigliveauctEndTime',
      key: 'pigliveauctEndTime',
      width: 100,
      align: 'center',
      render: (pigliveauctEndTime: number) => <>{!!pigliveauctEndTime ? pigliveauctEndTime : '-'}</>,
    },
    {
      title: '도매시장 주소',
      dataIndex: 'marketAddr',
      key: 'marketAddr',
      width: 100,
      align: 'center',
      render: (marketAddr: number) => <>{!!marketAddr ? marketAddr : '-'}</>,
    },
    {
      title: '도매시장 전화번호',
      dataIndex: 'marketOfftelNo',
      key: 'marketOfftelNo',
      width: 100,
      align: 'center',
      render: (marketOfftelNo: number) => <>{!!marketOfftelNo ? marketOfftelNo : '-'}</>,
    },
    {
      title: '도매시장 팩스번호',
      dataIndex: 'marketOfffaxNo',
      key: 'marketOfffaxNo',
      width: 100,
      align: 'center',
      render: (marketOfffaxNo: number) => <>{!!marketOfffaxNo ? marketOfffaxNo : '-'}</>,
    },
    {
      title: '실시간 소 경매 여부',
      dataIndex: 'liveCowauctYn',
      key: 'liveCowauctYn',
      width: 100,
      align: 'center',
      render: (liveCowauctYn: number) => <>{!!liveCowauctYn ? liveCowauctYn : '-'}</>,
    },
    {
      title: '실시간 돼지 경매 여부',
      dataIndex: 'livePigauctYn',
      key: 'livePigauctYn',
      width: 100,
      align: 'center',
      render: (livePigauctYn: number) => <>{!!livePigauctYn ? livePigauctYn : '-'}</>,
    },
  ];

  return (
    <Layout>
      <Row style={{ ...contentTitle }}>
        <Typography.Title level={5} style={{ margin: '0' }}>
          소도체 도매시장 정보
        </Typography.Title>
      </Row>

      <Row style={{ padding: '1rem' }}>
        <Col xs={24}>
          <Card style={{ width: '100%' }}>
            <Row gutter={[20, 10]}>
              <Col>
                <Space>
                  <label style={{ width: '100px', display: 'inline-block' }}>축중 구분 코드</label>
                  <Input
                    style={{ width: '180px' }}
                    name="kindCd"
                    value={query.kindCd.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
              <Col>
                <Space>
                  <label style={{ width: '100px', display: 'inline-block' }}>도매시장 코드</label>
                  <Input
                    style={{ width: '180px' }}
                    name="abattCd"
                    value={query.abattCd.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
            </Row>

            <Col xs={24}>
              <Divider />
            </Col>

            <Row justify="center">
              <Space>
                <Button type="primary" htmlType="submit" onClick={() => handleSearch()}>
                  검색
                </Button>
              </Space>
            </Row>
          </Card>
        </Col>

        <Col xs={24} style={{ marginTop: '2rem' }}>
          <Table
            size="large"
            style={{ width: '100%' }}
            rowKey="abattoirId"
            dataSource={page}
            columns={columns}
            scroll={{ x: 1400, y: 500 }}
          />
        </Col>
      </Row>
    </Layout>
  );
};
