import { Col, Input, Modal, Row } from 'antd';
import React, { ChangeEvent, useEffect, useState } from 'react';
import Button from 'antd-button-color';
import { useAxios } from '../../hook/axios';
import { OrderSuspendKeyState, OrderSuspendState } from '../../store/order/order.delivery.state';

export interface OrderDeliverySuspendPopupProps {
  open: boolean;
  close: () => void;
  keys: OrderSuspendKeyState[];
  reloadFn: Function;
}

export const OrderDeliverySuspendPopup = (props: OrderDeliverySuspendPopupProps): JSX.Element => {
  const [suspendState, setSuspendState] = useState<OrderSuspendState>({ keys: [], orderSuspendContents: '' });

  const { fetch: suspend } = useAxios<OrderSuspendState, undefined>({
    url: '/api/admin/orders/dispatches/suspend',
    method: 'post',
  });

  useEffect(() => {
    setSuspendState({
      keys: props.keys,
      orderSuspendContents: '',
    });
  }, [props.open]);

  const save = () => {
    suspend(suspendState).then(() => {
      props.reloadFn();
      props.close();
    });
  };

  const close = () => {
    props.close();
  };

  const handleSuspendContent = (e: ChangeEvent<HTMLInputElement>) => {
    setSuspendState({
      ...suspendState,
      orderSuspendContents: e.target.value,
    });
  };

  return (
    <Modal title="발송대기" visible={props.open} footer={null} onCancel={props.close}>
      <div style={{ padding: '20px' }}>
        <Row justify="space-evenly" align="middle">
          <Col>
            <label style={{ width: '140px' }}>발송대기 사유</label>
          </Col>
          <Col>
            <Input style={{ width: '300px' }} value={suspendState.orderSuspendContents} onChange={handleSuspendContent} />
          </Col>
        </Row>
        <Row justify="space-evenly" style={{ marginTop: '40px' }}>
          <Col>
            <Button type="primary" size="middle" style={{ width: '150px' }} onClick={() => save()}>
              확인
            </Button>
          </Col>
          <Col>
            <Button type="default" size="middle" style={{ width: '150px' }} onClick={() => close()}>
              취소
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};
