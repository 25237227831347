export interface Page<T> {
  totalElements: number;
  contents: Array<T>;
}

export interface Query {
  page: number;
  size: number;
}

export interface Code {
  id: string;
  name: string;
}

export interface LabelValue {
  label: string;
  value: string;
}

export interface ImageState {
  imageId?: number;
  imageUrl: string;
  imagePath: string;
  imageName: string;
  imageType: 'NORMAL' | 'THUMBNAIL';
  contentType: string;
  enabled: boolean;
}

export type EnterType = 'PASSWORD' | 'FREEDOM' | 'ETC';

export const defaultPage: Page<unknown> = {
  totalElements: 0,
  contents: [],
};

export interface CodeObject {
  AgreementType: LabelValue[];
  ProductImageType: LabelValue[];
  DeliveryRangeType: LabelValue[];
  ProductExtendType: LabelValue[];
  DeliveryStatusType: LabelValue[];
  ProductOptionType: LabelValue[];
  OrderCancelStatusType: LabelValue[];
  OrderPaymentType: LabelValue[];
  SalesChannel: LabelValue[];
  DeliveryFeeType: LabelValue[];
  PaymentStatusType: LabelValue[];
  AmountUnitType: LabelValue[];
  DeliveryAttributeType: LabelValue[];
  ProductStatusType: LabelValue[];
  MadeType: LabelValue[];
  RecipeMaterialType: LabelValue[];
  EnterType: LabelValue[];
  DeliveryType: LabelValue[];
  SelectDateAttribute: LabelValue[];
  NoticeQueryType: LabelValue[];
  OriginType: LabelValue[];
  OrderDecideType: LabelValue[];
  ProductType: LabelValue[];
  CardCode: LabelValue[];
  BankCode: LabelValue[];
  InquiryType: LabelValue[];
  OrderStatusType: LabelValue[];
  DeliveryRegionZoneType: LabelValue[];
  SalesChannelType: LabelValue[];
  DeliveryPaymentType: LabelValue[];
  NoticeType: LabelValue[];
}
