import React, { useEffect, useState } from 'react';
import { Modal, Space } from 'antd';
import confirm from 'antd/es/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { TemplateSelect } from '../../../components/product/template.select';

export interface ProductInfoTemplateEditCopyTargetProps {
  open: boolean;
  onSelect: Function;
  onClose: Function;
}

export const ProductInfoTemplateEditCopyTarget = (props: ProductInfoTemplateEditCopyTargetProps): JSX.Element => {
  const [templateId, setTemplateId] = useState<number>();
  useEffect(() => {
    if (props.open) {
      setTemplateId(undefined);
    }
  }, [props.open]);

  return (
    <Modal
      visible={props.open}
      centered
      onOk={() => {
        confirm({
          title: '복사등록',
          content: (
            <span>
              이 주요정보로 선택하시겠습니까? <br />
              입력된 내용은 수정 가능합니다.
            </span>
          ),
          icon: <ExclamationCircleOutlined />,
          okType: 'danger',
          okText: '예',
          cancelText: '아니오',
          type: 'error',
          onOk() {
            props.onSelect(templateId);
            props.onClose();
          },
        });
      }}
      onCancel={() => props.onClose()}
      title="상품 주요 정보 선택"
      okText="선택"
      cancelText="취소"
      width={300}
    >
      <Space>
        <label style={{ width: '100px' }}>상품</label>
        <TemplateSelect style={{ width: '200px' }} templateId={templateId} setTemplateId={setTemplateId} />
      </Space>
    </Modal>
  );
};
