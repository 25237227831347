import { ImageState, Query } from '../index';
import { Category } from './category.state';
import * as yup from 'yup';

export enum ProductListRangeDateSearch {
  createdAt = '상품등록일',
  saleStartDate = '판매시작일',
  saleEndDate = '판매종료일',
  updatedAt = '최종수정일',
}

export type RangeDateSearchType = 'createdAt' | 'saleStartDate' | 'saleEndDate' | 'updatedAt';

export enum RangeSelect {
  YEAR = '1년',
  SIX_MONTH = '6개월',
  THREE_MONTH = '3개월',
  ONE_MONTH = '1개월',
  ONE_WEEK = '1주일',
}

export type RangeSelectType = 'YEAR' | 'SIX_MONTH' | 'THREE_MONTH' | 'ONE_MONTH' | 'ONE_WEAK';

export interface ProductListQueryState extends Query {
  productSku: string;
  productName: string;
  manufacturerName: string;
  modelName: string;
  brandName: string;
  productStatusTypes: Array<ProductStatusType>;
  enabledView?: boolean;
  enabledSale?: boolean;
  dateSearchTarget?: RangeDateSearchType;
  rangeSelectType?: RangeSelectType;
  startDate?: string;
  endDate?: string;
  categories: Array<number>;
  page: number;
  size: number;
}

export interface ProductListState {
  productId: number;
  productSku: string;
  productName: string;
  productNormalPrice: number;
  productDiscountPrice: number;
  productSalePrice: number;
  productStockQuantity: number;
  productStatusType: ProductStatusType;
  enabledView: boolean;
  enabledSale: boolean;
  brandName: string;
  modelName: string;
  manufacturerName: string;
  createdAt: string;
  updatedAt: string;
}

export interface ProductState {
  productId?: number;
  productName: string;
  productContents: string;
  productSku: string;
  productDescription: string;
  productStockQuantity: number;
  productType: 'SINGULAR' | 'COMPOSED';
  productStatusType: ProductStatusType;
  enabledSalePeriod: boolean;
  productSaleStartDate?: string;
  productSaleEndDate?: string;
  enabledView: boolean;
  enabledSale: boolean;
  enabledReservation: boolean;
  enabledOption: boolean;
  enabledOptionPrice: boolean;
  enabledAdditionProduct: boolean;
  enabledRelatedProduct: boolean;
  viewCount: number;
  enabled: boolean;

  categories: Array<Category>;
  productPricePolicy: ProductPricePolicyState;
  productInformation: ProductInformationState;
  productDelivery: ProductDeliveryState;
  productCustomerPolicy: ProductCustomerPolicyState;
  images: Array<ImageState>;
}

export interface ProductPricePolicyState {
  productId?: number;
  productNormalPrice: number;
  enabledDiscount: boolean;
  productDiscountType: 'PRICE' | 'RATE';
  productDiscountUnit: number;
  productDiscountPrice: number;
  enableDiscountPeriod: boolean;
  productDiscountStartDate?: string;
  productDiscountEndDate?: string;
  productSalePrice: number;
  productTaxType: 'NORMAL' | 'FREE';
  productTaxFreePrice: number;
  productPercent?: number; // 임의로 설정해둔 금액 할인율
}

export interface ProductInformationState {
  productId?: number;
  modelName: string;
  brandName?: string;
  manufacturerName: string;
  originType: 'DOMESTIC' | 'FOREIGN' | 'ETC';
  originMemo?: string;
  madeType?: 'NORMAL' | 'ORDER_MADE';
  productionDateType: 'CALENDAR' | 'INPUT';
  productionDate: string;
  expiryDateType: 'CALENDAR' | 'INPUT';
  expiryDate: string;
  weight: string;
  size: string;
  storageMethod: string;
  thickness?: string;
  purpose?: string;
  legalProductComposition: string;
  legalGrade: string; // 축산법에 따른 등급표시
  quantityPerPackage: string; // 포장 단위별 수량
  legalHistoryInfo: string;
  cautionInfo?: string; // 주의사항
}

export interface ProductDeliveryState {
  productId?: number;
  deliveryType: 'SELECT' | 'PARCEL' | 'DIRECT';
  deliveryMemo: string;
  deliveryAttributeType: 'NORMAL' | 'TODAY';
  deliveryCheckOrderMaking: boolean;
  deliveryDueDay: number;
  deliveryEnabledBundling: boolean;
  deliveryFeeType: 'FREE' | 'CONDITION_FREE' | 'PAID';
  deliveryFee: number;
  deliveryPaymentType: 'PRE_PAID' | 'POST_PAID' | 'SELECT_PAID';
  deliveryFreeTerms: number;
  deliveryEnabledAddedRegionPayment: boolean;
  deliveryRegionZoneType: 'SECOND' | 'THIRD';
  deliveryAddedSecondZoneFee: number;
  deliveryAddedThirdZoneFee: number;
  deliveryDiffInformation: string;
  deliveryReleaseZipcode: string;
  deliveryReleaseAddress: string;
  deliveryReleaseAddressDetail: string;
}

export interface ProductCustomerPolicyState {
  productId?: number;
  changeDeliveryCompany: string;
  returnDeliveryFee: number;
  exchangeDeliveryFee: number;
  changeZipcode: string;
  changeAddress: string;
  changeAddressDetail: string;
  customerContact: string;
  changeInformation: string;
}

// Deprecated
export enum ProductTypes {
  SINGULAR = '단일상품',
  COMPOSED = '세트상품',
}

export type ProductStatusType = 'READY' | 'SALE' | 'SOLD_OUT' | 'STOP' | 'CLOSE' | 'FORBID';
export enum ProductStatusTypes {
  READY = '판매대기',
  SALE = '판매중',
  SOLD_OUT = '품절',
  STOP = '판매중지',
  CLOSE = '판매종료',
  FORBID = '판매금지',
}

export interface ProductInformationTemplateListQueryState extends Query {
  startDate?: string;
  endDate?: string;
  templateName?: string;
  enabled?: boolean;
  page: number;
  size: number;
}

export interface ProductInformationTemplatePreset {
  templateId?: number;
  templateName: string;
}

export interface ProductInformationTemplateListState {
  templateId?: number;
  templateName?: string;
  enabled?: boolean;
  createdAt: string;
  createdBy: string;
  updatedBy: string;
  updatedAt: string;
}

export interface ProductInformationTemplateState {
  templateId?: number;
  templateName: string;
  modelName: string;
  manufacturerName: string;
  originType: 'DOMESTIC' | 'FOREIGN' | 'ETC';
  originMemo?: string;
  productionDateType: 'CALENDAR' | 'INPUT';
  productionDate: string;
  expiryDateType: 'CALENDAR' | 'INPUT';
  expiryDate: string;
  weight: string;
  size: string;
  quantityPerPackage: string;
  legalProductComposition: string;
  storageMethod: string;
  legalGrade: string;
  legalHistoryInfo: string;
  cautionInfo?: string;
  enabled: boolean;
}

export const productInformationTemplateStateSchema: yup.SchemaOf<ProductInformationTemplateState> = yup.object().shape({
  templateId: yup.number(),
  templateName: yup.string().required(),
  modelName: yup.string().required(),
  manufacturerName: yup.string().required(),
  originType: yup.mixed().oneOf(['DOMESTIC', 'FOREIGN', 'ETC']).required(),
  originMemo: yup.string(),
  productionDateType: yup.mixed().oneOf(['CALENDAR', 'INPUT']).required(),
  productionDate: yup.string().required(),
  expiryDateType: yup.mixed().oneOf(['CALENDAR', 'INPUT']).required(),
  expiryDate: yup.string().required(),
  weight: yup.string().required(),
  size: yup.string().required(),
  quantityPerPackage: yup.string().required(),
  legalProductComposition: yup.string().required(),
  storageMethod: yup.string().required(),
  legalGrade: yup.string().required(),
  legalHistoryInfo: yup.string().required(),
  cautionInfo: yup.string(),
  enabled: yup.boolean().required(),
});
