import { Col, Collapse, Input, message, Radio, Row, Select, Space } from 'antd';
import React, { useEffect } from 'react';
import { InputPrice, LabelInputPrice, RangeDatePicker } from '../../../components/LabelInputs';
import { ProductEditProps } from '../ProductEditPage';
import { RangeValue } from 'rc-picker/lib/interface';
import { Moment } from 'moment';

export const ProductPricePolicyFragment = (props: ProductEditProps): JSX.Element => {
  const [product, setProduct] = [props.product, props.setProduct];
  const productPricePolicy = product.productPricePolicy;

  // initialize
  productPricePolicy.productPercent = (productPricePolicy.productDiscountUnit / productPricePolicy.productNormalPrice) * 100;

  useEffect(() => {
    setProduct({
      ...product,
      productPricePolicy: {
        ...product.productPricePolicy,
        productSalePrice: productPricePolicy.productNormalPrice - productPricePolicy.productDiscountPrice,
      },
    });
  }, [productPricePolicy.productNormalPrice, productPricePolicy.productDiscountPrice]);

  useEffect(() => {
    if (productPricePolicy.productNormalPrice < productPricePolicy.productDiscountUnit) {
      message.error('할인가는 정상가보다 클 수 없습니다.');

      setProduct({
        ...product,
        productPricePolicy: {
          ...product.productPricePolicy,
          productDiscountUnit: productPricePolicy.productNormalPrice,
          productDiscountPrice: productPricePolicy.productNormalPrice,
          productSalePrice: 0,
        },
      });
      return;
    }

    if (productPricePolicy.productDiscountType === 'RATE' && productPricePolicy.productDiscountUnit > 100) {
      message.error('할인은 100%를 넘길 수 없습니다.');
      setProduct({
        ...product,
        productPricePolicy: {
          ...product.productPricePolicy,
          productDiscountPrice: productPricePolicy.productNormalPrice,
          productDiscountUnit: 100,
          productSalePrice: 0,
        },
      });
      return;
    }

    if (productPricePolicy.productDiscountUnit < 0) {
      message.error(`0${productPricePolicy.productDiscountType === 'RATE' ? '%' : '원'} 이하로 입력할 수 없습니다.`);
      setProduct({
        ...product,
        productPricePolicy: {
          ...product.productPricePolicy,
          productDiscountUnit: 0,
        },
      });
      return;
    }

    if (productPricePolicy.productDiscountType === 'PRICE') {
      setProduct({
        ...product,
        productPricePolicy: {
          ...product.productPricePolicy,
          productDiscountPrice: productPricePolicy.productDiscountUnit,
          productPercent: (productPricePolicy.productDiscountUnit / productPricePolicy.productNormalPrice) * 100,
        },
      });
      return;
    }

    if (productPricePolicy.productDiscountType === 'RATE') {
      setProduct({
        ...product,
        productPricePolicy: {
          ...product.productPricePolicy,
          productDiscountPrice: productPricePolicy.productNormalPrice * (productPricePolicy.productDiscountUnit / 100),
        },
      });
    }
  }, [productPricePolicy.productDiscountUnit, productPricePolicy.productDiscountType]);

  useEffect(() => {
    if (productPricePolicy.productTaxType === 'FREE') {
      setProduct({
        ...product,
        productPricePolicy: {
          ...product.productPricePolicy,
          productTaxFreePrice: productPricePolicy.productNormalPrice - productPricePolicy.productDiscountPrice,
        },
      });
      return;
    }
    if (productPricePolicy.productTaxType === 'NORMAL') {
      setProduct({
        ...product,
        productPricePolicy: {
          ...product.productPricePolicy,
          productTaxFreePrice: 0,
        },
      });
      return;
    }
  }, [productPricePolicy.productTaxType, productPricePolicy.productSalePrice]);

  const handleChange = (name: string, value: string | number) => {
    setProduct({
      ...product,
      productPricePolicy: {
        ...product.productPricePolicy,
        [name]: value,
      },
    });
  };

  const handleRangeDate = (rangeDate: RangeValue<Moment>) => {
    if (!!rangeDate && !!rangeDate[0] && !!rangeDate[1]) {
      setProduct({
        ...product,
        productPricePolicy: {
          ...product.productPricePolicy,
          productDiscountStartDate: rangeDate[0]!.format('YYYY-MM-DD HH:mm:ss'),
          productDiscountEndDate: rangeDate[1]!.format('YYYY-MM-DD HH:mm:ss'),
        },
      });
    }
  };

  return (
    <Collapse defaultActiveKey={['product_price_policy']} expandIconPosition="right">
      <Collapse.Panel key={'product_price_policy'} header="가격설정">
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <LabelInputPrice
              labelStyle={{ width: '160px', display: 'inline-block' }}
              inputStyle={{ width: '190px' }}
              labelText="정상가"
              name="productNormalPrice"
              value={product.productPricePolicy.productNormalPrice}
              changeFn={handleChange}
            />
          </Col>
          <Col xs={24}>
            <Space align="start">
              <label style={{ width: '160px', display: 'inline-block' }}>할인</label>
              <Row gutter={[0, 12]}>
                <Col xs={24}>
                  <Radio.Group
                    style={{ width: '200px' }}
                    name="enabledDiscount"
                    value={product.productPricePolicy.enabledDiscount}
                    onChange={({ target: { name, value } }) => handleChange(name!, value)}
                  >
                    <Radio value={true}>사용</Radio>
                    <Radio value={false}>미사용</Radio>
                  </Radio.Group>
                </Col>
                {product.productPricePolicy.enabledDiscount && (
                  <>
                    <Col xs={24}>
                      <Space>
                        <InputPrice
                          inputStyle={{ width: '190px' }}
                          type="number"
                          name="productDiscountUnit"
                          value={product.productPricePolicy.productDiscountUnit}
                          changeFn={handleChange}
                        />

                        <Select
                          style={{ width: '60px' }}
                          value={product.productPricePolicy.productDiscountType}
                          onChange={(value) => handleChange('productDiscountType', value)}
                        >
                          <Select.Option value="PRICE">원</Select.Option>
                          <Select.Option value="RATE">%</Select.Option>
                        </Select>
                      </Space>
                    </Col>
                    <Col xs={24}>
                      <Space>
                        <label style={{ width: '70px', display: 'inline-block' }}>기간할인</label>
                        <Radio.Group
                          name="enableDiscountPeriod"
                          value={product.productPricePolicy.enableDiscountPeriod}
                          onChange={({ target: { name, value } }) => handleChange(name!, value)}
                        >
                          <Radio value={true}>사용</Radio>
                          <Radio value={false}>미사용</Radio>
                        </Radio.Group>
                      </Space>
                    </Col>
                    <Col xs={24}>
                      <RangeDatePicker
                        names={['productDiscountStartDate', 'productDiscountEndDate']}
                        value={[product.productPricePolicy.productDiscountStartDate, product.productPricePolicy.productDiscountEndDate]}
                        disabled={!product.productPricePolicy.enableDiscountPeriod}
                        changeFn={handleRangeDate}
                      />
                    </Col>
                    <Space>
                      <Col xs={24}>
                        <LabelInputPrice
                          labelStyle={{ width: '70px', display: 'inline-block' }}
                          inputStyle={{ width: '180px' }}
                          name="productDiscountPrice"
                          value={product.productPricePolicy.productDiscountPrice}
                          readonly={true}
                          changeFn={handleChange}
                          labelText="총 할인가"
                        />
                      </Col>
                      {product.productPricePolicy.productDiscountType === 'PRICE' && (
                        <Space>
                          <Input
                            style={{ width: '60px' }}
                            name="productPercent"
                            onChange={({ target: { name, value } }) => handleChange(name, value)}
                            value={product.productPricePolicy.productPercent?.toFixed(2)}
                            readOnly={true}
                          />
                          <span>%</span>
                        </Space>
                      )}
                    </Space>
                  </>
                )}
              </Row>
            </Space>
          </Col>
          <Col xs={24}>
            <Space>
              <label style={{ width: '160px', display: 'inline-block' }}>판매가</label>
              <Input
                style={{ width: '190px' }}
                name="productSalePrice"
                value={product.productPricePolicy.productSalePrice}
                readOnly={true}
                onChange={({ target: { name, value } }) => handleChange(name, value)}
              />
            </Space>
          </Col>
          <Col xs={24}>
            <Space align="start">
              <label style={{ width: '160px', display: 'inline-block' }}>부가세</label>
              <Row gutter={[0, 12]}>
                <Col xs={24}>
                  <Radio.Group
                    name="productTaxType"
                    value={product.productPricePolicy.productTaxType}
                    onChange={({ target: { name, value } }) => handleChange(name!, value)}
                  >
                    <Radio value="NORMAL">일반상품</Radio>
                    <Radio value="FREE">면세상품</Radio>
                  </Radio.Group>
                </Col>
                {product.productPricePolicy.productTaxType === 'FREE' && (
                  <Col xs={24}>
                    <LabelInputPrice
                      labelStyle={{ width: '70px', display: 'inline-block' }}
                      inputStyle={{ width: '180px' }}
                      labelText="면세금액"
                      name="productTaxFreePrice"
                      value={product.productPricePolicy.productTaxFreePrice}
                      changeFn={handleChange}
                    />
                  </Col>
                )}
              </Row>
            </Space>
          </Col>
        </Row>
      </Collapse.Panel>
    </Collapse>
  );
};
