import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Input, Layout, List, Row, Space, Table, Typography } from 'antd';
import { contentTitle } from '../../style/page';
import { useAxios } from '../../hook/axios';
import { ColumnsType } from 'antd/lib/table/interface';
import { LiveAuctCattle, LiveAuctCattleRequest } from '../../store/ext/live.auct.cattle';

const date: string =
  new Date().getFullYear() + (new Date().getMonth() + 1).toString().padStart(2, '0') + new Date().getDate().toString().padStart(2, '0');

const initQuery: LiveAuctCattleRequest = {
  auctDate: date,
  abattCd: '',
  startNo: '',
  endNo: '',
  kindCd: '',
  sexCd: '',
  gradeCd: '',
};

export const LiveAuctCattleSearch = (): JSX.Element => {
  const [query, setQuery] = useState<LiveAuctCattleRequest>({ ...initQuery });
  const [pageQuery, setPageQuery] = useState<LiveAuctCattleRequest>({ ...initQuery });

  const { result: page, fetch: listFetch } = useAxios<LiveAuctCattleRequest, Array<LiveAuctCattle>>({
    url: 'api/v1/ekape/live/auction/cattle',
    method: 'post',
    params: query,
  });

  const columns: ColumnsType<LiveAuctCattle> = [
    {
      title: '도매시장 코드',
      dataIndex: 'abattCode',
      key: 'abattCode',
      width: 100,
      align: 'center',
      render: (abattCode: string) => <>{abattCode || '-'}</>,
    },
    {
      title: '경매 일자',
      dataIndex: 'abattDate',
      key: 'abattDate',
      width: 100,
      align: 'center',
      render: (abattDate: string) => <>{abattDate || '-'}</>,
    },
    {
      title: '도축시장명',
      dataIndex: 'abattNm',
      key: 'abattNm',
      width: 100,
      align: 'center',
      render: (abattNm: string) => <>{abattNm || '-'}</>,
    },
    {
      title: '도축번호',
      dataIndex: 'abattNo',
      key: 'abattNo',
      width: 100,
      align: 'center',
      render: (abattNo: string) => <>{abattNo || '-'}</>,
    },
    {
      title: '시간',
      dataIndex: 'auctTime',
      key: 'auctTime',
      width: 100,
      align: 'center',
      render: (auctTime: string) => <>{auctTime || '-'}</>,
    },
    {
      title: '경매일자',
      dataIndex: 'auctDate',
      key: 'auctDate',
      width: 100,
      align: 'center',
      render: (auctDate: string) => <>{auctDate || '-'}</>,
    },
    {
      title: '순번',
      dataIndex: 'auctRank',
      key: 'auctRank',
      width: 100,
      align: 'center',
      render: (auctRank: string) => <>{auctRank || '-'}</>,
    },
    {
      title: '그외',
      dataIndex: 'etc',
      key: 'etc',
      width: 100,
      align: 'center',
      render: (etc: string) => <>{etc || '-'}</>,
    },
    {
      title: '도축품종코드',
      dataIndex: 'judgeBreedCd',
      key: 'judgeBreedCd',
      width: 100,
      align: 'center',
      render: (judgeBreedCd: string) => <>{judgeBreedCd || '-'}</>,
    },
    {
      title: '도축품종이름',
      dataIndex: 'judgeBreedNm',
      key: 'judgeBreedNm',
      width: 100,
      align: 'center',
      render: (judgeBreedNm: string) => <>{judgeBreedNm || '-'}</>,
    },
    {
      title: '성별코드',
      dataIndex: 'judgeSexCd',
      key: 'judgeSexCd',
      width: 100,
      align: 'center',
      render: (judgeSexCd: string) => <>{judgeSexCd || '-'}</>,
    },
    {
      title: '도축성별이름',
      dataIndex: 'judgeSexNm',
      key: 'judgeSexNm',
      width: 100,
      align: 'center',
      render: (judgeSexNm: string) => <>{judgeSexNm || '-'}</>,
    },
    {
      title: '좌지육중량',
      dataIndex: 'leftWeight',
      key: 'leftWeight',
      width: 100,
      align: 'center',
      render: (leftWeight: number) => <>{leftWeight || '-'}</>,
    },
    {
      title: '우지육중량',
      dataIndex: 'rightWeight',
      key: 'rightWeight',
      width: 100,
      align: 'center',
      render: (rightWeight: number) => <>{rightWeight || '-'}</>,
    },
    {
      title: '도체중',
      dataIndex: 'weight',
      key: 'weight',
      width: 100,
      align: 'center',
      render: (weight: number) => <>{weight || '-'}</>,
    },
    {
      title: '등급코드',
      dataIndex: 'gradeCd',
      key: 'gradeCd',
      width: 100,
      align: 'center',
      render: (gradeCd: string) => <>{gradeCd || '-'}</>,
    },
    {
      title: '등급명',
      dataIndex: 'gradeNm',
      key: 'gradeNm',
      width: 100,
      align: 'center',
      render: (gradeNm: string) => <>{gradeNm || '-'}</>,
    },
    {
      title: '단가',
      dataIndex: 'auctAmt',
      key: 'auctAmt',
      width: 100,
      align: 'center',
      render: (auctAmt: string) => <>{auctAmt || '-'}</>,
    },
  ];
  const handleSearch = () => {
    setPageQuery({ ...initQuery });
    listFetch(query);
  };

  return (
    <Layout>
      <Row style={{ ...contentTitle }}>
        <Typography.Title level={5} style={{ margin: '0' }}>
          실시간 소도체 경매 현황 조회
        </Typography.Title>
      </Row>

      <Row style={{ padding: '1rem' }}>
        <Col xs={24}>
          <Card style={{ width: '100%' }}>
            <Row gutter={[20, 10]}>
              <Col>
                <Space>
                  <label style={{ width: '100px', display: 'inline-block' }}>경매 일자</label>
                  <Input
                    style={{ width: '180px' }}
                    name="auctDate"
                    value={query.auctDate.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
              <Col>
                <Space>
                  <label style={{ width: '100px', display: 'inline-block' }}>도매 시장 코드</label>
                  <Input
                    style={{ width: '180px' }}
                    name="abattCd"
                    value={query.abattCd.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
              <Col>
                <Space>
                  <label style={{ width: '100px', display: 'inline-block' }}>축종 코드</label>
                  <Input
                    style={{ width: '180px' }}
                    name="kindCd"
                    value={query.kindCd.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
            </Row>

            <Col>
              <Divider />
            </Col>
            <Row gutter={[20, 10]}>
              <Col>
                <Space>
                  <label style={{ width: '100px', display: 'inline-block' }}>도체 시작 번호</label>
                  <Input
                    style={{ width: '180px' }}
                    name="startNo"
                    value={query.startNo.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
              <Col>
                <Space>
                  <label style={{ width: '100px', display: 'inline-block' }}>도체 종료 번호</label>
                  <Input
                    style={{ width: '180px' }}
                    name="endNo"
                    value={query.endNo.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
              <Col>
                <Space>
                  <label style={{ width: '100px', display: 'inline-block' }}>성별 코드</label>
                  <Input
                    style={{ width: '180px' }}
                    name="sexCd"
                    value={query.sexCd.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
            </Row>

            <Col>
              <Divider />
            </Col>
            <Row gutter={[20, 10]}>
              <Col>
                <Space>
                  <label style={{ width: '100px', display: 'inline-block' }}>등급 코드</label>
                  <Input
                    style={{ width: '180px' }}
                    name="gradeCd"
                    value={query.gradeCd.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
            </Row>

            <Col>
              <Divider />
            </Col>

            <Row justify="center">
              <Space>
                <Button type="primary" htmlType="submit" onClick={() => handleSearch()}>
                  검색
                </Button>
              </Space>
            </Row>
          </Card>
        </Col>

        <Col xs={24} style={{ marginTop: '2rem' }}>
          <Table
            size="large"
            style={{ width: '100%' }}
            rowKey="liveAuctCattleId"
            dataSource={page}
            columns={columns}
            scroll={{ x: 1400, y: 700 }}
          />
        </Col>
      </Row>
    </Layout>
  );
};
