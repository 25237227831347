import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import React, { useEffect, useRef } from 'react';

interface ToastEditorProps {
  name: string;
  width?: string;
  height?: string;
  initialValue: string | undefined;
  onChange: (name: string, value: string) => void;
}

export const ToastEditor = (props: ToastEditorProps): JSX.Element => {
  const handleEditor = () => {
    props.onChange(props.name, getContents());
  };

  const editorRef = useRef(null);
  const height = !props.height ? '400px' : props.height;

  const getContents = (): string => {
    try {
      // @ts-ignore
      return editorRef.current.getInstance().getHTML();
    } catch {
      return '';
    }
  };

  useEffect(() => {
    if (!!editorRef && !!editorRef.current) {
      if (!!props && props.initialValue !== getContents()) {
        // @ts-ignore
        editorRef.current.getInstance().setHTML(props.initialValue);
      }
    }
  }, [props, editorRef]);

  return (
    <div style={{ width: props.width }}>
      <Editor
        toolbarItems={[
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol'],
          ['table', 'image', 'link'],
        ]}
        initialValue={props.initialValue}
        previewStyle="vertical"
        initialEditType="wysiwyg"
        height={height}
        onChange={handleEditor}
        useCommandShortcut={true}
        usageStatistics={false}
        hideModeSwitch={true}
        ref={editorRef}
        autofocus={false}
      />
    </div>
  );
};
