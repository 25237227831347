import { Role } from '../store/system/user.state';
import { useRecoilValue } from 'recoil';
import { authorizationState } from '../store/auth/login.store';
import { Navigate } from 'react-router-dom';

const compareRoles = (menuRoles: Array<Role>, userRoles: Array<Role>) => {
  return menuRoles.some((menuRole) => userRoles.some((userRole) => menuRole === userRole));
};

/*관리자 모두 허용하는 경우*/
export const AllAuthPage = ({ children }: { children: JSX.Element }): JSX.Element => {
  const authorization = useRecoilValue(authorizationState);
  if (!authorization.authenticated) {
    return <Navigate replace={true} to="/login" />;
  }

  if (compareRoles(['ROLE_ADMIN', 'ROLE_SUPER_USER'], authorization.roles)) {
    return children;
  }

  return <Navigate replace={true} to="/login" />;
};

/*최고 관리자만 허용하는 경우*/
export const SuperUserAuthPage = ({ children }: { children: JSX.Element }): JSX.Element => {
  const authorization = useRecoilValue(authorizationState);
  if (!authorization.authenticated) {
    return <Navigate replace={true} to="/login" />;
  }

  if (compareRoles(['ROLE_SUPER_USER'], authorization.roles)) {
    return children;
  }

  return <Navigate replace={true} to="/login" />;
};
