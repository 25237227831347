import { Col, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { Category, CategoryType } from '../store/product/category.state';
import { concat, orderBy } from 'lodash';
import { useAxios } from '../hook/axios';

interface CategoryComponentProps {
  name: string;
  value: Array<number> | undefined;
  onChange: (name: string, value: Array<number>) => void;
}

interface Option {
  label: string;
  value?: number | string | null;
  categoryType: CategoryType;
}

export const CategoryComponent = (props: CategoryComponentProps) => {
  const { result: categories, fetch } = useAxios<void, Array<Category>>({
    url: '/api/admin/products/categories',
    method: 'get',
  });

  const [largeCategories, setLargeCategories] = useState<Array<Option>>([]);
  const [middleCategories, setMiddleCategories] = useState<Array<Option>>([]);
  const [smallCategories, setSmallCategories] = useState<Array<Option>>([]);

  const [largeCategory, setLargeCategory] = useState<number | string | undefined>('');
  const [middleCategory, setMiddleCategory] = useState<number | string | undefined>('');
  const [smallCategory, setSmallCategory] = useState<number | string | undefined>('');

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (!!categories) {
      const filteredCategories = categories.map<Option>((category) => ({
        label: category.categoryName,
        value: category.categoryId,
        categoryType: category.categoryType,
      }));

      filteredCategories.unshift({
        label: '전체',
        value: '',
        categoryType: 'SMALL',
      });

      filteredCategories.unshift({
        label: '전체',
        value: '',
        categoryType: 'MIDDLE',
      });

      filteredCategories.unshift({
        label: '전체',
        value: '',
        categoryType: 'LARGE',
      });

      setLargeCategories(filteredCategories.filter((category) => category.categoryType === 'LARGE'));

      setMiddleCategories(filteredCategories.filter((category) => category.categoryType === 'MIDDLE'));

      setSmallCategories(filteredCategories.filter((category) => category.categoryType === 'SMALL'));
    } else {
      setLargeCategories([]);
      setMiddleCategories([]);
      setSmallCategories([]);
    }
  }, [categories]);

  useEffect(() => {
    let handleCategories = [];
    if (!!largeCategory) {
      handleCategories.push(largeCategory);
    }

    if (!!middleCategory) {
      handleCategories.push(middleCategory);
    }

    if (!!smallCategory) {
      handleCategories.push(smallCategory);
    }

    props.onChange(props.name, handleCategories as Array<number>);
  }, [largeCategory, middleCategory, smallCategory]);

  useEffect(() => {
    if (!!props.value && props.value.length === 0) {
      setLargeCategory('');
      setMiddleCategory('');
      setSmallCategory('');
    }
  }, [props.value]);

  return (
    <Row gutter={[32, 8]}>
      <Col>
        <label style={{ width: '80px', display: 'inline-block' }}>대분류</label>
        <Select
          style={{ width: '160px' }}
          options={largeCategories}
          value={largeCategory}
          defaultActiveFirstOption={true}
          onChange={(value) => setLargeCategory(value)}
        />
      </Col>
      <Col>
        <label style={{ width: '80px', display: 'inline-block' }}>중분류</label>
        <Select
          style={{ width: '160px' }}
          options={middleCategories}
          value={middleCategory}
          defaultActiveFirstOption={true}
          onChange={(value) => setMiddleCategory(value)}
        />
      </Col>
      <Col>
        <label style={{ width: '80px', display: 'inline-block' }}>소분류</label>
        <Select
          style={{ width: '160px' }}
          options={smallCategories}
          value={smallCategory}
          defaultActiveFirstOption={true}
          onChange={(value) => setSmallCategory(value)}
        />
      </Col>
    </Row>
  );
};

interface SelectCategoryProps {
  name: string;
  value: Array<Category> | undefined;
  onChange: (name: string, value: Array<number>) => void;
}

export const SelectCategory = (props: SelectCategoryProps) => {
  const { result: categories, fetch } = useAxios<void, Array<Category>>({
    url: '/api/admin/products/categories',
    method: 'get',
  });

  const [largeCategories, setLargeCategories] = useState<Array<Option>>([]);
  const [middleCategories, setMiddleCategories] = useState<Array<Option>>([]);
  const [smallCategories, setSmallCategories] = useState<Array<Option>>([]);

  const [largeCategory, setLargeCategory] = useState<number | undefined>();
  const [middleCategory, setMiddleCategory] = useState<number | undefined>();
  const [smallCategory, setSmallCategory] = useState<Array<number> | undefined>();

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (!!categories) {
      const filteredCategories = categories.map<Option>((category) => ({
        label: category.categoryName,
        value: category.categoryId,
        categoryType: category.categoryType,
      }));

      setLargeCategories(filteredCategories.filter((category) => category.categoryType === 'LARGE'));

      setMiddleCategories(filteredCategories.filter((category) => category.categoryType === 'MIDDLE'));

      setSmallCategories(filteredCategories.filter((category) => category.categoryType === 'SMALL'));
    } else {
      setLargeCategories([]);
      setMiddleCategories([]);
      setSmallCategories([]);
    }
  }, [categories]);

  useEffect(() => {
    if (!!props.value && props.value.length > 0) {
      const categories = orderBy(props.value, ['categoryId'], ['asc']);

      const large = categories.find((category) => largeCategories.some((item) => category.categoryId === item.value));
      setLargeCategory(large?.categoryId);

      const middle = categories.find((category) => middleCategories.some((item) => category.categoryId === item.value));
      setMiddleCategory(middle?.categoryId);

      const smalls = categories.filter((category) => smallCategories.some((item) => category.categoryId === item.value));
      // @ts-ignore
      setSmallCategory(smalls?.map((item) => item.categoryId));
    }
  }, [props.value]);

  const handleLargeCategory = (value: number) => {
    setLargeCategory(value);
    props.onChange(props.name, concat(value, middleCategory, smallCategory) as Array<number>);
  };

  const handleMiddleCategory = (value: number) => {
    setMiddleCategory(value);
    props.onChange(props.name, concat(largeCategory, value, smallCategory) as Array<number>);
  };

  const handleSmallCategory = (value: number[]) => {
    setSmallCategory(value);
    props.onChange(props.name, concat(largeCategory, middleCategory, value) as Array<number>);
  };

  return (
    <Row gutter={[16, 8]}>
      <Col>
        <Select
          style={{ width: '160px' }}
          options={largeCategories}
          value={largeCategory}
          placeholder="대분류"
          onChange={(value) => handleLargeCategory(value)}
        />
      </Col>
      <Col>
        <Select
          style={{ width: '160px' }}
          options={middleCategories}
          value={middleCategory}
          placeholder="중분류"
          onChange={(value) => handleMiddleCategory(value)}
        />
      </Col>
      <Col>
        <Select
          mode="multiple"
          style={{ width: '336px' }}
          options={smallCategories}
          value={smallCategory}
          placeholder="소분류"
          onChange={(value) => handleSmallCategory(value)}
        />
      </Col>
    </Row>
  );
};
