import { Col, Collapse, Row, Table } from 'antd';
import React from 'react';
import { OrderDetailTrackingState } from '../../../store/order/order.state';
import { ColumnsType } from 'antd/lib/table/interface';

interface OrderDetailTrackingComponentProps {
  orderDetailTracking: Array<OrderDetailTrackingState>;
}

export const OrderDetailTrackingComponent = (props: OrderDetailTrackingComponentProps): JSX.Element => {
  const orderDetailTracking = props.orderDetailTracking;

  const columns: ColumnsType<OrderDetailTrackingState> = [
    {
      title: '항목',
      dataIndex: 'orderStatusType',
      key: 'orderStatusType',
      width: 200,
      align: 'center',
    },
    { title: '내용', dataIndex: 'orderTrackingContents', key: 'orderTrackingContents', width: 200, align: 'center' },
    { title: '담당자', dataIndex: 'orderTrackingBy', key: 'orderTrackingBy', width: 200, align: 'center' },
    { title: '일시', dataIndex: 'orderTrackingAt', key: 'orderTrackingAt', width: 200, align: 'center' },
  ];

  return (
    <Collapse defaultActiveKey={['order_detail_tracking']} expandIconPosition="end" style={{ margin: '1rem 0' }}>
      <Collapse.Panel key={'order_detail_tracking'} header={'주문처리이력'}>
        <Row gutter={[8, 8]}>
          {orderDetailTracking.length > 0 ? (
            <Col xs={24}>
              <Table bordered={true} dataSource={orderDetailTracking} columns={columns} pagination={false} />
            </Col>
          ) : (
            <Col xs={24}>조회된 데이터가 없습니다.</Col>
          )}
        </Row>
      </Collapse.Panel>
    </Collapse>
  );
};
