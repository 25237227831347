import { Col, Divider, message, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAxios } from '../../hook/axios';
import { BulkOrderContactState } from '../../store/order/bulk.order.contact.state';
import confirm from 'antd/es/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export interface BulkOrderContactDetailPopupProps {
  open: boolean;
  close: () => void;
  reload: Function;
  contactId: number | undefined;
}
const initPopup: BulkOrderContactState = {
  bulkOrderContactId: undefined,
  bulkOrderContactName: '',
  bulkOrderContactPhoneNo: '',
  bulkOrderContactEmail: '',
  bulkOrderContactOrgName: '',
  bulkOrderContactContents: '',
  bulkOrderContactChecked: false,
  bulkOrderContactCheckedAt: '',
  bulkOrderContactCheckedBy: '',
  createdAt: '',
};

export const BulkOrderContactDetailPopup = (props: BulkOrderContactDetailPopupProps): JSX.Element => {
  const [bulkOrderContact, setBulkOrderContact] = useState<BulkOrderContactState>({ ...initPopup });

  const { result, fetch } = useAxios<void, BulkOrderContactState>({
    url: `/api/admin/bulk/orders/contact/${props.contactId}`,
    method: 'get',
  });

  const { fetch: checkContact } = useAxios<void, void>({
    url: `/api/admin/bulk/orders/contact/${props.contactId}`,
    method: 'put',
  });

  useEffect(() => {
    if (props.open && props.contactId) {
      fetch();
    } else {
      setBulkOrderContact({ ...initPopup });
    }
  }, [props.open]);

  useEffect(() => {
    if (!!result) {
      setBulkOrderContact({
        ...result,
      });
    } else {
      setBulkOrderContact({
        ...initPopup,
      });
    }
  }, [result]);

  const check = () => {
    confirm({
      title: '문의를 확인처리 하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: '예',
      cancelText: '아니오',
      type: 'error',
      onOk() {
        checkContact()
          .then(() => {
            message.success('확인처리에 성공하였습니다.');
            props.reload();
            props.close();
          })
          .catch(() => {
            message.error('확인처리에 실패하였습니다.');
          });
      },
    });
  };

  return (
    <Modal title="대량주문 문의상세" visible={props.open} onCancel={props.close} cancelText="닫기" okText="확인처리" onOk={check}>
      <div>
        <Row>
          <Col xs={24}>
            <Row>
              <Col xs={12}>
                <label style={{ display: 'inline-block' }}>문의자명 : {bulkOrderContact.bulkOrderContactName}</label>
              </Col>
              <Col xs={12}>
                <label style={{ display: 'inline-block' }}>연락처 : {bulkOrderContact.bulkOrderContactPhoneNo}</label>
              </Col>
            </Row>
          </Col>
          <Col xs={24}>
            <Divider />
          </Col>
          <Col xs={24}>
            <Row>
              <Col xs={12}>
                <label style={{ display: 'inline-block' }}>이메일 : {bulkOrderContact.bulkOrderContactEmail}</label>
              </Col>
              <Col xs={12}>
                <label style={{ display: 'inline-block' }}>문의자 기업단체명 : {bulkOrderContact.bulkOrderContactOrgName}</label>
              </Col>
            </Row>
          </Col>
          <Col xs={24}>
            <Divider />
          </Col>
          <Col xs={24}>
            <label style={{ display: 'inline-block' }}>문의 내용 : {bulkOrderContact.bulkOrderContactContents}</label>
          </Col>
          <Col xs={24}>
            <Divider />
          </Col>
          <Col xs={24}>
            <Row>
              <Col xs={12}>
                <label style={{ display: 'inline-block' }}>문의 작성일 : {bulkOrderContact.createdAt}</label>
              </Col>
              <Col xs={12}>
                <label style={{ display: 'inline-block' }}>
                  문의 확인여부 : {bulkOrderContact.bulkOrderContactChecked ? '확인' : '미확인'}
                </label>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};
