import { atom } from 'recoil';
import { CodeObject } from './index';

export const codeListState = atom<CodeObject>({
  key: 'codeListState',
  default: {
    AgreementType: [],
    ProductImageType: [],
    DeliveryRangeType: [],
    ProductExtendType: [],
    DeliveryStatusType: [],
    ProductOptionType: [],
    OrderCancelStatusType: [],
    OrderPaymentType: [],
    SalesChannel: [],
    DeliveryFeeType: [],
    PaymentStatusType: [],
    AmountUnitType: [],
    DeliveryAttributeType: [],
    ProductStatusType: [],
    MadeType: [],
    RecipeMaterialType: [],
    EnterType: [],
    DeliveryType: [],
    SelectDateAttribute: [],
    NoticeQueryType: [],
    OriginType: [],
    OrderDecideType: [],
    ProductType: [],
    CardCode: [],
    BankCode: [],
    InquiryType: [],
    OrderStatusType: [],
    DeliveryRegionZoneType: [],
    SalesChannelType: [],
    DeliveryPaymentType: [],
    NoticeType: [],
  },
});
