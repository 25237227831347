import { Button, message } from 'antd';
import React from 'react';
import { useAxios } from '../../hook/axios';
import confirm from 'antd/es/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface UserDeleteButtonProps {
  userIds: Array<React.Key>;
  reloadFn: Function;
}

export const UserDeleteButton = (props: UserDeleteButtonProps): JSX.Element => {
  const { fetch } = useAxios<URLSearchParams, void>({
    url: '/api/admin/users',
    method: 'delete',
  });

  const deleteConfirm = () => {
    if (props.userIds.length === 0) {
      message.error('선택된 사용자가 없습니다.');
      return;
    }

    confirm({
      title: '선택한 사용자를 삭제하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: '예',
      cancelText: '아니오',
      type: 'error',
      onOk() {
        const urlSearchParams = new URLSearchParams();
        props.userIds.forEach((param) => urlSearchParams.append('id', String(param)));

        fetch(urlSearchParams)
          .then(() => {
            message.success('선택된 사용자가 삭제되었습니다.');
            props.reloadFn();
          })
          .catch(() => {
            message.error('선택된 사용자 삭제에 실패하였습니다.');
          });
      },
    });
  };

  return (
    <Button danger type="primary" onClick={() => deleteConfirm()}>
      선택 삭제
    </Button>
  );
};
