import { Button, Card, Col, Input, Layout, message, Row, Select, Space, Table, Typography } from 'antd';
import { contentTitle } from '../../style/page';
import React, { useEffect, useState } from 'react';
import { NoticeQueryState, NoticeQueryTypes, NoticeState } from '../../store/system/notice.state';
import { ColumnsType, TableRowSelection } from 'antd/lib/table/interface';
import { convertCheckboxArray } from '../../utils/EnumUtils';
import { NoticeEditPopup, NoticeEditPopupProps } from './NoticeEditPopup';
import confirm from 'antd/es/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useAxios } from '../../hook/axios';
import { defaultPage, Page } from '../../store';

const initQuery: NoticeQueryState = {
  type: 'ALL',
  query: '',
  page: 1,
  size: 10,
};

const initPage: Page<NoticeState> = {
  ...defaultPage,
  contents: [],
};

export const NoticeListPage = (): JSX.Element => {
  const [query, setQuery] = useState(initQuery);
  const [pageQuery, setPageQuery] = useState(initQuery);
  const [page, setPage] = useState(initPage);
  const [urlSearchParam, setUrlSearchParam] = useState<URLSearchParams>();

  const { result, fetch } = useAxios<NoticeQueryState, Page<NoticeState>>({
    url: '/api/admin/notice',
    params: query,
    method: 'get',
  });

  const [editPopup, setEditPopup] = useState<NoticeEditPopupProps>({
    open: false,
    detail: undefined,
    close: () => setEditPopup({ ...editPopup, open: false, detail: undefined }),
    reloadFn: fetch,
  });

  const noticeQueryTypes = convertCheckboxArray(NoticeQueryTypes);

  useEffect(() => {
    if (!!result) {
      setPage(result);
    } else {
      setPage({ ...initPage });
    }
  }, [result]);

  const columns: ColumnsType<NoticeState> = [
    {
      title: '제목',
      dataIndex: 'noticeTitle',
      width: 100,
      ellipsis: true,
      render: (value, record) => (
        <Button type="link" onClick={() => setEditPopup({ ...editPopup, open: true, detail: record })}>
          {value}
        </Button>
      ),
    },
    {
      title: '고정공지여부',
      dataIndex: 'noticeTopFixed',
      width: 30,
      align: 'center',
      render: (value) => (value ? '예' : '아니오'),
    },
    {
      title: '노출 시작일',
      dataIndex: 'noticeViewStartDate',
      width: 50,
      align: 'center',
      ellipsis: true,
      render: (value) => (!!value ? value : '-'),
    },
    {
      title: '노출 종료일',
      dataIndex: 'noticeViewEndDate',
      ellipsis: true,
      align: 'center',
      width: 50,
      render: (value) => (!!value ? value : '-'),
    },
    {
      title: '사용여부',
      dataIndex: 'enabled',
      align: 'center',
      width: 30,
      render: (value) => (value ? '사용' : '미사용'),
    },
    {
      title: '생성자',
      dataIndex: 'createdBy',
      width: 30,
      align: 'center',
      ellipsis: true,
      render: (value) => (!!value ? value : '-'),
    },
    {
      title: '생성일',
      dataIndex: 'createdAt',
      width: 50,
      align: 'center',
      ellipsis: true,
      render: (value) => (!!value ? value : '-'),
    },
    {
      title: '수정자',
      dataIndex: 'updatedBy',
      width: 30,
      align: 'center',
      ellipsis: true,
      render: (value) => (!!value ? value : '-'),
    },
    {
      title: '수정일',
      dataIndex: 'updatedAt',
      width: 50,
      align: 'center',
      ellipsis: true,
      render: (value) => (!!value ? value : '-'),
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const rowSelection: TableRowSelection<NoticeState> = {
    selectedRowKeys: selectedRowKeys,
    type: 'checkbox',
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  useEffect(() => {
    setPageQuery({ ...query });
    fetch();
  }, []);

  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      const params = new URLSearchParams();
      for (let i = 0; i < selectedRowKeys.length; i++) {
        params.append('id', String(selectedRowKeys[i]));
      }
      setUrlSearchParam(params);
    }
  }, [selectedRowKeys]);

  const handleSearch = () => {
    setPageQuery({ ...query, page: 1 });
    fetch();
  };

  const handleReset = () => {
    setQuery({ ...initQuery });
    setPageQuery({ ...initQuery });
  };

  const handlePagination = (page: number, pageSize: number) => {
    const newePageQuery: NoticeQueryState = {
      ...query,
      ['page']: page,
      ['size']: pageSize,
    };
    // page만 변경
    setPageQuery(newePageQuery);
    fetch(newePageQuery);
  };

  const { fetch: deleteFn } = useAxios<URLSearchParams, undefined>({
    url: '/api/admin/notice',
    method: 'delete',
    params: urlSearchParam,
  });

  const deleteForSelected = () => {
    confirm({
      title: '선택된 공지사항을 삭제하시겠습니까?',
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: '예',
      cancelText: '아니오',
      type: 'error',
      onOk() {
        deleteFn()
          .then(() => {
            message.info('공지사항 삭제에 성공하였습니다.');
            fetch();
          })
          .catch(() => {
            message.error('공지사항 삭제에 실패하였습니다.');
          });
      },
    });
  };

  return (
    <Layout style={{ minWidth: '1200px' }}>
      <Row style={{ ...contentTitle }}>
        <Typography.Title level={5} style={{ margin: '0' }}>
          공지사항 관리
        </Typography.Title>
      </Row>

      <Row style={{ padding: '1rem' }}>
        <Col xs={24}>
          <Card style={{ width: '100%' }}>
            <Row>
              <Col xs={24}>
                <Row gutter={[12, 2]}>
                  <Col xs={24}>
                    <Row align="middle" gutter={[16, 0]}>
                      <Col>
                        <label style={{ display: 'inline-block' }}>조회</label>
                      </Col>
                      <Col>
                        <Select
                          options={noticeQueryTypes}
                          value={query.type}
                          onChange={(value) => setQuery({ ...query, ['type']: value })}
                        />
                      </Col>
                      <Col>
                        <Input
                          name="query"
                          value={query.query}
                          onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                          onPressEnter={() => fetch()}
                        />
                      </Col>
                      <Col>
                        <Space>
                          <Button type="primary" htmlType="submit" onClick={() => handleSearch()}>
                            검색
                          </Button>
                          <Button type="default" onClick={() => handleReset()}>
                            초기화
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={24} style={{ marginTop: '2rem' }}>
          <Row justify="space-between">
            <Button danger type="primary" onClick={() => deleteForSelected()}>
              선택삭제
            </Button>

            <Button type="primary" onClick={() => setEditPopup({ ...editPopup, open: true, detail: undefined })}>
              등록
            </Button>
          </Row>
        </Col>
        <Col xs={24} style={{ marginTop: '.5rem' }}>
          <Table
            rowKey="noticeId"
            rowSelection={rowSelection}
            dataSource={page.contents}
            columns={columns}
            pagination={{
              position: ['bottomCenter'],
              pageSize: pageQuery.size,
              defaultPageSize: query.size,
              current: pageQuery.page,
              defaultCurrent: query.page,
              total: page.totalElements,
              onChange: (page, pageSize) => handlePagination(page, pageSize),
            }}
          />
        </Col>
      </Row>
      <NoticeEditPopup {...editPopup} />
    </Layout>
  );
};
