import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import Grid from '@toast-ui/react-grid';
import { OptColumn, OptRow } from 'tui-grid/types/options';
import { GridEventProps, TuiGridEvent } from 'tui-grid/types/event';
import 'tui-grid/dist/tui-grid.css';

export interface DataGridProps {
  data: Array<any & OptRow>;
  columns: OptColumn[];
  onSetChange: Function;
}

export const DataGrid = (props: DataGridProps): JSX.Element => {
  const gridRef = React.useRef<Grid>(null);
  const [list, setList] = useState<Array<OptRow>>([]);

  useEffect(() => {
    return () => {
      gridRef.current!.getInstance().off('afterChange');
      gridRef.current!.getInstance().off('check');
      gridRef.current!.getInstance().off('uncheck');
      gridRef.current!.getInstance().off('checkAll');
      gridRef.current!.getInstance().off('uncheckAll');
      setList([]);
    };
  }, []);

  useEffect(() => {
    gridRef.current!.getInstance().off('afterChange');
    gridRef.current!.getInstance().off('check');
    gridRef.current!.getInstance().off('uncheck');
    gridRef.current!.getInstance().off('checkAll');
    gridRef.current!.getInstance().off('uncheckAll');

    if (!!props.data && props.data.length > 0) {
      gridRef.current!.getInstance().resetData(props.data);
      setList(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    gridRef.current!.getInstance().on('afterChange', onChange);
    gridRef.current!.getInstance().on('check', checked);
    gridRef.current!.getInstance().on('uncheck', uncheck);
    gridRef.current!.getInstance().on('checkAll', checkAll);
    gridRef.current!.getInstance().on('uncheckAll', uncheckAll);
  }, [list]);

  const checked = (gridEvent: TuiGridEvent) => {
    const grid = gridEvent as GridEventProps;

    const rowKey = grid.rowKey as number;
    const cloneList = cloneDeep(list);
    let item = cloneList[rowKey];

    item._attributes!.checked = true;

    cloneList.splice(rowKey, 1, item);
    props.onSetChange(cloneList);
  };

  const uncheck = (gridEvent: TuiGridEvent) => {
    const grid = gridEvent as GridEventProps;

    const rowKey = grid.rowKey as number;
    const cloneList = cloneDeep(list);
    let item = cloneList[rowKey];

    item._attributes!.checked = false;

    cloneList.splice(rowKey, 1, item);
    props.onSetChange(cloneList);
  };

  const checkAll = () => {
    const checkAllList = cloneDeep(list).map((item) => ({
      ...item,
      _attributes: {
        ...item._attributes,
        checked: true,
      },
    }));

    props.onSetChange(checkAllList);
  };

  const uncheckAll = () => {
    const checkAllList = cloneDeep(list).map((item) => ({
      ...item,
      _attributes: {
        ...item._attributes,
        checked: false,
      },
    }));

    props.onSetChange(checkAllList);
  };

  const onChange = (gridEvent: TuiGridEvent) => {
    const grid = gridEvent as GridEventProps;
    const items = cloneDeep(list) as Array<any>;

    if (items.length === 0) {
      return;
    }

    if (!!grid.changes) {
      for (let i = 0; i < grid.changes!.length; i++) {
        const change = grid.changes[i];
        const rowKey = change.rowKey as number;
        let item = cloneDeep(items[rowKey]) as any;

        item = {
          ...item,
          [change.columnName]: change.value,
        };

        items.splice(rowKey, 1, item);
      }
    }

    console.log(items);
    props.onSetChange(items);
  };

  return (
    <Grid
      ref={gridRef}
      data={props.data}
      columns={props.columns}
      style={{ width: '100%', overflowX: 'scroll' }}
      scrollX={true}
      rowHeight={25}
      bodyHeight={400}
      heightResizable={true}
      rowHeaders={['rowNum', 'checkbox']}
      usageStatistics={false}
    />
  );
};
