import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Input,
  Layout,
  message,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';
import { contentTitle } from '../../style/page';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { ColumnsType, TableRowSelection } from 'antd/lib/table/interface';
import {
  ProductListQueryState,
  ProductListRangeDateSearch,
  ProductListState,
  ProductState,
  ProductStatusTypes,
  RangeDateSearchType,
  RangeSelect,
  RangeSelectType,
} from '../../store/product/product.state';
import { convertCheckboxArray, convertCheckboxArrayWithAll, valueToLabel } from '../../utils/EnumUtils';
import 'moment/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { koreanWon } from '../../utils/StringUtils';
import { CategoryComponent } from '../../components/CategoryComponent';
import confirm from 'antd/es/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useAxios } from '../../hook/axios';
import { defaultPage, Page } from '../../store';

const initQuery: ProductListQueryState = {
  productSku: '',
  productName: '',
  manufacturerName: '',
  modelName: '',
  brandName: '',
  productStatusTypes: [],
  enabledView: undefined,
  enabledSale: undefined,
  dateSearchTarget: undefined,
  rangeSelectType: undefined,
  startDate: undefined,
  endDate: undefined,
  categories: [],
  page: 1,
  size: 10,
};

const initPage: Page<ProductListState> = defaultPage as Page<ProductListState>;

export const ProductListPage = (): JSX.Element => {
  const dateSelect = convertCheckboxArray(ProductListRangeDateSearch);
  const productStatusTypes = convertCheckboxArray(ProductStatusTypes);
  const rangeSelect = convertCheckboxArrayWithAll(RangeSelect);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [query, setQuery] = useState<ProductListQueryState>({ ...initQuery });
  const [pageQuery, setPageQuery] = useState<ProductListQueryState>({ ...initQuery }); // 페이지 전환할 때 필요한 기존 쿼리 기억

  const [page, setPage] = useState<Page<ProductListState>>({ ...initPage });

  const { result, fetch: reload } = useAxios<ProductListQueryState, Page<ProductListState>>({
    params: query,
    url: '/api/admin/products',
    method: 'get',
  });

  const { fetch: deleteFn } = useAxios<URLSearchParams, void>({
    url: '/api/admin/products',
    method: 'delete',
  });

  useEffect(() => {
    setPageQuery({ ...query });
    reload();
  }, []);

  useEffect(() => {
    if (!!result) {
      setPage(result);
    } else {
      setPage({ ...initPage });
    }
  }, [result]);

  const handleCategoriesChange = (name: string, value: Array<number>) => {
    setQuery({
      ...query,
      [name]: value,
    });
  };

  const columns: ColumnsType<ProductListState> = [
    { title: '상품코드', dataIndex: 'productSku', key: 'productSku', width: 150, align: 'center' },
    {
      title: '상품명',
      dataIndex: 'productName',
      key: 'productName',
      width: 300,
      align: 'center',
      ellipsis: true,
      render: (productName: string, record: ProductListState) => (
        <div style={{ textAlign: 'left' }}>
          <Button type="link" href={`/products/edit/${record.productId}`}>
            {productName}
          </Button>
        </div>
      ),
    },
    {
      title: '정상가',
      dataIndex: 'productNormalPrice',
      key: 'productNormalPrice',
      width: 150,
      align: 'center',
      render: (productNormalPrice) => koreanWon(productNormalPrice),
    },
    {
      title: '할인가',
      dataIndex: 'productDiscountPrice',
      key: 'productDiscountPrice',
      width: 150,
      align: 'center',
      render: (productDiscountPrice) => koreanWon(productDiscountPrice),
    },
    {
      title: '판매가',
      dataIndex: 'productSalePrice',
      key: 'productSalePrice',
      width: 150,
      align: 'center',
      render: (productSalePrice) => koreanWon(productSalePrice),
    },
    { title: '재고수', dataIndex: 'productStockQuantity', key: 'productStockQuantity', width: 100, align: 'center' },
    {
      title: '판매상태',
      dataIndex: 'productStatusType',
      key: 'productStatusType',
      width: 100,
      align: 'center',
      render: (productStatusType, value) =>
        value.productStockQuantity <= 0 && productStatusType === 'SALE'
          ? '판매중(품절)'
          : valueToLabel(ProductStatusTypes, productStatusType),
    },
    {
      title: '전시여부',
      dataIndex: 'enabledView',
      key: 'enabledView',
      width: 100,
      align: 'center',
      render: (enabledView: boolean) => <>{enabledView ? 'Y' : 'N'}</>,
    },
    {
      title: '판매여부',
      dataIndex: 'enabledSale',
      key: 'enabledSale',
      width: 100,
      align: 'center',
      render: (enabledSale: boolean) => <>{enabledSale ? 'Y' : 'N'}</>,
    },
    { title: '브랜드명', dataIndex: 'brandName', key: 'brandName', width: 150, align: 'center' },
    { title: '모델명', dataIndex: 'modelName', key: 'modelName', width: 400, align: 'center' },
    { title: '제조사', dataIndex: 'manufacturerName', key: 'manufacturerName', width: 200, align: 'center' },
    { title: '등록일', dataIndex: 'createdAt', key: 'createdAt', width: 200, align: 'center' },
    { title: '수정일', dataIndex: 'updatedAt', key: 'updatedAt', width: 200, align: 'center' },
  ];

  const handlePagination = (page: number, pageSize: number) => {
    const newPageQuery: ProductListQueryState = {
      ...pageQuery,
      ['page']: page,
      ['size']: pageSize,
    };
    // page만 전환
    setPageQuery(newPageQuery);
    reload(newPageQuery);
  };

  const handleSearch = () => {
    setPageQuery({ ...query, page: 1 });
    reload();
  };

  const handleReset = () => {
    setQuery({ ...initQuery });
    setPageQuery({ ...initQuery });
  };

  const handleSaleStatus = (e: any) => {
    setQuery({ ...query, productStatusTypes: e });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setQuery({ ...query, [name]: value });
  };

  const handleDateSearchType = (value: string) => {
    const data = value as RangeDateSearchType;
    setQuery({ ...query, ['dateSearchTarget']: data });
  };

  const handleSelectDate = (value: string) => {
    const today = moment();
    const otherDay = moment();

    let rangeDate = [moment(), moment()];
    switch (value) {
      case 'YEAR':
        rangeDate = [otherDay.add(-1, 'year'), today];
        break;
      case 'SIX_MONTH':
        rangeDate = [otherDay.add(-6, 'months'), today];
        break;
      case 'THREE_MONTH':
        rangeDate = [otherDay.add(-3, 'months'), today];
        break;
      case 'ONE_MONTH':
        rangeDate = [otherDay.add(-1, 'months'), today];
        break;
      case 'ONE_WEEK':
        rangeDate = [otherDay.add(-1, 'weeks'), today];
        break;
    }

    setQuery({
      ...query,
      rangeSelectType: value as RangeSelectType,
      ['startDate']: rangeDate[0].format('YYYY-MM-DD HH:mm:ss'),
      ['endDate']: rangeDate[1].format('YYYY-MM-DD HH:mm:ss'),
    });
  };

  const handleRangeDate = (rangeDate: RangeValue<Moment>) => {
    if (!!rangeDate && !!rangeDate[0] && !!rangeDate[1]) {
      setQuery({
        ...query,
        rangeSelectType: undefined,
        ['startDate']: rangeDate[0].format('YYYY-MM-DD HH:mm:ss'),
        ['endDate']: rangeDate[1].format('YYYY-MM-DD HH:mm:ss'),
      });
    } else {
      setQuery({
        ...query,
        rangeSelectType: undefined,
        ['startDate']: undefined,
        ['endDate']: undefined,
      });
    }
  };

  const rangeDateValue = (): RangeValue<Moment> | null => {
    if (!!query.startDate && !!query.endDate) {
      return [moment(query.startDate), moment(query.endDate)];
    }

    return null;
  };

  const handleRadio = (e: RadioChangeEvent) => {
    const { name, value } = e.target;
    setQuery({ ...query, [name as string]: value });
  };

  const handleRemove = () => {
    if (selectedRowKeys.length > 0) {
      confirm({
        title: '선택한 상품을 삭제하겠습니까?',
        icon: <ExclamationCircleOutlined />,
        okType: 'danger',
        okText: '예',
        cancelText: '아니오',
        type: 'error',
        onOk() {
          const urlSearchParams = new URLSearchParams();
          selectedRowKeys.forEach((param) => urlSearchParams.append('productIds', String(param)));
          deleteFn(urlSearchParams)
            .then(() => {
              message.info('선택하신 상품이 삭제되었습니다.');
              reload();
            })
            .catch(() => {
              message.error('상품 삭제 처리 중 오류가 발생했습니다.');
            });
        },
        onCancel() {},
      });
    }
  };

  const rowSelection: TableRowSelection<ProductListState> = {
    selectedRowKeys: selectedRowKeys,
    type: 'checkbox',
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  return (
    <Layout>
      <Row style={{ ...contentTitle }}>
        <Typography.Title level={5} style={{ margin: '0' }}>
          상품 리스트
        </Typography.Title>
      </Row>

      <Row style={{ padding: '1rem' }}>
        <Col xs={24}>
          <Card style={{ width: '100%' }}>
            <Row>
              <Col xs={24}>
                <Row gutter={[12, 2]}>
                  <Col xs={24}>
                    <Space>
                      <label style={{ width: '140px', display: 'inline-block' }}>카테고리</label>
                      <CategoryComponent value={query.categories} name="categories" onChange={handleCategoriesChange} />
                    </Space>
                  </Col>

                  <Col xs={24}>
                    <Divider />
                  </Col>

                  <Col xs={24}>
                    <Space align="start">
                      <label style={{ width: '140px', display: 'inline-block' }}>조건</label>
                      <Row gutter={[32, 8]}>
                        <Col>
                          <label style={{ width: '80px', display: 'inline-block' }}>상품코드</label>
                          <Input style={{ width: '160px' }} name="productSku" value={query.productSku} onChange={handleChange} />
                        </Col>
                        <Col>
                          <label style={{ width: '80px', display: 'inline-block' }}>상품명</label>
                          <Input style={{ width: '160px' }} name="productName" value={query.productName} onChange={handleChange} />
                        </Col>
                        <Col>
                          <label style={{ width: '80px', display: 'inline-block' }}>제조사명</label>
                          <Input
                            style={{ width: '160px' }}
                            name="manufacturerName"
                            value={query.manufacturerName}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col>
                          <label style={{ width: '80px', display: 'inline-block' }}>모델명</label>
                          <Input style={{ width: '160px' }} name="modelName" value={query.modelName} onChange={handleChange} />
                        </Col>
                        <Col>
                          <label style={{ width: '80px', display: 'inline-block' }}>브랜드명</label>
                          <Input style={{ width: '160px' }} name="brandName" value={query.brandName} onChange={handleChange} />
                        </Col>
                      </Row>
                    </Space>
                  </Col>
                </Row>
              </Col>

              <Col xs={24}>
                <Divider />
              </Col>

              <Col xs={24}>
                <Space align="start">
                  <label style={{ width: '140px', display: 'inline-block' }}>상품상태조건</label>
                  <Row gutter={[32, 16]}>
                    <Col xs={24}>
                      <label style={{ width: '90px', display: 'inline-block' }}>상품판매상태</label>
                      <Checkbox.Group
                        name="productStatusTypes"
                        options={productStatusTypes}
                        defaultValue={query.productStatusTypes}
                        value={query.productStatusTypes}
                        onChange={handleSaleStatus}
                      />
                    </Col>
                    <Col xs={24}>
                      <label style={{ width: '90px', display: 'inline-block' }}>전시여부</label>
                      <Radio.Group name="enabledView" defaultValue={query.enabledSale} value={query.enabledView} onChange={handleRadio}>
                        <Radio value={undefined} defaultChecked>
                          전체
                        </Radio>
                        <Radio value={true}>전시</Radio>
                        <Radio value={false}>미전시</Radio>
                      </Radio.Group>
                    </Col>
                    {/* <Col xs={24}>
                      <label style={{ width: '90px', display: 'inline-block' }}>판매여부</label>
                      <Radio.Group name="enabledSale" defaultValue={query.enabledSale} value={query.enabledSale} onChange={handleRadio}>
                        <Radio value={undefined} defaultChecked>
                          전체
                        </Radio>
                        <Radio value={true}>판매중</Radio>
                        <Radio value={false}>미판매</Radio>
                      </Radio.Group>
                    </Col> */}
                  </Row>
                </Space>
              </Col>

              <Col xs={24}>
                <Divider />
              </Col>

              <Col xs={24}>
                <Space>
                  <label style={{ width: '140px', display: 'inline-block' }}>기간검색</label>
                  <Row gutter={[8, 0]}>
                    <Col>
                      <Select
                        options={dateSelect}
                        style={{ width: '150px' }}
                        defaultValue={query.dateSearchTarget}
                        value={query.dateSearchTarget}
                        onChange={handleDateSearchType}
                      />
                    </Col>
                    <Col>
                      <Select
                        options={rangeSelect}
                        style={{ width: '150px' }}
                        defaultValue={query.rangeSelectType}
                        value={query.rangeSelectType}
                        onChange={handleSelectDate}
                      />
                    </Col>
                    <Col>
                      <DatePicker.RangePicker
                        name="date"
                        locale={locale}
                        style={{ width: '308px' }}
                        defaultValue={rangeDateValue()}
                        value={rangeDateValue()}
                        onChange={handleRangeDate}
                      />
                    </Col>
                  </Row>
                </Space>
              </Col>

              <Col xs={24}>
                <Divider />
              </Col>
            </Row>

            <Row justify="center">
              <Space>
                <Button type="primary" htmlType="submit" onClick={() => handleSearch()}>
                  검색
                </Button>

                <Button type="default" onClick={() => handleReset()}>
                  초기화
                </Button>
              </Space>
            </Row>
          </Card>
        </Col>

        <Col xs={24} style={{ marginTop: '2rem' }}>
          <Row justify="space-between">
            <Button danger type="primary" onClick={() => handleRemove()}>
              선택 삭제
            </Button>
            <Button type="primary" href="/products/edit">
              상품 등록
            </Button>
          </Row>
        </Col>
        <Col xs={24} style={{ marginTop: '.5rem' }}>
          <Table
            rowKey="productId"
            style={{ width: '2300px', overflow: 'hidden' }}
            rowSelection={rowSelection}
            dataSource={page.contents}
            columns={columns}
            scroll={{ x: 800, y: 600 }}
            pagination={{
              position: ['bottomCenter'],
              pageSize: pageQuery.size,
              defaultPageSize: query.size,
              current: pageQuery.page,
              defaultCurrent: query.page,
              total: page.totalElements,
              onChange: handlePagination,
            }}
          />
        </Col>
      </Row>
    </Layout>
  );
};
