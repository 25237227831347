import { CSSProperties } from 'react';

export const fullSize: CSSProperties = {
  width: '100%',
  height: '100%',
};

export const contentTitle: CSSProperties = {
  padding: '1rem',
  backgroundColor: 'white',
  borderWidth: '1px 0',
  borderColor: 'rgba(224, 224, 224, 1)',
  borderStyle: 'solid',
};
