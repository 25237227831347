import { Query } from '../index';

export interface OrderListQueryState extends Query {
  categories: Array<number>;
  paymentCompletedStartDate: string;
  paymentCompletedEndDate: string;
  orderCheckedStartDate: string;
  orderCheckedEndDate: string;
  deliveryTrackingCreatedStartDate: string;
  deliveryTrackingCreatedEndDate: string;
  orderNo: string;
  purchaserName: string;
  purchaserEmail: string;
  destRecipientName: string;
  destRecipientCellphoneNo: string;
  productSku: string;
  deliveryTrackingNo: string;
  orderStatusType?: '' | OrderStatusType;
  deliveryStatusType?: '' | 'SEND_SUSPEND' | 'SEND_READY' | 'DELIVERY_PROGRESS' | 'DELIVERY_COMPLETED';
  paymentStatusType?: '' | 'READY' | 'REQUEST_PAYMENT' | 'COMPLETED_PAYMENT' | 'REQUEST_REFUND' | 'REFUND';
  orderCancelStatusType?: '' | 'REQUEST_CANCEL_ORDER' | 'CONFIRM_CANCEL_ORDER' | 'COMPLETE_CANCEL_ORDER';
}

export interface OrderListState {
  orderId: number;
  orderNo: string;
  createdAt: string;
  orderStatusType: OrderStatusType;
  purchaserName: string;
  purchaserEmail: string;
  productName: string;
  orderTotalQuantity: number;
  orderCheckedAt: string;
  deliveryTrackingCreatedAt: string;
  paymentStatusType: string;
  destRecipientName: string;
  destRecipientCellphoneNo: string;
}

export interface OrderState {
  orderId?: number;
  orderNo: string;
  orderStatusType: OrderStatusType;
  salesChannelType: 'MODAM' | 'NAVER';
  salesChannelOrderNo: string;
  orderNormalAmount: number;
  orderDiscountAmount: number;
  orderDeliveryAmount: number;
  orderTotalQuantity: number;
  orderAmount: number;
  orderTaxFreeAmount: number;
  orderGrandTotal: number;
  orderMileage: number;
  purchaserUserId: number;
  purchaserName: string;
  purchaserPhoneNo: string;
  purchaserCellphoneNo: string;
  purchaserEmail: string;
  purchaserZipcode: string;
  purchaserAddress: string;
  purchaserAddressDetail: string;
  orderChecked: boolean;
  orderCheckUserId: number;
  orderCheckedAt: string;
  purchaseDecided: boolean;
  purchaseDecidedAt: string;
  purchaseDecidedBY: string;
  orderCancelled: boolean;
  orderCancelReason: string;
  orderCancelRequestAt: string;
  orderCancelCheckBy: number;
  orderCancelCheckAt: string;
  orderCancelSettledBy: number;
  orderCancelSettledAt: string;
  enabled: boolean;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deleted: boolean;
}

export interface OrderDetailState {
  orderId: number;
  orderNo: string;
  createdAt: string; // 주문일
  orderCheckedAt: string; // 주문확인일
  purchaseDecidedAt: string; // 구매 확정일
  orderStatusType: string;
  deliveryStatusType: string;
  orderCancelStatusType: string;
  paymentStatusType: string;
  purchaserName: string;
  purchaserEmail: string;
  purchaserCellphoneNo: string;
  orderAmount: number;
  paidAmount: number;
  refundAmount: number;
  orderDiscountAmount: number;
  orderDeliveryAmount: number;
  orderGrandTotal: number;
  orderTotalQuantity: number;
  orderCancelReason: string;
  orderProducts: Array<OrderDetailProductState>;
  orderDeliveries: Array<OrderDetailDeliveryState>;
  orderDestinations: Array<OrderDetailDestinationState>;
  orderTracking: Array<OrderDetailTrackingState>;
  orderMemos: Array<OrderDetailMemoState>;
  orderPayments: Array<OrderDetailPaymentState>;
  orderDeliveryFeeInfos: Array<OrderDeliveryFeeInfo>;
  orderPaymentTracking: Array<OrderPaymentTrackingState>;
}

export interface OrderDetailProductState {
  productName: string;
  productNormalPrice: number;
  productDiscountPrice: number;
  productSalePrice: number;
  productQuantity: number;
}

export interface OrderDetailDeliveryState {
  deliveryStatusType: string;
  deliveryType: string;
  deliveryFee: number;
  deliveryTrackingCreatedAt: string;
  deliveryCompanyName: string;
  deliveryTrackingNo: string;
}

export interface OrderDeliveryFeeInfo {
  orderId: number; // 주문아이디
  orderDeliveryFeeInfoSeq: number;
  deliveryFee: number; // 배송비
  reason: string; // 계산근거
}

export interface OrderDetailDestinationState {
  destRecipientName: string;
  destRecipientCellphoneNo: string;
  destZipcode: string;
  destAddress: string;
  destAddressDetail: string;
  destEnterType: string; // 출입방법
  destEnterTypeMemo: string; // 출입방법 상세
  deliveryRequestMemo: string; // 배송요청사항
}

export interface OrderDetailTrackingState {
  orderStatusType: string;
  orderTrackingContents: string;
  orderTrackingBy: string;
  orderTrackingAt: string;
}

export interface OrderDetailMemoState {
  orderChargerMemoContents: string;
  orderChargerMemoCreatedBy: string;
  orderChargerMemoCreatedAt: string;
  createdBy: string;
  createdAt: string;
}

export interface OrderDetailPaymentState {
  paymentStatusType: string;
  orderPaymentType: string;
  paymentAmount: number;
  approvalDate: string;
}

export interface OrderPaymentTrackingState {
  orderPaymentType: string;
  orderPaymentSeq: number;
  orderPaymentTrackingSeq: number;
  paymentStatusType: string;
  orderPaymentTrackingContents: string;
  orderPaymentTrackingCreatedAt: string;
  orderPaymentTrackingCreatedBy: string;
}

export enum OrderStatusType {
  UNAVAILABLE = 'UNAVAILABLE',
  READY = 'READY',
  ORDER_PROCESSING = 'ORDER_PROCESSING',
  REQUEST_PAYMENT = 'REQUEST_PAYMENT',
  COMPLETED_PAYMENT = 'COMPLETED_PAYMENT',
  CHECK_ORDER = 'CHECK_ORDER',
  DELIVERY_PROGRESS = 'DELIVERY_PROGRESS',
  DELIVERY_COMPLETED = 'DELIVERY_COMPLETED',
  PURCHASE_DECIDED = 'PURCHASE_DECIDED',
  REQUEST_CANCEL_ORDER = 'REQUEST_CANCEL_ORDER',
  COMPLETE_CANCEL_ORDER = 'COMPLETED_CANCEL_ORDER',
}
