export const convertBooleanToString = (data: boolean | undefined): string => {
  if (!data) {
    return 'false';
  }

  return String(data);
};

export const koreanWon = (data: number): string => {
  return data?.toLocaleString('ko-KR');
};
