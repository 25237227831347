import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Input, Layout, List, Row, Space, Table, Typography } from 'antd';
import { contentTitle } from '../../style/page';
import { useAxios } from '../../hook/axios';
import { ColumnsType } from 'antd/lib/table/interface';
import { CattlePart, CattlePartRequest } from '../../store/ext/cattle.part';

const date: string =
  new Date().getFullYear() +
  '-' +
  (new Date().getMonth() + 1).toString().padStart(2, '0') +
  '-' +
  (new Date().getDate() - 1).toString().padStart(2, '0');

const initQuery: CattlePartRequest = {
  issueNo: '',
  issueDate: date,
  weight: '',
};

export const CattlePartSearch = (): JSX.Element => {
  const [query, setQuery] = useState<CattlePartRequest>({ ...initQuery });
  const [pageQuery, setPageQuery] = useState<CattlePartRequest>({ ...initQuery });

  const { result: page, fetch: listFetch } = useAxios<CattlePartRequest, Array<CattlePart>>({
    url: 'api/v1/ekape/confirm/cattle/part',
    method: 'post',
    params: query,
  });

  const handleSearch = () => {
    console.log(listFetch(query));
    setPageQuery({ ...query });
    listFetch(query);
  };

  const columns: ColumnsType<CattlePart> = [
    {
      title: '부위 구분',
      dataIndex: 'meatType',
      key: 'meatType',
      width: 100,
      align: 'center',
      render: (meatType: string) => <>{!!meatType ? meatType : '-'}</>,
    },
    {
      title: '부위 코드',
      dataIndex: 'cutmeatCode',
      key: 'cutmeatCode',
      width: 100,
      align: 'center',
      render: (cutmeatCode: string) => <>{!!cutmeatCode ? cutmeatCode : '정보없음'}</>,
    },
    {
      title: '부위명',
      dataIndex: 'cutmeatName',
      key: 'cutmeatName',
      width: 100,
      align: 'center',
      render: (cutmeatName: string) => <>{!!cutmeatName ? cutmeatName : '정보없음'}</>,
    },
    {
      title: '부위중량',
      dataIndex: 'cutmeatWeight',
      key: 'cutmeatWeight',
      width: 100,
      align: 'center',
      render: (cutmeatWeight: string) => <>{!!cutmeatWeight ? cutmeatWeight : '정보없음'}</>,
    },
    {
      title: '비율',
      dataIndex: 'cutmeatRate',
      key: 'cutmeatRate',
      width: 100,
      align: 'center',
      render: (cutmeatRate: string) => <>{!!cutmeatRate ? cutmeatRate : '정보없음'}</>,
    },
  ];

  return (
    <Layout>
      <Row style={{ ...contentTitle }}>
        <Typography.Title level={5} style={{ margin: '0' }}>
          소 등급판정 부위별 고기 및 부산물 정보
        </Typography.Title>
      </Row>

      <Row style={{ padding: '1rem' }}>
        <Col xs={24}>
          <Card style={{ width: '100%' }}>
            <Row gutter={[20, 10]}>
              <Col>
                <Space>
                  <label style={{ width: '60px', display: 'inline-block' }}>발급 번호</label>
                  <Input
                    style={{ width: '180px' }}
                    name="issueNo"
                    value={query.issueNo.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
              <Col>
                <Space>
                  <label style={{ width: '60px', display: 'inline-block' }}>발급 일자</label>
                  <Input
                    style={{ width: '180px' }}
                    name="issueDate"
                    value={query.issueDate.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
              <Col>
                <Space>
                  <label style={{ width: '40px', display: 'inline-block' }}>도체중</label>
                  <Input
                    style={{ width: '180px' }}
                    name="weight"
                    value={query.weight.toString()}
                    onChange={({ target: { name, value } }) => setQuery({ ...query, [name]: value })}
                  />
                </Space>
              </Col>
            </Row>

            <Col xs={24}>
              <Divider />
            </Col>

            <Row justify="center">
              <Space>
                <Button type="primary" htmlType="submit" onClick={() => handleSearch()}>
                  검색
                </Button>
              </Space>
            </Row>
          </Card>
        </Col>

        <Col xs={24} style={{ marginTop: '2rem' }}>
          <Table
            size="large"
            style={{ width: '100%' }}
            rowKey="cattlePartId"
            dataSource={page}
            columns={columns}
            scroll={{ x: 1400, y: 700 }}
          />
        </Col>
      </Row>
    </Layout>
  );
};
